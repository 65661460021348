import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch10, fetch2, fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  loading: false,
  discountListCount: 0,
  addDiscountCount: 0,
  discountDetailCount: 0,
  editDiscountCount: 0,
  discountBranchCount: 0,
  discountTagCount: 0,
  deleteDiscountCount: false,
  loadingStatus: 0,
  discountTableList: [],
  singleDiscountDetails: {},
  discountTableMeta: {},
  isFiltered: false,
  pageCount: 0,
};

export let discountFilterValue: any = {
  type: '',
};
export let discountDetailPerId: any;
export let discountListItems: any;
export let discountTagListItems: any;

export let discountsFilter: any = {
  name: null,
  reference: null,
  updated_after: null,
  qualification: null,
  is_percentage: null,
  is_taxable: null,
  is_deleted: null,
};

export const discountTable: any = createAsyncThunk('discountTable', async (body: any) => {
  try {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/discounts`, body);
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const discountList: any = createAsyncThunk('discountList', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/discount_list_filter_search_type`, body);
  discountListItems = result.data;
  return result;
});

export const addDiscount: any = createAsyncThunk('addDiscount', async body => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/discounts`, body);
    return result;
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
          notify(error?.response?.data?.errors[field][0]),
        )
      : notify(error?.response?.data?.message);
  }
});

export const discountDetails: any = createAsyncThunk('discountDetails', async (body: any) => {
  try {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/discounts/${body}`);
    return result.data;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const editDiscount: any = createAsyncThunk('editDiscount', async (body: any) => {
  try {
    const result = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/discounts/${body.discount_id}`, body);
    return result;
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
          notify(error?.response?.data?.errors[field][0]),
        )
      : notify(error?.response?.data?.message);
  }
});

export const deleteDiscount: any = createAsyncThunk('deleteDiscount', async body => {
  const result = await fetch10(`${process.env.REACT_APP_BASEURL}/manage/discounts/${body}`);
  return result;
});

export const discountOnBranch: any = createAsyncThunk('discountOnBranch', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/manage/discountOnbranch`, body);
  return result;
});

export const discountOnTag: any = createAsyncThunk('discountOnTag', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/discountOntag`, body);
  return result;
});

export const restoreDiscount: any = createAsyncThunk('restoreDiscount', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/discounts/${body}/restore`, body);
  return result;
});

export const editDiscountCardsDetails: any = createAsyncThunk(
  'discounts/editDiscountCardsDetails',
  async (body: any) => {
    try {
      const result = await fetch9(`${process.env.REACT_APP_BASEURL}/core-api/updating`, body);
      return result;
    } catch (error: any) {
      return notify(error?.response?.data?.message);
    }
  },
);

const manageDiscountSlice: any = createSlice({
  name: 'manageDiscount',
  initialState,

  reducers: {
    deleteBoolean(state = initialState, action) {
      state.deleteDiscountCount = false;
    },
    setshowLoading(state = initialState) {
      //   state.loadingStatus += 1;
    },
    discountFilterData(state = initialState, action) {
      state.isFiltered = true;
      discountsFilter.name = action.payload.name || null;
      discountsFilter.qualification = action.payload.qualification;
      discountsFilter.is_percentage = action.payload.is_percentage;
      discountsFilter.is_taxable = action.payload.is_taxable;
      discountsFilter.reference = action.payload.reference || null;
      discountsFilter.updated_after = action.payload.updated_after !== undefined ? action.payload.updated_after : null;
      discountsFilter.is_deleted = action.payload.is_deleted;
    },
    discountResetData(state = initialState) {
      state.isFiltered = false;
      discountsFilter.name = null;
      discountsFilter.qualification = null;
      discountsFilter.is_percentage = null;
      discountsFilter.is_taxable = null;
      discountsFilter.reference = null;
      discountsFilter.updated_after = null;
      discountsFilter.is_deleted = false;
    },
    setPageCount(state = initialState, action) {
      state.pageCount = action.payload;
    },
  },
  extraReducers: {
    [discountTable.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.discountTableList = payload.data;
      state.discountTableMeta = payload.meta;
      if (payload?.error) {
        state.error = payload.error;
        notify(payload?.error?.response?.data?.message);
      } else {
        state.error = payload?.message;
      }
    },
    [discountTable.pending]: (state: any) => {
      state.loading = true;
    },
    [discountTable.rejected]: (state: any) => {
      state.loading = false;
    },
    [discountList.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.discountListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [addDiscount.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      // state.addDiscountCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify('Dicount added successfully', 'success');
        state.error = message;
      }
    },
    [discountDetails.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.singleDiscountDetails = payload;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [editDiscount.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.editDiscountCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify('Discount updated successfully', 'success');
        state.error = message;
      }
    },
    [discountOnBranch.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.discountBranchCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, 'success');
        else notify(message);
        state.error = message;
      }
    },
    [discountOnTag.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.discountTagCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, 'success');
        else notify(message);
        state.error = message;
      }
    },
    [deleteDiscount.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.deleteDiscountCount = true;
      if (error) {
        state.error = error;
      } else {
        notify('Discount deleted successfully', 'success');
        state.error = message;
      }
    },
    [restoreDiscount.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.deleteDiscountCount = true;
      if (error) {
        state.error = error;
      } else {
        notify('Discount restored successfully', 'success');
        state.error = message;
      }
    },
    [editDiscountCardsDetails.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      // state.deleteDiscountCount = true;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, 'success');
        else notify(message);
        state.error = message;
      }
    },
  },
});

export const { deleteBoolean, setshowLoading, discountFilterData, discountResetData, setPageCount } =
  manageDiscountSlice.actions;
export default manageDiscountSlice.reducer;
