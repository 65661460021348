// Redux Toolkit
import { createSlice, createAsyncThunk, AnyAction } from "@reduxjs/toolkit";
// Axios for making API requests
import {
  fetch11,
  fetch5,
  fetch10,
  fetch6,
  fetch9,
} from "../../Apis/commonApis";
import { notify } from "../../constants/notifyconstants";

export let createNewTransferTDataT: any;

const initialState = {
  getAllTransfersData: null,
  inventorytransfersshowData: null,
  createNewTransferTData: null,
  inventoryTransferBranchesIdT: null,
  wareHouseForTransfersDataT: null,
  array1: null,
  loading: false,
  onetimeLoading1: false,
  error: null,
  transferCreationData: {
    source: null,
    destination: null,
  },
  isFiltered: false,
  transferFilter: {
    status: null,
    type: null,
    receiving_due_date: null,
    reference: null,
    business_date: null,
    updated_after: null,
    branch: null,
    creator: null,
    poster: null,
  },
};

// Async thunk action creator
export const getAllTransfersList: any = createAsyncThunk(
  "inventoryTransferOrderSlice",
  async (body: any) => {
    try {
      const response = await fetch6(
        `${process.env.REACT_APP_BASEURL}/inventory/transfers`,
        body
      );
      return response;
    } catch (error: any) {
      return notify(error?.response?.data?.message);
    }
  }
);

export const inventorytransfersshow: any = createAsyncThunk(
  "inventorytransfersshow",
  async (id) => {
    const response = await fetch6(
      `${process.env.REACT_APP_BASEURL}/inventory/transfers/${id}`
    );
    console.log(response, "responseresponse");
    // getAllTransferOrdersData = response.data;
    return response;
  }
);
export const acceptTransfer: any = createAsyncThunk(
  "acceptTransfer",
  async (id) => {
    const response = await fetch11(
      `${process.env.REACT_APP_BASEURL}/transfer-orders/acceptTransfer/${id}`
    );
    notify("Accept Transfer Successfully ", "success");

    console.log(response, "responseresponse");
    // getAllTransferOrdersData = response.data;
    return response.data;
  }
);
export const rejectTR: any = createAsyncThunk("rejectTR", async (body: any) => {
  const response = await fetch9(
    `${process.env.REACT_APP_BASEURL}/inventory/transfers/${body.transferId}`,
    body.data
  );
  notify("Reject Transfer Successfully   ", "success");

  console.log(response, "responseresponse");
  // getAllTransferOrdersData = response.data;
  return response;
});
export const delinceTransfer: any = createAsyncThunk(
  "delinceTransfer",
  async (id) => {
    const response = await fetch11(
      `${process.env.REACT_APP_BASEURL}/inventory/transfer-orders/declineTransfer/${id}`
    );
    notify("Declined Transfer Successfully   ", "success");

    console.log(response, "responseresponse");
    // getAllTransferOrdersData = response.data;
    return response.data;
  }
);
export const receiveTransfer: any = createAsyncThunk(
  "receiveTransfer",
  async (body: any) => {
    const response = await fetch9(
      `${process.env.REACT_APP_BASEURL}/inventory/transfers/${body.transferId}`,
      body.data
    );
    notify("Receive Transfer Successfully  ", "success");

    console.log(response, "responseresponse");
    // getAllTransferOrdersData = response.data;
    return response;
  }
);
export const delOneItemT: any = createAsyncThunk("delOneItemT", async (id) => {
  const response = await fetch6(
    `${process.env.REACT_APP_BASEURL}/inventory/transfers/delete_item/${id}`
  );
  notify("Deleted Transfer successfully ", "success");

  console.log(response, "responseresponse");
  // getAllTransferOrdersData = response.data;
  return response.data;
});
export const editTransferReceiving: any = createAsyncThunk(
  "editTransferReceiving",
  async ({ body, id }: any) => {
    const response = await fetch9(
      `${process.env.REACT_APP_BASEURL}/inventory/transfers/${id}`,
      body
    );

    console.log(response, "responseresponse");
    // getAllTransferOrdersData = response.data;
    return response.data;
  }
);
export const inventorytransfersupdate: any = createAsyncThunk(
  "inventorytransfersupdate",
  async (body: any) => {
    try {
      const response = await fetch9(
        `${process.env.REACT_APP_BASEURL}/inventory/transfers/${body.transferId}`,
        body.data
      );
      notify("inventory transfers update   ", "success");
      return response.data;
    } catch (error: any) {
      return Object.keys(error?.response?.data?.errors)?.length > 0
        ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
          notify(error?.response?.data?.errors[field][0])
        )
        : notify(error?.response?.data?.message);
    }
  }
);
export const sendTransfer: any = createAsyncThunk(
  "transfers/sendTransfer",
  async (body: any) => {
    const response = await fetch9(
      `${process.env.REACT_APP_BASEURL}/inventory/transfers/${body.id}`,
      body.data
    );
    notify("Send Transfer successfully ", "success");

    console.log(response, "responseresponse");
    // getAllTransferOrdersData = response.data;
    return response.data;
  }
);
export const transferordersupdate_item: any = createAsyncThunk(
  "transferordersupdate_item",
  async ({ body, id }: any) => {
    const response = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/transfer-orders/update_item/${id}`,
      body
    );
    notify("transferordersupdate_item  ", "success");

    console.log(response, "responseresponse");
    // getAllTransferOrdersData = response.data;
    return response.data;
  }
);
export const transferordersupdateOneitem: any = createAsyncThunk(
  "transferordersupdateOneitem",
  async ({ body, id }: any) => {
    const response = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/transfer-orders/update_one_item/${id}`,
      body
    );
    notify("transferordersupdate_item  ", "success");

    console.log(response, "responseresponse");
    // getAllTransferOrdersData = response.data;
    return response.data;
  }
);
export const createNewTransferT: any = createAsyncThunk(
  "createNewTransferT",
  async (body) => {
    const response = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/transfers`,
      body
    );
    console.log(response, "responseresponse");
    createNewTransferTDataT = response.data;
    notify("created New Transfer ", "success");
    // getAllTransferOrdersData = response.data;
    return response.data;
  }
);
export const updatetransfersendingoneitem: any = createAsyncThunk(
  "updatetransfersendingoneitem",
  async ({ body, id }: any) => {
    const response = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/transfers/update_transfer_sending_one_item/${id}`,
      body
    );
    console.log(response, "responseresponse");
    // if (response.data) {

    // }
    // getNewransferOrdersData = response.data
    notify("update transfer item Qty", "success");
    // getAllTransferOrdersData = response.data;
    return response.data;
  }
);
export const updateAllQtyT: any = createAsyncThunk(
  "updateAllQty",
  async ({ body, id, type }: any) => {
    if (type == "TR") {
      const response = await fetch5(
        `${process.env.REACT_APP_BASEURL}/inventory/transfers/update_transfer_receiving_item/${id}`,
        body
      );
      notify("update transfer item Qty", "success");
      return response.data;
    } else {
      const response = await fetch5(
        `${process.env.REACT_APP_BASEURL}/inventory/transfers/update_transfer_sending_item/${id}`,
        body
      );
      notify("update transfer item Qty", "success");
      return response.data;
    }
  }
);

export const updateOneQtyTransfer: any = createAsyncThunk(
  "transfers/updateOneQty",
  async (body: any) => {
    try {
      const response = await fetch5(
        `${process.env.REACT_APP_BASEURL}/inventory/transfers/${body.transferId}/update_item/${body.itemId}`,
        body.data
      );
      return response.data;
    } catch (error: any) {
      return Object.keys(error?.response?.data?.errors)?.length > 0
        ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
          notify(error?.response?.data?.errors[field][0])
        )
        : notify(error?.response?.data?.message);
    }
  }
);

export const permanentDeleteTransfer: any = createAsyncThunk(
  "transfers/permenantDeleteTransfer",
  async (id) => {
    const response = await fetch10(
      `${process.env.REACT_APP_BASEURL}/inventory/transfers/${id}`
    );
    console.log(response, "responseresponse");
    // getAllTransferOrdersData = response.data;
    notify("Deleted Transfer Order Successfully !", "success");

    return response.data;
  }
);

export const delItemTransferById: any = createAsyncThunk(
  "transfers/delItemTransferById",
  async (body: any) => {
    const response = await fetch10(
      `${process.env.REACT_APP_BASEURL}/inventory/transfers/${body.transferId}/update_item/${body.itemId}`
    );

    // getAllTransferOrdersData = response.data;
    notify("Deleted item Successfully !", "success");

    return response.data;
  }
);

export const updateItemsForTransfers: any = createAsyncThunk(
  "transfers/updateItemsForTransfers",
  async (body: any) => {
    const response = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/transfers/add_items/${body.id}`,
      body.data
    );
    console.log(response, "responseresponse");
    // getAllTransferOrdersData = response.data;
    notify("Add items Successfully", "success");

    return response.data;
  }
);

export const duplicateTransfer: any = createAsyncThunk(
  "duplicateTransfer",
  async (id: any) => {
    try {
      const response = await fetch6(
        `${process.env.REACT_APP_BASEURL}/inventory/transfers/duplicate/${id}`
      );
      return response.data;
    } catch (error: any) {
      return Object.keys(error?.response?.data?.errors)?.length > 0
        ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
          notify(error?.response?.data?.errors[field][0])
        )
        : notify(error?.response?.data?.message);
    }
  }
);

const inventoryTransferSlice: any = createSlice({
  name: "inventoryTransferSlice",
  initialState,

  reducers: {
    setTransferFilterObject: (state, action) => {
      state.isFiltered = true;
      state.transferFilter.status = action.payload.status
        ? action.payload.status
        : null;
      state.transferFilter.type = action.payload.type
        ? action.payload.type
        : null;
      state.transferFilter.reference = action.payload.reference
        ? action.payload.reference
        : null;
      state.transferFilter.business_date = action.payload.business_date
        ? action.payload.business_date
        : null;
      state.transferFilter.updated_after = action.payload.updated_after
        ? action.payload.updated_after
        : null;
      state.transferFilter.branch = action.payload.branch
        ? action.payload.branch
        : null;
      state.transferFilter.receiving_due_date = action.payload
        .receiving_due_date
        ? action.payload.receiving_due_date
        : null;
      state.transferFilter.creator = action.payload.creator
        ? action.payload.creator
        : null;
      state.transferFilter.poster = action.payload.poster
        ? action.payload.poster
        : null;
    },
    resetTransferFilterObject: (state, action) => {
      state.transferFilter = initialState.transferFilter;
      state.isFiltered = false;
    },
    changeIsFiltered: (state, action) => {
      state.isFiltered = action.payload;
    },
    saveTransferBranchIdT: (state, action) => {
      state.inventoryTransferBranchesIdT = action.payload;
    },
    saveTransferWareHouseIdT: (state, action) => {
      state.wareHouseForTransfersDataT = action.payload;
    },
    savearray1: (state, action) => {
      state.array1 = action.payload;
    },
    onetimeLoading: (state, action) => {
      state.onetimeLoading1 = action.payload;
    },
    saveTransferCreationData: (state, action) => {
      state.transferCreationData = action.payload;
    },
    resetTransferCreationData: (state, action) => {
      state.transferCreationData = {
        source: null,
        destination: null,
      };
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(getAllTransfersList.fulfilled, (state: any, action: any) => {
        state.getAllTransfersData = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(createNewTransferT.fulfilled, (state: any, action: any) => {
        state.createNewTransferTData = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllTransfersList.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllTransfersList.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(inventorytransfersshow.fulfilled, (state: any, action: any) => {
        state.inventorytransfersshowData = action.payload;
        state.onetimeLoading1 = false;
        state.loading = false;
        state.error = null;
      })
      .addCase(inventorytransfersshow.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(
        inventorytransfersupdate.fulfilled,
        (state: any, action: any) => {
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(inventorytransfersupdate.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(inventorytransfersshow.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateOneQtyTransfer.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(permanentDeleteTransfer.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(delItemTransferById.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateItemsForTransfers.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(duplicateTransfer.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.error = null;
      });
  },
});

export const {
  saveTransferBranchIdT,
  saveTransferWareHouseIdT,
  savearray1,
  onetimeLoading,
  saveTransferCreationData,
  setTransferFilterObject,
  resetTransferFilterObject,
  changeIsFiltered,
} = inventoryTransferSlice.actions;
export default inventoryTransferSlice.reducer;
