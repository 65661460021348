import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch2, fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  giftListItems: {},
  loading: false,
  progressLoader: false,
  sku: '',
  getGiftDetails: {},
  pageLoading: false,
  isDataFiltered: false,
  pageCount: 0,
};
export let giftCardFilterValue: any = {
  restaurant_id: '',
  search: '',
  type: '',
  name: '',
  sku: '',
  barcode: '',
  category_id: '',
  groups: '',
  modifiers: '',
  tag: '',
  pricingmethod: '',
  active: '',
  deleted: '',
  updateafter: '',
};
export let filterArray: any = {
  categories: [],
  groups: [],
  tag: [],
  pricingMethod: [],
};
export let giftListItems: any;
export let viewPerSingleDetails: any;
export let addGiftCardType: string;
export let addGiftCardTypeName: string;
export let parameterTagAndBranch: string;
export let giftCardId: number;

// =============================================
export const giftList: any = createAsyncThunk('giftList ', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/gift-cards`, body);
  return result;
});
// =============================================
export const createGift: any = createAsyncThunk('createGift ', async body => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/menu/gift-cards`, body);

    return result;
  } catch (error: any) {
    notify(error?.response?.data?.message, 'error');
  }
});
// =============================================
export const getSKU: any = createAsyncThunk('generateSKU', async (id: any) => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/generate_sku`, {
      model: 'gift_cards',
    });
    return result?.data;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});
// =============================================
export const getGiftDetails: any = createAsyncThunk('getGiftDetails ', async body => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/menu/gift-cards/${body}`, 'get');
  return result.data;
});
// =============================================
export const deleteGiftCard: any = createAsyncThunk('deleteGiftCard', async body => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/menu/gift-cards/${body}`, 'delete');
  return result;
});
// =============================================
export const restoreGiftCard: any = createAsyncThunk('restoreGiftCard ', async body => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/menu/gift-cards/${body}/restore`);
  return result;
});
// =============================================
export const editGift: any = createAsyncThunk('editGift ', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/menu/gift-cards/${body.id}`, body);
  return result;
});
// =============================================
export const addGiftCardTag: any = createAsyncThunk('addGiftCardTag ', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/menu/createGiftCardTag/${body.id}`, body);
  return result;
});
// =============================================
export const addGiftCardBranch: any = createAsyncThunk('addGiftCardBranch ', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/menu/createGiftCardBranchInactive/${body.id}`, body);
  return result;
});
// =============================================

const menuGiftSlice: any = createSlice({
  name: 'menuGift',
  initialState,
  reducers: {
    setshowLoading(state = initialState) {
      state.loading = true;
    },
    setShowPageLoading(state = initialState) {
      state.pageLoading = true;
    },
    setIsDataFiltered(state = initialState, action: any) {
      state.isDataFiltered = action.payload;
    },
    setProgressLoader(state = initialState, action: any) {
      state.progressLoader = action.payload;
    },
    setPageCount(state = initialState, action: any) {
      state.pageCount = action.payload;
    },
  },
  extraReducers: {
    [giftList.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.giftListItems = action.payload;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [giftList.rejected]: (state: any) => {
      state.loading = false;
    },
    [giftList.pending]: (state: any) => {
      state.loading = true;
    },
    // =============================================
    [getGiftDetails.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.getGiftDetails = action.payload;
      state.pageLoading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [getGiftDetails.rejected]: (state: any) => {
      state.loading = false;
      state.pageLoading = false;
    },
    [getGiftDetails.pending]: (state: any) => {
      state.loading = true;
      state.pageLoading = true;
    },
    // =============================================
    [createGift.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        notify(action.payload.message, 'success');
        state.error = action.payload.message;
      }
    },
    [createGift.rejected]: (state: any) => {
      state.loading = false;
    },
    // =============================================
    [getSKU.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.sku = action.payload;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    // =============================================
    [deleteGiftCard.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        notify('Gift Card deleted Successfully', 'success');
        state.error = action.payload.message;
      }
    },
    [deleteGiftCard.rejected]: (state: any) => {
      state.loading = false;
    },
    // =============================================
    [restoreGiftCard.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        notify(action.payload.message, 'success');

        state.error = action.payload.message;
      }
    },
    [restoreGiftCard.rejected]: (state: any) => {
      state.loading = false;
    },
    // =============================================
    [editGift.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        notify(action.payload.message, 'success');

        state.error = action.payload.message;
      }
    },
    [editGift.rejected]: (state: any) => {
      state.loading = false;
    },
    // =============================================
    [addGiftCardTag.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        notify('Gift Card Tag added Successfully', 'success');

        state.error = action.payload.message;
      }
    },
    [editGift.rejected]: (state: any) => {
      state.loading = false;
    },
    // =============================================
    [addGiftCardBranch.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        notify('Gift Card Branch added Successfully', 'success');

        state.error = action.payload.message;
      }
    },
    [editGift.rejected]: (state: any) => {
      state.loading = false;
    },
    // =============================================
  },
});

export const { setshowLoading, setShowPageLoading, setIsDataFiltered, setProgressLoader, setPageCount } =
  menuGiftSlice.actions;
export default menuGiftSlice.reducer;
