import React, { useState } from 'react';
import st from '../../style.module.scss';
import cx from './ReportsInventory.module.scss';
import { Card, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Modals from '../../components/Modals/OrderModal';
import { GlobalState } from '../../store/global/global.slice';
import { useSelector } from 'react-redux';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import WithPermission from '../../utils/WithPermission';
import { POSPERMISSIONS } from '../../utils/Roles';
import { useTranslation } from 'react-i18next';

export default function ReportsInventory() {
  useDocumentTitle('GLOBAL.INVENTORY_REPORTS');
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState('');
  const { t } = useTranslation();
  const handleShow = (modalname: string, status: boolean) => {
    setModalName(modalname);
    setShow(status);
  };
  const handleClose = () => {
    setModalName('');
    setShow(false);
  };

  //Check the Language and Change the style
  const language = useSelector((state: { app: GlobalState }) => state.app?.language);
  const marginStyle = {
    marginLeft: language === 'en' ? '250px' : '0',
    marginRight: language === 'ar' ? '250px' : '0',
  };

  return (
    <>
      <section className={`${language === 'en' ? `${st.pageWrapper}` : `${st.pageWrapperAr}`}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.pageTitleRow}`}>
            <div className={`${st.rowTitleLeft}`}>
              <h5>{t('GLOBAL.INVENTORY_REPORTS')}</h5>
            </div>
          </div>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body className={`${cx.cardBody}`}>
              <Row>
                <WithPermission permissionRequired={POSPERMISSIONS.canInventoryLevelsReport}>
                  <Col lg={3}>
                    <NavLink to='/reports/inventory/inventory-levels' className={`${cx.moreOption}`}>
                      {t('GLOBAL.INVENTORY_LEVELS')}
                    </NavLink>
                  </Col>
                </WithPermission>
                <WithPermission permissionRequired={POSPERMISSIONS.canInventoryControlReport}>
                  <Col lg={3}>
                    <NavLink to='/reports/inventory/inventory-control' className={`${cx.moreOption}`}>
                      {t('GLOBAL.INVENTORY_CONTROL')}
                    </NavLink>
                  </Col>
                </WithPermission>
                <WithPermission permissionRequired={POSPERMISSIONS.canInventoryTransactionsReport}>
                  <Col lg={3}>
                    <NavLink to='/reports/inventory/inventory-history' className={`${cx.moreOption}`}>
                      {t('GLOBAL.INVENTORY_HISTORY')}
                    </NavLink>
                  </Col>
                  <Col lg={3}>
                    <NavLink to='/reports/inventory/purchase-order' className={`${cx.moreOption}`}>
                      {t('GLOBAL.PURCHASE_ORDER')}
                    </NavLink>
                  </Col>
                  <Col lg={3}>
                    <NavLink to='/reports/inventory/transfer-order' className={`${cx.moreOption}`}>
                      {t('GLOBAL.TRANSFER_ORDER')}
                    </NavLink>
                  </Col>
                  <Col lg={3}>
                    <NavLink to='/reports/inventory/transfer' className={`${cx.moreOption}`}>
                      {t('GLOBAL.TRNASFERS')}
                    </NavLink>
                  </Col>
                  <Col lg={3}>
                    <NavLink to='/reports/inventory/purchasing' className={`${cx.moreOption}`}>
                      {t('GLOBAL.PURCHASING')}
                    </NavLink>
                  </Col>
                </WithPermission>
                <WithPermission permissionRequired={POSPERMISSIONS.canCostAdjustmentHistoryReport}>
                  <Col lg={3}>
                    <NavLink to='/reports/inventory/cost-adjustmenthistory' className={`${cx.moreOption}`}>
                      {t('GLOBAL.COST_ADJUSTMENT_HISTORY')}
                    </NavLink>
                  </Col>
                </WithPermission>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </section>

      <Modals show={show} handleClose={handleClose} modalName={modalName} />
    </>
  );
}
