import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { fetch10, fetch2, fetch22, fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

export const showPartnerList: any = createAsyncThunk('showPartnerList', async body => {
  try {
    const response: any = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/customers?filter[is_delivery_partner]=1`,
      body,
    );
    // notify("success", "success");
    return response;
  } catch (error: any) {
    // notify(error.response.data.message, "error");
    throw error;
  }
});

export const editCustomer: any = createAsyncThunk('editCustomer', async ({ id = '', body = null }: any) => {
  try {
    const response: any = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/customers/${id}`, body);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    const referenceKey = Object.keys(error.response.data.errors)[0];
    const referenceValue = error.response.data.errors[referenceKey];
    notify(referenceValue[0] || error.response.data.message, 'error');
    throw error;
  }
});
export const createCustomerTag: any = createAsyncThunk('createCustomerTag', async ({ id = '', body = null }: any) => {
  try {
    const response: any = await fetch5(
      `${process.env.REACT_APP_BASEURL}/manage/customers/createCustomerTag/${id}`,
      body,
    );
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    const referenceKey = Object.keys(error.response.data.errors)[0];
    const referenceValue = error.response.data.errors[referenceKey];
    notify(referenceValue[0] || error.response.data.message, 'error');
    throw error;
  }
});
export const delCustomer: any = createAsyncThunk('delCustomer', async body => {
  try {
    const response: any = await fetch10(`${process.env.REACT_APP_BASEURL}/manage/customers/${body}`);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    throw error;
  }
});
export const restoreCustomer: any = createAsyncThunk('restoreCustomer', async body => {
  try {
    const response: any = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/customers/${body}/restore`);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    throw error;
  }
});
export const tagsFIlterCus: any = createAsyncThunk('yagsFIlter', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});
export const delivery_zones: any = createAsyncThunk('delivery_zones', async body => {
  try {
    const response: any = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/delivery_zones?filter[is_deleted]=false`,
      body,
    );
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});
export const customerDetails: any = createAsyncThunk('customerDetails', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/customers/${body}`);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    // notify(error.response.data.message, "error");
    throw error;
  }
});
export const adressessDetails: any = createAsyncThunk('adressessDetails', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/addresses`, body);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});
export const house_account_transactions: any = createAsyncThunk('house_account_transactions', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/house_account_transactions`, body);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});
export const customerInsights: any = createAsyncThunk('customerInsights', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/customer_insights/${body}`);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});
export const payment_methods: any = createAsyncThunk('payment_methods', async (body: any = null) => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/payment_methods`, body);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});
export const PriceTagsList: any = createAsyncThunk('PriceTagsList', async (body: any = null) => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/price_tags`, body);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});
export const createCustomer: any = createAsyncThunk('createCustomer', async body => {
  try {
    const response: any = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/customers`, body);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    console.log(error);
    const referenceKey = Object.keys(error.response.data.errors)[0];
    const referenceValue = error.response.data.errors[referenceKey];
    notify(referenceValue[0] || error.response.data.message, 'error');
    throw error;
  }
});
export const house_account_status_toggle: any = createAsyncThunk('house_account_status_toggle', async body => {
  try {
    const response: any = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/customers/house_account_status_toggle/${body}`,
    );

    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    console.log(error);
    const referenceKey = Object.keys(error.response.data.errors)[0];
    const referenceValue = error.response.data.errors[referenceKey];
    notify(referenceValue[0] || error.response.data.message, 'error');
    throw error;
  }
});

const customerSlice: any = createSlice({
  name: 'customerSlice',
  initialState: {
    showPartnerListData: null,
    settypeFilterData: null,
    tagsFIlterCusData: [],
    customerDetailsData: [],
    PriceTagsListData: [],
    adressessDetailsData: null,
    delivery_zonesData: null,
    house_account_transactionsData: [],
    payment_methodsData: null,
    newnavRouteKey: 0,
    oneTimeNavDataC: false,
    customerInsight: false,

    isLoading: false,
    isLoadingList: false
  },
  reducers: {
    settypeFilter: (state, action) => {
      state.settypeFilterData = action.payload;
    },
    newnavRoute: (state, action) => {
      state.newnavRouteKey = state.newnavRouteKey + 1;
    },
    oneTimeNavC(state, action) {
      state.oneTimeNavDataC = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(showPartnerList.fulfilled, (state: any, action) => {
        state.isLoadingList = false;

        state.showPartnerListData = action.payload;
      })
      .addCase(showPartnerList.pending, (state: any, action) => {
        state.isLoadingList = true;
      })
      .addCase(tagsFIlterCus.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.tagsFIlterCusData = action.payload;
      })
      .addCase(customerDetails.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.oneTimeNavDataC = false;
        state.customerDetailsData = action.payload;
      })
      .addCase(adressessDetails.fulfilled, (state: any, action) => {
        state.adressessDetailsData = action.payload;
      })
      .addCase(house_account_transactions.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.house_account_transactionsData = action.payload;
      })
      .addCase(delivery_zones.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.delivery_zonesData = action.payload;
      })
      .addCase(customerInsights.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.customerInsight = action.payload;
      })
      .addCase(payment_methods.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.payment_methodsData = action.payload;
      })
      .addCase(PriceTagsList.fulfilled, (state: any, action: any) => {
        state.isLoading = false;
        state.PriceTagsListData = action.payload;
      });
  },
});

export const { settypeFilter, newnavRoute, oneTimeNavC } = customerSlice.actions;
export default customerSlice.reducer;
