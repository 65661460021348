import React, { useState } from 'react';
import st from '../../style.module.scss';
import cx from './ReportsSalesPage.module.scss';
import { Card, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Modals from '../../components/Modals/OrderModal';
import { GlobalState } from '../../store/global/global.slice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';

export default function ReportsSalesPage() {
  useDocumentTitle('GLOBAL.SALES_REPORTS');
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState('');
  const handleShow = (modalname: string, status: boolean) => {
    setModalName(modalname);
    setShow(status);
  };
  const handleClose = () => {
    setModalName('');
    setShow(false);
  };

  //Check the Language and Change the style
  const language = useSelector((state: { app: GlobalState }) => state.app?.language);
  const { t } = useTranslation();

  return (
    <>
      <section className={`${language === 'en' ? `${st.pageWrapper}` : `${st.pageWrapperAr}`}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.pageTitleRow}`}>
            <div className={`${st.rowTitleLeft}`}>
              <h5>{t('GLOBAL.SALES_REPORTS')}</h5>
            </div>
          </div>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body className={`${cx.cardBody}`}>
              <Row>
                <Col lg={3}>
                  <NavLink to='/reports/sales/sales-by-branch' className={`${cx.moreOption}`}>
                    {t('GLOBAL.SALES_REPORTS')}
                  </NavLink>
                </Col>
                <Col lg={3}>
                  <NavLink to='/reports/sales/payments' className={`${cx.moreOption}`}>
                    {t('GLOBAL.PAYMENTS_REPORTS')}
                  </NavLink>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </section>

      <Modals show={show} handleClose={handleClose} modalName={modalName} />
    </>
  );
}
