import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetch5, fetch6, fetch10, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  loading: false,
  filterStatus: false,
  error: null,
  filterBody: null,
  transferOrderReportsList: {},
  getInventoryTObranchesData: [],
  getInventoryTOcategoriesData: [],
  getInventoryTOitemsData: [],
  getInventoryTOTagsData: [],
  getInventoryToWarehouseData: [],
};

export const getTransferOrderReportsList: any = createAsyncThunk('getTransferOrderReportsList', async body => {
  let result;
  if (process.env.REACT_APP_BASEURL === 'https://sandbox-api.dot.sa/api/v1') {
    result = await fetch6(`${process.env.REACT_APP_BASEURL}/reports/inventory-report/inventory_transfer-order`, body);
  } else {
    result = await fetch6(`${process.env.REACT_APP_BASEURL}/reports/report_order_transfer`, body);
  }
  return result;
});
export const getInventoryTObranches: any = createAsyncThunk('getInventoryTObranches', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/branches`, body);
  return result.data;
});
export const getInventoryTOcategories: any = createAsyncThunk('getInventoryTOcategories', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory-categories`, body);
  return result.data;
});
export const getInventoryTOitems: any = createAsyncThunk('getInventoryTOitems', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items`, body);
  return result.data;
});
export const getInventoryTOTags: any = createAsyncThunk('getInventoryTOTags', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);
  return result.data;
});
export const getInventoryToWarehouse: any = createAsyncThunk('getInventoryToWarehouse', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory-warehouses`, body);
  return result.data;
});

const ReportsTransferOrderApiReducer: any = createSlice({
  name: 'ReportsTransferOrderApiReducer',
  initialState,
  reducers: {
    changeFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    setFilter: (state, action) => {
      state.filterBody = action.payload;
    },
  },
  extraReducers: {
    [getTransferOrderReportsList.pending]: state => {
      state.loading = true;
    },
    [getTransferOrderReportsList.fulfilled]: (state, action) => {
      state.loading = false;
      state.transferOrderReportsList = action.payload;
    },
    [getTransferOrderReportsList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryToWarehouse.pending]: state => {
      state.loading = true;
    },
    [getInventoryToWarehouse.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryToWarehouseData = action.payload;
    },
    [getInventoryToWarehouse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getInventoryTObranches.pending]: state => {
      state.loading = true;
    },
    [getInventoryTObranches.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryTObranchesData = action.payload;
    },
    [getInventoryTObranches.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryTOcategories.pending]: state => {
      state.loading = true;
    },
    [getInventoryTOcategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryTOcategoriesData = action.payload;
    },
    [getInventoryTOcategories.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryTOitems.pending]: state => {
      state.loading = true;
    },
    [getInventoryTOitems.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryTOitemsData = action.payload;
    },
    [getInventoryTOitems.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryTOTags.pending]: state => {
      state.loading = true;
    },
    [getInventoryTOTags.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryTOTagsData = action.payload;
    },
    [getInventoryTOTags.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});
export const { changeFilterStatus, setFilter } = ReportsTransferOrderApiReducer.actions;
export default ReportsTransferOrderApiReducer.reducer;
