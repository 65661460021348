import React, { useEffect, useState, useContext, Fragment } from 'react';
import st from '../../style.module.scss';
import moment from 'moment';
import Modals from '../../components/Modals/MarketplaceOdooM';
import GlobalContext from '../../store/global-context';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalState } from '../../store/global/global.slice';
import { MdArrowBackIos, MdDeleteOutline, MdOutlineDelete } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import table from '../../datatable.module.scss';
import cx from './MarketplaceDetails.module.scss';
import icon4 from '../../images/icon-printer.svg';
import iconEdit from '../../images/icon-edit2.svg';
import { Card, Button, Row, Table, Col, Modal, Form, Dropdown } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import NoDataPlaceHolder from '../../components/NoData/NoDataPlaceHolder';
import {
  showCustomersList,
  showPaymentMethodsList,
  showProductList,
  showbranchesList,
  showprice_tagsList,
  showtax_groupsList,
} from '../../redux_toolkit/reducer/manageCommanApiReducer';
import {
  branches,
  businessList,
  companyId,
  customers,
  paymentMehods,
  priceTags,
  products,
  taxes,
  toggleDisconnected,
} from '../../redux_toolkit/reducer/MarketPlaceApiReducer';
import TopBarProgressInd from '../../shared/TopBarProgressInd';
import { LoadingButton } from '@mui/lab';
import { showTaxList } from '../../redux_toolkit/reducer/manageTaxGroupApiReducer';

export default function MarketplaceDetails() {
  const dispatch = useDispatch();

  const globalGtx = useContext(GlobalContext);
  // Modals
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState('');
  const [item, setitem] = useState<any>({});
  const [details, setDetails] = useState<any>({});

  const handleShow = (modalname: string, status: boolean) => {
    setModalName(modalname);
    setShow(status);
  };

  const handleClose = () => {
    setShow(false);
  };

  //Check the Language and Change the style
  const language = useSelector((state: { app: GlobalState }) => state.app?.language);

  const statusMarketPlace = useSelector((state: any) => state.MarketPlaceApiReducer);

  const statusComman = useSelector((state: any) => state.manageCommanApiReducer);

  const statusCommanTax = useSelector((state: any) => state.manageTaxGroupApiReducer);

  const [loading, setLoading] = useState(false);
  const [loadingBranch, setLoadingBranch] = useState(false);
  const [loadingTax, setLoadingTax] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [loadingPriceList, setLoadingPriceList] = useState(false);
  const [productsData, setProductsData] = useState<any[]>([]);
  const [customersData, setCustomersData] = useState<any[]>([]);
  const [branchesData, setBranchesData] = useState<any[]>([]);
  const [paymentData, setPaymentData] = useState<any[]>([]);
  const [taxGroups, setTaxGroup] = useState<any[]>([]);
  const [arrPriceTags, setArrPriceTags] = useState<any[]>([]);

  const [resProp, setResProp] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      var res = await dispatch(companyId());

      await dispatch(
        products({
          id: res?.payload?.data?.odoo_id,
        }),
      );
      await dispatch(
        branches({
          id: res?.payload?.data?.odoo_id,
        }),
      );
      await dispatch(
        taxes({
          id: res?.payload?.data?.odoo_id,
        }),
      );
      await dispatch(
        paymentMehods({
          id: res?.payload?.data?.odoo_id,
        }),
      );
      await dispatch(
        priceTags({
          id: res?.payload?.data?.odoo_id,
        }),
      );
      await dispatch(
        customers({
          id: res?.payload?.data?.odoo_id,
        }),
      );
      dispatch(showProductList({ filter: { is_deleted: false }, type: 'all' }));
      dispatch(showbranchesList({ filter: { is_deleted: false }, type: 'all' }));
      dispatch(showbranchesList({ filter: { is_deleted: false }, type: 'all' }));
      dispatch(showPaymentMethodsList({ filter: { is_deleted: false }, type: 'all' }));
      dispatch(showTaxList({ filter: { is_deleted: false }, type: 'all' }));
      dispatch(showprice_tagsList({ filter: { is_deleted: false }, type: 'all' }));

      dispatch(showCustomersList({ filter: { is_deleted: false }, type: 'all' }));
    })();
  }, [dispatch]);

  useEffect(() => {
    var arr: any = [];
    statusMarketPlace?.productsListData?.data?.map((a: any) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    setProductsData(arr);
  }, [statusMarketPlace?.productsListData]);

  useEffect(() => {
    var arr: any = [];
    statusMarketPlace?.customersListData?.data?.map((a: any) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    setCustomersData(arr);
  }, [statusMarketPlace?.customersListData]);

  useEffect(() => {
    console.log(statusMarketPlace);

    var arr: any = [];
    statusMarketPlace?.branchesListData?.data?.map((a: any) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    setBranchesData(arr);
  }, [statusMarketPlace?.branchesListData]);

  useEffect(() => {
    console.log(statusMarketPlace);

    var arr: any = [];
    statusMarketPlace?.paymentMehodsListData?.data?.map((a: any) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    setPaymentData(arr);
  }, [statusMarketPlace?.paymentMehodsListData]);

  useEffect(() => {
    console.log(statusMarketPlace);

    var arr: any = [];
    statusMarketPlace?.priceTagDataListData?.data?.map((a: any) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    setArrPriceTags(arr);
  }, [statusMarketPlace?.priceTagDataListData]);

  useEffect(() => {
    console.log(statusMarketPlace);

    var arr: any = [];
    statusMarketPlace?.taxesListData?.data?.map((a: any) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    setTaxGroup(arr);
  }, [statusMarketPlace?.taxesListData]);
  console.log(branchesData.find((a: any) => a.value == item?.odoo_id)?.label);
  console.log(branchesData);

  return (
    <>
      <ToastContainer />

      <section className={`${language === 'en' ? `${st.pageWrapper}` : `${st.pageWrapperAr}`}`}>
        <div className={`${st.pageTitle}`}>
          <NavLink to='/marketplace' className={`${st.backBtn}`}>
            <MdArrowBackIos className={`${st.icon}`} /> Back
          </NavLink>
          <div className={`${st.pageTitleRow}`}>
            <div className={`${st.rowTitleLeft}`}>
              <h5>Odoo</h5>
            </div>

            <div className={`${st.rowTitleRight}`} style={{ width: '20%' }}>
              <LoadingButton
                loading={statusMarketPlace?.isLoadingBusinessList}
                className={`${st.btnSubmit} bg-primary w-100 rounded-pill ${
                  statusMarketPlace?.isLoadingBusinessList ? 'transparent' : 'text-white text-capitalize'
                }`}
                disabled={statusComman.showPaymentMethodsListData.length == 0 ? true : false}
                onClick={async () => {
                  await dispatch(businessList());
                  handleShow('Business', true);
                }}
              >
                Edit Business
              </LoadingButton>
              <LoadingButton
                className={`${st.btnSubmit} bg-danger mx-1 w-100 rounded-pill py-2 text-white text-capitalize`}
                onClick={async () => {
                  await navigate('/marketplace');
                  dispatch(toggleDisconnected());
                }}
              >
                Reconnect
              </LoadingButton>
            </div>
          </div>
        </div>

        <div className={`${st.pageWrapperInside} ${st.setWidth}`}>
          {statusMarketPlace.isLoadingProducts ? (
            <>
              <div className=' h-100 pt-5 d-flex justify-content-center align-items-center'>
                <ReactLoading className='mx-auto' type={'spin'} color={'rgb(84, 152, 253)'} height={30} width={30} />
              </div>
              <TopBarProgressInd />
            </>
          ) : (
            <Card>
              <Card.Body className={`${cx.cardBody}`}>
                <div className={`${cx.contentBox}`}>
                  <Row>
                    <Col lg={6} className={`${cx.formField}`}>
                      <label>URL</label>
                      <p>{statusMarketPlace?.dataStore?.url || statusMarketPlace?.companyIdData?.data?.odoo_url}</p>
                    </Col>
                    <Col lg={6} className={`${cx.formField}`}>
                      <label>Database Name</label>
                      <p>
                        {statusMarketPlace?.dataStore?.database ||
                          statusMarketPlace?.companyIdData?.data?.odoo_database}
                      </p>
                    </Col>
                    <Col lg={6} className={`${cx.formField}`}>
                      <label>User Name</label>
                      <p>{statusMarketPlace?.dataStore?.user || statusMarketPlace?.companyIdData?.data?.odoo_user}</p>
                    </Col>
                  </Row>
                </div>

                {/* ========================================================================= */}
                {/* products */}
                <div className={`${cx.pageTitle}`}>
                  <div className={`${cx.rowTitleLeft}`}>
                    <h5>Products</h5>
                  </div>
                  <div className={`${cx.rowTitleRight}`}>
                    <LoadingButton
                      loading={statusMarketPlace?.isLoadingProducts}
                      className={`${st.btnSubmit} bg-light w-100 rounded-pill py-2 ${
                        statusMarketPlace?.isLoadingProducts
                          ? 'transparent'
                          : 'border border-secondary text-red text-capitalize'
                      }`}
                      disabled={statusComman.showProductListData?.length == 0 ? true : false}
                      onClick={async () => {
                        console.log(statusMarketPlace?.companyIdData?.data);

                        // await dispatch(
                        //   products({
                        //     id: statusMarketPlace?.companyIdData?.data?.odoo_id,
                        //   })
                        // );
                        // await dispatch(showProductList());
                        handleShow('edit products', true);
                      }}
                    >
                      Edit
                    </LoadingButton>
                  </div>
                </div>

                <div className={`${cx.contentBox}`}>
                  <div className={`table-responsive`}>
                    <Table className={`${table.tableCt}`}>
                      <thead>
                        <tr>
                          <th>Dot</th>
                          <th>Odoo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statusComman.showProductListData?.map((item: any, index: number) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                handleShow('edit single product', true);
                                setResProp(item);
                              }}
                            >
                              <td>{item?.name ? item?.name : '-'}</td>
                              <td>
                                {item?.odoo_id ? productsData.find((a: any) => a.value == item?.odoo_id)?.label : '-'}
                              </td>
                              {}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>

                {/* ========================================================================= */}
                {/* customers */}
                <div className={`${cx.pageTitle}`}>
                  <div className={`${cx.rowTitleLeft}`}>
                    <h5>Customers</h5>
                  </div>
                  <div className={`${cx.rowTitleRight}`}>
                    <LoadingButton
                      loading={statusMarketPlace?.isLoadingProducts}
                      className={`${st.btnSubmit} bg-light w-100 rounded-pill py-2 ${
                        statusMarketPlace?.isLoadingProducts
                          ? 'transparent'
                          : 'border border-secondary text-red text-capitalize'
                      }`}
                      disabled={statusComman.showProductListData?.length == 0 ? true : false}
                      onClick={async () => {
                        console.log(statusMarketPlace?.companyIdData?.data);

                        // await dispatch(
                        //   products({
                        //     id: statusMarketPlace?.companyIdData?.data?.odoo_id,
                        //   })
                        // );
                        // await dispatch(showProductList());
                        handleShow('edit customers', true);
                      }}
                    >
                      Edit
                    </LoadingButton>
                  </div>
                </div>

                <div className={`${cx.contentBox}`}>
                  <div className={`table-responsive`}>
                    <Table className={`${table.tableCt}`}>
                      <thead>
                        <tr>
                          <th>Dot</th>
                          <th>Odoo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statusComman.showCustomersListData?.map((item: any, index: number) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                handleShow('edit single customer', true);
                                setResProp(item);
                              }}
                            >
                              <td>{item?.name ? item?.name : '-'}</td>
                              <td>
                                {item?.odoo_id ? customersData.find((a: any) => a.value == item?.odoo_id)?.label : '-'}
                              </td>
                              {}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
                {/* ========================================================================= */}
                {/* branches */}

                <div className={`${cx.pageTitle}`}>
                  <div className={`${cx.rowTitleLeft}`}>
                    <h5>Branches</h5>
                  </div>
                  <div className={`${cx.rowTitleRight}`}>
                    <LoadingButton
                      loading={loadingBranch}
                      className={`${st.btnSubmit} bg-light w-100 rounded-pill py-2 ${
                        loadingBranch ? 'transparent' : 'border border-secondary text-red text-capitalize'
                      }`}
                      disabled={statusComman?.showbranchesListData?.length == 0 ? true : false}
                      onClick={async () => {
                        // setLoadingBranch(true);
                        // var res = await dispatch(
                        //   branches({
                        //     id: statusMarketPlace?.companyIdData?.data?.odoo_id,
                        //   })
                        // );
                        // console.log(res);

                        // await dispatch(showbranchesList());

                        handleShow('edit branches', true);

                        // setLoadingBranch(false);
                      }}
                    >
                      Edit
                    </LoadingButton>
                  </div>
                </div>

                <div className={`${cx.contentBox}`}>
                  <div className={`table-responsive`}>
                    <Table className={`${table.tableCt}`}>
                      <thead>
                        <tr>
                          <th>Dot</th>
                          <th>Odoo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statusComman.showbranchesListData?.map((item: any, index: number) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                handleShow('edit single branch', true);
                                setResProp(item);
                              }}
                            >
                              <td>{item?.name ? item?.name : '-'}</td>
                              <td>
                                {item?.odoo_id ? branchesData.find((a: any) => a.value == item?.odoo_id)?.label : '-'}
                              </td>
                              {}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>

                {/* ========================================================================= */}
                {/* Taxs & groups */}
                <div className={`${cx.pageTitle}`}>
                  <div className={`${cx.rowTitleLeft}`}>
                    <h5>Tax & Groups</h5>
                  </div>
                  <div className={`${cx.rowTitleRight}`}>
                    <LoadingButton
                      loading={loadingTax}
                      className={`${st.btnSubmit} bg-light w-100 rounded-pill py-2 ${
                        loadingTax ? 'transparent' : 'border border-secondary text-red text-capitalize'
                      }`}
                      disabled={statusCommanTax?.showAllshowTaxList?.length == 0 ? true : false}
                      onClick={async () => {
                        // setLoadingTax(true);
                        // await dispatch(
                        //   taxes({
                        //     id: statusMarketPlace?.companyIdData?.data?.odoo_id,
                        //   })
                        // );
                        // await dispatch(
                        //   showTaxList({
                        //     filter: { is_deleted: false },
                        //     type: "all",
                        //   })
                        // );
                        // setLoadingTax(false);

                        handleShow('edit taxandgroups', true);
                      }}
                    >
                      Edit
                    </LoadingButton>
                  </div>
                </div>

                <div className={`${cx.contentBox}`}>
                  <div className={`table-responsive`}>
                    <Table className={`${table.tableCt}`}>
                      <thead>
                        <tr>
                          <th>Dot</th>
                          <th>Odoo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statusCommanTax.showAllshowTaxList?.map((item: any, index: number) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                handleShow('edit single tax', true);
                                setResProp(item);
                              }}
                            >
                              <td>{item?.name ? item?.name : '-'}</td>
                              <td>
                                {item?.odoo_id ? taxGroups.find((a: any) => a.value == item?.odoo_id)?.label : '-'}
                              </td>
                              {}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
                {/* <div className={`${cx.contentBox}`}>
                  <div className={`table-responsive`}>
                    {statusCommanTax?.showAllshowTaxList?.length > 0 ? (
                      <Table className={`${table.tableCt}`}>
                        <thead>
                          <tr>
                            <th>Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {statusCommanTax?.showAllshowTaxList?.map(
                            (item: any) => {
                              return (
                                <tr>
                                  <td>{item?.name}</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    ) : (
                      <div className={`p-4 text-center ${table.noData}`}>
                        <NoDataPlaceHolder />
                      </div>
                    )}
                  </div>
                </div> */}
                {/* ========================================================================= */}
                {/*payment methods */}
                <div className={`${cx.pageTitle}`}>
                  <div className={`${cx.rowTitleLeft}`}>
                    <h5>Payment</h5>
                  </div>
                  <div className={`${cx.rowTitleRight}`}>
                    <LoadingButton
                      loading={loadingPayment}
                      className={`${st.btnSubmit} bg-light w-100 rounded-pill py-2 ${
                        loadingPayment ? 'transparent' : 'border border-secondary text-red text-capitalize'
                      }`}
                      disabled={statusComman?.showPaymentMethodsListData?.length == 0 ? true : false}
                      onClick={async () => {
                        // setLoadingPayment(true);
                        // await dispatch(
                        //   paymentMehods({
                        //     id: statusMarketPlace?.companyIdData?.data?.odoo_id,
                        //   })
                        // );
                        // await dispatch(showPaymentMethodsList());
                        // setLoadingPayment(false);

                        handleShow('edit paymentMethod', true);
                      }}
                    >
                      Edit
                    </LoadingButton>
                  </div>
                </div>

                <div className={`${cx.contentBox}`}>
                  <div className={`table-responsive`}>
                    <Table className={`${table.tableCt}`}>
                      <thead>
                        <tr>
                          <th>Dot</th>
                          <th>Odoo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statusComman?.showPaymentMethodsListData?.map((item: any, index: number) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                handleShow('edit single payment', true);
                                setResProp(item);
                              }}
                            >
                              <td>{item?.name ? item?.name : '-'}</td>
                              <td>
                                {item?.odoo_id ? paymentData.find((a: any) => a.value == item?.odoo_id)?.label : '-'}
                              </td>
                              {}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>

                {/* ========================================================================= */}
                {/*Price tags */}
                <div className={`${cx.pageTitle}`}>
                  <div className={`${cx.rowTitleLeft}`}>
                    <h5>Price List</h5>
                  </div>
                  <div className={`${cx.rowTitleRight}`}>
                    <LoadingButton
                      loading={loadingPriceList}
                      className={`${st.btnSubmit} bg-light w-100 rounded-pill py-2 ${
                        loadingPriceList ? 'transparent' : 'border border-secondary text-red text-capitalize'
                      }`}
                      disabled={statusComman?.showprice_tagsListData?.length == 0 ? true : false}
                      onClick={async () => {
                        // setLoadingPriceList(true);
                        // await dispatch(
                        //   priceTags({
                        //     id: statusMarketPlace?.companyIdData?.data?.odoo_id,
                        //   })
                        // );
                        // await dispatch(showprice_tagsList());
                        // setLoadingPriceList(false);

                        handleShow('edit priceTags', true);
                      }}
                    >
                      Edit
                    </LoadingButton>
                  </div>
                </div>

                <div className={`${cx.contentBox}`}>
                  <div className={`table-responsive`}>
                    <Table className={`${table.tableCt}`}>
                      <thead>
                        <tr>
                          <th>Dot</th>
                          <th>Odoo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statusComman.showprice_tagsListData?.map((item: any, index: number) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                handleShow('edit single price list', true);
                                setResProp(item);
                              }}
                            >
                              <td>{item?.name ? item?.name : '-'}</td>
                              <td>
                                {item?.odoo_id ? arrPriceTags.find((a: any) => a.value == item?.odoo_id)?.label : '-'}
                              </td>
                              {}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
                {/* <div className={`${cx.contentBox}`}>
                  <div className={`table-responsive`}>
                    {statusComman.showprice_tagsListData.length > 0 ? (
                      <Table className={`${table.tableCt}`}>
                        <thead>
                          <tr>
                            <th>Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {statusComman.showprice_tagsListData?.map(
                            (item: any) => {
                              return (
                                <tr>
                                  <td>{item?.name}</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    ) : (
                      <div className={`p-4 text-center ${table.noData}`}>
                        <NoDataPlaceHolder />
                      </div>
                    )}
                  </div>
                </div> */}
              </Card.Body>
            </Card>
          )}
        </div>
      </section>

      <Modals show={show} handleClose={handleClose} handleShow={handleShow} modalName={modalName} res={resProp} />
    </>
  );
}
