import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch2, fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  loading: false,
  deliveryZoneList: {},
  isDataFiltered: false,
  progressLoading: false,

  deliveryListCount: 0,
  addDeliveryCount: 0,
  deleteDeliveryCount: 0,
  loadingStatus: 0,
  pageCount: 0,
};
export let deliveryFilter = {
  type: '',
};
export let filterArray: any = {
  deliveryZone: [],
};
export let deliveryListItems: any;

export let editDeliveryData: any = {
  id: '',
  name: '',
  name_localized: '',
  branch_id: '',
  reference: '',
  deleted_at: null,
  cordinate: '',
  branches: [],
};

export const deliveryList: any = createAsyncThunk('deliveryList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/delivery_zones`, body);

  return result;
});

export const addDelivery: any = createAsyncThunk('addDelivery', async body => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/delivery_zones`, body);
  return result;
});

export const editDelivery: any = createAsyncThunk('editDelivery', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/delivery_zones/${body?.id}`, body);
  return result;
});

export const restoreDelivery: any = createAsyncThunk('restoreDelivery', async body => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/delivery_zones/${body}/restore`, body);
  return result;
});

export const deleteDelivery: any = createAsyncThunk('deleteDelivery', async body => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/manage/delivery_zones/${body}`, 'delete');
  return result;
});

const manageMoreDeliverySlice: any = createSlice({
  name: 'manageMoreDeliverySlice',
  initialState,
  reducers: {
    editDeliveryFunc(state = initialState, action) {
      editDeliveryData.id = action.payload.id;
      editDeliveryData.name = action.payload.name;
      editDeliveryData.name_localized = action.payload.name_localized;
      editDeliveryData.branches = action.payload.branches;
      editDeliveryData.reference = action.payload.reference;
      editDeliveryData.cordinate = action.payload.cordinate;
      editDeliveryData.deleted_at = action.payload.deleted_at;
    },
    resetDeliveryZones(state = initialState) {
      deliveryFilter.type = '';

      filterArray.deliveryZone = [];
    },
    setShowLoading(state = initialState) {
      state.loading = true;
    },
    showLoadingOff(state = initialState) {
      state.loadingStatus += 1;
    },
    setIsDataFiltered(state = initialState, action: any) {
      state.isDataFiltered = action.payload;
    },
    setProgressLoading(state = initialState, action: any) {
      state.progressLoading = action.payload;
    },
    setPageCount(state = initialState, action) {
      state.pageCount = action.payload;
    },
  },
  extraReducers: {
    [deliveryList.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.deliveryZoneList = action.payload;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [deliveryList.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [deliveryList.rejected]: state => {
      state.loading = false;
    },
    // ===================================================
    [addDelivery.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [addDelivery.rejected]: state => {
      state.loading = false;
    },
    // ===================================================
    [addDelivery.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.addDeliveryCount += 1;
      state.loadingStatus += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, 'success');
        else notify(message);
        state.error = message;
      }
    },
    [deleteDelivery.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.deleteDeliveryCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, 'success');
        else notify(message);
        state.error = message;
      }
    },
  },
});

export const {
  editDeliveryFunc,
  resetDeliveryZones,
  showLoadingOff,
  setShowLoading,
  setIsDataFiltered,
  setProgressLoading,
  setPageCount,
} = manageMoreDeliverySlice.actions;
export default manageMoreDeliverySlice.reducer;
