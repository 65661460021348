import React, { useEffect, useState } from 'react';
import { Form, Button, Card, Row, Col, Tab, Nav } from 'react-bootstrap';
import { MdArrowBackIos } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import st from '../../style.module.scss';
import cx from '../../components/Modals/Modals.module.scss';
import cxt from '../ManageTimedEvents/ManageTimedEvents.module.scss';
import { useLanguage } from '../../hooks/useLanguage';
import { NormalBtn } from '../../components/buttons/NormalBtn';
import { Badge } from '../../components/Badge/bagde';
import { OutlineBtn } from '../../components/buttons/OutlinedBtn';
import lock from '../../images/lock.svg';
import Modals from '../../components/Modals/UserProfileM';
import { whoAmI } from '../../redux_toolkit/reducer/LoginReducer';
import { useDispatch } from 'react-redux';
import { id } from 'date-fns/locale';
import { userEdit } from '../../redux_toolkit/reducer/manageUserApiReducer';

interface ProfileFormProps {
  name?: string;
  email?: string;
  phone?: string;
  loginPin?: string;
  language?: string;
  displayLocalizedNames?: boolean;
  onSaveChanges?: (data: ProfileData) => void;
}

interface ProfileData {
  name: string;
  email?: string;
  phone?: string;
  loginPin?: string;
  language?: string;
  displayLocalizedNames?: boolean;
}

const UserProfile: React.FC<any> = () => {
  const [formData, setFormData] = React.useState<any>({});

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  const dispatch = useDispatch();
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // setonSaveFormData(formData);
    const data = { ...formData, display_localized_names: formData.display_localized_names == true ? 1 : 0 };
    dispatch(userEdit(data));
  };
  const { t, language } = useLanguage();
  const [show, setShow] = useState(false);

  const [modalName, setModalName] = useState('');

  const handleShow = (modalname: string, status: boolean) => {
    setModalName(modalname);
    setShow(status);
  };
  const handleClose = () => {
    // dispatch(setshowLoading());
    setModalName('');
    setShow(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await dispatch(whoAmI()).unwrap();
        console.log(data, 'data');

        setFormData({
          id: data?.user?.id,
          name: data?.user?.name,
          email: data?.user?.email,
          phone: data?.user?.phone,
          loginPin: data?.user?.pin,
          language: data?.user?.lang,
          display_localized_names: data?.user?.display_localized_names === 1 ? true : false,
        });
      } catch (error) {
        console.error('Failed to fetch inventory data: ', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <section className={`${language === 'en' ? `${st.pageWrapper}` : `${st.pageWrapperAr}`}`}>
        <Tab.Container id='left-tabs-example' defaultActiveKey='first'>
          <div className={`${st.pageTitle}`}>
            <NavLink to='/dashboard' className={`${st.backBtn}`}>
              <MdArrowBackIos className={`${st.icon}`} /> {t('GLOBAL.BACK')}
            </NavLink>
            <div className={`${st.pageTitleRow}`}>
              <div className={`${st.rowTitleLeft}`}>
                <h5>
                  {' '}
                  <h5>Ayman</h5>{' '}
                  <Badge
                    type='Secondary'
                    text='Account Owner

'
                  />
                </h5>
              </div>
              <div className={`${st.rowTitleRight}`}>
                <OutlineBtn
                  onClick={() => {
                    handleShow('change Password', true);
                  }}
                >
                  <img src={lock} className={`${st.icon}`} />

                  {t('GLOBAL.CHANGE_PASSWORD')}
                </OutlineBtn>
              </div>
            </div>
            <Nav className={`${cxt.headertabs}`}>
              <Nav.Item>
                <Nav.Link
                  onClick={async () => {
                    //   await dispatch(showtimed_eventsList(paramsObj1));
                  }}
                  eventKey='first'
                  className={`${cxt.headertabslinks}`}
                >
                  {t('GLOBAL.PROFILE')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={async () => {
                    //   await dispatch(showtimed_eventsList(paramsObj3));
                  }}
                  eventKey='second'
                  className={`${cxt.headertabslinks}`}
                >
                  {t('GLOBAL.NOTIFICATIONS')}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className={`${st.pageWrapperInside} ${st.setWidth} pt-0`}>
            <Tab.Content className={`${st.pageWrapperInside}`}>
              <Tab.Pane
                eventKey='first'
                onEnter={async () => {
                  // await dispatch(showtimed_eventsList(paramsObj1));
                }}
              >
                <Card>
                  <Card.Body className={`contentBox p-4`}>
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col md={12} lg={12} className='my-1'>
                          <Form.Group className={`${cx.formField}`}>
                            <Form.Label>Name</Form.Label>
                            <Form.Group className={`${cx.formField}`}>
                              <Form.Control
                                type='text'
                                name='name'
                                value={formData.name}
                                onChange={handleChange}
                                required
                              />
                            </Form.Group>
                          </Form.Group>
                        </Col>
                        <Col md={12} lg={12} className='my-1'>
                          <Form.Group className={`${cx.formField}`}>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                              type='text'
                              name='phone'
                              value={formData.phone || ''}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12} lg={12} className='my-1'>
                          <Form.Group className={`${cx.formField}`}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type='email'
                              name='email'
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12} lg={12} className='my-1'>
                          <Form.Group className={`${cx.formField}`}>
                            <Form.Label>Login Pin</Form.Label>
                            <Form.Control
                              type='text'
                              name='loginPin'
                              value={formData.loginPin || ''}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12} lg={12} className='my-1'>
                          <Form.Group className={`${cx.formField}`}>
                            <Form.Label>Language</Form.Label>
                            <Form.Control
                              as='select'
                              name='language'
                              value={formData.language}
                              onChange={handleChange}
                              required
                            >
                              <option value='en'>English</option>
                              <option value='ar'>Arabic </option>
                              {/* Add more languages as needed */}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={12} lg={12} className='my-3'>
                          <Form.Group className={`${cx.formField}`}>
                            <Form.Check
                              type='checkbox'
                              name='display_localized_names'
                              label='Display Localized Names'
                              checked={formData.display_localized_names}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12} lg={12}>
                          <NormalBtn type='submit'>Save Changes</NormalBtn>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </section>
      <Modals show={show} handleClose={handleClose} modalName={modalName} />
    </>
  );
};

export default UserProfile;
