import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Modal, Row, Col, Form, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import cx from './Modals.module.scss';
import st from '../../style.module.scss';
import Select from 'react-select';
import { FilterDropdown, SingleFilterDropdown } from '../Dropdown/Dropdowns';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  AllDiscountTyps,
  changeFilterState,
  customersAllData,
  filterArray,
  filterClrBtn,
  getAllordersListData,
  getAllordersListDataBranch,
  // getOrderList,
  OrdertagList,
  resetOrderFilter,
  setOrdersFilter,
  setPageCount,
  showLoading,
  tagsData,
} from '../../redux_toolkit/reducer/orderModalsReducer';
import GlobalContext from '../../store/global-context';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  OrderSourceOption,
  OrderStatusOption,
  OrderTypeOption,
  YesNoOptions,
  loadingSelect,
} from '../../constants/dropdownconstants';
// import { userBranchListItems } from "../../redux_toolkit/reducer/manageBranchApiReducer";
// import { customerList } from "../../redux_toolkit/reducer/menuCustomerApiReducer";
import { createFilterParam } from '../../utils/functions';

import { discountListItems } from '../../redux_toolkit/reducer/manageDiscountApiReducer';
import { BusinessDate, CustomContent } from '../../pages/Orders/OrderDropdowns';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import { showTagsList, showprice_tagsList } from '../../redux_toolkit/reducer/manageCommanApiReducer';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CustomSelect } from '../CustomSelect/CustomSelect';
import { OutlineBtn } from '../buttons/OutlinedBtn';
import { NormalBtn } from '../buttons/NormalBtn';
import { TopBarLoadingComp } from '../Loading/TopBarLoading';
interface OptionsFilter {
  label: string;
  value: string | number;
  key?: string;
  disabled?: boolean;
}

const RefactoreOrderModal = (props: any) => {
  const { t } = useTranslation();
  const counting = useSelector((state: any) => state.orderModalsReducer.branchCount);
  const loadingStatus = useSelector((state: any) => state.orderModalsReducer.loadingStatus);
  const userBranchList = useSelector((state: any) => state.manageBranchApiReducer);
  const comman = useSelector((state: any) => state.manageCommanApiReducer);
  const discountState = useSelector((state: any) => state.manageDiscountApiReducer);
  const deviceState = useSelector((state: any) => state.manageDeviceApiReducer);
  const OrderState = useSelector((state: any) => state.orderModalsReducer);

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  //for tag listing //
  let { show, handleClose, modalName, data, postdata, defaultTags, handlesetAllTagData } = props;
  let option: OptionsFilter[] = [];
  data?.forEach((element: any) => {
    option.push({ value: element?.id, label: element?.name });
  });
  const options = [
    {
      value: '1',
      label: 'Pick up',
    },
    {
      value: '2',
      label: 'Delivery',
    },
  ];
  const deliveryzone = [
    {
      value: '1',
      label: 'Shipping',
    },
    {
      value: '2',
      label: 'Ordered',
    },
  ];
  const param = useParams();
  const [advanceOptions, setadvanceOptions] = useState(false);

  const [ordertype, setOrderType] = useState('');
  const orderTypehandler = (event: any) => {
    setOrderType(event.label);
  };

  const [subshow, setsubShow] = useState(false);
  const [optionTags, setOptionTags] = useState([]);
  const [submodalName, setsubModalName] = useState('');
  const [dateRange, setDateRange] = useState<any>([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    const startDate: any = searchParams.get('business_date')?.split(' - ')[0];
    const endDate: any = searchParams.get('business_date')?.split(' - ')[1];
    setDateRange([new Date(startDate), new Date(endDate)]);
  }, [searchParams]);

  const subdeliveryAddress = (modalname: string, status: boolean) => {
    setsubModalName(modalname);
    setsubShow(status);
  };
  const subhandleClose = () => {
    setsubShow(false);
    setsubModalName('');
  };
  //for add tag //
  function newpostdata(tags: string) {
    postdata(tags);
  }

  const [laoding, showloading] = useState(false);
  const [dropdownLoading, setDropdownLoading] = useState(false);

  const [branchData, setBranchData] = useState<any[]>([]);
  const [customerData, setCustomerData] = useState<any[]>([]);
  const [discountData, setDiscountData] = useState<any[]>([]);
  const [devicceData, setDeviceData] = useState<any[]>([]);
  const [orderTagData, setOrderTagData] = useState<any[]>([]);

  const orderFilter = () => {
    var urlString = window.location.href;
    var url = new URL(urlString);
    var customerId = url.searchParams.get('customerId');
    if (customerId) filterState.customer = customerId;
  };

  useEffect(() => {
    orderFilter();
  }, [counting]);
  useEffect(() => {
    let tagArray: any[] = [];
    OrdertagList?.map((item: any, index: number) => {
      tagArray.push({
        label: item?.name,
        value: item?.id,
      });
    });
    setOrderTagData(tagArray);
  }, [OrderState.tagListcount]);

  useEffect(() => {
    showloading(false);
  }, [loadingStatus]);

  const filterObj = useMemo(() => {
    return Object.fromEntries([...searchParams]);
  }, [searchParams]);
  const [filterState, setFilterState] = useState<any>();

  useEffect(() => {
    Object.keys(filterObj).length > 0 && dispatch(changeFilterState(true));
    if (props.modalName === 'order filter') {
      setFilterState({
        ...filterObj,
        branches: filterObj?.branches?.split(',') || [],
        device_id: filterObj?.device_id?.split(',') || [],
        tags: filterObj?.tags?.split(',') || [],
        has_tag: (filterObj?.has_tag),
        has_discount_amount: (filterObj?.has_discount_amount),
      });
    }
  }, [props.modalName, filterObj])

  useEffect(() => {
    let branchArray: any[] = [];
    // userBranchListItems?.map((item: any, index: number) => {
    //   branchArray.push({
    //     label: item?.name,
    //     value: item?.id,
    //   });
    // });
    setBranchData(branchArray);
  }, [userBranchList.userBranchListCount]);
  useEffect(() => { }, []);

  const getAllordersListDataxBranch: any = useSelector(
    (state: any) => state.orderModalsReducer.getAllordersListDataxBranch,
  );

  const tagsAllData: any = useSelector((state: any) => state.orderModalsReducer.tagsAllData);

  useEffect(() => {
    let discountArray: any[] = [];
    discountListItems?.map((item: any, index: number) => {
      discountArray.push({
        label: item?.english_name,
        value: item?.id,
      });
    });
    setDiscountData(discountArray);
  }, [discountState.discountListCount]);

  useEffect(() => {
    let deviceArray: any[] = [];
    comman?.showdevicesListData?.map((item: any, index: number) => {
      deviceArray.push({
        label: item?.name,
        value: item?.id,
      });
    });
    setDeviceData(deviceArray);
  }, [deviceState.userDeviceListCount]);

  const handleCloseModal = () => {
    props.handleClose();
  };

  const [branchId, setbranchId] = useState('');
  let navigate = useNavigate();

  useEffect(() => {
    let arr: any = [];
    comman?.showTagsListData.map((a: any) => {
      arr.push({ value: a.id, label: a.name });
    });
    setOptionTags(arr);
  }, [comman?.showTagsListData]);


  return (
    <>
      {/* ############################################################
 ######################## ORDER MODALS ########################### */}
      {/* START New Call Center Order */}
      {props.modalName === 'order call' && (
        <Modal scrollable className={`${cx.ctsModal}`} show={props.show} onHide={props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('GLOBAL.NEW_CALL_CENTER_ORDER')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label> {t('GLOBAL.CUSTOMER_PHONE')}</Form.Label>
                  <Form.Control type='text' />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label> {t('GLOBAL.NEW_CUSTOMER_NAME')}</Form.Label>
                  <Form.Control type='text' />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label> {t('GLOBAL.type')}</Form.Label>
                  <Select
                    isSearchable={true}
                    options={options}
                    onChange={e => {
                      orderTypehandler(e);
                    }}
                    placeholder={t('GLOBAL.PICK_UP')}
                  />
                </Form.Group>
              </Col>

              {ordertype === 'Delivery' && (
                <Col md={12} lg={12}>
                  <Form.Group className={`${cx.formField}`}>
                    <Form.Label>{t('GLOBAL.ADDRESS')}</Form.Label>
                    <button
                      className={`${cx.addressBtn}`}
                      onClick={() => {
                        subdeliveryAddress('delivery address', true);
                      }}
                    >
                      {t('GLOBAL.ADD_NEW_ADDRESS')}
                    </button>
                  </Form.Group>
                </Col>
              )}

              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{t('GLOBAL.BRANCH')}</Form.Label>
                  <Form.Select>
                    <option>Mall 1</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div>
              <Button
                className={`${cx.btnClose}`}
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                {t('GLOBAL.CLOSE')}
              </Button>
              <Button className={`${cx.SubmitBtn}`}>{t('GLOBAL.APPLY')}</Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {/* END New Call Center Order */}

      {/* START Delivery Address */}
      {submodalName === 'delivery address' && (
        <Modal scrollable className={`${cx.ctsModal}`} show={props.show} onHide={props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> {t('GLOBAL.CREATE_ADDRESS')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label> {t('GLOBAL.NAME')}</Form.Label>
                  <Form.Control type='text' />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label> {t('GLOBAL.DESCRIPTION')}</Form.Label>
                  <Form.Control as='textarea' type='text' style={{ height: '150px' }} />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label> {t('GLOBAL.DELIVERY_ZONE')}</Form.Label>
                  <Select
                    isSearchable={true}
                    options={deliveryzone}
                    onChange={e => {
                      orderTypehandler(e);
                    }}
                    placeholder={t('GLOBAL.SELECT_PLACEHOLDER')}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div>
              <Button className={`${cx.btnClose}`} onClick={subhandleClose}>
                {t('GLOBAL.CLOSE')}
              </Button>
              <Button className={`${cx.SubmitBtn}`}>{t('GLOBAL.APPLY')}</Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {/* END Delivery Address */}

      {/* START Order Filter */}
      {props.modalName === 'order filter' && (
        <Modal scrollable className={`${cx.ctsModal}`} show={props.show}>
          <Modal.Header closeButton>
            <Modal.Title>{t('GLOBAL.FILTER')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{t('GLOBAL.REFERENCE')}</Form.Label>
                  <Form.Control
                    defaultValue={filterState?.reference}
                    type='text'
                    onChange={(e: any) => {
                      setFilterState((prev: any) => {
                        return { ...prev, reference: e.target.value };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{t('GLOBAL.REFERENCE_AFTER')}</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={filterState?.after_reference}
                    onChange={e => {
                      setFilterState((prev: any) => {
                        return { ...prev, after_reference: e.target.value };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{t('GLOBAL.NUMBER')}</Form.Label>
                  <Form.Control
                    type='number'
                    defaultValue={filterState?.number}
                    onChange={e => {
                      setFilterState((prev: any) => {
                        return { ...prev, number: e.target.value };
                      });
                    }}
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <CustomSelect
                    name='branch'
                    isMulti
                    label={t('GLOBAL.BRANCH')}
                    isLoading={comman?.isLoading}
                    options={comman?.showbranchesListData?.map((item: any) => {
                      return { value: item.id, label: item.name };
                    })}
                    defaultValue={filterState?.branches}
                    onChange={(items: any) => {
                      setFilterState((prev: any) => {
                        return {
                          ...prev,
                          branches: items.map((e: any) => e.value),
                        };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <CustomSelect
                    name='has_tag'
                    placeholder={t('GLOBAL.SELECT_PLACEHOLDER')}
                    label={t('GLOBAL.HAS_TAGS')}
                    options={YesNoOptions}
                    defaultValue={+filterState?.has_tag}
                    onChange={(e: any) => {
                      setFilterState((prev: any) => {
                        return { ...prev, has_tag: e.value };
                      });
                    }}
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <CustomSelect
                    name='has_discount'
                    placeholder={t('GLOBAL.SELECT_PLACEHOLDER')}
                    label={t('GLOBAL.HAS_DISCOUNT')}
                    options={YesNoOptions}
                    defaultValue={+filterState?.has_discount_amount}
                    onChange={(e: any) => {
                      setFilterState((prev: any) => {
                        return { ...prev, has_discount_amount: e.value };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <CustomSelect
                    name='tag'
                    isMulti
                    label={t('GLOBAL.TAG')}
                    isLoading={dropdownLoading}
                    options={optionTags}
                    defaultValue={filterState?.tags}
                    onChange={(items: any) =>
                      setFilterState((prev: any) => {
                        return {
                          ...prev,
                          tags: items.map((e: any) => e.value),
                        };
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <CustomSelect
                    name='status'
                    placeholder={t('GLOBAL.SELECT_PLACEHOLDER')}
                    label={t('GLOBAL.STATUS')}
                    options={OrderStatusOption}
                    defaultValue={filterState?.status}
                    onChange={(e: any) => {
                      setFilterState((prev: any) => {
                        return { ...prev, status: e.value };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <CustomSelect
                    name='type'
                    placeholder={t('GLOBAL.SELECT_PLACEHOLDER')}
                    label={t('GLOBAL.TYPE')}
                    options={OrderTypeOption}
                    onChange={(e: any) => {
                      setFilterState((prev: any) => {
                        return { ...prev, type: e.value };
                      });
                    }}
                    defaultValue={filterState?.type}
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <CustomSelect
                    name='source'
                    placeholder={t('GLOBAL.SELECT_PLACEHOLDER')}
                    label={t('GLOBAL.SOURCE')}
                    defaultValue={filterState?.source}
                    options={OrderSourceOption}
                    onChange={(e: any) => {
                      setFilterState((prev: any) => {
                        return { ...prev, source: e.value };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <CustomSelect
                    name='ahead'
                    placeholder={t('GLOBAL.SELECT_PLACEHOLDER')}
                    label={t('GLOBAL.AHEAD')}
                    defaultValue={filterState?.Ahead}
                    options={YesNoOptions}
                    onChange={(e: any) => {
                      setFilterState((prev: any) => {
                        return { ...prev, Ahead: e.value };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{t('GLOBAL.CHECK_NUMBER')} </Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={filterState?.check_number}
                    onChange={e => {
                      setFilterState((prev: any) => {
                        return { ...prev, check_number: e.target.value };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{t('GLOBAL.RECEIPT_NOTES')} </Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={filterState?.customer_notes}
                    onChange={e => {
                      setFilterState((prev: any) => {
                        return { ...prev, customer_notes: e.target.value };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{t('GLOBAL.KITCHEN_NOTES')} </Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={filterState?.kitchen_notes}
                    onChange={e => {
                      setFilterState((prev: any) => {
                        return { ...prev, kitchen_notes: e.target.value };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <CustomSelect
                    name='customer'
                    placeholder={t('GLOBAL.SELECT_PLACEHOLDER')}
                    label={t('GLOBAL.CUSTOMER')}
                    isLoading={comman?.isLoading}
                    defaultValue={filterState?.customer}
                    options={comman?.showCustomersListData?.map((item: any) => {
                      return { value: item.id, label: item.name };
                    })}
                    onChange={(e: any) => {
                      setFilterState((prev: any) => {
                        return { ...prev, customer: e.value };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <CustomSelect
                    name='discount_name'
                    placeholder={t('GLOBAL.SELECT_PLACEHOLDER')}
                    label={t('GLOBAL.DISCOUNT_NAME')}
                    isLoading={comman?.isLoading}
                    defaultValue={filterState?.discount}
                    options={
                      comman?.showdiscountsListData?.map((item: any) => {
                        return { value: item.id, label: item.name };
                      }) || []
                    }
                    onChange={(e: any) => {
                      setFilterState((prev: any) => {
                        return { ...prev, discount: e.value };
                      });
                    }}
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{t('GLOBAL.DUE_DATE')}</Form.Label>
                  <Form.Control
                    type='date'
                    defaultValue={filterState?.due_at}
                    onChange={e => {
                      setFilterState((prev: any) => {
                        return { ...prev, due_at: e.target.value };
                      });
                    }}
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <CustomSelect
                    name='device'
                    isMulti
                    placeholder={t('GLOBAL.SELECT_PLACEHOLDER')}
                    label={t('GLOBAL.DEVICE')}
                    isLoading={comman?.isLoading}
                    options={comman?.showdevicesListData?.map((item: any) => {
                      return { value: item.id, label: item.name };
                    })}
                    onChange={(items: any) =>
                      setFilterState((prev: any) => {
                        return {
                          ...prev,
                          device_id: items.map((e: any) => e.value),
                        };
                      })
                    }
                    defaultValue={filterState?.device_id}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <NavLink
              to='#'
              className={`${cx.rightOption}`}
              onClick={async () => {

                setSearchParams({})
                props.handleClose();
                setFilterState({})


              }}
            >
              {t('GLOBAL.CLEAR')}
            </NavLink>{' '}
            <div className='d-flex gap-2'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                }}
              >
                {t('GLOBAL.CLOSE')}
              </OutlineBtn>
              <NormalBtn
                loading={laoding}
                onClick={async () => {


                  setSearchParams({
                    ...createFilterParam('reference', filterState?.reference),
                    ...createFilterParam('after_reference', filterState?.after_reference),
                    ...createFilterParam('number', filterState?.number),
                    ...createFilterParam('branches', filterState?.branches?.toString()),
                    ...createFilterParam('has_discount_amount', filterState?.has_discount_amount?.toString()),
                    ...createFilterParam('has_tag', filterState?.has_tag?.toString()),
                    ...createFilterParam('tags', filterState?.tags?.toString()),
                    ...createFilterParam('status', filterState?.status),
                    ...createFilterParam('type', filterState?.type),
                    ...createFilterParam('source', filterState?.source?.toString()),
                    ...createFilterParam('Ahead', filterState?.Ahead?.toString()),
                    ...createFilterParam('check_number', filterState?.check_number),
                    ...createFilterParam('kitchen_notes', filterState?.kitchen_notes),
                    ...createFilterParam('customer', filterState?.customer),
                    ...createFilterParam('discount', filterState?.discount?.toString()),
                    ...createFilterParam('due_at', filterState?.due_at),
                    ...createFilterParam('device_id', filterState?.device_id?.toString()),
                  });
                  setFilterState({})
                  props.handleClose();
                }}
              >
                {t('GLOBAL.APPLY')}
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {/* END Order Filter */}

      {/* START Order add tags Filter */}
      {props.modalName === 'order add tags' && (
        <Modal className={`${cx.ctsModal}`} show={props.show} onHide={props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> {t('GLOBAL.ADD_TAGS')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <CustomSelect
                    name='tag'
                    isMulti
                    label={t('GLOBAL.TAGS')}
                    options={optionTags}
                    onChange={(items: any) => newpostdata(items.map((e: any) => e.value).join(','))}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                {t('GLOBAL.CLOSE')}
              </OutlineBtn>

              <NormalBtn
                loading={laoding}
                onClick={() => {
                  showloading(true);
                  props.addOrderTags(showloading);
                  setOrderType('');
                }}
              >
                {t('GLOBAL.APPLY')}
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {/* END Order add tags Filter */}

      {/* START Order edit tags Filter */}
      {props.modalName === 'order edit tags' && (
        <Modal className={`${cx.ctsModal}`} show={props.show} onHide={props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> {t('GLOBAL.EDIT_TAGS')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <CustomSelect
                    name='tag'
                    isMulti
                    defaultValue={defaultTags.map((e: any) => e.value)}
                    isLoading={dropdownLoading}
                    label={t('GLOBAL.TAGS')}
                    options={optionTags}
                    onChange={(items: any) => newpostdata(items.map((e: any) => e.value).join(','))}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                {t('GLOBAL.CLOSE')}
              </OutlineBtn>
              <NormalBtn
                loading={laoding}
                onClick={() => {
                  showloading(true);
                  props.addOrderTags(showloading);
                  setOrderType('');
                }}
              >
                {t('GLOBAL.APPLY')}
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {/* END Order add tags Filter */}

      {/* START Order Remove tags Filter */}
      {props.modalName === 'order remove tags' && (
        <Modal className={`${cx.ctsModal}`} show={props.show} onHide={props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> {t('GLOBAL.REMOVE_TAGS')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <CustomSelect
                    name='tag'
                    isMulti
                    label={t('GLOBAL.TAGS')}
                    options={optionTags}
                    onChange={(items: any) => newpostdata(items.map((e: any) => e.value).join(','))}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <OutlineBtn
              onClick={() => {
                props.handleClose();
                setOrderType('');
              }}
            >
              {t('GLOBAL.CLOSE')}
            </OutlineBtn>
            <NormalBtn
              loading={laoding}
              onClick={() => {
                showloading(true);
                props.removeOrderTags();
                setOrderType('');
              }}
            >
              {t('GLOBAL.APPLY')}
            </NormalBtn>
          </Modal.Footer>
        </Modal>
      )}
      {/* END Order Remove tags Filter */}

      {props.modalName === 'order add Order Type' && (
        <Modal className={`${cx.ctsModal}`} show={props.show} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title> {t('GLOBAL.ADD_BRANCH')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>
                    {t('GLOBAL.TAGS')}
                    {['top'].map(placement => (
                      <OverlayTrigger
                        key={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>{t('GLOBAL.TO_ASSIGN_TAGS_TO_THIS_CUSTOMER')}</Tooltip>
                        }
                      >
                        <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                          <AiOutlineInfoCircle />
                        </span>
                      </OverlayTrigger>
                    ))}
                  </Form.Label>
                  {/* {tagsFIlterCusData && ( */}
                  {getAllordersListDataxBranch && (
                    <SingleFilterDropdown
                      loading={dropdownLoading}
                      options={getAllordersListDataxBranch?.map((item: any) => ({
                        label: item?.name,
                        value: item?.id,
                      }))}
                      onSelect={(e: any) => {
                        setbranchId(e);
                      }}
                    />
                  )}
                  {!getAllordersListDataxBranch && <SingleFilterDropdown defaultoptions={loadingSelect} />}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                {t('GLOBAL.CLOSE')}
              </OutlineBtn>
              <NormalBtn
                onClick={async () => {
                  localStorage.setItem('branchId', branchId);
                  navigate('/newcallcenter');
                }}
              >
                {t('GLOBAL.APPLY')}
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default RefactoreOrderModal;
