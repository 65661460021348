import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { notify } from '../../constants/notifyconstants';
import { fetch5, fetch6, getReqNoToken, loginFetch } from '../../Apis/commonApis';
import { t } from 'i18next';
import { getCookie, setCookie } from '../../utils/functions';

export const loginUser: any = createAsyncThunk('auth/login', async loginData => {
  try {
    const response = await loginFetch(`${process.env.REACT_APP_BASEURL}/auth/Login`, loginData);

    if (response.status == true) {
      notify(response?.message, 'success');
      localStorage.setItem(
        '___data',
        JSON.stringify({
          ...response.data.user,
          first_login: response.data.first_login,
          trial_ends_at: response.data.trial_ends_at,
        }),
      );

      const rememberMe = localStorage.getItem('xPmOtQSRM');
      if (rememberMe == 'true') {
        localStorage.setItem('xPmOtQST', response.data.token);
        // setCookie('xPmOtQST', response.data.token, 7);
      } else {
        localStorage.setItem('xPmOtQST', response.data.token);

        // setCookie('xPmOtQST', response.data.token, 1);
      }

      localStorage.setItem('__scope__', JSON.stringify(response.data.oauth.user_scopes));
      return response.data;
    } else {
      return response;
    }
  } catch (error: any) {
    if (error?.response?.data?.message == 'Invalid credentials') {
      notify(t('GLOBAL.INVALID_CREDENTIALS'), 'error');
      return error;
    }
    // notify(error?.message, "error");
  }
});
export const signUp: any = createAsyncThunk('auth/signUp', async loginData => {
  try {
    const response = await loginFetch(`${process.env.REACT_APP_BASEURL}/auth/Register`, loginData);
    if (response.status == true) {
      notify('registration completed successfully', 'success');
    } else {
      notify(response.message, 'error');
    }
    return response;
  } catch (error: any) {
    notify(error?.response?.data?.message, 'error');
  }
});
export const businessTypesSignUp: any = createAsyncThunk('auth/businessTypesSignUp', async loginData => {
  try {
    const response = await getReqNoToken(`${process.env.REACT_APP_BASEURL}/auth/get-business-type`, loginData);
    return response;
  } catch (error: any) {
    // notify(error?.response?.data?.message, 'error');
  }
});
export const countriesSignUp: any = createAsyncThunk('auth/countriesSignUp', async loginData => {
  try {
    const response = await getReqNoToken(`${process.env.REACT_APP_BASEURL}/auth/get-countries`, loginData);
    return response;
  } catch (error: any) {
    // notify(error?.response?.data?.message, 'error');
  }
});
export const whoAmI: any = createAsyncThunk('auth/whoAmI', async (userData?: any) => {
  try {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/auth/whoami`, userData);
    return response;
  } catch (error: any) {
    // notify(error?.response?.data?.message, 'error');
  }
});
const initialState = {
  user: null,
  isLoading: false,
  businessTypesSignUpData: [],
  countriesSignUpData: [],
};
const LoginReducer: any = createSlice({
  name: 'LoginReducer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(signUp.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      // state.user = action.payload;
      state.isLoading = false;
    });
    builder.addCase(businessTypesSignUp.fulfilled, (state, action) => {
      state.businessTypesSignUpData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(countriesSignUp.fulfilled, (state, action) => {
      state.countriesSignUpData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(countriesSignUp.pending, (state, action) => {
      state.countriesSignUpData = action.payload;
      state.isLoading = true;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default LoginReducer.reducer;
