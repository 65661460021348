import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch2, fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  costAdjustmentList: {},
  singleCostAdjustment: {},
  addedCostAdjustmentId: '',
  loading: false,
  progressLoading: false,
  pageLoading: false,
  isDataFiltered: false,
  error: '',
  costAdjustmentListCount: 0,
  costAdjustmentFilter: 0,
  loadingStatus: 0,
  editItemData: {
    id: '',
    cost_per_unit: '',
  },
};

export let newCostAdjustment: any = {
  branch: '',
  item: '',
};

export let filterValue: any = {};

export let get_costAdjustment_destinationList: any;
export let get_costAdjustment_reasonList: any;
export let get_costAdjustment_itemList: any;
export let get_costAdjustment_productList: any;
export let get_costAdjustment_creatorList: any;

export let showLoading: boolean = false;

export const costAdjustmentList: any = createAsyncThunk('costAdjustmentList', async (body: any) => {
  try {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/cost_adjustments`, body);

    return result;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

// =====================================================
export const getSingleCostAdjustment: any = createAsyncThunk('getSingleCostAdjustment', async (body: any) => {
  try {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/cost_adjustments/${body}`);

    return result;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

// =====================================================
export const updateCostAdjustment: any = createAsyncThunk('updateCostAdjustment', async (body: any) => {
  try {
    const result = await fetch9(`${process.env.REACT_APP_BASEURL}/inventory/cost_adjustments/${body.id}`, body.data);
    notify('Cost adjustment updated successfully', 'success');
    return result;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

// =====================================================

export const deleteCostAdjustment: any = createAsyncThunk('deleteCostAdjustment', async (body: any) => {
  try {
    const result = await fetch3(`${process.env.REACT_APP_BASEURL}/inventory/cost_adjustments/${body.id}`, 'delete');
    notify('Cost adjustment deleted successfully', 'success');
    return result;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

// =====================================================

export const addCostAdjustment: any = createAsyncThunk('addCostAdjustment', async body => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/cost_adjustments`, body);
    notify('Cost adjustment added successfully', 'success');
    return result;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

// =====================================================

export const submitCostAdjustment: any = createAsyncThunk('submitCostAdjustment', async (body: any) => {
  try {
    const result = await fetch3(
      `${process.env.REACT_APP_BASEURL}/inventory/cost_adjustments/submitCost/${body.id}`,
      'get',
    );

    return result;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

// =====================================================

export const filterCostAdjustment: any = createAsyncThunk('filterCostAdjustment', async body => {
  try {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/cost_adjustments/filterCostAdjustments`,
      body,
    );

    return result;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

// =====================================================

export const get_costAdjustment_destination: any = createAsyncThunk('get_costAdjustment_destination', async () => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/manage/branches`, 'get');
  get_costAdjustment_destinationList = result.data;
  return result;
});

export const get_costAdjustment_creator: any = createAsyncThunk('get_costAdjustment_creator', async () => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/auth/users`);
  get_costAdjustment_creatorList = result.data;
  return result;
});

export const get_costAdjustment_reason: any = createAsyncThunk('get_costAdjustment_reason', async () => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/manage/reasons`, 'get');
  get_costAdjustment_reasonList = result.data.qty_adjust_reasons_arr;
  return result;
});

export const get_costAdjustment_item: any = createAsyncThunk('get_costAdjustment_item', async () => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/inventory/items?type=all`, 'get');
  get_costAdjustment_itemList = result.data.items;
  return result;
});

export const get_costAdjustment_product: any = createAsyncThunk('get_costAdjustment_product', async () => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/menu/products`, 'get');
  get_costAdjustment_productList = result.data;
  return result;
});

export const addCostAdjustmentItem: any = createAsyncThunk('addCostAdjustmentItem', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/cost_adjustments/addItems/${body?.id}`,
    body?.data,
  );

  return result;
});

export const editCostAdjustmentItem: any = createAsyncThunk('editCostAdjustmentItem', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/cost_adjustments/editItems/${body?.id}`,
    body?.data,
  );

  return result;
});

export const deleteCostAdjustmentItem: any = createAsyncThunk('deleteCostAdjustmentItem', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/cost_adjustments/deleteItem/${body.id}`,
    body.data,
  );
  return result;
});

const costAdjustmentSlice: any = createSlice({
  name: 'Cost Adjustment',
  initialState,
  reducers: {
    setshowLoading(state = initialState, action: any) {
      showLoading = action.payload;
      state.loadingStatus += 1;
    },
    setEditItemData(state = initialState, action: any) {
      state.editItemData = { ...action.payload };
    },
    setIsDataFiltered(state = initialState, action: any) {
      state.isDataFiltered = action.payload;
    },
    setProgressLoading(state = initialState, action: any) {
      state.progressLoading = action.payload;
    },
    setPageLoading(state = initialState, action: any) {
      state.pageLoading = true;
    },
  },
  extraReducers: {
    [costAdjustmentList.fulfilled]: (state: any, { payload }: any) => {
      state.loading = false;
      state.progressLoading = false;
      state.costAdjustmentList = payload;
      state.isDataFiltered = false;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
        state.progressLoading = false;
      }
    },
    [costAdjustmentList.rejected]: state => {
      state.loading = false;
    },
    [costAdjustmentList.pending]: state => {
      state.loading = true;
    },
    // ==============================================
    [filterCostAdjustment.fulfilled]: (state: any, { payload }: any) => {
      state.loading = false;
      state.costAdjustmentList = payload;
      state.isDataFiltered = true;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    // ==============================================
    [getSingleCostAdjustment.fulfilled]: (state: any, { payload }: any) => {
      state.loading = false;
      state.singleCostAdjustment = payload.data[0];
      state.pageLoading = false;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [getSingleCostAdjustment.rejected]: state => {
      state.loading = false;
      state.pageLoading = false;

    },
    [getSingleCostAdjustment.pending]: state => {
      state.loading = true;
      state.pageLoading = true;

    },
    // ==============================================
    [updateCostAdjustment.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    // ==============================================
    [deleteCostAdjustment.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    // ==============================================
    [addCostAdjustment.fulfilled]: (state: any, { payload }: any) => {
      state.loading = false;
      state.addedCostAdjustmentId = payload.data[0].id;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    // ==============================================

    [get_costAdjustment_destination.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.costAdjustmentListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },

    [get_costAdjustment_reason.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.costAdjustmentListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [get_costAdjustment_creator.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.costAdjustmentListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [get_costAdjustment_item.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.costAdjustmentListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
  },
});

export const { setEditItemData, setIsDataFiltered, setProgressLoading, setPageLoading } = costAdjustmentSlice.actions;

export default costAdjustmentSlice.reducer;
