import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch10, fetch2, fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  loading: false,
  promotionsListCount: 0,
  productListCount: 0,
  comboListCount: 0,
  promotionsAddCount: 0,
  promotionDetailsCount: 0,
  activatePromotionsCount: 0,
  loadingStatus: 0,
  perIdPromotionDetail: null,
};

export let promotionsFilterValue: any = {
  type: '',
};

export let promotionsListItems: any;
export let promotionsListProducts: any;
export let promotionsListCombos: any;

export const promotionsList: any = createAsyncThunk('promotionsList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/promotions`, body);
  promotionsListItems = result.data;
  return result;
});

export const getProductList: any = createAsyncThunk('getProductList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/products`, body);
  promotionsListProducts = result.data;
  return result;
});
export const getCombosList: any = createAsyncThunk('getCombosList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/combos`, body);
  promotionsListCombos = result.data;
  return result;
});

export const promotionsAdd: any = createAsyncThunk('promotionsAdd', async body => {
  console.log(body);

  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/promotions`, body);
    console.log(result);
    return result;
  } catch (err: any) {
    console.log(err.response.data.message);
    notify(err.response.data.message);

    throw new Error(err);
  }
});
export const updatePromotions: any = createAsyncThunk('updatePromotions', async (body: any) => {
  try {
    const result = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/promotions/` + body.id, body);
    return result;
  } catch (err: any) {
    console.log(err.response.data.message);
    notify(err.response.data.message);

    throw new Error(err);
  }
});
export const promotionAddBranch: any = createAsyncThunk('promotionsAdd', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/promotion_add_branch`, body);
  return result;
});
export const promotionDetails: any = createAsyncThunk('promotionDetails', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/promotions/${body}`);
  // perIdPromotionDetail = result.data;
  return result;
});
export const promotionAddCustomertag: any = createAsyncThunk('promotionAddCustomertag ', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/promotion_add_customertag`, body);
  return result;
});
export const activatePromotions: any = createAsyncThunk('activatePromotions', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/activate_promotion/` + body);
  return result;
});
export const deletePromotions: any = createAsyncThunk('deletePromotions', async body => {
  const result = await fetch10(`${process.env.REACT_APP_BASEURL}/manage/promotions/` + body);
  notify('Promotion deleted successfully', 'success');
  return result;
});

const managePromotionsSlice: any = createSlice({
  name: 'Product',
  initialState,
  reducers: {
    changeStateAddPromotion(state = initialState) {
      state.promotionsAddCount = 0;
    },
    setshowLoading(state = initialState) {
      state.loadingStatus += 1;
    },
  },
  extraReducers: {
    [promotionsList.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.promotionsListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [getProductList.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.productListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [getCombosList.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.comboListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [promotionsAdd.fulfilled]: (state: any, action) => {
      state.loading = false;
      notify('Promotion added successfully', 'success');
    },
    [updatePromotions.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      // state.promotionsAddCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify('Promotion updated successfully', 'success');
        state.error = message;
      }
    },
    [promotionDetails.fulfilled]: (state: any, { payload: { error, message, data } }) => {
      state.loading = false;
      state.promotionDetailsCount += 1;
      state.perIdPromotionDetail = data;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [activatePromotions.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.activatePromotionsCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
  },
});

export const { changeStateAddPromotion, setshowLoading } = managePromotionsSlice.actions;
export default managePromotionsSlice.reducer;
