import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch6 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  loading: false,
  progressLoading: false,
  error: null,
  isDataFiltered: false,
  generalPageData: [],
  branchesPageData: [],
  inventoryPageData: [],
  itemConsumtion: [],
  itemWasted: [],
  dashboardFilter: {
    branches: null,
    branch_tags: null,
  },
};

export const getGeneralPageDashboard: any = createAsyncThunk('dashboard/general', async (body: any) => {
  try {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/reports/overview`, body);
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const getInventoryItemsConsumtion: any = createAsyncThunk('dashboard/consumption', async (body: any) => {
  try {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/reports/overview/top-items/consumed`, body);
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const getInventoryItemsWasted: any = createAsyncThunk('dashboard/wasted', async (body: any) => {
  try {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/reports/overview/top-items/wasted`, body);
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const getBranchesPageData: any = createAsyncThunk('dashboard/branches', async (body: any) => {
  try {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/reports/overview/branches`, body);
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const getInventoryPageData: any = createAsyncThunk('dashboard/inventory', async (body: any) => {
  try {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/reports/overview/inventory`, body);
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

const dashboardSlice: any = createSlice({
  name: 'dashboardApis',
  initialState,
  reducers: {
    setProgressLoading(state: any = initialState, action: any) {
      state.progressLoading = action.payload;
    },
    setIsDataFiltered(state: any = initialState, action: any) {
      state.progressLoading = action.payload;
    },
    setDashboardFilterObj(state: any = initialState, action: any) {
      state.dashboardFilter.branches = action.payload ? action.payload.branches : null;
      state.dashboardFilter.branch_tags = action.payload ? action.payload.branch_tags : null;
    },
  },
  extraReducers: {
    [getGeneralPageDashboard.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.generalPageData = payload.data;
      if (payload?.error) {
        state.error = payload?.error;
      } else {
        state.error = payload?.message;
      }
    },
    [getInventoryItemsConsumtion.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.itemConsumtion = payload.data;
      if (payload?.error) {
        state.error = payload?.error;
      } else {
        state.error = payload?.message;
      }
    },
    [getInventoryItemsWasted.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.itemWasted = payload.data;
      if (payload?.error) {
        state.error = payload?.error;
      } else {
        state.error = payload?.message;
      }
    },
    [getBranchesPageData.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.branchesPageData = payload.data;
      if (payload?.error) {
        state.error = payload?.error;
      } else {
        state.error = payload?.message;
      }
    },
    [getBranchesPageData.rejected]: (state: any, { payload }) => {
      state.loading = true;
      state.branchesPageData = [];
    },
    [getInventoryPageData.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.inventoryPageData = payload.data;
      if (payload?.error) {
        state.error = payload?.error;
      } else {
        state.error = payload?.message;
      }
    },
    [getInventoryPageData.rejected]: (state: any, { payload }) => {
      state.loading = true;
      state.inventoryPageData = [];
    },
  },
});

export const { setProgressLoading, setIsDataFiltered, setDashboardFilterObj } = dashboardSlice.actions;
export default dashboardSlice.reducer;
