import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch10, fetch2, fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  loading: false,
  paymentListCount: 0,
  paymentSortListCount: 0,
  modifySortCount: 0,
  addPaymentCount: 0,
  deletePaymentCount: 0,
  loadingStatus: 0,
  paymentListItems: [],
};
export let paymentFilter = {
  type: '',
};
export let paymentListItems: any;
export let paymentSortListItems: any;
export let editPaymentData = {
  id: '',
  name: '',
  name_localized: '',
  is_active: '',
  auto_open_drawer: '',
  code: '',
  type: '',
  is_deleted: false,
};

export const paymentList: any = createAsyncThunk('paymentList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/payment_methods`, body);
  paymentListItems = result.data;
  return result;
});

export const addPayment: any = createAsyncThunk('addPayment', async body => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/payment_methods`, body);
  return result;
});
export const updatePayment: any = createAsyncThunk('updatePayment', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/payment_methods/${body.id}`, body);
  return result;
});
export const restorePayment: any = createAsyncThunk('restorePayment', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/payment_methods/${body.id}/restore`);
  return result;
});
export const payementMethodSortView: any = createAsyncThunk('payementMethodSortView', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/payement_method_sort_view`, body);
  paymentSortListItems = result.data;
  return result;
});
export const modifyPaymentSortView: any = createAsyncThunk('modifyPaymentSortView', async body => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/sort_payment_methods`, body);
  paymentSortListItems = result.data;
  return result;
});
export const deletePayment: any = createAsyncThunk('deletePayment', async body => {
  const result = await fetch10(`${process.env.REACT_APP_BASEURL}/manage/payment_methods/${body}`);
  return result;
});

const manageMorePaymentSlice: any = createSlice({
  name: 'manageMorePaymentSlice',
  initialState,
  reducers: {
    editPaymentFunc(state = initialState, action) {
      editPaymentData.id = action.payload.id;
      editPaymentData.name = action.payload.name;
      editPaymentData.name_localized = action.payload.name_localized;
      editPaymentData.is_active = action.payload.is_active;
      editPaymentData.auto_open_drawer = action.payload.auto_open_drawer;
      editPaymentData.code = action.payload.code;
      editPaymentData.type = action.payload.type;
      editPaymentData.is_deleted = action.payload.is_deleted;
    },
    showLoadingoff(state = initialState) {
      state.loadingStatus += 1;
    },
  },
  extraReducers: {
    [paymentList.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.paymentListItems = action.payload;
    },
    [paymentList.pending]: (state: any) => {
      state.loading = true;
    },
    [payementMethodSortView.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.paymentSortListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [addPayment.fulfilled]: (state: any, payload: any) => {
      state.loading = false;

      notify(payload?.payload?.massage, 'success');
    },
    [updatePayment.fulfilled]: (state: any, payload: any) => {
      state.loading = false;

      notify('Update payment method Successfully', 'success');

    },
    [restorePayment.fulfilled]: (state: any, payload: any) => {
      state.loading = false;

      notify("Restore payment method Successfully", 'success');

    },
    [modifyPaymentSortView.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.modifySortCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [deletePayment.fulfilled]: (state: any, payload: any) => {
      state.loading = false;
      console.log(payload, "pppppppppppp");

      notify("Delete payment method Successfully", 'success');
    },
  },
});

export const { editPaymentFunc, showLoadingoff } = manageMorePaymentSlice.actions;
export default manageMorePaymentSlice.reducer;
