import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { fetch10, fetch2, fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  token: '',
  loading: false,
  error: '',
  categoryList: 0,
  categoryListItems: [],
  categoryFilter: 0,
  createCategory: 0,
  editMenu: 0,
  filterValue: 0,
  sortMenuCategories: 0,
  sortMenuSubCategories: 0,
  catId: '',
  loadingStatus: 0,
  categorySortList: [],
  categoryGiftsList: [],
  categoryCombosList: [],
  categoryProductsList: [],
  editMenuCategoryData: {
    id: '',
    image: '',
    engname: '',
    localize: '',
    referance: '',
    name_localized: '',
    deleted_at: null,
    branches: [],
  },
  isFiltered: null,
  pageCount: 0,
};

export let showLoading: boolean = false;
export let categoryListItems: any = [];
export let sortCategories: any;
export let sortSubCategories: any;
export let filterValue: any = {
  type: '',
  name: '',
  referance: '',
  updatedafter: '',
  isdeleted: '',
};
// export let editMenuCategoryData: any = {
//   id: "",
//   img: "",
//   engname: "",
//   localize: "",
//   referance: "",
// };

export const categoryList: any = createAsyncThunk('categoryList', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/categories`, body);

  return result;
});
export const menuDisplay: any = createAsyncThunk('menuDisplay', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/menu_display`, body);
  return result;
});
export const categoryProducts: any = createAsyncThunk('categoryProducts', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/products`, body);
  return result;
});
export const categoryCombos: any = createAsyncThunk('categoryCombos', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/combos`, body);
  return result;
});
export const categoryGifts: any = createAsyncThunk('categoryGifts', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/gift_card_products`, body);
  return result;
});

export const createCategory: any = createAsyncThunk('createCategory', async body => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/menu/categories`, body);
  return result;
});
export const updateCategory: any = createAsyncThunk('updateCategory', async (body: any) => {
  console.log(body, '000');

  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/menu/categories/` + body.id, body);
  return result;
});

export const multiDeleteCategory: any = createAsyncThunk('multiDeleteCategory', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/category_multipledelete`, body);
  return result;
});
export const deleteCategory: any = createAsyncThunk('deleteCategory', async id => {
  try {
    const result = await fetch10(`${process.env.REACT_APP_BASEURL}/menu/categories/` + id);
    return result;
  } catch (res: any) {
    notify(res.response.data.errors.category[0], 'error');
  }
});
export const restoreCategory: any = createAsyncThunk('restoreCategory', async id => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/menu/categories/${id}/restore`);
  return result;
});

// export const allCategories: any = createAsyncThunk(
//   "allCategories",
//   async () => {
//     const result = await fetch3(
//       `${process.env.REACT_APP_BASEURL}/category_short`,
//       "get"
//     );
//     sortCategories = result.data;
//     return result;
//   }
// );

// export const allSubCategories: any = createAsyncThunk(
//   "allSubCategories",
//   async (body) => {
//     const result = await fetch3(
//       `${process.env.REACT_APP_BASEURL}/category_short_view/${body}`,
//       "get"
//     );
//     sortSubCategories = result.data;
//     return result;
//   }
// );

export const reorderCategories: any = createAsyncThunk('reorderCategories', async body => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/menu_display`, body);
  return result;
});

export const reorderSubCategories: any = createAsyncThunk('reorderSubCategories', async (body: any) => {
  console.log(body, 'data');
  let data: any = { data: JSON.stringify(body.data) };
  console.log(data, 'body');
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/category_productReorder/${body.id}`, data);
  sortSubCategories = result.data;
  return result;
});
export const importCategories: any = createAsyncThunk('importCategories', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/import_categories`, body);
  return result;
});
export const exportCategories: any = createAsyncThunk('exportCategories', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/export_categories`, body);
  return result;
});
const categorySlice: any = createSlice({
  name: 'Category',
  initialState,
  reducers: {
    setshowLoading(state = initialState, action: any) {
      showLoading = action.payload;
      state.loadingStatus += 1;
    },
    editMenuCategory(state = initialState, action) {
      state.editMenu += 1;
      state.editMenuCategoryData.id = action.payload.id;
      state.editMenuCategoryData.image = action.payload.image;
      state.editMenuCategoryData.engname = action.payload.name;
      state.editMenuCategoryData.localize = action.payload.localize;
      state.editMenuCategoryData.referance = action.payload.reference;
      state.editMenuCategoryData.name_localized = action.payload.name_localized;
      state.editMenuCategoryData.deleted_at = action.payload.deleted_at;
      state.editMenuCategoryData.branches = action.payload.branches;
    },
    filterData(state = initialState, action) {
      console.log(action, 'action');
      // if (action.payload.type === "Yes") {
      //   filterValue.type = "deleted"
      // } else if (action.payload.type === "No") {
      //   filterValue.type = "All"
      // } else {
      //   filterValue.type = "All"
      // }
      filterValue.type = action.payload.type;
      filterValue.name = action.payload.name;
      filterValue.referance = action.payload.referance;
      filterValue.updatedafter = action.payload.updatedafter !== undefined ? action.payload.updatedafter : '';
      filterValue.isdeleted = action.payload.isdeleted;
      state.filterValue += 1;
    },
    categoryId(state = initialState, action) {
      state.catId = action.payload;
    },
    resetCategoriesFilter(state = initialState) {
      filterValue.type = 'All';
      filterValue.name = '';
      filterValue.referance = '';
      filterValue.updatedafter = '';
      filterValue.isdeleted = '';
      state.filterValue += 1;
    },
    changeIsFiltered(state = initialState, action: any) {
      state.isFiltered = action.payload;
    },
    setPageCount(state = initialState, action: any) {
      state.pageCount = action.payload;
    },
  },
  extraReducers: {
    [categoryList.fulfilled]: (state: any, { payload }) => {
      state.categoryListItems = payload;
      state.categoryList += 1;
      state.loading = false;
    },
    [categoryList.pending]: (state: any) => {
      state.loading = true;
      state.categoryListItems = [];
    },
    [menuDisplay.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.categorySortList = payload?.data?.categories;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [menuDisplay.rejected]: (state: any, { payload }) => {
      state.loading = false;
    },
    [menuDisplay.pending]: (state: any) => {
      state.loading = true;
    },
    [categoryProducts.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.categoryProductsList = payload?.data;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [categoryCombos.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.categoryCombosList = payload?.data;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [categoryGifts.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.categoryGiftsList = payload?.data;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [categoryList.pending]: (state, action) => {
      state.loading = true;
    },
    [createCategory.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.createCategory += 1;
      if (error) {
        state.error = error;
      } else {
        notify(message, 'success');
        state.error = message;
      }
    },
    [multiDeleteCategory.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.createCategory += 1;
      if (payload.error) {
        state.error = payload.error;
        notify('Category Deleted Successfully', 'error');
      } else {
        notify('Category Deleted Successfully', 'success');
        state.error = payload.message;
      }
    },

    // [allCategories.fulfilled]: (
    //   state: any,
    //   { payload: { error, message } }
    // ) => {
    //   state.loading = false;
    //   state.sortMenuCategories += 1;
    //   if (error) {
    //     state.error = error;
    //   } else {
    //     state.error = message;
    //   }
    // },
    // [allSubCategories.fulfilled]: (
    //   state: any,
    //   { payload: { error, message } }
    // ) => {
    //   state.loading = false;
    //   state.sortMenuSubCategories += 1;
    //   if (error) {
    //     state.error = error;
    //   } else {
    //     state.error = message;
    //   }
    // },

    [reorderCategories.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.sortMenuSubCategories += 1;
      if (error) {
        state.error = error;
      } else {
        notify('Reorder Category Successfully', 'success');
        state.error = message;
      }
    },
    [reorderSubCategories.fulfilled]: (state: any, { payload: { error, msg } }) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        notify(msg, 'success');
        state.error = msg;
      }
    },
    [importCategories.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.createCategory += 1;
      if (error) {
        state.error = error;
      } else {
        notify(message, 'success');
        state.error = message;
      }
    },
    [exportCategories.fulfilled]: (state: any, action) => {
      state.loading = false;
      if (isRejectedWithValue(action)) {
        state.error = action?.error.data.message;
      } else {
        state.error = action?.payload?.data;
        window.open(`${process.env.REACT_APP_EXPORTBASEURL}` + action?.payload.data, '_blank');
      }
    },
  },
});

export const {
  editMenuCategory,
  clearFilter,
  categoryId,
  filterData,
  setshowLoading,
  resetCategoriesFilter,
  changeIsFiltered,
  setPageCount,
} = categorySlice.actions;
export default categorySlice.reducer;
