import React from 'react';
import { useTranslation } from 'react-i18next';

type IInput = {
    type?: string;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    pattern?: string;
    className?: string;
    label: any;
    name: string;
    errors?: any;
    register?: any;
    minLength?: number;
    maxLength?: number;
    valuePattern?: RegExp;
    messagePattern?: string;
};

const CustomInput = ({
    name,
    label,
    register,
    errors,
    className,
    onKeyDown,
    pattern,
    type,
    valuePattern,
    minLength,
    maxLength,
    messagePattern,
    ...rest
}: IInput) => {
    const { t } = useTranslation();
    return (
        <div>
            <div className={`placeholder-container ${className}`}>
                <input
                    className={`${errors[name] && 'border border-danger'}`}
                    type={type}
                    id={name}
                    required
                    name={name}
                    pattern={pattern}
                    onKeyDown={onKeyDown}
                    {...register(name, {
                        required: `required`,
                        pattern: {
                            value: valuePattern,
                            message: messagePattern,
                        },
                        minLength: {
                            value: minLength,
                            message: `Please enter ${minLength} digitis`,
                        },
                        maxLength: {
                            value: maxLength,
                            message: `Please enter ${minLength} digitis`,
                        },
                    })}
                    {...rest}
                />

                <label htmlFor={name}>{label}</label>
            </div>
            {errors[name] && <small className='text-danger ms-1 mt-1 text-md text-bold'>{errors[name].message}</small>}
        </div>
    );
};

export default CustomInput;
