import React from 'react';
import { Spinner } from 'react-bootstrap';

/////////////////////////////////////////////////////////////////////////
/// default color is blue as we pass by default primary to bgColor   ///
/// 1) to use red button pass this delete in bgColor                ///
//////////////////////////////////////////////////////////////////////

interface BtnProps {
    children: React.ReactNode;
    onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    bgColor?: 'primary' | 'delete' | 'lightRed' | 'transparent';
    className?: string;
    type?: 'submit' | 'reset' | 'button';
    loading?: boolean;
    id?: string
}

export const NormalBtn: React.FC<BtnProps> = ({
    disabled,
    children,
    bgColor = 'primary',
    className,
    onClick,
    type = 'button',
    loading,
    id
}) => {
    return (
        <button
            id={id}
            disabled={disabled || loading}
            className={`button button__normal button__normal-${bgColor} ${className}`}
            onClick={onClick}
            type={type}
        >
            {loading ? (
                <Spinner size='sm' animation='border' variant='secondary' />
            ) : (
                children
            )}
        </button>
    );
};
