import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { fetch10, fetch2, fetch22, fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';

import { notify } from '../../constants/notifyconstants';
import axios from 'axios';
import { useDispatch } from 'react-redux';

export const showTaxList: any = createAsyncThunk('showTaxList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/taxes`, body);

    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});
export const showTaxGroupList: any = createAsyncThunk('showTaxGroupList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tax_groups`, body);

    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});

export const showTaxdetails: any = createAsyncThunk('showTaxdetails', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/taxes/${body}`);

    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});

export const addTaxGroup: any = createAsyncThunk('addTaxGroupdetails', async body => {
  try {
    const response: any = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/tax_groups`, body);
    if (response.code === 302) {
      notify(response.massage);
    } else {
      notify('Add Tax Group Successfully', 'success');
    }

    return response.data;
  } catch (error: any) {
    throw error;
  }
});
export const addtaxes: any = createAsyncThunk('adddatataxes', async body => {
  try {
    const response: any = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/taxes`, body);

    notify('Add Tax Successfully', 'success');
    return response.data;
  } catch (error: any) {
    throw error;
  }
});
export const edittaxes: any = createAsyncThunk('edittaxes', async ({ id, body }: any) => {
  try {
    const response: any = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/taxes/${id}`, body);

    notify('Update Tax Successfully', 'success');
    return response.data;
  } catch (error: any) {
    throw error;
  }
});
export const edittaxeGroup: any = createAsyncThunk('edittaxeGroup', async ({ id, body }: any) => {
  try {
    const response: any = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/tax_groups/${id}`, body);

    notify('Update Tax Group Successfully', 'success');
    return response.data;
  } catch (error: any) {
    console.log(error, 'tax_groups');

    throw error;
  }
});
export const getBranches: any = createAsyncThunk('getBranches', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/branches`);

    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});
export const gettags: any = createAsyncThunk('gettags', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`);

    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});
export const getproducts: any = createAsyncThunk('getproducts', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/products`);

    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});
export const getmodifier_options: any = createAsyncThunk('getmodifier_options', async body => {
  try {
    const response: any = await fetch6(
      `${process.env.REACT_APP_BASEURL}/menu/modifier_options?filter[is_deleted]=false`,
    );

    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});

export const showTaxGroupdetails: any = createAsyncThunk('showTaxGroupdetails', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tax_groups/${body}`);

    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});
export const delTaxes: any = createAsyncThunk('delTaxes', async body => {
  try {
    const response: any = await fetch10(`${process.env.REACT_APP_BASEURL}/manage/taxes/${body}`);

    return response.data;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    throw error;
  }
});
export const delTaxesGroup: any = createAsyncThunk('delTaxesGroup', async body => {
  try {
    const response: any = await fetch10(`${process.env.REACT_APP_BASEURL}/manage/tax_groups/${body}`);

    return response;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    throw error;
  }
});
export const addProdToMod: any = createAsyncThunk('addProdToMod', async ({ id = '', body = null }: any) => {
  try {
    const response: any = await fetch9(`${process.env.REACT_APP_BASEURL}/menu/products/${id}`, { tax_group_id: body });
    notify(response.message, 'success');
    console.log(response, 'ssssssssss');

    return response.data;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    throw error;
  }
});
export const addModfire: any = createAsyncThunk('addModfire', async ({ id = '', id1 = '', body = null }: any) => {
  try {
    const response: any = await fetch9(`${process.env.REACT_APP_BASEURL}/menu/modifiers/${id}/options/${id1}`, {
      tax_group_id: body,
    });
    return response.data;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    throw error;
  }
});

const manageTaxGroup: any = createSlice({
  name: 'manageTaxGroup',
  initialState: {
    delResons: null,
    addNewResons: null,
    showAllshowTaxList: [],
    showAllshowTaxGroupList: null,
    showAllshowTaxListDetails: null,
    getBranchesData: null,
    gettagsData: null,
    getProductData: null,
    getmodifier_optionsData: [],
    showTaxGroupdetailsData: null,
    showTaxGroupdetailsForAll: [],
    isLoading: false,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(showTaxList.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.showAllshowTaxList = action.payload;
      })
      .addCase(showTaxList.pending, (state: any, action) => {
        state.isLoading = true;
      })
      .addCase(showTaxList.rejected, (state: any, action) => {
        state.isLoading = false;
      })
      .addCase(showTaxdetails.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.showAllshowTaxListDetails = action.payload;
      })
      .addCase(showTaxGroupList.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.showAllshowTaxGroupList = action.payload;
      })
      .addCase(showTaxGroupList.pending, (state: any, action) => {
        state.isLoading = true;
      })
      .addCase(getBranches.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.getBranchesData = action.payload;
      })
      .addCase(gettags.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.gettagsData = action.payload;
      })
      .addCase(getproducts.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.getProductData = action.payload;
      })
      .addCase(getmodifier_options.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.getmodifier_optionsData = action.payload;
      })
      .addCase(delTaxesGroup.fulfilled, (state: any, action) => {
        notify('Delete Tax Group Successfully', 'success');
      })
      .addCase(delTaxes.fulfilled, (state: any, action) => {
        notify('Delete Tax  Successfully', 'success');
      })
      .addCase(showTaxGroupdetails.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.showTaxGroupdetailsData = action.payload;
        state.showTaxGroupdetailsForAll.push(action.payload);
      });
  },
});

// export const { changeDeleteValue, setshowLoading,resetFilterData,setFilterData } = manageCouponSlice.actions;
export default manageTaxGroup.reducer;
