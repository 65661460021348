import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { fetch2, fetch3, fetch6, fetch9, fetch5 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  usersList: {},
  singleUser: {},

  loading: false,
  progressLoading: false,
  pageLoading: false,
  isDataFiltered: false,
  userListCount: 0,
  userNotificationListCount: 0,
  userCreateCount: 0,
  loadingStatus: 0,
  userDetailCount: 0,
  editUserCount: 0,
  deleteUser: 0,
  pageCount: 0,
};

export let userFilterValue: any = {
  search: '',
  name: '',
  emp_no: '',
  email: '',
  email_verified: '',
  phone: '',
  ['branches.id']: '',
  branch_by_tag: '',
  ['roles.id']: '',
  ['tags.id']: '',
  has_branches: '',
  has_roles: '',
  is_deleted: '',
  updated_after: '',
  has_console_access: '',
  has_app_access: '',
};
export let filterArray: any = {
  branch: [],
  role: [],
  usertag: [],
  branch_by_tag: [],
};
export let userListItems: any;
export let userNotificationListitems: any;
export let singleUserDetails: any;

// ==============================================

export const userList: any = createAsyncThunk('userList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/auth/users`, body);

  return result;
});
// ==============================================

export const userCreate: any = createAsyncThunk('userCreate', async body => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/auth/users`, body);

    notify('User Created successfully', 'success');
    return result;
  } catch (error: any) {
    notify(error?.data?.message, 'error');
  }
});
// ==============================================
export const getUserDetails: any = createAsyncThunk('getUserDetails', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/auth/users/${body}`);

  return result;
});
// ==============================================

export const deleteUserDetails: any = createAsyncThunk('deleteUserDetails', async body => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/auth/users/${body}`, 'delete');

  return result;
});

// ==============================================
export const userEdit: any = createAsyncThunk('userEdit', async (body: any) => {
  try {
    const result = await fetch9(`${process.env.REACT_APP_BASEURL}/auth/users/${body.id}`, body);
    return result;
  } catch (error: any) {
    notify(error?.message, 'error');
  }
});
// ==============================================
export const userRestore: any = createAsyncThunk('userRestore', async (body: any) => {
  try {
    const result = await fetch9(`${process.env.REACT_APP_BASEURL}/auth/users/${body}/restore`, body);
    notify('User Restored successfully', 'success');
    return result;
  } catch (error: any) {
    notify(error?.message, 'error');
  }
});

// ==============================================

export const userNotification: any = createAsyncThunk('userNotification', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/notification_list`, body);
  userNotificationListitems = result.data;
  return result;
});

export const modifyNotification: any = createAsyncThunk('modifyNotification', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/modify_notification`, body);
  return result;
});
export const addNotification: any = createAsyncThunk('addNotification', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/add_user_notification`, body);
  return result;
});
export const removeNotification: any = createAsyncThunk('removeNotification', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/remove_user_notification`, body);
  return result;
});

export const userImport: any = createAsyncThunk('userImport', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/import_users`, body);
  return result;
});
export const userExport: any = createAsyncThunk('userExport', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/export_users`, body);
  return result;
});

export const changePassword: any = createAsyncThunk('changePassword', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/change_password`, body);
  return result;
});

const manageUserSlice: any = createSlice({
  name: 'manageUser',
  initialState,
  reducers: {
    showPageLoading(state = initialState) {
      state.pageLoading = true;
    },
    setshowLoading(state = initialState) {
      state.loadingStatus += 1;
    },
    setIsDataFiltered(state = initialState, action) {
      state.isDataFiltered = action.payload;
    },
    setProgressLoading(state = initialState, action) {
      state.progressLoading = action.payload;
    },
    setPageCount(state = initialState, action) {
      state.pageCount = action.payload;
    },
  },
  extraReducers: {
    [userList.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.usersList = action.payload;
      state.pageLoading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [userList.rejected]: (state: any) => {
      state.loading = false;
    },
    [userList.pending]: (state: any) => {
      state.loading = true;
    },
    // =============================================
    [getUserDetails.fulfilled]: (state: any, action: any) => {
      state.singleUser = action.payload;
      state.pageLoading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [getUserDetails.rejected]: (state: any) => {
      state.pageLoading = false;
    },
    [getUserDetails.pending]: (state: any) => {
      state.pageLoading = true;
    },
    // =============================================
    [userCreate.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [userCreate.rejected]: (state: any, action: any) => {
      state.loading = false;
    },
    // =============================================
    [deleteUserDetails.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [deleteUserDetails.rejected]: (state: any) => {
      state.loading = false;
    },
    // =============================================
    [userRestore.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [userRestore.rejected]: (state: any) => {
      state.loading = false;
    },
    // =============================================
    [userNotification.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.userNotificationListCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, 'success');
        else notify(message);
        state.error = message;
      }
    },
    [modifyNotification.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.editUserCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [addNotification.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.editUserCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [removeNotification.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.editUserCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [userEdit.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.editUserCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, 'success');
        else notify(message);
        state.error = message;
      }
    },
    [userImport.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.userCreateCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(message, 'success');
        state.error = message;
      }
    },
    [userExport.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (isRejectedWithValue(action)) {
        state.error = action?.error.data.message;
      } else {
        state.error = action?.payload?.data;
        window.open(`${process.env.REACT_APP_EXPORTBASEURL}` + action?.payload.data, '_blank');
      }
    },

    [changePassword.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.editUserCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, 'success');
        else notify(message);
        state.error = message;
      }
    },
  },
});

export const {
  userFilterData,
  resetUser,
  setshowLoading,
  showPageLoading,
  setIsDataFiltered,
  setProgressLoading,
  setPageCount,
} = manageUserSlice.actions;
export default manageUserSlice.reducer;
