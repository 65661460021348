// Redux Toolkit
import { createSlice, createAsyncThunk, AnyAction } from '@reduxjs/toolkit';
// Axios for making API requests
import { fetch11, fetch5, fetch10, fetch6, fetch9, fetch30 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

export let createNewTransferTDataT: any;

const initialState = {
  announcementData: {},
  announcementNotification: [],
  loading: false,
  error: null,
  announcementDetails: null,
  announcementDetailsLoading: false,
};

// Async thunk action creator
export const getAllAnnouncementList: any = createAsyncThunk('announcement', async (query: any) => {
  try {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/announcement${query}`);
    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});
export const getAnnouncement: any = createAsyncThunk('getAnnouncement', async (id: any) => {
  try {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/announcement/${id}`);
    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});
export const editAnnouncement: any = createAsyncThunk('getAnnouncement', async (id: any, body) => {
  try {
    const response = await fetch30(`${process.env.REACT_APP_BASEURL}/announcement/${id}/edit`, body);
    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const deleteAnnouncement: any = createAsyncThunk('deleteAnnouncement', async (body: any) => {
  try {
    const response = await fetch30(`${process.env.REACT_APP_BASEURL}/announcement/${body?.id}/delete-file`, body);
    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});



export const getAnnouncementNotification: any = createAsyncThunk('getAnnouncementNotification', async () => {
  try {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/user-notifications`);
    return response;
  } catch (error: any) {
  }
});
export const announcementNotificationFcmToken: any = createAsyncThunk('announcementNotificationFcmToken', async (body: any) => {
  try {
    const response = await fetch30(`${process.env.REACT_APP_BASEURL}/auth/update-fcm`, body);
    return response;
  } catch (error: any) {
  }
});
const annoucementSlice: any = createSlice({
  name: 'annoucementSlice',
  initialState,

  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getAllAnnouncementList.fulfilled, (state: any, action: any) => {
        state.announcementData = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllAnnouncementList.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllAnnouncementList.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAnnouncement.fulfilled, (state: any, action: any) => {
        state.announcementDetails = action.payload;
        state.announcementDetailsLoading = false;
        state.error = null;
      })
      .addCase(getAnnouncement.pending, (state: any) => {
        state.announcementDetailsLoading = true;
        state.error = null;
      })
      .addCase(getAnnouncement.rejected, (state: any, action: any) => {
        state.announcementDetailsLoading = false;
        state.error = action.error.message;
      })
      .addCase(getAnnouncementNotification.fulfilled, (state: any, action: any) => {
        state.announcementNotification = action.payload;
      });
    // .addCase(editAnnouncement.fulfilled, (state: any, action: any) => {
    //   state.announcementDetails = action.payload;
    //   state.announcementDetailsLoading = false;
    //   state.error = null;
    // })
    // .addCase(editAnnouncement.pending, (state: any) => {
    //   state.announcementDetailsLoading = true;
    //   state.error = null;
    // })
    // .addCase(editAnnouncement.rejected, (state: any, action: any) => {
    //   state.announcementDetailsLoading = false;
    //   state.error = action.error.message;
    // });
  },
});

export const { } = annoucementSlice.actions;
export default annoucementSlice.reducer;
