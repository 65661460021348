import React from 'react';
import { Spinner } from 'react-bootstrap';

interface BtnProps {
    onClick?: () => void;
    children: React.ReactNode;
    disabled?: boolean;
    className?: string;
    type?: 'submit' | 'reset' | 'button';
    loading?: boolean;
}

export const OutlineBtn: React.FC<BtnProps> = ({
    disabled,
    children,
    className,
    onClick,
    type = 'button',
    loading,
}) => {
    return (
        <button
            disabled={disabled || loading}
            className={`button button__outline ${className}`}
            onClick={onClick}
            type={type}
        >
            {loading ? (
                <Spinner size='sm' animation='border' variant='secondary' />
            ) : (
                children
            )}
        </button>
    );
};
