import React, { useState } from 'react';
import { Modal, Row, Col, Form } from 'react-bootstrap';
import cx from './Modals.module.scss';

import { OutlineBtn } from '../buttons/OutlinedBtn';
import { NormalBtn } from '../buttons/NormalBtn';
import { useLanguage } from '../../hooks/useLanguage';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Modals = (props: any) => {
  const [addchangePassword, setChangePassword] = useState({
    currentPassword: '',
    password: '',
    confirmPassword: '',
    type: 'cashier',
  });
  const { t } = useLanguage();
  const handleChange = (e?: any) => {
    // const { name, value, type, checked } = e.target;
    // setChangePassword((prevState: any) => ({
    //   ...prevState,
    //   [name]: value,
    // }));
    console.log('');
  };
  const navigate = useNavigate()
  const handleAddUser = (e?: any) => {
    // const { name, value, type, checked } = e.target;
    // setChangePassword((prevState: any) => ({
    //   ...prevState,
    //   [name]: value,
    // }));
    Cookies.remove('xPmOtQST');
    Cookies.remove('xPmOtQSRM');
    navigate('/login')
  };
  const handleSubmit = () => {};
  //   <Dropdown.Item className={`${cx.borderM}`} href='#'>
  //   <p>Phone </p>
  //   <h5>{storedData?.phone}</h5>
  // </Dropdown.Item>

  // <Dropdown.Item onClick={toggleDirection} className={`${cx.borderM}`}>
  //   {language === 'en' ? (
  //     <h5
  //       style={{
  //         fontFamily: 'Noto Kufi Arabic, sans-serif',
  //       }}
  //     >
  //       عربى
  //     </h5>
  //   ) : (
  //     <h5
  //       style={{
  //         fontFamily: 'Noto Kufi Arabic, sans-serif',
  //       }}
  //     >
  //       English
  //     </h5>
  //   )}
  // </Dropdown.Item>
  return (
    <>
      {props.modalName === 'change Password' && (
        <Modal scrollable className={`${cx.ctsModal}`} show={props.show}>
          <Modal.Header closeButton>
            <Modal.Title>{t('GLOBAL.CHANGE_PASSWORD')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{t('GLOBAL.CURRENT_PASSWORD')}</Form.Label>
                  <Form.Control type='password' onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{t('GLOBAL.NEW_PASSWORD')}</Form.Label>
                  <Form.Control type='password' onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{t('GLOBAL.CONFIRM_NEW_PASSWORD')}</Form.Label>
                  <Form.Control type='password' onChange={handleChange} />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className={`${cx.bottomRight}`}>
            <div className='d-flex'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                }}
              >
                {t('GLOBAL.CLOSE')}
              </OutlineBtn>
              <NormalBtn onClick={handleSubmit}>{t('GLOBAL.SAVE')}</NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {props.modalName === 'add user' && (
        <Modal scrollable className={`${cx.ctsModal}`} show={props.show}>
          <Modal.Header closeButton>
            <Modal.Title>{t('GLOBAL.SWITCH_ACCOUNT')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='d-flex justify-content-between align-items-center  '>
              <div className='col-7'>
                <Form.Group className={`${cx.a}`}>
                  {/* <Form.Label>{t('GLOBAL.CURRENT_PASSWORD')}</Form.Label> */}
                  <Form.Control type='text' onChange={handleChange} />
                </Form.Group>
              </div>
              <div className='col-5'>
                <Form.Group className={`${cx.a} `}>
                  {/* <Form.Label>{t('GLOBAL.NEW_PASSWORD')}</Form.Label> */}
                  <OutlineBtn className='py-2 w-100' onClick={handleAddUser }>
                    add account
                  </OutlineBtn>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className={`${cx.bottomRight}`}>
            <div className='d-flex'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                }}
              >
                {t('GLOBAL.CLOSE')}
              </OutlineBtn>
              {/* <NormalBtn onClick={handleSubmit}>{t('GLOBAL.SAVE')}</NormalBtn> */}
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Modals;
