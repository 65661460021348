import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch10, fetch2, fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';
const initialState = {
  loading: false,
  loadingList: false,
  userBranchListCount: 0,
  BranchSecTableListCount: 0,
  branchDetailCount: 0,
  editBranchCount: 0,
  deleteBranchCount: 0,
  timeEventListCount: 0,
  addTimeEventListCount: 0,
  timeEventPerIdCount: 0,
  editTimeEventCount: 0,
  ActiveDeactiveEventCount: 0,
  deleteTimeEventCount: 0,
  applyOnBranchEventCount: 0,
  applyCategoryDataCount: 0,
  applyOnProductDataCount: 0,
  applyOnProductTagsDataCount: 0,
  loadingStatus: 0,
  addSectionCount: 0,
  editSectionCount: 0,
  addBranchSecTableCount: 0,
  branchSecDetailCount: 0,
  branchAddCount: 0,
  branchTableListCount: 0,
  createNewBranchCount: 0,
  branchesTableItems: [],
  branchesTableMeta: {},
  singleBranchData: {},
  isFiltered: false,
  singleBranchSecData: {},
  singleBranchSecTableData: [],
  singleBranchSecTableMeta: {},
  singleTableData: {},
  pageCount: 0,
};

export let userBranchFilterValue: any = {
  //   type: "",
  name: null,
  reference: null,
  users: null,
  delivery_zones: null,
  tags: null,
  tax_group: null,
  updated_after: null,
  is_deleted: false,
};

export let sectionFilterValue: any = {
  is_deleted: false,
};

export let filterArray: any = {
  tag: [],
  taxgroup: [],
  usertag: [],
  deliveryZone: [],
};
export let userBranchListItems: any;
export let BranchTableListItems: any;
export let BranchSecTableListItems: any;
export let singleUserBranchDetails: any;
export let singleBranchSecDetails: any;
export let newBranchSectionId: any;
export let editTableData: any;

export const userBranchList: any = createAsyncThunk('userBranchList', async body => {
  try {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/branches?include[0]=tax_group`,

      body,
    );
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const getBranchDetail: any = createAsyncThunk('getBranchDetail', async body => {
  try {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/branches/${body}`);
    return result.data;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const branchAdd: any = createAsyncThunk('branchAdd', async (body: any) => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/branches`, body);
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const editBranch: any = createAsyncThunk('branch/edit', async (body: any) => {
  try {
    const result = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/branches/${body.id}`, body.data);
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const addBranchSection: any = createAsyncThunk('addBranchSection', async (body: any) => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/add_section/${body.id}`, body.data);
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const deleteBranch: any = createAsyncThunk('deleteBranch', async (id: any) => {
  try {
    const result = await fetch10(`${process.env.REACT_APP_BASEURL}/manage/branches/${id}`);
    return result;
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
        notify(error?.response?.data?.errors[field][0]),
      )
      : notify(error?.response?.data?.message);
  }
});

export const BranchSectionData: any = createAsyncThunk('BranchSectionData', async (body: any) => {
  try {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/branches/${body.branchId}/sections/${body.sectionId}`,
    );
    return result.data;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const editBranchSection: any = createAsyncThunk('editBranchSection', async (body: any) => {
  try {
    const result = await fetch9(
      `${process.env.REACT_APP_BASEURL}/manage/branches/${body.branchId}/sections/${body.sectionId}`,
      body.data,
    );
    return result.data;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const deleteBranchSection: any = createAsyncThunk('deleteBranchSection', async (body: any) => {
  try {
    const result = await fetch10(
      `${process.env.REACT_APP_BASEURL}/manage/branches/${body.branchId}/sections/${body.sectionId}`,
    );
    return result;
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
        notify(error?.response?.data?.errors[field][0]),
      )
      : notify(error?.response?.data?.message);
  }
});

export const restoreBranchSection: any = createAsyncThunk('restoreBranchSection', async (body: any) => {
  try {
    const result = await fetch9(
      `${process.env.REACT_APP_BASEURL}/manage/branches/${body.branchId}/sections/${body.sectionId}/restore`,
    );
    return result;
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
        notify(error?.response?.data?.errors[field][0]),
      )
      : notify(error?.response?.data?.message);
  }
});

export const BranchSectionTableList: any = createAsyncThunk('BranchSectionTableList', async (body: any) => {
  try {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/sections/${body.id}/tables`, body.data);
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const addBranchSecTable: any = createAsyncThunk('addBranchSecTable', async (body: any) => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/sections/${body.sectionId}/tables`, body.data);
    return result;
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
        notify(error?.response?.data?.errors[field][0]),
      )
      : notify(error?.response?.data?.message);
  }
});

export const editBranchSecTable: any = createAsyncThunk('editBranchSecTable', async (body: any) => {
  try {
    const result = await fetch9(
      `${process.env.REACT_APP_BASEURL}/manage/sections/${body.sectionId}/tables/${body.tableId}`,
      body.data,
    );
    return result;
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
        notify(error?.response?.data?.errors[field][0]),
      )
      : notify(error?.response?.data?.message);
  }
});

export const deleteBranchSecTable: any = createAsyncThunk('deleteBranchSecTable', async (body: any) => {
  try {
    const result = await fetch10(
      `${process.env.REACT_APP_BASEURL}/manage/sections/${body.sectionId}/tables/${body.tableId}`,
      body.data,
    );
    return result;
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
        notify(error?.response?.data?.errors[field][0]),
      )
      : notify(error?.response?.data?.message);
  }
});

export const restoreBranchSecTable: any = createAsyncThunk('restoreBranchSecTable', async (body: any) => {
  try {
    const result = await fetch9(
      `${process.env.REACT_APP_BASEURL}/manage/sections/${body.sectionId}/tables/${body.tableId}/restore`,
      body.data,
    );
    return result;
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
        notify(error?.response?.data?.errors[field][0]),
      )
      : notify(error?.response?.data?.message);
  }
});

export const generateReference: any = createAsyncThunk('generateReference', async (id: any) => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/generate_reference`, {
      model: 'branches',
    });
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const copyBranchSettings: any = createAsyncThunk('copyBranchSettings', async (body: any) => {
  try {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/manage/branches/${body.branch_id}/copy_branch_settings`,
      body.data,
    );
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const deleteBranchOrders: any = createAsyncThunk('deleteBranchOrders', async id => {
  try {
    const result = await fetch10(`${process.env.REACT_APP_BASEURL}/manage/branches/${id}/orders`);
    notify('Orders deleted successfully', 'success');
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const deleteBranchInventoryTrans: any = createAsyncThunk('deleteBranchInventoryTrans', async id => {
  try {
    const result = await fetch10(`${process.env.REACT_APP_BASEURL}/manage/branches/${id}/transactions`);
    notify('Transactions deleted successfully', 'success');
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const BranchSecTableList: any = createAsyncThunk('BranchSecTableList', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/branch_sac_table_list`, body);
  BranchSecTableListItems = result.data;
  return result;
});
export const branchTableList: any = createAsyncThunk('branchTableList', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/branch_table_list`, body);
  BranchTableListItems = result.data;
  return result;
});

export const modifyBranchDeliveryZones: any = createAsyncThunk('modifyBranchDeliveryZones', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/modify_branch_delivery_zones`, body);
  return result;
});

export const modifyBranchTags: any = createAsyncThunk('modifyBranchTags', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/modify_branch_tags`, body);
  return result;
});
export const removeMultipleBranchTags: any = createAsyncThunk('removeMultipleBranchTags', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/remove_multiple_branch_tags`, body);
  return result;
});

export const getBranchSectionDetail: any = createAsyncThunk('getBranchSectionDetail', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/branch_saction_details`, body);
  singleBranchSecDetails = result.data;
  return result;
});

const manageBranchSlice: any = createSlice({
  name: 'Product',
  initialState,
  reducers: {
    setshowLoading(state = initialState) {
      state.loadingStatus += 1;
    },
    userBranchFilterData(state = initialState, action) {
      state.isFiltered = true;
      //   userBranchFilterValue.type = action.payload.type;
      userBranchFilterValue.name = action.payload.name;
      userBranchFilterValue.reference = action.payload.reference;
      userBranchFilterValue.users = action.payload.users;
      userBranchFilterValue.delivery_zones = action.payload.delivery_zones;
      userBranchFilterValue.tags = action.payload.tags;
      userBranchFilterValue.tax_group = action.payload.tax_group;
      userBranchFilterValue.updated_after = action.payload.updated_after;
    },
    BranchResetFilterData(state = initialState, action) {
      state.isFiltered = false;
      //   userBranchFilterValue.type = "";
      userBranchFilterValue.name = null;
      userBranchFilterValue.reference = null;
      userBranchFilterValue.users = null;
      userBranchFilterValue.delivery_zones = null;
      userBranchFilterValue.tags = null;
      userBranchFilterValue.tax_group = null;
      userBranchFilterValue.updated_after = null;
      userBranchFilterValue.is_deleted = false;
      filterArray.tag = [];
      filterArray.taxgroup = [];
      filterArray.usertag = [];
      filterArray.deliveryZone = [];
    },
    setEdtTableData(state = initialState, action) {
      state.singleTableData = action.payload;
    },
    setcreateNewBranch(state = initialState) {
      state.createNewBranchCount += 1;
    },
    setPageCount(state = initialState, action) {
      state.pageCount = action.payload;
    },
  },
  extraReducers: {
    [userBranchList.fulfilled]: (state: any, { payload }) => {
      state.loadingList = false;
      state.branchesTableItems = payload?.data;
      state.branchesTableMeta = payload?.meta;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [userBranchList.pending]: (state: any) => {
      state.loadingList = true;
    },
    [BranchSectionData.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.singleBranchSecData = payload;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [BranchSectionTableList.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.singleBranchSecTableData = payload.data;
      state.singleBranchSecTableMeta = payload.meta;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [BranchSecTableList.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.BranchSecTableListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [branchTableList.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.branchTableListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [getBranchDetail.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.singleBranchData = payload;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [getBranchSectionDetail.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.branchSecDetailCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [modifyBranchDeliveryZones.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.editBranchCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [copyBranchSettings.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      // state.editBranchCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [branchAdd.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      // state.branchAddCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, 'success');
        else notify(message);
        state.error = message;
      }
    },
    [editBranch.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      // state.branchAddCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, 'success');
        else notify(message);
        state.error = message;
      }
    },
    [modifyBranchTags.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.editBranchCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [removeMultipleBranchTags.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.editBranchCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [addBranchSection.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      // state.addSectionCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [editBranchSection.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      // state.editSectionCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [restoreBranchSection.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      // state.editSectionCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [deleteBranchSection.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      // state.editSectionCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [generateReference.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      // state.editSectionCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [addBranchSecTable.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      // state.addBranchSecTableCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [editBranchSecTable.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      // state.addBranchSecTableCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [deleteBranchSecTable.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      // state.addBranchSecTableCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [restoreBranchSecTable.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      // state.addBranchSecTableCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [deleteBranchOrders.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.editBranchCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [deleteBranchInventoryTrans.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.editBranchCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [deleteBranch.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.deleteBranchCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
  },
});

export const {
  setshowLoading,
  userBranchFilterData,
  setEdtTableData,
  BranchResetFilterData,
  setcreateNewBranch,
  setPageCount,
} = manageBranchSlice.actions;
export default manageBranchSlice.reducer;
