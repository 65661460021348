import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { fetch10, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

export const showModfireListOpt: any = createAsyncThunk('showModfireListOpt', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/modifier_options?per_page=50`, body);
    // notify("success", "success");
    return response;
  } catch (error: any) {
    notify(error?.response?.data?.message, 'error');
    throw error;
  }
});
export const showModfireListOptDetails: any = createAsyncThunk('showModfireListOptDetails', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/modifier_options/${body}`);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    notify(error?.response?.data?.message, 'error');

    throw error;
  }
});
export const editModOptR: any = createAsyncThunk('editModOpt', async ({ id = '', id1 = '', body = null }: any) => {
  try {
    const response: any = await fetch9(`${process.env.REACT_APP_BASEURL}/menu/modifier_options/${id1}`, body);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    notify(error?.response?.data?.message, 'error');
    throw error;
  }
});
export const delModOpt: any = createAsyncThunk('delModOpt', async ({ id = '', id1 = '' }: any) => {
  try {
    const response: any = await fetch10(`${process.env.REACT_APP_BASEURL}/menu/modifier_options/${id1}`);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    notify(error?.response?.data?.message, 'error');

    throw error;
  }
});
export const delBulkModOpt: any = createAsyncThunk('delBulkModOpt', async (ids: any) => {
  try {
    const response: any = await fetch5(
      `${process.env.REACT_APP_BASEURL}/general/modifier_options/delete_bulck_data`,
      ids,
    );
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    notify(error?.response?.data?.message, 'error');

    throw error;
  }
});
export const resBulkModOpt: any = createAsyncThunk('resBulkModOpt', async (ids: any) => {
  try {
    const response: any = await fetch5(
      `${process.env.REACT_APP_BASEURL}/general/modifier_options/restore_bulck_data`,
      ids,
    );
    return response.data;
  } catch (error: any) {
    notify(error?.response?.data?.message, 'error');

    throw error;
  }
});
export const restoreModOpt: any = createAsyncThunk('restoreModOpt', async (id: any) => {
  try {
    const response: any = await fetch9(`${process.env.REACT_APP_BASEURL}/menu/modifier_options/${id}/restore`);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    notify(error?.response?.data?.message, 'error');
    throw error;
  }
});

export const allInventoryItems: any = createAsyncThunk('allInventoryItems', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items`, body);

    return response.data;
  } catch (error: any) {
    notify(error?.response?.data?.message, 'error');
    throw error;
  }
});
export const createModOpt: any = createAsyncThunk('createModOpt', async (body: any) => {
  try {
    const response: any = await fetch5(`${process.env.REACT_APP_BASEURL}/menu/modifier_options`, body);
    notify('Created Successfully', 'success');
    return response.data;
  } catch (error: any) {
    console.log(error);
    notify(error?.response?.data?.message, 'error');

    throw error;
  }
});
export const addIngredients: any = createAsyncThunk(
  'addIngredients',
  async ({ id1 = '', id2 = '', id3 = '', body = null, type }: any) => {
    try {
      const response: any = await fetch5(
        `${process.env.REACT_APP_BASEURL}/menu/modifiers/${id1}/options/${id2}/ingredients/${id3}${
          type ? '/tags' : ''
        }`,
        body,
      );
      // notify("success", "success");
      return response.data;
    } catch (error: any) {
      console.log(error);

      throw error;
    }
  },
);
export const delIngredients: any = createAsyncThunk(
  'delIngredients',
  async ({ id1 = '', id2 = '', id3 = '', body = null }: any) => {
    try {
      const response: any = await fetch10(
        `${process.env.REACT_APP_BASEURL}/menu/modifiers/${id1}/options/${id2}/ingredients/${id3}`,
        body,
      );
      // notify("success", "success");
      return response.data;
    } catch (error: any) {
      console.log(error);

      throw error;
    }
  },
);
export const showbranchesList: any = createAsyncThunk('showbranchesList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/branches`, body);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    notify(error?.response?.data?.message, 'error');
    throw error;
  }
});
export const addBranchToModOpt: any = createAsyncThunk(
  'addBranchToModOpt',
  async ({ id1 = '', id2 = '', id3 = '', body = null }: any) => {
    try {
      const response: any = await fetch5(
        `${process.env.REACT_APP_BASEURL}/menu/modifiers/${id1}/options/${id2}/branches/${id3}`,
        body,
      );
      notify('Add Branch Price Sucessfully', 'success');
      return response.data;
    } catch (error: any) {
      notify(error?.response?.data?.message, 'error');
      throw error;
    }
  },
);
export const delIBranchToModOpt: any = createAsyncThunk(
  'delIBranchToModOpt',
  async ({ id1 = '', id2 = '', id3 = '', body = null }: any) => {
    try {
      const response: any = await fetch5(
        `${process.env.REACT_APP_BASEURL}/menu/modifiers/${id1}/options/${id2}/branches/${id3}`,
        body,
      );
      // notify("success", "success");
      return response.data;
    } catch (error: any) {
      console.log(error);
      notify(error?.response?.data?.message, 'error');

      throw error;
    }
  },
);
export const allPriceTags: any = createAsyncThunk('allPriceTags', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/price_tags?filter[is_deleted]=false`);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    console.log(error);
    notify(error?.response?.data?.message, 'error');

    throw error;
  }
});
export const allPriceTagsSelect: any = createAsyncThunk('allPriceTagsSelect', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    console.log(error);
    notify(error?.response?.data?.message, 'error');

    throw error;
  }
});
export const addallPriceTags: any = createAsyncThunk(
  'addallPriceTags',
  async ({ id1 = '', id2 = '', id3 = '', body = null }: any) => {
    try {
      const response: any = await fetch5(
        `${process.env.REACT_APP_BASEURL}/manage/price_tags/${id3}/modifier_options/${id2}`,
        body,
      );
      // notify("success", "success");
      return response.data;
    } catch (error: any) {
      console.log(error);
      notify(error?.response?.data?.message, 'error');

      throw error;
    }
  },
);
export const deladdallPriceTags: any = createAsyncThunk('deladdallPriceTags', async ({ id1 = '', id2 = '' }: any) => {
  try {
    const response: any = await fetch10(`${process.env.REACT_APP_BASEURL}/manage/price_tags/${id1}`);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    notify(error.message, 'error');
    console.log(error);
    notify(error?.response?.data?.message, 'error');

    throw error;
  }
});
const manageModifierOptionsSlice: any = createSlice({
  name: 'manageModifierOptionsSlice',
  initialState: {
    showModfireListDataOpt: [],
    showModfireListDataOptDetails: null,
    allInventoryItemsData: null,
    showbranchesListData: null,
    allPriceTagsData: null,
    allPriceTagsSelectData: null,
    oneTimeNavData: false,
    pageCount: 0,
    isLoading: false,
    isFiltered: false,
    modfireOptionsLoading: false,
  },
  reducers: {
    oneTimeNav(state, action) {
      state.oneTimeNavData = action.payload;
    },
    setPageCount(state, action) {
      state.pageCount = action.payload;
    },
    updateIsFiltered(state, action) {
      state.isFiltered = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(showModfireListOpt.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.showModfireListDataOpt = action.payload;
    });
    builder.addCase(showModfireListOpt.pending, (state: any, action) => {
      state.isLoading = true;
    });
    builder.addCase(showModfireListOpt.rejected, (state: any, action) => {
      state.isLoading = false;
    });
    builder.addCase(allPriceTagsSelect.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.allPriceTagsSelectData = action.payload;
    });
    builder.addCase(showModfireListOptDetails.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.showModfireListDataOptDetails = action.payload;
      state.oneTimeNavData = false;
      state.modfireOptionsLoading = false;
    });
    builder.addCase(showModfireListOptDetails.pending, (state: any, action) => {
      state.modfireOptionsLoading = true;
      state.isLoading = true;
      state.oneTimeNavData = true;
    });
    builder.addCase(showModfireListOptDetails.rejected, (state: any, action) => {
      state.isLoading = false;
    });
    builder.addCase(allInventoryItems.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.allInventoryItemsData = action.payload;
    });
    builder.addCase(delBulkModOpt.fulfilled, (state: any, action) => {
      state.isLoading = false;
    });
    builder.addCase(showbranchesList.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.showbranchesListData = action.payload;
    });
    builder.addCase(allPriceTags.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.allPriceTagsData = action.payload;
    });
  },
});

export const { oneTimeNav, setPageCount, updateIsFiltered } = manageModifierOptionsSlice.actions;
export default manageModifierOptionsSlice.reducer;
