import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetch2, fetch3, fetch5, fetch6 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

// export const initialState = {
//   getAllordersListDatax: null,
//   getAllordersListDataxBranch: null,
// };

export let data: any;
export let OrdertagList: any;
export let text: string;
// export let type: string;
// export let branchIds: string;
// // export let branchDate: string;
// export let branchEndDate: string;
// export let refnumberFilter: string = "";
// export let refafterFilter: string = "";
// export let newnumberFilter: string = "";
// export let businessdateFilter: string = "";
export let showLoading: boolean = false;
export let filterClrBtn: any = {
  filterClrBtnX: false,
};
export let SelectedOrderIds: any = [];

export let filterArray: any = {
  branches: [],
  devices: [],
  tag: [],
};
export let modArrVal: any = {
  maximum_options: [],
};
export let OrderCalc: any = {
  branch_id: '',
  charges: [
    // {
    //   charge_id: "99bf4672-77c1-4a8b-b95f-af351b8a3c59",
    //   amount: 10,
    //   charge_percent: null,
    // },
    // {
    //   charge_id: "99a2d8d3-a3c2-4bc9-9884-6e147c6c3d31",
    //   amount: null,
    //   charge_percent: 0.001,
    // },
  ],
  combos: [],
  products: [],
};
export let productCalc: any = {
  products: [
    {
      // discount_amount: null,
      // discount_id: "",
      // discount_percent: null,
      // discount_type: null,
      product_id: '',
      quantity: 1,
      unit_price: 0,
      status: 1,
      type: 2,
      options: [
        // {
        //   modifier_option_id: "99b31e98-4080-4087-aae0-ac95311e1b86",
        //   quantity: 1,
        //   partition: 1,
        //   unit_price: 22,
        // },
      ],
    },
  ],
};

export const getAllordersListData: any = createAsyncThunk('getAllordersListData', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/orders`, body);
    // notify("success", "success");
    return response;
  } catch (error: any) {
    notify(error.message, 'error');
    throw error;
  }
});

export const getAllordersListDataDetails: any = createAsyncThunk('getAllordersListDataDetails', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/orders/${body}`);
    // notify("success", "success");

    return response.data;
  } catch (error: any) {
    notify(error.message, 'error');
    throw error;
  }
});
export const getAllordersListDataBranch: any = createAsyncThunk('getAllordersListDataBranch', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/branches`, body);
    // notify("success", "success");

    return response.data;
  } catch (error: any) {
    notify(error.message, 'error');
    throw error;
  }
});
export const customersAllData: any = createAsyncThunk('customersAllData', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/customers`, body);
    // notify("success", "success");

    return response.data;
  } catch (error: any) {
    notify(error.message, 'error');
    throw error;
  }
});
export const showAllCategory: any = createAsyncThunk('showAllCategory', async body => {
  try {
    const response: any = await fetch6(
      `${process.env.REACT_APP_BASEURL}/menu/categories?filter[is_deleted]=false&include=discounts,timed_events,products,combos,gift_card_products&page=1`,
      body,
    );
    // notify("success", "success");
    // let filteredArray = response?.data?.map((item: any) => {
    //   if (item.products.length > 0) {
    //     return item;
    //   }
    // });
    return response.data;
  } catch (error: any) {
    notify(error.message, 'error');
    throw error;
  }
});
export const showAllProduct: any = createAsyncThunk('showAllProduct', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/products?filter[category_id]=${body}`, {
      include: [
        'category',
        'price_tags',
        'tax_group',
        'tags',
        'branches',
        'ingredients',
        'modifiers',
        'modifiers_options',
        'discounts',
        'timed_events',
        'groups',
      ],
    });
    // notify("success", "success");
    // let filteredArray = response?.data?.map((item: any) => {
    //   if (item.products.length > 0) {
    //     return item;
    //   }
    // });
    return response.data;
  } catch (error: any) {
    notify(error.message, 'error');
    throw error;
  }
});
export const showAllProductSearch: any = createAsyncThunk('showAllProductSearch', async body => {
  try {
    const response: any = await fetch6(
      `${process.env.REACT_APP_BASEURL}/menu/products?filter[is_deleted]=false&include=category,price_tags,tax_group,tags,branches,ingredients.branches,modifiers,modifiers.options,modifiers.options.branches,discounts,timed_events,groups`,
    );
    // notify("success", "success");
    // let filteredArray = response?.data?.map((item: any) => {
    //   if (item.products.length > 0) {
    //     return item;
    //   }
    // });
    return response.data;
  } catch (error: any) {
    notify(error.message, 'error');
    throw error;
  }
});
export const showAllCombosSearch: any = createAsyncThunk('showAllCombosSearch', async body => {
  try {
    const response: any = await fetch6(
      `${process.env.REACT_APP_BASEURL}/menu/combos?filter[category_id]=${body}&filter[is_active]=true&include[0]=sizes&include[1]=items&include[2]=category&include[3]=tags&include[4]=timed_events&include[5]=branches&include[6]=sizes&include[7]=products&include[8]=options&include[9]=price_tags`,
    );
    // notify("success", "success");

    // let filteredArray = response?.data?.map((item: any) => {
    //   if (item.products.length > 0) {
    //     return item;
    //   }
    // });
    return response.data;
  } catch (error: any) {
    notify(error.message, 'error');
    throw error;
  }
});
export const createNewOrder: any = createAsyncThunk('createNewOrder', async body => {
  try {
    const response: any = await fetch5(`${process.env.REACT_APP_BASEURL}/orders`);
    // notify("success", "success");

    // let filteredArray = response?.data?.map((item: any) => {
    //   if (item.products.length > 0) {
    //     return item;
    //   }
    // });
    return response.data;
  } catch (error: any) {
    notify(error.respon.data.message, 'error');
    throw error;
  }
});
export const AllDiscountTyps: any = createAsyncThunk('AllDiscountTyps', async body => {
  try {
    const response: any = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/discounts?filter[is_deleted]=false&include=products,combos,product_tags,categories,branches,customer_tags&page=1`,
    );
    // notify("success", "success");

    // let filteredArray = response?.data?.map((item: any) => {
    //   if (item.products.length > 0) {
    //     return item;
    //   }
    // });
    return response.data;
  } catch (error: any) {
    // notify(error.respon.data.message, "error");
    throw error;
  }
});
export const calcOrderitems: any = createAsyncThunk('calcOrderitems', async body => {
  try {
    const response: any = await fetch5(`${process.env.REACT_APP_BASEURL}/orders_calculator`, body);
    // notify("success", "success");

    // let filteredArray = response?.data?.map((item: any) => {
    //   if (item.products.length > 0) {
    //     return item;
    //   }
    // });
    return response;
  } catch (error: any) {
    const referenceKey = Object.keys(error.response.data.errors)[0];
    const referenceValue = error.response.data.errors[referenceKey];
    notify(referenceValue[0] || error.response.data.message, 'error');
    // notify(error.respon.data.message, "error");
    throw error;
  }
});
export const modifier_options: any = createAsyncThunk('modifier_options?page=1', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/modifier_options?page=1`, body);
    // notify("success", "success");

    // let filteredArray = response?.data?.map((item: any) => {
    //   if (item.products.length > 0) {
    //     return item;
    //   }
    // });
    return response.data;
  } catch (error: any) {
    const referenceKey = Object.keys(error.response.data.errors)[0];
    const referenceValue = error.response.data.errors[referenceKey];
    notify(referenceValue[0] || error.response.data.message, 'error');
    // notify(error.respon.data.message, "error");
    throw error;
  }
});
export const chargesData: any = createAsyncThunk('charges?page=1', async body => {
  try {
    const response: any = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/charges?filter[is_deleted]=false`,
      body,
    );

    return response.data;
  } catch (error: any) {
    const referenceKey = Object.keys(error.response.data.errors)[0];
    const referenceValue = error.response.data.errors[referenceKey];
    notify(referenceValue[0] || error.response.data.message, 'error');
    // notify(error.respon.data.message, "error");
    throw error;
  }
});
export const tagsData: any = createAsyncThunk('tags?page=1', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags?filter[is_deleted]=false`, body);

    return response.data;
  } catch (error: any) {
    const referenceKey = Object.keys(error.response.data.errors)[0];
    const referenceValue = error.response.data.errors[referenceKey];
    notify(referenceValue[0] || error.response.data.message, 'error');
    // notify(error.respon.data.message, "error");
    throw error;
  }
});
export const deviceData: any = createAsyncThunk('Device?page=1', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags?filter[is_deleted]=false`, body);

    return response.data;
  } catch (error: any) {
    const referenceKey = Object.keys(error.response.data.errors)[0];
    const referenceValue = error.response.data.errors[referenceKey];
    notify(referenceValue[0] || error.response.data.message, 'error');
    // notify(error.respon.data.message, "error");
    throw error;
  }
});

const orderModalsReducer: any = createSlice({
  name: 'ReducerForOrder',
  initialState: {
    getAllordersListDatax: null,
    deviceAllData: null,
    isLoading: true,
    isLoadingOrder: true,
    isLoadingDetails: true,

    getAllordersListDataDetailsData: null,
    getAllordersListDataxBranch: null,
    customersAllDataData: null,
    showAllCategoryData: null,
    showAllProductData: null,

    showAllCombosSearchData: null,
    AllDiscountTypsData: null,
    calcOrderitemsData: null,
    showAllProductSearchData: null,
    modifier_optionsData: null,
    chargesAllData: null,
    tagsAllData: null,
    pageCount: null,
    isFiltered: false,
    OrderList_filter: {},
  },
  reducers: {
    moduleModule(state, action) {},
    falseState(state) {},
    typeFilter(state, action) {},
    branchFilter(state, action) {
      // state.OrderList_filter.branch = action.payload;
    },
    businessDateFilter(state, action) {},
    branchHide(state) {},
    removeFilter(state) {},
    filterOptions(state, action) {},
    resetOrders(state) {},
    setshowLoading(state, action: any) {},
    setSelectedOrderdata(state, action: any) {},
    setOrdersFilter(state, action: any) {
      state.OrderList_filter = action.payload;
    },
    resetOrderFilter(state, action: any) {
      state.OrderList_filter = {
        reference: null,
        after_reference: null,
        business_date: null,
        start_date: null,
        end_date: null,
        number: null,
        branch: null,
        tags: null,
        has_tag: null,
        status: null,
        type: null,
        source: null,
        Ahead: null,
        check_number: null,
        customer_notes: null,
        discount_id: null,
        kitchen_notes: null,
        customer: null,
        due_at: null,
        device_id: null,
        order_type: null,
      };
    },
    setPageCount(state, action) {
      state.pageCount = action.payload;
    },
    changeFilterState(state, action) {
      state.isFiltered = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllordersListData.fulfilled, (state: any, action) => {
      state.isLoadingOrder = false;
      state.getAllordersListDatax = action.payload;
    });
    builder.addCase(getAllordersListData.pending, (state: any, action) => {
      state.isLoadingOrder = true;
    });
    builder.addCase(getAllordersListDataBranch.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.getAllordersListDataxBranch = action.payload;
    });
    builder.addCase(deviceData.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.deviceAllData = action.payload;
    });
    builder.addCase(customersAllData.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.customersAllDataData = action.payload;
    });
    builder.addCase(tagsData.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.tagsAllData = action.payload;
    });
    builder.addCase(getAllordersListDataDetails.fulfilled, (state: any, action) => {
      state.isLoadingDetails = false;
      state.getAllordersListDataDetailsData = action.payload;
    });
    builder.addCase(getAllordersListDataDetails.pending, (state: any, action) => {
      state.isLoadingDetails = true;
      // state.getAllordersListDataDetailsData = action.payload;
    });
    builder.addCase(showAllCategory.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.showAllCategoryData = action.payload;
    });
    builder.addCase(showAllProduct.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.showAllProductData = action.payload;
    });
    builder.addCase(AllDiscountTyps.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.AllDiscountTypsData = action.payload;
    });
    builder.addCase(calcOrderitems.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.calcOrderitemsData = action.payload;
    });
    builder.addCase(showAllProductSearch.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.showAllProductSearchData = action.payload;
    });
    builder.addCase(modifier_options.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.modifier_optionsData = action.payload;
    });

    builder.addCase(showAllCombosSearch.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.showAllCombosSearchData = action.payload;
    });
    builder.addCase(chargesData.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.chargesAllData = action.payload;
      const x = action.payload;
      const branchId = localStorage.getItem('branchId');
      const filteredData = x.filter(
        (item: any) =>
          item.is_auto_applied === true &&
          (item.branches?.some(({ id }: any) => id === branchId) || item.associate_to_all_branches === true),
      );
      const finalData = filteredData.map((item: any) => ({
        charge_id: item.id,
        amount: item.type == 1 ? item.value : null,
        charge_percent: item.type == 1 ? null : item.value,
      }));
      OrderCalc.charges = finalData;
    });
  },
});

export const {
  moduleModule,
  falseState,
  typeFilter,
  branchFilter,
  businessDateFilter,
  branchHide,
  removeFilter,
  filterOptions,
  resetOrders,
  setshowLoading,
  setSelectedOrderdata,
  setOrdersFilter,
  resetOrderFilter,
  setPageCount,
  changeFilterState,
} = orderModalsReducer.actions;
export default orderModalsReducer.reducer;
