import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GlobalContextProvider } from './store/global-context';
import { store } from './redux_toolkit/redux_store/store';
import { Provider } from 'react-redux';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import './i18n';
import './fonts.module.scss';
import OnBoardingRide from './lib/OnBoardingRide/OnBoardingRide';

TimeAgo.addDefaultLocale(en);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>F
  <BrowserRouter basename='/'>
    {/* <BrowserRouter basename = "/" >F */}
    <Provider store={store}>
      <GlobalContextProvider>
        <div className='position-absolute top-0 bg-danger' style={{ zIndex: 912399 }}>
          <OnBoardingRide />
        </div>
        <App />
      </GlobalContextProvider>
    </Provider>
  </BrowserRouter>,
  //{/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
