import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch6 } from '../../Apis/commonApis';

const initialState = {
  loading: false,
  branchesCount: 0,
  tagItems: [],
};

export const getTagItemsByType: any = createAsyncThunk('get tag items by Type', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags?filter[type]=${body}`);
  return result.data;
});

export let branchesList: any;
//Beanches
export const branches: any = createAsyncThunk('branches', async () => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/branches`);
  branchesList = result.data;
  return result;
});

const inventoryItemSlice: any = createSlice({
  name: 'SharedApis',
  initialState,
  reducers: {},
  extraReducers: {
    [branches.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.branchesCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [getTagItemsByType.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.tagItems = [...payload];
      if (payload?.error) {
        state.error = payload?.error;
      } else {
        state.error = payload?.message;
      }
    },
    [getTagItemsByType.pending]: (state: any) => {
      state.loading = true;
    },
  },
});

export const {} = inventoryItemSlice.actions;
export default inventoryItemSlice.reducer;
