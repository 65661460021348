import React, { useState, useEffect, useRef } from 'react';
import forgetPassword from './ForgetPassword.module.scss';
import login from '../Login/Login.module.scss';
import { Container, Col, Form } from 'react-bootstrap';
import logo from '../../images/dot-logo.png';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { changeLanguage } from '../../store/global/global.slice';
import { useLanguage } from '../../hooks/useLanguage';
import { NormalBtn } from '../../components/buttons/NormalBtn';
import CustomInput from '../../components/inputs/PlaceHolderInput';
import Cookies from 'js-cookie';
import { validateUserPassword } from '../../redux_toolkit/reducer/ForgetPasswordReducer';

export default function ChangePassword() {
  const [show, setShow] = useState(false);
  const [showLoading, setshowLoading] = useState(false);
  const dispatch = useDispatch();

  // ----------------------------- validation ----------------------------- //
  const {
    trigger,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const tokenValidationCookie = Cookies.get('verify_token');
  const navigate = useNavigate();
  const onSubmit = async (formData: any) => {
    setshowLoading(true)
    const data = { ...formData, verify_token: tokenValidationCookie };
    const response = await dispatch(validateUserPassword(data));
    console.log('Response:', response);
    
    if (response.payload.code === 200) {
      Cookies.remove('verify_token');
      Cookies.remove('isOtpSended');
      navigate('/login');
    }
    setshowLoading(false)

  };
  //----------------------------- Language  -----------------------------//
  const { t, language } = useLanguage();
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      trigger();
    } else {
      mounted.current = true;
    }
  }, [language]);

  const toggleDirection = () => {
    const newDirection = language === 'en' ? 'ar' : 'en';
    dispatch(changeLanguage({ language: newDirection }));
    trigger();
  };

  //----------------------------- return -----------------------------//
  return (
    <>
      <section className={`${forgetPassword.loginSection} vh-100 vw-100 bg-white row `}>
        <div className='position-absolute bottom-0 start-0 m-4'>
          <div style={{ color: '#2e68ff', cursor: 'pointer' }} className=' h5' onClick={toggleDirection}>
            {language === 'en' ? 'عربي' : 'English'}
          </div>
        </div>
        <div className='position-absolute top-0 start-0 m-4'>
          <img src={logo} className={`${login.logo} `} alt='logo' />
        </div>
        <Container className={`${forgetPassword.loginContainer} d-flex align-items-center`} style={{ width: '40%' }}>
          <Col className={`${forgetPassword.loginBox}`}>
            {/* <img src={logo} className={`${forgetPassword.logo}`} alt='logo' /> */}
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group
                className={`mb-4 ${language === 'en' ? `${login.formBoxEn} ` : `${login.formBoxAr}`}
                                   
                                    `}
              >
                <div className='d-flex flex-column pb-3 ps-0'>
                  {/* <div className={`${login.signIn} `}>{t('GLOBAL.SIGN_IN')}</div> */}
                  {/* <div className={`${login.subHeader}`}>
                    {t('GLOBAL.NEW_TO_DOT')}
                    <Link to={'/register'} className={`${login.signUp} `} style={{ color: '#004fee' }}>
                      {t('GLOBAL.SIGN_UP')}
                    </Link>
                  </div> */}
                </div>
                <CustomInput
                  onKeyDown={(e: any) => {
                    const charCode = e.which ? e.which : e.keyCode;
                    if (/\p{Script=Arabic}/u.test(String.fromCharCode(charCode))) {
                      e.preventDefault();
                    }
                  }}
                  {...register('password')}
                  label={t('GLOBAL.PASSWORD')}
                  type={show ? 'password' : 'text'}
                  register={register}
                  errors={errors}
                  valuePattern={/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/}
                  messagePattern='Password can only contain English letters, numbers, and special characters'
                  minLength={6}
                />
                {/* {renderErrorComponent(errors, 'business_reference')} */}
              </Form.Group>
              <Form.Group className={`mb-4 ${language === 'en' ? `${login.formBoxEn}` : `${login.formBoxAr}`} `}>
                <CustomInput
                  onKeyDown={(e: any) => {
                    const charCode = e.which ? e.which : e.keyCode;
                    if (/\p{Script=Arabic}/u.test(String.fromCharCode(charCode))) {
                      e.preventDefault();
                    }
                  }}
                  {...register('password_confirmation', {
                    required: 'Please confirm your password',
                    validate: value => value === watch('password') || 'Passwords do not match',
                  })}
                  label={t('GLOBAL.REPEAT_PASSWORD')}
                  type={show ? 'password' : 'text'}
                  register={register}
                  errors={errors}
                  valuePattern={/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/}
                  messagePattern='Password can only contain English letters, numbers, and special characters'
                  minLength={6}
                />
              </Form.Group>

              <NormalBtn
                // loading={show}
                type='submit'
                className={`${forgetPassword.submitBtn} bg-primary  w-100 rounded-pill py-2 ${
                  show ? 'transparent' : 'text-white text-capitalize'
                }`}
              >
                {t('GLOBAL.REQUEST_NEW_PASSWORD')}
              </NormalBtn>
            </Form>
          </Col>
        </Container>
      </section>
    </>
  );
}
