import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { fetch6, fetch2, fetch3, fetch9, fetch5 } from '../../Apis/commonApis';

import { notify } from '../../constants/notifyconstants';

const initialState = {
  quantityAdjustmentList: {},
  singleQuantityAdjustment: {},
  addedQuantityAdjustmentId: null,
  loading: false,
  progressLoading: false,
  pageLoading: false,
  isDataFilterd: false,
  error: '',
  quantityAdjustmentListCount: 0,
  quantityAdjustmentFilter: 0,
  loadingStatus: 0,
  editItemData: {
    id: '',
    quantity: '',
  },
};

export let newQuantityAdjustment: any = {
  reason: '',
  branch: '',
  item: '',
};

export let filterValue: any = {};

export let get_transferorder_destinationList: any;
export let get_quantityAdjustment_itemList: any;
export let get_quantityAdjustment_productList: any;

export let showLoading: boolean = false;
export let quantityAdjustmentListItems: any;

export let singleQuantityAdjustment: any;

export const quantityAdjustmentList: any = createAsyncThunk(
  'quantityAdjustmentList',

  async (body: any) => {
    try {
      const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/Quantity_adjustments`, body);

      return result;
    } catch (error: any) {
      notify(error.message, 'error');
    }
  },
);

// ===================================================================
export const addQuantityAdjustment: any = createAsyncThunk('addQuantityAdjustment', async (body: any) => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/Quantity_adjustments`, body);

    notify('Quantity adjustment added successfully', 'success');
    return result;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

// ===================================================================

export const getSingleQuantityAdjustment: any = createAsyncThunk('getSingleQuantityAdjustment', async (body: any) => {
  try {
    const result = await fetch3(`${process.env.REACT_APP_BASEURL}/inventory/Quantity_adjustments/${body.id}`, 'get');

    return result;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

// ===================================================================

export const filterQuantityAdjustment: any = createAsyncThunk('filterQuantityAdjustment', async body => {
  try {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/Quantity_adjustments/filterQuantityAdjustments`,
      body,
    );

    return result;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

// ===================================================================

export const get_transferorder_destination: any = createAsyncThunk('get_transferorder_destination', async () => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/manage/branches`, 'get');
  get_transferorder_destinationList = result.data;
  return result;
});

export const get_quantityAdjustment_product: any = createAsyncThunk('get_quantityAdjustment_product', async () => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/menu/products?type=all`, 'get');
  get_quantityAdjustment_productList = result.data;
  return result;
});

export const updateQuantityAdjustment: any = createAsyncThunk('updateQuantityAdjustment', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/inventory/Quantity_adjustments/${body.id}`, body.data);
  return result;
});

export const deleteQuantityAdjustment: any = createAsyncThunk('deleteQuantityAdjustment', async (body: any) => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/inventory/Quantity_adjustments/${body.id}`, 'delete');
  return result;
});

export const submitQuantityAdjustment: any = createAsyncThunk('submitQuantityAdjustment', async (body: any) => {
  const result = await fetch3(
    `${process.env.REACT_APP_BASEURL}/inventory/Quantity_adjustments/submitQuantity/${body.id}`,
    'get',
  );

  return result;
});

export const addQuantityAdjustmentItem: any = createAsyncThunk('addQuantityAdjustmentItem', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/Quantity_adjustments/addItems/${body?.id}`,
    body?.data,
  );

  return result;
});

export const addQuantityAdjustmentItemByProduct: any = createAsyncThunk(
  'addQuantityAdjustmentItem',
  async (body: any) => {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/Quantity_adjustments/addItemsProduct/${body?.id}`,
      body?.data,
    );

    return result;
  },
);

export const editQuantityAdjustmentItem: any = createAsyncThunk('editQuantityAdjustmentItem', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/Quantity_adjustments/editItems/${body?.id}`,
    body?.data,
  );

  return result;
});

export const deleteQuantityAdjustmentItem: any = createAsyncThunk('deleteQuantityAdjustmentItem', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/Quantity_adjustments/deleteItem/${body.id}`,
    body.data,
  );
  return result;
});

const quantityAdjustmentSlice: any = createSlice({
  name: 'Quantity Adjustment',
  initialState,
  reducers: {
    setshowLoading(state = initialState, action: any) {
      showLoading = action.payload;
      state.loadingStatus += 1;
    },
    setEditItemData(state = initialState, action: any) {
      state.editItemData = { ...action.payload };
    },
    setProgressLoading(state = initialState, action: any) {
      state.progressLoading = action.payload;
    },
    setPageLoading(state = initialState, action: any) {
      state.pageLoading = true;
    },
    setIsDataFiltered(state = initialState, action: any) {
      state.isDataFilterd = action.payload;
    },
  },
  extraReducers: {
    [quantityAdjustmentList.fulfilled]: (state: any, { payload }: any) => {
      state.loading = false;
      state.quantityAdjustmentList = payload;
      state.isDataFiltered = false;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [quantityAdjustmentList.rejected]: (state, action) => {
      state.loading = false;
    },
    [quantityAdjustmentList.pending]: (state, action) => {
      state.loading = true;
    },
    // ===================================================
    [getSingleQuantityAdjustment.fulfilled]: (state: any, { payload }: any) => {
      state.loading = false;
      state.pageLoading = false;
      state.progressLoading = false;
      state.singleQuantityAdjustment = payload.data;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [getSingleQuantityAdjustment.rejected]: (state, action) => {
      state.progressLoading = false;
    },
    [getSingleQuantityAdjustment.pending]: (state, action) => {
      state.progressLoading = true;
    },
    // ===================================================
    [addQuantityAdjustment.fulfilled]: (state: any, { payload }: any) => {
      state.loading = false;
      state.addedQuantityAdjustmentId = payload.data[0].id;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [addQuantityAdjustment.rejected]: (state, action) => {
      state.loading = false;
    },
    // ===================================================
    [filterQuantityAdjustment.fulfilled]: (state: any, { payload }: any) => {
      state.quantityAdjustmentList = payload;
      state.loading = false;
      state.isDataFiltered = true;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [filterQuantityAdjustment.rejected]: (state, action) => {
      state.loading = false;
    },
    // ===================================================

    [updateQuantityAdjustment.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.quantityAdjustmentListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [deleteQuantityAdjustment.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.quantityAdjustmentListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [get_transferorder_destination.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.quantityAdjustmentListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
  },
});

export const { setEditItemData, setProgressLoading, setPageLoading, setIsDataFiltered } =
  quantityAdjustmentSlice.actions;

export default quantityAdjustmentSlice.reducer;
