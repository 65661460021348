import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  loading: false,
  loadingList: false,
  tagsList: {},
  progressLoader: false,

  tagsListCount: 0,
  addTagsCount: 0,
  modalCount: 0,
  editTagsCount: 0,
  deleteTagsCount: 0,
  loadingStatus: 0,
};
export let tagsFilter = {
  restaurent_id: '',
  type: '',
};
export let tagsListItems: any;

export let editTagsData = {
  type: '',
  name: '',
  name_localized: '',
  id: '',
};

export let modal = {
  name: '',
  number: '',
};
export const tagsList: any = createAsyncThunk('tagsList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);
  return result;
});

export const addTags: any = createAsyncThunk('addTags', async body => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);

  return result;
});

export const editTags: any = createAsyncThunk('editTags', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/tags/${body.id}`, body);
  return result;
});

export const deleteTags: any = createAsyncThunk('deleteTags', async body => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/manage/tags/${body}`, 'delete');
  return result;
});

export const restoreTags: any = createAsyncThunk('restoreTags', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/tags/${body}/restore`);
  return result;
});

const manageMoretagsSlice: any = createSlice({
  name: 'manageMoretagsSlice',
  initialState,
  reducers: {
    openModal(state = initialState, action) {
      state.modalCount += 1;
      modal.name = action.payload.name;
      modal.number = action.payload.number;
    },
    editTagsFunc(state = initialState, action) {
      editTagsData.id = action.payload.id;
      editTagsData.name = action.payload.name;
      editTagsData.name_localized = action.payload.name_localized;
      editTagsData.type = action.payload.tagtype;
    },
    showLoadingOff(state = initialState) {
      state.loadingStatus += 1;
    },
    setProgressLoader(state = initialState, action) {
      state.progressLoader = action.payload;
    },
  },
  extraReducers: {
    [tagsList.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.loadingList = false;
      state.tagsList = action.payload;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [tagsList.pending]: (state: any, action: any) => {
      state.loadingList = true;
    },
    [tagsList.rejected]: (state: any) => {
      state.loading = false;
    },
    // ======================================
    [addTags.fulfilled]: (state: any, action: any) => {
      state.loading = false;

      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        notify(action.payload.message, 'success');
        state.error = action.payload.message;
      }
    },
    [addTags.rejected]: (state: any) => {
      state.loading = false;
    },
    // ======================================
    [editTags.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
        notify(action.payload.message, 'success');
      }
    },
    [editTags.rejected]: (state: any) => {
      state.loading = false;
    },
    // ======================================
    [deleteTags.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
        notify(action.payload.message, 'success');
      }
    },
    [deleteTags.rejected]: (state: any) => {
      state.loading = false;
    },
    // ======================================
    [restoreTags.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
        notify(action.payload.message, 'success');
      }
    },
    [restoreTags.rejected]: (state: any) => {
      state.loading = false;
    },
  },
});

export const { openModal, editTagsFunc, showLoadingOff, setProgressLoader } = manageMoretagsSlice.actions;
export default manageMoretagsSlice.reducer;
