import React from 'react';
import SelectField from './SelectField';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { StylesConfig } from 'react-select';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';

interface FormikReactSelect<OptionType> {
  label?: React.ReactNode;
  name: string;
  isMulti?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  options: OptionType[];
  className?: string;
  required?: boolean;
  tooltip?: boolean;
  tooltipContent?: string | JSX.Element;
  onChange?: (e: OptionType) => void;
  formatOptionLabel?: (item: OptionType) => JSX.Element;
  removeSelectAll?: boolean;
  placeholder?: string;
  defaultValue?: any;
  id?: string;
  none?: boolean;
}

export const CustomSelect = <OptionType,>({
  label,
  name,
  isLoading,
  className,
  options,
  required,
  tooltip,
  isDisabled,
  onChange,
  defaultValue,
  removeSelectAll,
  placeholder,
  none,
  id,
  tooltipContent,

  ...rest
}: FormikReactSelect<OptionType>) => {
  const { t } = useTranslation();

  const colorStyles: StylesConfig = {
    control: (styles, { isDisabled }: any) => ({
      ...styles,
      backgroundColor: isDisabled ? '#e9ecef' : '#FFFFFF',
      borderRadius: '50px',
      padding: '5px 10px',
      borderColor: '#e5e5e5',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#e5e5e5',
      },
      ...(isDisabled
        ? {
            pointerEvents: 'auto',
            cursor: 'not-allowed',
          }
        : {
            pointerEvents: 'auto',
            cursor: 'pointer',
          }),
    }),

    menuList: styles => ({ ...styles, maxHeight: '200px' }),
    menu: styles => ({ ...styles, zIndex: 9999 }),

    multiValue: styles => ({
      ...styles,
      backgroundColor: '#fff',
      border: '1px solid #2e68ff',
      borderRadius: '8px',
      padding: '3px 6px',
    }),

    multiValueLabel: styles => ({
      ...styles,
      color: '#000',
      fontSize: '14px',
    }),

    multiValueRemove: (styles, { isDisabled }: any) => ({
      ...styles,
      display: isDisabled ? 'none' : 'inline-block',
    }),
  };

  return (
    <Form.Group className={`${className}`} controlId={name}>
      <div className='d-flex'>
        <span className='d-flex'>
          <div className='mb-2'>{label}</div>
          {required && <span className='text-danger'>*</span>}
        </span>
        {'   '}
        {tooltip && (
          <>
            {'  '}
            <div className='ms-2'>
              {['top'].map(placement => (
                <OverlayTrigger
                  key={placement}
                  overlay={<Tooltip id={`tooltip-${placement}`}>{tooltipContent}</Tooltip>}
                >
                  <span className='' style={{ top: '1px' }}>
                    <AiOutlineInfoCircle size={18} color='#808080' />
                  </span>
                </OverlayTrigger>
              ))}
            </div>
          </>
        )}
      </div>
      {isLoading ? (
        <Form.Control defaultValue={`${t('GLOBAL.LOADING')}...`} disabled />
      ) : (
        <SelectField
          options={options}
          id={id}
          name={name}
          defaultValue={defaultValue}
          removeSelectAll={removeSelectAll}
          none={none}
          required={required}
          onChange={onChange}
          {...rest}
          styles={colorStyles}
          isDisabled={isDisabled}
          placeholder={placeholder}
        />
      )}
      {/* <ErrorMessage name={name} component={TextError} /> */}
    </Form.Group>
  );
};
