// Redux Toolkit
import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
// Axios for making API requests

import { fetch11, fetch5, fetch10, fetch6, fetch2, fetch3, fetch9 } from '../../Apis/commonApis';

import { notify } from '../../constants/notifyconstants';

const initialState = {
  getAllTransferOrdersData: null,
  inventoryTransferOrderBranchesId: null,
  getAllinventoryTransferOrderWareHouseData: null,
  wareHouseForTransferOrdersData: null,
  showInventoryTransferOrderDetailsById: null,
  createNewTransferOrderData: null,
  showInventoryTransferOrderDetailsSendData: null,
  TransferOrderFilteredBranch: null,
  TransferOrderCreationData: {
    warehouse: null,
    destination: null,
  },
  TransferOrderFilteredWareHouse: null,
  loading: false,
  isLoadingList: false,
  navgaiterLoader: false,
  error: null,
  isFiltered: false,
  transferOrderFilter: {
    status: null,
    reference: null,
    business_date: null,
    updated_after: null,
    branch: null,
    creator: null,
    poster: null,
    warehouse: null,
  },
};

export let getNewransferOrdersData: any;
export let getNewransferOrdersDataTID: any;
// Async thunk action creator
export const getAllTransferOrders: any = createAsyncThunk('inventoryTransferOrderSlice', async (body: any) => {
  try {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/transfer-orders`, body);
    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
  // getAllTransferOrdersData = response.data;
});

export const getAllWareHouseForTransferOrders: any = createAsyncThunk('getAllWareHouseForTransferOrders', async () => {
  const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory-warehouses`);
  console.log(response, 'responseresponse');
  // getAllTransferOrdersData = response.data;
  return response.data;
});
export const createNewTransferOrder: any = createAsyncThunk('createNewTransferOrder', async body => {
  try {
    const response = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/transfer-orders`, body);
    console.log(response, 'responseresponse');
    notify('created New Transfer Order', 'success');
    return response;
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
          notify(error?.response?.data?.errors[field][0]),
        )
      : notify(error?.response?.data?.message);
  }
});

export const showInventoryTransferOrderDetails: any = createAsyncThunk(
  'showInventoryTransferOrderDetails',
  async id => {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/transfer-orders/${id}`);
    console.log(response, 'responseresponse');
    // getAllTransferOrdersData = response.data;
    return response.data;
  },
);
export const submitInventoryTransferOrderDetails: any = createAsyncThunk(
  'submitInventoryTransferOrderDetails',
  async id => {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/transfer-orders/submitReview/${id}`);
    console.log(response, 'responseresponse');
    // getAllTransferOrdersData = response.data;
    notify('submit Transfer Order', 'success');

    return response.data;
  },
);
export const sendInventoryTransferOrderDetails: any = createAsyncThunk(
  'sendInventoryTransferOrderDetails',
  async id => {
    const response = await fetch6(
      `${process.env.REACT_APP_BASEURL}/inventory/transfer-orders/sendItemTransferOrder/${id}`,
    );
    console.log(response, 'responseresponse');
    // getAllTransferOrdersData = response.data;
    notify('submit Transfer Order', 'success');

    return response.data;
  },
);

export const acceptInventoryTransferOrderDetails: any = createAsyncThunk(
  'acceptInventoryTransferOrderDetails',
  async id => {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/transfer-orders/accept/${id}`);
    console.log(response, 'responseresponse');
    // getAllTransferOrdersData = response.data;
    notify('accept Transfer Order', 'success');

    return response.data;
  },
);
export const declineInventoryTransferOrderDetails: any = createAsyncThunk(
  'declineInventoryTransferOrderDetails',
  async id => {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/transfer-orders/decline/${id}`);
    console.log(response, 'responseresponse');
    // getAllTransferOrdersData = response.data;
    notify('decline Transfer Order', 'success');

    return response.data;
  },
);
export const updateQtyForAllTransferOrder: any = createAsyncThunk(
  'updateQtyForAllTransferOrder',
  async ({ body, id }: any) => {
    const response = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/transfer-orders/${id}`, body);
    console.log(response, 'responseresponse');
    // getAllTransferOrdersData = response.data;
    notify('accept Transfer Order', 'success');

    return response.data;
  },
);

export const updateOneQtyTransferOrder: any = createAsyncThunk('updateOneQtyTransferOrder', async (body: any) => {
  try {
    const response = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/transfer-orders/${body.transferOrderId}/update_item/${body.itemId}`,
      body.data,
    );
    return response.data;
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
          notify(error?.response?.data?.errors[field][0]),
        )
      : notify(error?.response?.data?.message);
  }
});

export const updateTransferOrder: any = createAsyncThunk('updateTransferOrder', async (body: any) => {
  try {
    const response = await fetch9(
      `${process.env.REACT_APP_BASEURL}/inventory/transfer-orders/${body.transferOrderId}`,
      body.data,
    );
    console.log(response, 'responseresponse');
    if (response.code === 403) {
      notify(response.massage, 'error');
      return response;
    } else {
      return response.data;
    }
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
          notify(error?.response?.data?.errors[field][0]),
        )
      : notify(error?.response?.data?.message);
  }
});

export const duplicateTransferOrder: any = createAsyncThunk('duplicateTransferOrder', async (id: any) => {
  try {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/transfer-orders/duplicate/${id}`);
    return response.data;
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
          notify(error?.response?.data?.errors[field][0]),
        )
      : notify(error?.response?.data?.message);
  }
});
export const updateItemsForTO: any = createAsyncThunk('updateItemsForTO', async (body: any) => {
  const response = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/transfer-orders/add_items/${body.id}`,
    body.data,
  );
  console.log(response, 'responseresponse');
  // getAllTransferOrdersData = response.data;
  notify('accept Transfer Order', 'success');

  return response.data;
});
export const delTOById: any = createAsyncThunk('delTOById', async id => {
  const response = await fetch10(`${process.env.REACT_APP_BASEURL}/inventory/transfer-orders/${id}`);
  console.log(response, 'responseresponse');
  // getAllTransferOrdersData = response.data;
  notify('Deleted Transfer Order succefelly !', 'success');

  return response.data;
});

export const delItemTOById: any = createAsyncThunk('delItemTOById', async (body: any) => {
  try {
    const response = await fetch10(
      `${process.env.REACT_APP_BASEURL}/inventory/transfer-orders/${body.transferOrderId}/update_item/${body.itemId}`,
    );
    notify('deleted Transfer Order item', 'success');
    return response.data;
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
          notify(error?.response?.data?.errors[field][0]),
        )
      : notify(error?.response?.data?.message);
  }
});

const inventoryTransferOrderSlice: any = createSlice({
  name: 'inventoryTransferOrderSlice',
  initialState,

  reducers: {
    setTransferOrderFilterObject: (state, action) => {
      state.isFiltered = true;
      state.transferOrderFilter.status = action.payload.status ? action.payload.status : null;
      state.transferOrderFilter.reference = action.payload.reference ? action.payload.reference : null;
      state.transferOrderFilter.business_date = action.payload.business_date ? action.payload.business_date : null;
      state.transferOrderFilter.updated_after = action.payload.updated_after ? action.payload.updated_after : null;
      state.transferOrderFilter.branch = action.payload.branch ? action.payload.branch : null;
      state.transferOrderFilter.warehouse = action.payload.warehouse ? action.payload.warehouse : null;
      state.transferOrderFilter.creator = action.payload.creator ? action.payload.creator : null;
      state.transferOrderFilter.poster = action.payload.poster ? action.payload.poster : null;
    },
    resetTransferOrderFilterObject: (state, action) => {
      state.transferOrderFilter = initialState.transferOrderFilter;
      state.isFiltered = false;
    },
    changeIsFiltered: (state, action) => {
      state.isFiltered = action.payload;
    },
    saveTransferOrderBranchId: (state, action) => {
      state.inventoryTransferOrderBranchesId = action.payload;
    },
    saveTransferOrderWareHouseId: (state, action) => {
      state.wareHouseForTransferOrdersData = action.payload;
    },
    saveTransferOrderFilteredBranch: (state, action) => {
      state.TransferOrderFilteredBranch = action.payload;
    },
    saveTransferOrderCreationData: (state, action) => {
      state.TransferOrderCreationData = action.payload;
    },
    resetTransferOrderCreationData: (state, action) => {
      state.TransferOrderCreationData = {
        warehouse: null,
        destination: null,
      };
    },
    saveTransferOrderFilteredWareHouse: (state, action) => {
      state.TransferOrderFilteredWareHouse = action.payload;
    },
    setnavgaiteLoader: (state, action) => {
      state.navgaiterLoader = action.payload;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getAllTransferOrders.pending, (state: any) => {
        state.isLoadingList = true;
        state.error = null;
      })
      .addCase(getAllTransferOrders.fulfilled, (state: any, action: any) => {
        state.getAllTransferOrdersData = action.payload;
        state.isLoadingList = false;
        state.error = null;
        // notify('get Transfer Orders succefelly','success')
      })
      .addCase(getAllTransferOrders.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllWareHouseForTransferOrders.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllWareHouseForTransferOrders.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(showInventoryTransferOrderDetails.pending, (state: any, action: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(showInventoryTransferOrderDetails.fulfilled, (state: any, action: any) => {
        state.showInventoryTransferOrderDetailsById = action.payload;
        state.loading = false;
        state.navgaiterLoader = false;

        state.error = null;
      })
      .addCase(showInventoryTransferOrderDetails.rejected, (state: any, action: any) => {
        console.log(action.error.message);

        notify(action.error.message, 'error');
      })
      .addCase(createNewTransferOrder.fulfilled, (state: any, action: any) => {
        state.createNewTransferOrderData = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(sendInventoryTransferOrderDetails.fulfilled, (state: any, action: any) => {
        state.showInventoryTransferOrderDetailsSendData = action.payload;
        getNewransferOrdersDataTID = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(updateOneQtyTransferOrder.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateTransferOrder.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(duplicateTransferOrder.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(sendInventoryTransferOrderDetails.pending, (state: any, action: any) => {
        state.showInventoryTransferOrderDetailsSendData = action.payload;
        state.loading = true;
        state.error = null;
      });
  },
});
export const {
  setTransferOrderFilterObject,
  resetTransferOrderFilterObject,
  saveTransferOrderBranchId,
  saveTransferOrderWareHouseId,
  saveTransferOrderFilteredBranch,
  saveTransferOrderFilteredWareHouse,
  setnavgaiteLoader,
  changeIsFiltered,
  saveTransferOrderCreationData,
  resetTransferOrderCreationData,
} = inventoryTransferOrderSlice.actions;

export default inventoryTransferOrderSlice.reducer;
