import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch10, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';
import toast from 'react-hot-toast';

export const showFranchiseList: any = createAsyncThunk('showFranchiseList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/franchise`, body);
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
});
export const getFranchiseDetails: any = createAsyncThunk('getFranchiseDetails', async id => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/franchise/${id}`);
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
});

export const getFranchiseDetailsPayments: any = createAsyncThunk('getFranchiseDetailsPayments', async id => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/franchise/${id}/payments`);
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
});

export const createFranchiseDetailsPayments: any = createAsyncThunk(
  'createFranchiseDetailsPayments',
  async (body: any) => {
    try {
      const response: any = await fetch5(`${process.env.REACT_APP_BASEURL}/franchise/${body.id}/payments`, body);
      return response;
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  },
);

export const createFranchise: any = createAsyncThunk('createFranchise', async body => {
  try {
    const response: any = await fetch5(`${process.env.REACT_APP_BASEURL}/franchise`, body);
    toast.success('Created Successfully');

    return response.data;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
});
export const editFranchise: any = createAsyncThunk('editFranchise', async (body: any) => {
  try {
    const response: any = await fetch9(`${process.env.REACT_APP_BASEURL}/franchise/${body.id}`, body);
    toast.success('Edited Successfully');

    return response.data;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
});

export const deletefranchise: any = createAsyncThunk('deletefranchise', async id => {
  try {
    const response: any = await fetch10(`${process.env.REACT_APP_BASEURL}/franchise/${id}`);
    toast.success('Deleted Successfully');

    return response.data;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
});
export const getAllFranchiseDocs: any = createAsyncThunk('getAllFranchiseDocs', async id => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/franchise-documents?franchiser_id=${id}`);
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
});

export const createFranchiseDocs: any = createAsyncThunk('createFranchiseDocs', async body => {
  try {
    const response: any = await fetch5(`${process.env.REACT_APP_BASEURL}/franchise-documents`, body);
    toast.success('Created Successfully');

    return response.data;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
});
export const editFranchiseDocs: any = createAsyncThunk('editFranchiseDocs', async ({ body, id }: any) => {
  try {
    const response: any = await fetch9(`${process.env.REACT_APP_BASEURL}/franchise-documents/${id}`, body);
    toast.success('Edited Successfully');

    return response.data;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
});

export const deleteFranchiseDocs: any = createAsyncThunk('deleteFranchiseDocs', async id => {
  try {
    const response: any = await fetch10(`${process.env.REACT_APP_BASEURL}/franchise-documents/${id}`);
    toast.success('Deleted Successfully');

    return response.data;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
});

export const activeBranch: any = createAsyncThunk('activeBranch', async (body: any) => {
  try {
    const response: any = await fetch5(
      `${process.env.REACT_APP_BASEURL}/franchise/${body?.id}/switch-branch/${body.branchId}`,
      body,
    );

    return response.data;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
});

export const restorefranchise: any = createAsyncThunk('restorefranchise', async id => {
  try {
    const response: any = await fetch5(`${process.env.REACT_APP_BASEURL}/franchise/${id}`);
    toast.success('Restore Successfully');

    return response.data;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
});

const franchiseSlice: any = createSlice({
  name: 'franchiseSlice',
  initialState: {
    showFranchiseListData: null,
    franchiseDetails: null,
    franchiseDetailsPayments: null,
    isFiltered: false,
    getAllDocsForFranchiseDetailsData: null,
  },
  reducers: {
    changeFilterState(state, action) {
      state.isFiltered = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(showFranchiseList.fulfilled, (state: any, action) => {
      state.isLoading = false;

      state.showFranchiseListData = action.payload;
    });
    builder.addCase(showFranchiseList.pending, (state: any, action) => {
      state.isLoading = true;
    });
    builder.addCase(showFranchiseList.rejected, (state: any, action) => {
      state.isLoading = false;
    });
    builder.addCase(getFranchiseDetails.fulfilled, (state: any, action) => {
      state.isLoading = false;

      state.franchiseDetails = action.payload.data;
    });
    builder.addCase(getFranchiseDetailsPayments.fulfilled, (state: any, action) => {
      state.isLoading = false;

      state.franchiseDetailsPayments = action?.payload?.data;
    });
    builder.addCase(getAllFranchiseDocs.fulfilled, (state: any, action) => {
      state.isLoading = false;

      state.getAllDocsForFranchiseDetailsData = action.payload.data;
    });
  },
});

export const { changeFilterState } = franchiseSlice.actions;
export default franchiseSlice.reducer;
