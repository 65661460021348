// Redux Toolkit
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// Axios for making API requests
import {
  fetch11,
  fetch5,
  fetch10,
  fetch6,
  fetch9,
} from "../../Apis/commonApis";
import { notify } from "../../constants/notifyconstants";

export let inventoryCountBranches: any;
export let inventoryCountBranchesId: any;
export let itemsForInventoryCount: any;
export let createNewInventoryCount: any;
export let getInventoryCountDetails: any;

export let inventoryCount_list: any = {
  reference: "",
  business_date: "",
  status: "",
  branch: [],
  tags: [],
  creator: "",
  updated_at: "",
};
// Async thunk action creator
export const fetchBranches: any = createAsyncThunk(
  "fetchBranches",
  async (body: any) => {
    const response = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/branches`,
      body
    );

    return response.data;
  }
);
export const inventoryCount: any = createAsyncThunk(
  "inventoryCount",
  async () => {
    const response = await fetch6(
      `${process.env.REACT_APP_BASEURL}/inventory/items`
    );

    return response.data;
  }
);
export const createInventoryCount: any = createAsyncThunk(
  "createInventoryCount",
  async (body) => {
    const response = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/inventory-count`,
      body
    );
    console.log(response.data, "createInventoryCount");
    createNewInventoryCount = response;
    console.log(createNewInventoryCount, "createInventoryCount");
    notify("Create Inventory Count Successfully", "success");
    return response;
  }
);
export const getInventoryCountById: any = createAsyncThunk(
  "getInventoryCountById",
  async (body) => {
    const response = await fetch6(
      `${process.env.REACT_APP_BASEURL}/inventory/inventory-count/${body}`
    );

    console.log(getInventoryCountDetails, "getInventoryCountDetails");
    return response;
  }
);
export const importCountItems: any = createAsyncThunk(
  "importCountItems",
  async ({ e, id, file }: any) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await fetch5(
          `${process.env.REACT_APP_BASEURL}/inventory-count/import_items/${id}`,
          formData
        );

        if (response.code == 200) {
          notify(response.message, "success");
          return response;
        }
      } catch (error: any) {
        notify(error?.response?.data?.message);
      }
    }
  }
);

export const delInventoryCountById: any = createAsyncThunk(
  "getInventoryCountDetails",
  async (body) => {
    const response = await fetch10(
      `${process.env.REACT_APP_BASEURL}/inventory/inventory-count/${body}`
    );
    notify("Delete Inventory Count Successfully", "success");

    return response;
  }
);

export const updateInventoryCountById: any = createAsyncThunk(
  "updateInventoryCountById",
  async (body: any) => {
    const response = await fetch9(
      `${process.env.REACT_APP_BASEURL}/inventory/inventory-count/${body.id}`,
      body
    );
    notify("Update Inventory Count Successfully", "success");

    return response;
  }
);
export const addNewItem: any = createAsyncThunk(
  "addNewItem",
  async (body: any) => {
    const response = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/inventory-count/add_item/${body.id}`,
      body
    );
    notify("Added New Item", "success");

    return response;
  }
);
export const updateInventoryCountOneItemQty: any = createAsyncThunk(
  "updateInventoryCountOneItemQty",
  async (body: any) => {
    const response = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/inventory-count/update_one_item/${body.id}`,
      body
    );
    notify("Update Inventory Count Successfully", "success");

    return response;
  }
);
export const delOneItem: any = createAsyncThunk("delOneItem", async (id) => {
  const response = await fetch6(
    `${process.env.REACT_APP_BASEURL}/inventory/inventory-count/delete_item/${id}`
  );
  notify("Delete item of inventory Count is Successfully", "success");

  return response;
});
export const submitCount: any = createAsyncThunk("submitCount", async (id) => {
  const response = await fetch6(
    `${process.env.REACT_APP_BASEURL}/inventory/inventory-count/receiver_items/${id}`
  );
  notify("updateInventoryCountById", "success");

  return response;
});
export const updateInventoryAllQty: any = createAsyncThunk(
  "updateInventoryAllQty",
  async ({ body, id }: any) => {
    const response = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/inventory-count/update_item/${id}`,
      body
    );
    notify("updateInventoryCountById", "success");

    return response;
  }
);
export const inventoryCountList: any = createAsyncThunk(
  "inventoryCountList",
  async (body: any) => {
    const response = await fetch6(
      `${process.env.REACT_APP_BASEURL}/inventory/inventory-count`,
      body
    );

    return response;
  }
);

export const countSheetList: any = createAsyncThunk(
  "countSheetList",
  async (body: any) => {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/inventory/count_sheets`,
      body
    );
    return result;
  }
);
export const countSheetOne: any = createAsyncThunk(
  "countSheetOne",
  async (body: any) => {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/inventory/count_sheets/${body.id}`,
      body
    );
    return result;
  }
);

export const inventoryCountDuplicate: any = createAsyncThunk(
  "inventoryCountDuplicate",
  async (body: any) => {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/inventory/inventory-count/duplicate/${body.id}`
    );
    return result;
  }
);
// Slice
const inventoryCountReducer: any = createSlice({
  name: "inventoryCountBranches",
  initialState: {
    dataBranches: {},
    itemsForInventoryCount: {},
    InventoryCountDetails: null,
    updateInventoryCountDataById: null,
    inventoryCountListData: null,
    loading: false,
    oneTimeLoading1: false,
    error: null,
    inventoryCountBranchesId: "",
    isFilter: false,
    countSheetListData: [],
    countSheetOneDate: {},
    loadingPage: false,
    inventoryCountLoading: false,
  },
  reducers: {
    saveBranchId: (state, action) => {
      state.inventoryCountBranchesId = action.payload;
    },
    setPageLoading(state: any) {
      state.loadingPage = true;
    },
    setFilter: (state: any, action: any) => {
      state.isFilter = true;
      inventoryCount_list.reference = action.payload.reference;
      inventoryCount_list.business_date = action.payload.business_date;
      inventoryCount_list.status = action.payload.status;
      inventoryCount_list.tags = action.payload.tags;
      inventoryCount_list.branch = action.payload.branch;
      inventoryCount_list.creator = action.payload.creator;
      inventoryCount_list.updated_at = action.payload.updated_at;
    },
    resetFilter: (state: any) => {
      state.isFilter = false;
      inventoryCount_list.reference = "";
      inventoryCount_list.business_date = "";
      inventoryCount_list.status = "";
      inventoryCount_list.tags = [];
      inventoryCount_list.creator = "";
      inventoryCount_list.branch = [];

      inventoryCount_list.updated_at = "";
    },
    setIconFilter: (state: any) => {
      state.isFilter = false;
    },
    iconResetFilter: (state: any) => {
      state.isFilter = true;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchBranches.fulfilled, (state: any, action: any) => {
        state.dataBranches = action.payload;
        console.log(action.payload, "action.payload");

        state.loading = false;

        // state.error = null;
      })

      .addCase(createInventoryCount.pending, (state: any) => {
        state.loading = true;
        // state.error = null;
      })
      .addCase(createInventoryCount.fulfilled, (state: any, action: any) => {
        // state.dataBranches = action.payload;
        state.loading = false;
        // state.error = null;
      })
      .addCase(createInventoryCount.rejected, (state: any, action: any) => {
        state.loading = false;
        // state.error = action.error.message;
        notify(action.error.message, "error");
      })

      .addCase(inventoryCount.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(inventoryCount.fulfilled, (state: any, action: any) => {
        state.itemsForInventoryCount = action.payload;
        state.loading = false;
        // state.error = null;
      })
      .addCase(inventoryCount.rejected, (state: any, action: any) => {
        state.loading = false;
        // state.error = null;
      })

      .addCase(getInventoryCountById.fulfilled, (state: any, action: any) => {
        state.InventoryCountDetails = action.payload.data;

        state.loadingPage = false;
      })
      .addCase(getInventoryCountById.pending, (state: any, action: any) => {
        state.loadingPage = true;

      })

      .addCase(
        updateInventoryCountById.fulfilled,
        (state: any, action: any) => {
          state.updateInventoryCountDataById = action.payload.data;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(updateInventoryCountById.pending, (state: any, action: any) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(inventoryCountList.fulfilled, (state: any, action: any) => {
        state.inventoryCountListData = action.payload;

        state.inventoryCountLoading = false;
        state.loading = false;
        state.error = null;
      })
      .addCase(inventoryCountList.pending, (state: any, action: any) => {
        state.loading = true;
        state.inventoryCountLoading = true;
        state.error = null;
      })
      .addCase(inventoryCountList.rejected, (state: any, action: any) => {
        state.loading = false;
        state.inventoryCountLoading = false;
        state.error = null;
      })
      .addCase(countSheetList.fulfilled, (state: any, action: any) => {
        state.countSheetListData = action.payload.data;

        state.loading = false;
        state.error = null;
      })
      .addCase(countSheetList.pending, (state: any, action: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(countSheetList.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(countSheetOne.fulfilled, (state: any, action: any) => {
        state.countSheetOneData = action.payload.data;

        state.loading = false;
        state.error = null;
      });
  },
});

// Export the slice reducer
export const {
  saveBranchId,
  oneTimeLoading,
  setFilter,
  resetFilter,
  setIconFilter,
  iconResetFilter,
  setPageLoading,
} = inventoryCountReducer.actions;

export default inventoryCountReducer.reducer;
