import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetch10,
  fetch2,
  fetch22,
  fetch3,
  fetch5,
  fetch6,
  fetch9,
} from "../../Apis/commonApis";

import { notify } from "../../constants/notifyconstants";

export const showModfireList: any = createAsyncThunk(
  "showModfireList",
  async (body) => {
    try {
      const response: any = await fetch6(
        `${process.env.REACT_APP_BASEURL}/menu/modifiers?per_page=50`,
        body
      );
      // notify("success", "success");
      return response;
    } catch (error: any) {
      notify(error.message, "error");
      throw error;
    }
  }
);
export const editMod: any = createAsyncThunk(
  "editMod",
  async ({ id = "", body = null }: any) => {
    try {
      const response: any = await fetch9(
        `${process.env.REACT_APP_BASEURL}/menu/modifiers/${id}`,
        body
      );
      // notify("success", "success");
      return response.data;
    } catch (error: any) {
      notify(error.message, "error");
      throw error;
    }
  }
);
export const delMod: any = createAsyncThunk("delMod", async (body) => {
  try {
    const response: any = await fetch10(
      `${process.env.REACT_APP_BASEURL}/menu/modifiers/${body}`
    );
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    const referenceKey = Object.keys(error.response.data.errors)[0];
    const referenceValue = error.response.data.errors[referenceKey];
    console.log(referenceValue);

    console.log(referenceValue.values);

    // notify(error.message, "error");
    notify(referenceValue[0], "error");
    // notify(error.message, "error");
    throw error;
  }
});
export const restoreMod: any = createAsyncThunk("restoreMod", async (body) => {
  try {
    const response: any = await fetch9(
      `${process.env.REACT_APP_BASEURL}/menu/modifiers/${body}/restore`
    );
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    notify(error.message, "error");
    throw error;
  }
});
export const createMod: any = createAsyncThunk("createMod", async (body) => {
  try {
    const response: any = await fetch5(
      `${process.env.REACT_APP_BASEURL}/menu/modifiers`,
      body
    );
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    console.log(error);
    const referenceKey = Object.keys(error.response.data.errors)[0];
    const referenceValue = error.response.data.errors[referenceKey];
    console.log(referenceValue);

    console.log(referenceValue.values);

    // notify(error.message, "error");
    notify(referenceValue[0], "error");
    throw error;
  }
});
export const createModProduct: any = createAsyncThunk(
  "createModProduct",
  async ({ body = null, url = "" }: any) => {
    try {
      const response: any = await fetch5(url, body);
      // notify("success", "success");
      return response.data;
    } catch (error: any) {
      console.log(error);
      const referenceKey = Object.keys(error.response.data.errors)[0];
      const referenceValue = error.response.data.errors[referenceKey];

      notify(referenceValue[0] || error.response.data.message, "error");
      throw error;
    }
  }
);

const modifierSliceMenu: any = createSlice({
  name: "modifierSliceMenu",
  initialState: {
    showModfireListData: null,
    settypeFilterData: null,
    newnavRouteKey: 0,
    isLoading: false,
    pageCount: 0,
    isFiltered: false
  },
  reducers: {
    settypeFilter: (state, action) => {
      // Perform state update logic here based on the action
      // Return the updated state
      state.settypeFilterData = action.payload;
    },
    newnavRoute: (state, action) => {
      // Perform state update logic here based on the action
      // Return the updated state
      state.newnavRouteKey = state.newnavRouteKey + 1;
    },
    setPageCount(state, action) {
      state.pageCount = action.payload;
    },
    setIsDataFiltered(state, action: any) {
      state.isFiltered = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(showModfireList.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.showModfireListData = action.payload;
    });
    builder.addCase(showModfireList.pending, (state: any, action) => {
      state.isLoading = true;
      state.showModfireListData = action.payload;
    });
  },
});

export const { setIsDataFiltered, settypeFilter, newnavRoute, setPageCount } =
  modifierSliceMenu.actions;
export default modifierSliceMenu.reducer;
