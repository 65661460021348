import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetch5, fetch6, fetch10, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';
import { RoundNum } from '../../utils/functions';

const initialState = {
  loading: false,
  filterStatus: false,
  error: null,
  filterBody: null,
  purchaseordersReportsList: {},
  getInventoryPurchaseOrderbranchesData: [],
  getInventoryPurchaseOrdercategoriesData: [],
  getInventoryPurchaseOrderitemsData: [],
  getInventoryPurchaseOrderTagsData: [],
  getInventoryPurchaseOrderSupplierData: [],
};

export const getPurchaseordersReportsList: any = createAsyncThunk('getPurchaseordersReportsList', async body => {
  const result = await fetch6(
    `${process.env.REACT_APP_BASEURL}/reports/inventory-report/inventory_purchase_order`,
    body,
  );
  return result;
});

export const getInventoryPurchaseOrderbranches: any = createAsyncThunk(
  'getInventoryPurchaseOrderbranches',
  async (body: any) => {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/branches`, body);
    return result.data;
  },
);
export const getInventoryPurchaseOrdercategories: any = createAsyncThunk(
  'getInventoryPurchaseOrdercategories',
  async (body: any) => {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory-categories`, body);
    return result.data;
  },
);
export const getInventoryPurchaseOrderitems: any = createAsyncThunk(
  'getInventoryPurchaseOrderitems',
  async (body: any) => {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items`, body);
    return result.data;
  },
);
export const getInventoryPurchaseOrderTags: any = createAsyncThunk(
  'getInventoryPurchaseOrderTags',
  async (body: any) => {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);
    return result.data;
  },
);
export const getInventoryPurchaseOrderSupplier: any = createAsyncThunk(
  'getInventoryPurchaseOrderSupplier',
  async (body: any) => {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/suppliers`, body);
    return result.data;
  },
);

const ReportsPurchaseOrdersApiReducer = createSlice({
  name: 'ReportsPurchaseOrdersApiReducer',
  initialState,
  reducers: {
    changeFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    setFilter: (state, action) => {
      state.filterBody = action.payload;
    },
  },
  extraReducers: {
    [getPurchaseordersReportsList.pending]: state => {
      state.loading = true;
    },
    [getPurchaseordersReportsList.fulfilled]: (state, action) => {
      state.loading = false;
      state.purchaseordersReportsList = {
        data: action.payload.data.map((obj: { [key: string]: any }) => {
          Object.entries(obj).forEach(([key, value]: any) => {
            obj[key] = !isNaN(+value) ? RoundNum(+value) : value;
          });

          return obj;
        }),
        ...action.payload,
      };
    },
    [getPurchaseordersReportsList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryPurchaseOrderSupplier.pending]: state => {
      state.loading = true;
    },
    [getInventoryPurchaseOrderSupplier.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryPurchaseOrderSupplierData = action.payload;
    },
    [getInventoryPurchaseOrderSupplier.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getInventoryPurchaseOrderbranches.pending]: state => {
      state.loading = true;
    },
    [getInventoryPurchaseOrderbranches.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryPurchaseOrderbranchesData = action.payload;
    },
    [getInventoryPurchaseOrderbranches.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryPurchaseOrdercategories.pending]: state => {
      state.loading = true;
    },
    [getInventoryPurchaseOrdercategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryPurchaseOrdercategoriesData = action.payload;
    },
    [getInventoryPurchaseOrdercategories.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryPurchaseOrderitems.pending]: state => {
      state.loading = true;
    },
    [getInventoryPurchaseOrderitems.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryPurchaseOrderitemsData = action.payload;
    },
    [getInventoryPurchaseOrderitems.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryPurchaseOrderTags.pending]: state => {
      state.loading = true;
    },
    [getInventoryPurchaseOrderTags.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryPurchaseOrderTagsData = action.payload;
    },
    [getInventoryPurchaseOrderTags.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});
export const { changeFilterStatus, setFilter } = ReportsPurchaseOrdersApiReducer.actions;
export default ReportsPurchaseOrdersApiReducer.reducer;
