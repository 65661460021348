import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetch5, fetch6, fetch10, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  loading: false,
  filterStatus: false,
  error: null,
  filterBody: null,
  transfersReportsList: [],
  getInventoryTbranchesData: [],
  getInventoryTcategoriesData: [],
  getInventoryTitemsData: [],
  getInventoryTTagsData: [],
  getInventoryTSupplierData: [],
};

export const getTransfersReportsList: any = createAsyncThunk('getTransfersReportsList', async body => {
  let result;
  if (process.env.REACT_APP_BASEURL === 'https://sandbox-api.dot.sa/api/v1') {
    result = await fetch6(`${process.env.REACT_APP_BASEURL}/reports/inventory-report/inventory_transfer`, body);
  } else {
    result = await fetch6(`${process.env.REACT_APP_BASEURL}/reports/Transfers`, body);
  }

  return result;
});
export const getInventoryTbranches: any = createAsyncThunk('getInventoryTbranches', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/branches`, body);
  return result.data;
});
export const getInventoryTcategories: any = createAsyncThunk('getInventoryTcategories', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory-categories`, body);
  return result.data;
});
export const getInventoryTitems: any = createAsyncThunk('getInventoryTitems', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items`, body);
  return result.data;
});
export const getInventoryTTags: any = createAsyncThunk('getInventoryTTags', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);
  return result.data;
});
export const getInventoryTSupplier: any = createAsyncThunk('getInventoryTSupplier', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/suppliers`);
  return result.data;
});

const ReportsTransfersApiReducer = createSlice({
  name: 'ReportsTransfersApiReducer',
  initialState,
  reducers: {
    changeFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    setFilter: (state, action) => {
      state.filterBody = action.payload;
    },
  },
  extraReducers: {
    [getTransfersReportsList.pending]: state => {
      state.loading = true;
    },
    [getTransfersReportsList.fulfilled]: (state, action) => {
      state.loading = false;
      state.transfersReportsList = action.payload;
    },
    [getTransfersReportsList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryTSupplier.pending]: state => {
      state.loading = true;
    },
    [getInventoryTSupplier.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryTSupplierData = action.payload;
    },
    [getInventoryTSupplier.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getInventoryTbranches.pending]: state => {
      state.loading = true;
    },
    [getInventoryTbranches.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryTbranchesData = action.payload;
    },
    [getInventoryTbranches.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryTcategories.pending]: state => {
      state.loading = true;
    },
    [getInventoryTcategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryTcategoriesData = action.payload;
    },
    [getInventoryTcategories.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryTitems.pending]: state => {
      state.loading = true;
    },
    [getInventoryTitems.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryTitemsData = action.payload;
    },
    [getInventoryTitems.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryTTags.pending]: state => {
      state.loading = true;
    },
    [getInventoryTTags.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryTTagsData = action.payload;
    },
    [getInventoryTTags.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export const { changeFilterStatus, setFilter } = ReportsTransfersApiReducer.actions;
export default ReportsTransfersApiReducer.reducer;
