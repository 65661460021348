import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import cx from './Modals.module.scss';
import st from '../../style.module.scss';
import Select from 'react-select';
import { FilterDropdown } from '../Dropdown/Dropdowns';
import { useDispatch, useSelector } from 'react-redux';
import { showLoading } from '../../redux_toolkit/reducer/orderModalsReducer';

import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import {
  addConfigurationApi,
  businessList,
  businessSync,
  companyId,
  connectItems,
  connectionConfig,
  connections,
  editConnectionApi,
  deleteConnectionApi,
  getOdooCustomers,
  getOdooJournals,
  getOdooProducts,
  getPaymentMethods,
  getTaxes,
  paymentMehods,
  priceTags,
  syncObject,
  addTakerConnectionApi,
} from '../../redux_toolkit/reducer/MarketPlaceApiReducer';
import { notify } from '../../constants/notifyconstants';
import {
  showCustomersList,
  showPaymentMethodsList,
  showProductList,
  showbranchesList,
  showprice_tagsList,
} from '../../redux_toolkit/reducer/manageCommanApiReducer';
import { showTaxList } from '../../redux_toolkit/reducer/manageTaxGroupApiReducer';
import { paymentList } from '../../redux_toolkit/reducer/manageMorePaymentApiReducer';
import { showPartnerList } from '../../redux_toolkit/reducer/deliveryPartenersApiReducer';
import { addConnectionApi } from '../../redux_toolkit/reducer/MarketPlaceApiReducer';
import { NormalBtn } from '../buttons/NormalBtn';
import { OutlineBtn } from '../buttons/OutlinedBtn';
import { productList } from '../../redux_toolkit/reducer/menuProductsApiReducer';

const Modals = (props: any) => {
  // ============================================State====================================

  const dispatch = useDispatch();
  const param = useParams();
  const statusComman = useSelector((state: any) => state.manageCommanApiReducer);

  const connectionConfigList = useSelector((state: any) => state.MarketPlaceApiReducer.connectionConfigList);
  const CompaniesList = useSelector((state: any) => state.MarketPlaceApiReducer.CompaniesList);
  const CustomersList = useSelector((state: any) => state.MarketPlaceApiReducer.CustomersList);
  const JournalsList = useSelector((state: any) => state.MarketPlaceApiReducer.JournalsList);

  const ProductsList = useSelector((state: any) => state.MarketPlaceApiReducer.ProductsList);

  const TaxesList = useSelector((state: any) => state.MarketPlaceApiReducer.TaxesList);

  const PaymentMethodsList = useSelector((state: any) => state.MarketPlaceApiReducer.PaymentMethodsList);

  const isLoading = useSelector((state: any) => state.MarketPlaceApiReducer.isLoading);

  const BranchesList = useSelector((state: any) => state.manageCommanApiReducer.showbranchesListData);

  const DeliveryLocationsList = useSelector((state: any) => state.MarketPlaceApiReducer.DeliveryLocationsList);

  const [subshow, setsubShow] = useState(false);
  const [submodalName, setsubModalName] = useState('');
  const [advanceOptions, setadvanceOptions] = useState(false);
  const [ordertype, setOrderType] = useState('');
  const [businessListData, setBusinessListData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [customersData, setCustomersData] = useState([]);
  const [branchesData, setBranchesData] = useState([]);
  const [taxGroups, setTaxGroup] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [arrProduct, setArrProduct] = useState<any[]>([]);
  const [arrBranches, setArrBranches] = useState<any[]>([]);
  const [arrCustomers, setArrCustomers] = useState<any[]>([]);
  const [arrTax, setArrTax] = useState<any[]>([]);
  const [arrPaymentMethods, setArrPaymentMethods] = useState<any[]>([]);
  const [arrPriceTags, setArrPriceTags] = useState<any[]>([]);
  const [singleProduct, setSingleProduct] = useState();
  const [singleCustomer, setSingleCustomer] = useState();
  const [singleBranch, setSingleBranch] = useState();
  const [singlePayment, setSinglePayment] = useState();
  const [singleTax, setSingleTax] = useState();
  const [singlePriceList, setSinglePriceList] = useState();
  const [businessId, setBusinessId] = useState({ id: null });
  const [companies, setCompanies] = useState<any[]>([]);
  const [taxes, setTaxes] = useState<any[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [customers, setCustomers] = useState<any[]>([]);
  const [journals, setJournals] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [deliveryLocationsData, setdeliveryLocationsData] = useState<any[]>([]);
  const [modalName, setModalName] = useState('');
  const [show, setShow] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [compID, setCompID] = useState(connectionConfigList?.configuration?.configurations?.company_id);

  const [laoding, showloading] = useState(false);

  const [addConnection, setAddConnection] = useState({
    service: 'odoo',
    name: '',
    credentials: {
      db: '',
      url: '',
      user: '',
      password: '',
    },
  });
  const [addTakerConnection, setAddTakerConnection] = useState({
    service: 'taker',
    name: '',
    credentials: {
      user: '',
      password: '',
    },
  });
  const [addConfig, setAddConfig] = useState({
    connection_id: '',
    branches: [],
    service: 'odoo',
    configurations: {
      company_id: 0,
      customer_id: 0,
      journal_id: 0,
      product_id: 0,
      picking_type_id: 0,
    },
  });

  let navigate = useNavigate();
  const statusMarketPlace = useSelector((state: any) => state.MarketPlaceApiReducer);
  const statusCommanTax = useSelector((state: any) => state.manageTaxGroupApiReducer);

  // ============================================hooks====================================

  useEffect(() => {
    var arr: any = [];
    statusMarketPlace?.businessListData?.data?.map((a: any) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    setBusinessListData(arr);
  }, [statusMarketPlace?.businessListData]);

  useEffect(() => {
    var arr: any = [];
    statusMarketPlace?.ProductsList?.map((a: any) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    setProductsData(arr);
  }, [statusMarketPlace?.ProductsList]);

  useEffect(() => {
    var arr: any = [];
    statusMarketPlace?.branchesListData?.data?.map((a: any) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    setBranchesData(arr);
  }, [statusMarketPlace?.branchesListData]);

  useEffect(() => {
    var arr: any = [];
    statusMarketPlace?.taxesListData?.data?.map((a: any) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    setTaxGroup(arr);
  }, [statusMarketPlace?.taxesListData]);

  useEffect(() => {
    var arr: any = [];
    statusMarketPlace?.paymentMehodsListData?.data?.map((a: any) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    setPaymentData(arr);
  }, [statusMarketPlace?.paymentMehodsListData]);

  useEffect(() => {
    console.log(statusMarketPlace);

    var arr: any = [];
    statusMarketPlace?.priceTagDataListData?.data?.map((a: any) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    setArrPriceTags(arr);
  }, [statusMarketPlace?.priceTagDataListData]);

  useEffect(() => {
    console.log(statusMarketPlace);

    var arr: any = [];
    statusMarketPlace?.customersListData?.data?.map((a: any) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    setCustomersData(arr);
  }, [statusMarketPlace?.customersListData]);

  useEffect(() => {
    setAddConnection({
      service: 'odoo',
      name: connectionConfigList?.connection?.name ? connectionConfigList?.connection?.name : '',
      credentials: {
        db: connectionConfigList?.connection?.credentials?.db ? connectionConfigList?.connection?.credentials?.db : '',
        url: connectionConfigList?.connection?.credentials?.url
          ? connectionConfigList?.connection?.credentials?.url
          : '',
        user: connectionConfigList?.connection?.credentials?.user
          ? connectionConfigList?.connection?.credentials?.user
          : '',
        password: '',
      },
    });
  }, [connectionConfigList, props.modalName === 'edit connection']);

  useEffect(() => {
    console.log(connectionConfigList, 'ALFA');

    setAddConfig({
      connection_id: connectionConfigList?.connection?.id ? connectionConfigList?.connection?.id : param?.id,
      branches: connectionConfigList?.configuration?.branches ? connectionConfigList?.configuration?.branches : [],
      service: 'odoo',
      configurations: {
        company_id: compID ? compID : connectionConfigList?.configuration?.configurations?.company_id,
        customer_id: connectionConfigList?.configuration?.configurations?.customer_id
          ? connectionConfigList?.configuration?.configurations?.customer_id
          : 0,
        journal_id: connectionConfigList?.configuration?.configurations?.journal_id
          ? connectionConfigList?.configuration?.configurations?.journal_id
          : 0,
        product_id: connectionConfigList?.configuration?.configurations?.product_id
          ? connectionConfigList?.configuration?.configurations?.product_id
          : 0,
        picking_type_id: connectionConfigList?.configuration?.configurations?.picking_type_id
          ? connectionConfigList?.configuration?.configurations?.picking_type_id
          : 0,
      },
    });
  }, [connectionConfigList, compID, param?.id]);

  useEffect(() => {
    const company: any[] = [];
    const customer: any[] = [];
    const journal: any[] = [];
    const deliveryLocations: any[] = [];
    const product: any[] = [];
    const tax: any[] = [];
    const paymentmethod: any[] = [];
    const branch: any[] = [];
    CompaniesList?.map((item: any, index: number) => {
      company.push({
        label: item.name,
        value: item.id,
      });
    });
    CustomersList?.map((item: any, index: number) => {
      customer.push({
        label: item.name,
        value: item.id,
      });
    });
    JournalsList?.map((item: any, index: number) => {
      journal.push({
        label: item.name,
        value: item.id,
      });
    });
    DeliveryLocationsList?.map((item: any, index: number) => {
      deliveryLocations.push({
        label: item.name,
        value: item.id,
      });
    });
    ProductsList?.map((item: any, index: number) => {
      product.push({
        label: item.name,
        value: item.id,
      });
    });
    TaxesList?.map((item: any, index: number) => {
      tax.push({
        label: item.name,
        value: item.id,
      });
    });
    PaymentMethodsList?.map((item: any, index: number) => {
      paymentmethod.push({
        label: item.name,
        value: item.id,
      });
    });
    BranchesList?.map((item: any, index: number) => {
      branch.push({
        label: item.name,
        value: item.id,
      });
    });
    setCompanies(company);
    setCustomers(customer);
    setJournals(journal);
    setdeliveryLocationsData(deliveryLocations);
    setProducts(product);
    setTaxes(tax);
    setPaymentMethods(paymentmethod);
    setBranches(branch);
  }, [
    ProductsList,
    JournalsList,
    DeliveryLocationsList,
    CustomersList,
    CompaniesList,
    BranchesList,
    TaxesList,
    PaymentMethodsList,
  ]);

  const handleSubShow = (modalname: string, status: boolean) => {
    setsubModalName(modalname);
    setsubShow(status);
  };

  const subhandleClose = () => {
    setsubShow(false);
    setsubModalName('');
  };

  const handleDeliveryPartnersConnection = async () => {
    showloading(true);
    await dispatch(
      connectItems({
        service: addConfig?.service,
        type: 'customers',
        dot_id: props.res.id,
        item_id: singleCustomer,
        configuration_id: connectionConfigList?.configuration?.id,
      }),
    ).unwrap();
    await dispatch(
      showPartnerList({
        filter: { is_deleted: false },
        type: 'all',
        odoo_configuration_id: connectionConfigList?.configuration?.id,
      }),
    );
    props.handleClose();
    showloading(false);
  };

  const handlePaymentMethodConnection = async () => {
    showloading(true);
    await dispatch(
      connectItems({
        service: addConfig?.service,
        type: 'payment_methods',
        dot_id: props.res.id,
        item_id: singlePayment,
        configuration_id: connectionConfigList?.configuration?.id,
      }),
    ).unwrap();
    await dispatch(
      paymentList({
        filter: { is_deleted: false },
        type: 'all',
        odoo_configuration_id: connectionConfigList?.configuration?.id,
      }),
    );
    props.handleClose();
    showloading(false);
  };

  const handleTaxConnection = async () => {
    showloading(true);
    await dispatch(
      connectItems({
        service: addConfig?.service,
        type: 'taxes',
        dot_id: props?.res?.id,
        item_id: singleTax,
        configuration_id: connectionConfigList?.configuration?.id,
      }),
    ).unwrap();
    await dispatch(
      showTaxList({
        filter: { is_deleted: false },
        type: 'all',
        odoo_configuration_id: connectionConfigList?.configuration?.id,
      }),
    );
    props.handleClose();
    showloading(false);
  };

  const handleProductsConnection = async () => {
    console.log(props, 'PROPS');
    console.log(addConfig, 'addConfig');
    console.log(singleProduct, 'singleProduct');
    console.log(connectionConfigList, 'singleProduct');

    showloading(true);
    await dispatch(
      connectItems({
        service: addConfig?.service,
        type: 'products',
        dot_id: props?.resProp?.id,
        item_id: singleProduct,
        configuration_id: connectionConfigList?.configuration?.id,
      }),
    ).unwrap();
    await dispatch(
      productList({
        filter: { is_deleted: false },
        type: 'all',
        odoo_configuration_id: connectionConfigList?.configuration?.id,
      }),
    );
    props.handleClose();
    showloading(false);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setDropdownloading(true);
  //     await dispatch(getOdooCompanies(param?.id)).unwrap();
  //     await dispatch(getOdooJournals(param?.id)).unwrap();
  //     await dispatch(getOdooProducts(param?.id)).unwrap();
  //     await dispatch(
  //       showbranchesList({
  //         filter: { is_deleted: false },
  //         type: "all",
  //       })
  //     ).unwrap();
  //   };
  //   fetchData();
  //   setDropdownloading(false);
  // }, []);

  // ============================================functions====================================

  function validtionAddConnection() {
    if (addConnection.name === '') {
      notify('Enter connection name');
      return false;
    }
    if (addConnection.credentials.db === '') {
      notify('Enter database name');
      return false;
    }
    if (addConnection.credentials.url === '') {
      notify('Enter URL ');
      return false;
    }

    if (addConnection.credentials.user === '') {
      notify('Enter user name');
      return false;
    }

    if (addConnection.credentials.password === '') {
      notify('Enter password');
      return false;
    }
    return true;
  }
  function validtionAddTakerConnection() {
    if (addTakerConnection.name === '') {
      notify('Enter connection name');
      return false;
    }
    if (addTakerConnection.credentials.user === '') {
      notify('Enter user name');
      return false;
    }

    if (addTakerConnection.credentials.password === '') {
      notify('Enter password');
      return false;
    }
    return true;
  }

  function validtionConfigurationSettings() {
    if (addConfig.configurations.company_id === 0) {
      notify('Enter your default Company');
      return false;
    }
    if (addConfig.configurations.customer_id === 0) {
      notify('Enter your default Customer');
      return false;
    }

    if (addConfig.configurations.journal_id === 0) {
      notify('Enter your default Journal');
      return false;
    }

    if (addConfig.configurations.product_id === 0) {
      notify('Enter your default Product');
      return false;
    }

    if (addConfig.branches === undefined) {
      notify('Enter the Branches');
      return false;
    }

    return true;
  }

  const handleAddConnection = async () => {
    if (validtionAddConnection()) {
      try {
        await dispatch(addConnectionApi(addConnection)).unwrap();
        await dispatch(connections({ currentPage: 1 })).unwrap();
        props.handleClose();
      } catch (error: any) {
        console.log(error.message, 'error');
      } finally {
        showloading(false);
      }
    }
  };

  const handleAddTakerConnection = async () => {
    const requestBody = {
      service: 'taker',
      page: 1,
    };
    if (validtionAddTakerConnection()) {
      try {
        await dispatch(addTakerConnectionApi(addTakerConnection)).unwrap();
        await dispatch(connections(requestBody)).unwrap();
        props.handleClose();
      } catch (error: any) {
        console.log(error.message, 'error');
      } finally {
        showloading(false);
      }
    }
  };

  const handleEditConnection = async () => {
    if (validtionAddConnection()) {
      try {
        const res = await dispatch(editConnectionApi({ id: param?.id, data: addConnection })).unwrap();
        if (res.status === 200) {
          await dispatch(connectionConfig(param?.id)).unwrap();
          props.handleClose();
        }
      } catch (error: any) {
        console.log(error.message, 'error');
      } finally {
        showloading(false);
      }
    }
  };

  const handleDeleteConnection = async () => {
    try {
      await dispatch(deleteConnectionApi(param?.id)).unwrap();
      props.handleClose();
      navigate('/marketplace/connections');
      setShowConfirmModal(false);
    } catch (error: any) {
      console.log(error.message, 'error');
    } finally {
      showloading(false);
    }
  };

  const handleAddConfig = async () => {
    if (validtionConfigurationSettings()) {
      try {
        await dispatch(addConfigurationApi(addConfig)).unwrap();
        await dispatch(connectionConfig(param?.id)).unwrap();
        props.handleClose();
      } catch (error: any) {
        console.log(error.message, 'error');
      } finally {
        showloading(false);
      }
    }
  };

  const handleProducts = async () => {
    const addPromises = arrProduct.map(async (item: any) => {
      const submittedData = {
        model: 'products',
        odoo_id: item.odoo,
        dot_id: item.dot,
      };
      return await dispatch(syncObject(submittedData)).unwrap();
    });
    await Promise.all(addPromises);
    setArrProduct([]);
    await dispatch(
      productList({
        filter: { is_deleted: false },
        type: 'all',
        odoo_configuration_id: connectionConfigList?.configuration?.id,
      }),
    ).unwrap();

    props.handleClose();
  };

  const handleBranches = async () => {
    showloading(true);
    const addPromises = arrBranches.map(async (item: any) => {
      const submittedData = {
        model: 'branches',
        odoo_id: item.odoo,
        dot_id: item.dot,
      };
      return await dispatch(syncObject(submittedData)).unwrap();
    });
    await Promise.all(addPromises);
    showloading(false);
    setArrBranches([]);
    await dispatch(showbranchesList());

    props.handleClose();
  };

  const handleTax = async () => {
    showloading(true);

    const addPromises = arrTax.map(async (item: any) => {
      const submittedData = {
        model: 'taxes',
        odoo_id: item.odoo,
        dot_id: item.dot,
      };
      return await dispatch(syncObject(submittedData)).unwrap();
    });
    await Promise.all(addPromises);
    showloading(false);
    setArrTax([]);
    await dispatch(
      showTaxList({
        filter: { is_deleted: false },
        type: 'all',
        odoo_configuration_id: connectionConfigList?.configuration?.id,
      }),
    );

    props.handleClose();
  };

  const handlePaymentMethod = async () => {
    showloading(true);

    const addPromises = arrPaymentMethods.map(async (item: any) => {
      const submittedData = {
        model: 'payment_methods',
        odoo_id: item.odoo,
        dot_id: item.dot,
      };
      return await dispatch(syncObject(submittedData)).unwrap();
    });
    await Promise.all(addPromises);
    showloading(false);
    setArrPaymentMethods([]);
    await dispatch(showPaymentMethodsList());

    props.handleClose();
  };
  const handlePriceTags = async () => {
    showloading(true);

    const addPromises = arrPriceTags.map(async (item: any) => {
      const submittedData = {
        model: 'price_tags',
        odoo_id: item.odoo,
        dot_id: item.dot,
      };
      return await dispatch(syncObject(submittedData)).unwrap();
    });
    await Promise.all(addPromises);
    showloading(false);
    setArrPriceTags([]);
    await dispatch(showprice_tagsList());

    props.handleClose();
  };

  function selectBranchesHandler(items: any) {
    setAddConfig((prev: any) => {
      return { ...prev, branches: items.map((e: any) => e.value) };
    });
  }

  const handleCustomers = async () => {
    showloading(true);

    const addPromises = arrCustomers.map(async (item: any) => {
      const submittedData = {
        model: 'customers',
        odoo_id: item.odoo,
        dot_id: item.dot,
      };
      return await dispatch(syncObject(submittedData)).unwrap();
    });
    await Promise.all(addPromises);
    showloading(false);
    setArrCustomers([]);
    await dispatch(
      showCustomersList({
        filter: { is_deleted: false, is_delivery_partner: true },
        type: 'all',
      }),
    );

    props.handleClose();
  };

  useEffect(() => {
    if (props.modalName == 'Business') {
      dispatch(businessList());
    }
  }, [props.modalName === 'Business', dispatch]);

  return (
    <>
      {props.modalName === 'order add branch' && (
        <Modal className={`${cx.ctsModal}`} show={props.show}>
          <Modal.Header closeButton>
            <Modal.Title>Add Connection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Connection Name <span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={<Tooltip id={`tooltip-${placement}`}>Add your Connection Name</Tooltip>}
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                <Form.Control
                  type='text'
                  onChange={(e: any) => {
                    setAddConnection((prev: any) => {
                      return {
                        ...prev,
                        name: e.target.value,
                        credentials: {
                          ...prev.credentials,
                        },
                      };
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Database Name <span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={<Tooltip id={`tooltip-${placement}`}>Add your Service Database Name</Tooltip>}
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                <Form.Control
                  type='text'
                  onChange={(e: any) => {
                    setAddConnection((prev: any) => {
                      return {
                        ...prev,
                        credentials: {
                          ...prev.credentials,
                          db: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Database URL <span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={<Tooltip id={`tooltip-${placement}`}>Add your service Database URL</Tooltip>}
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                <Form.Control
                  type='text'
                  onChange={(e: any) => {
                    setAddConnection((prev: any) => {
                      return {
                        ...prev,
                        credentials: {
                          ...prev.credentials,
                          url: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  User Name <span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={<Tooltip id={`tooltip-${placement}`}>Add the Username</Tooltip>}
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                <Form.Control
                  type='text'
                  onChange={(e: any) => {
                    setAddConnection((prev: any) => {
                      return {
                        ...prev,
                        credentials: {
                          ...prev.credentials,
                          user: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Password<span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={<Tooltip id={`tooltip-${placement}`}>Enter the Password</Tooltip>}
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                <Form.Control
                  type='password'
                  onChange={(e: any) => {
                    setAddConnection((prev: any) => {
                      return {
                        ...prev,
                        credentials: {
                          ...prev.credentials,
                          password: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                loading={showLoading}
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                Close
              </OutlineBtn>

              <NormalBtn loading={isLoading} onClick={handleAddConnection}>
                Apply
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {props.modalName === 'add taker connection' && (
        <Modal className={`${cx.ctsModal}`} show={props.show}>
          <Modal.Header closeButton>
            <Modal.Title>Add Taker Connection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Connection Name <span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={<Tooltip id={`tooltip-${placement}`}>Add your Connection Name</Tooltip>}
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                <Form.Control
                  type='text'
                  onChange={(e: any) => {
                    setAddTakerConnection((prev: any) => {
                      return {
                        ...prev,
                        name: e.target.value,
                        credentials: {
                          ...prev.credentials,
                        },
                      };
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  User Name <span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={<Tooltip id={`tooltip-${placement}`}>Add the Username</Tooltip>}
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                <Form.Control
                  type='text'
                  onChange={(e: any) => {
                    setAddTakerConnection((prev: any) => {
                      return {
                        ...prev,
                        credentials: {
                          ...prev.credentials,
                          user: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Password<span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={<Tooltip id={`tooltip-${placement}`}>Enter the Password</Tooltip>}
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                <Form.Control
                  type='password'
                  onChange={(e: any) => {
                    setAddTakerConnection((prev: any) => {
                      return {
                        ...prev,
                        credentials: {
                          ...prev.credentials,
                          password: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                loading={showLoading}
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                Close
              </OutlineBtn>

              <NormalBtn loading={isLoading} onClick={handleAddTakerConnection}>
                Apply
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {props.modalName === 'edit connection' && (
        <>
          <Modal className={`${cx.ctsModal}`} show={props.show}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Connection</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>
                    Connection Name <span style={{ color: 'red' }}>*</span>
                    {['top'].map(placement => (
                      <OverlayTrigger
                        key={placement}
                        overlay={<Tooltip id={`tooltip-${placement}`}>Add your Service Connection Name</Tooltip>}
                      >
                        <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                          <AiOutlineInfoCircle />
                        </span>
                      </OverlayTrigger>
                    ))}
                  </Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={addConnection?.name}
                    placeholder='Connection Name'
                    onChange={(e: any) => {
                      setAddConnection((prev: any) => {
                        return {
                          ...prev,
                          name: e.target.value,
                          credentials: {
                            ...prev.credentials,
                          },
                        };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>
                    Database Name <span style={{ color: 'red' }}>*</span>
                    {['top'].map(placement => (
                      <OverlayTrigger
                        key={placement}
                        overlay={<Tooltip id={`tooltip-${placement}`}>Add your Service Database Name</Tooltip>}
                      >
                        <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                          <AiOutlineInfoCircle />
                        </span>
                      </OverlayTrigger>
                    ))}
                  </Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={addConnection?.credentials?.db}
                    onChange={(e: any) => {
                      setAddConnection((prev: any) => {
                        return {
                          ...prev,
                          credentials: {
                            ...prev.credentials,
                            db: e.target.value,
                          },
                        };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>
                    Database URL <span style={{ color: 'red' }}>*</span>
                    {['top'].map(placement => (
                      <OverlayTrigger
                        key={placement}
                        overlay={<Tooltip id={`tooltip-${placement}`}>Add your service Database URL</Tooltip>}
                      >
                        <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                          <AiOutlineInfoCircle />
                        </span>
                      </OverlayTrigger>
                    ))}
                  </Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={addConnection?.credentials?.url}
                    onChange={(e: any) => {
                      setAddConnection((prev: any) => {
                        return {
                          ...prev,
                          credentials: {
                            ...prev.credentials,
                            url: e.target.value,
                          },
                        };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>
                    User Name <span style={{ color: 'red' }}>*</span>
                    {['top'].map(placement => (
                      <OverlayTrigger
                        key={placement}
                        overlay={<Tooltip id={`tooltip-${placement}`}>Add the Username</Tooltip>}
                      >
                        <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                          <AiOutlineInfoCircle />
                        </span>
                      </OverlayTrigger>
                    ))}
                  </Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={addConnection?.credentials?.user}
                    onChange={(e: any) => {
                      setAddConnection((prev: any) => {
                        return {
                          ...prev,
                          credentials: {
                            ...prev.credentials,
                            user: e.target.value,
                          },
                        };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>
                    Password<span style={{ color: 'red' }}>*</span>
                    {['top'].map(placement => (
                      <OverlayTrigger
                        key={placement}
                        overlay={<Tooltip id={`tooltip-${placement}`}>Enter the Password</Tooltip>}
                      >
                        <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                          <AiOutlineInfoCircle />
                        </span>
                      </OverlayTrigger>
                    ))}
                  </Form.Label>
                  <Form.Control
                    type='password'
                    onChange={(e: any) => {
                      setAddConnection((prev: any) => {
                        return {
                          ...prev,
                          credentials: {
                            ...prev.credentials,
                            password: e.target.value,
                          },
                        };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            </Modal.Body>
            <Modal.Footer>
              <div>
                <span
                  style={{ cursor: 'pointer' }}
                  className={`${cx.rightOption} ${cx.rightRed}`}
                  onClick={() => {
                    setShowConfirmModal(true);
                  }}
                >
                  Delete
                </span>
              </div>
              <div className='d-flex'>
                <OutlineBtn
                  loading={showLoading}
                  onClick={() => {
                    props.handleClose();
                    setOrderType('');
                  }}
                >
                  Close
                </OutlineBtn>

                <NormalBtn loading={isLoading} onClick={handleEditConnection}>
                  Apply{' '}
                </NormalBtn>
              </div>
            </Modal.Footer>
          </Modal>

          {/* Confirmation delete Model */}
          <Modal className={`${cx.ctsModal}`} show={showConfirmModal}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete Connection</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Col md={12} lg={12}>
                Are you sure you want to delete this connection ?
              </Col>
            </Modal.Body>
            <Modal.Footer>
              <div></div>
              <div>
                <LoadingButton
                  loading={showLoading}
                  className={`${cx.btnClose}`}
                  onClick={() => {
                    props.handleClose();
                    setShowConfirmModal(false);
                    setOrderType('');
                  }}
                >
                  Close
                </LoadingButton>

                <NormalBtn loading={isLoading} bgColor='delete' onClick={handleDeleteConnection}>
                  Yes
                </NormalBtn>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      )}

      {props.modalName === 'add company' && (
        <Modal className={`${cx.ctsModal}`} show={props.show}>
          <Modal.Header closeButton>
            <Modal.Title>Configuration Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Default Company <span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={<Tooltip id={`tooltip-${placement}`}>Add your Default Company</Tooltip>}
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                {props?.dropdownLoading ? (
                  <Form.Control type='text' disabled placeholder='loading...' />
                ) : (
                  <Select
                    defaultValue={{
                      value: CompaniesList?.find((item: any) => item?.id == addConfig?.configurations?.company_id)?.id,
                      label: CompaniesList?.find((item: any) => item?.id == addConfig?.configurations?.company_id)
                        ?.name,
                    }}
                    isSearchable={true}
                    options={companies}
                    onChange={(e: any) => {
                      setCompID(e?.value);
                    }}
                    placeholder='Select Company..'
                  />
                )}
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                loading={showLoading}
                onClick={() => {
                  props.handleClose();
                  setCompID(connectionConfigList?.configuration?.configurations?.company_id);
                }}
              >
                Close
              </OutlineBtn>
              <NormalBtn
                loading={laoding}
                onClick={(e: any) => {
                  // setAddConfig((prev: any) => {
                  //   return {
                  //     ...prev,
                  //     configurations: {
                  //       ...prev.configurations,
                  //       company_id: props?.compID,
                  //     },
                  //   };
                  // });
                  handleSubShow('add config', true);
                }}
              >
                Next
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {props.modalName === 'add config' && (
        <Modal className={`${cx.ctsModal}`} show={props.show}>
          <Modal.Header closeButton>
            <Modal.Title>Configuration Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Default Customer <span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={<Tooltip id={`tooltip-${placement}`}>Add your Default Customer</Tooltip>}
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                {props?.dropdownLoading ? (
                  <Form.Control type='text' disabled placeholder='loading...' />
                ) : (
                  <Select
                    defaultValue={{
                      value: CustomersList?.find((item: any) => item?.id == addConfig?.configurations?.customer_id)?.id,
                      label: CustomersList?.find((item: any) => item?.id == addConfig?.configurations?.customer_id)
                        ?.name,
                    }}
                    isSearchable={true}
                    options={customers}
                    onChange={(e: any) => {
                      setAddConfig((prev: any) => {
                        return {
                          ...prev,
                          configurations: {
                            ...prev.configurations,
                            customer_id: e?.value,
                          },
                        };
                      });
                    }}
                    placeholder='Select Customer..'
                  />
                )}
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Default Journal <span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={<Tooltip id={`tooltip-${placement}`}>Add your Default Journal</Tooltip>}
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                {props?.dropdownLoading ? (
                  <Form.Control type='text' disabled placeholder='loading...' />
                ) : (
                  <Select
                    defaultValue={{
                      value: JournalsList?.find((item: any) => item?.id == addConfig?.configurations?.journal_id)?.id,
                      label: JournalsList?.find((item: any) => item?.id == addConfig?.configurations?.journal_id)?.name,
                    }}
                    isSearchable={true}
                    options={journals}
                    onChange={(e: any) => {
                      setAddConfig((prev: any) => {
                        return {
                          ...prev,
                          configurations: {
                            ...prev.configurations,
                            journal_id: e?.value,
                          },
                        };
                      });
                    }}
                    placeholder='Select Journal..'
                  />
                )}
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Default Product<span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={<Tooltip id={`tooltip-${placement}`}>Add your Default Product</Tooltip>}
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                {props?.dropdownLoading ? (
                  <Form.Control type='text' disabled placeholder='loading...' />
                ) : (
                  <Select
                    defaultValue={{
                      value: ProductsList?.find((item: any) => item?.id == addConfig?.configurations?.product_id)?.id,
                      label: ProductsList?.find((item: any) => item?.id == addConfig?.configurations?.product_id)?.name,
                    }}
                    isSearchable={true}
                    options={products}
                    onChange={(e: any) => {
                      setAddConfig((prev: any) => {
                        return {
                          ...prev,
                          configurations: {
                            ...prev.configurations,
                            product_id: e?.value,
                          },
                        };
                      });
                    }}
                    placeholder='Select Product..'
                  />
                )}
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Applies on<span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={
                        <Tooltip id={`tooltip-${placement}`}>
                          The branches that this configuration will applied on
                        </Tooltip>
                      }
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                {props?.dropdownLoading ? (
                  <Form.Control type='text' disabled placeholder='loading...' />
                ) : (
                  <FilterDropdown
                    defaultoptions={connectionConfigList?.configuration?.branches?.map((item: any) => {
                      const defaultData = BranchesList?.find((x: any) => {
                        return x?.id == item;
                      });
                      return {
                        label: defaultData?.name,
                        value: defaultData?.value,
                      };
                    })}
                    options={branches}
                    selectedValue={selectBranchesHandler}
                    isSelectAll='true'
                    selectAllText='All Branches'
                  />
                )}
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                loading={showLoading}
                onClick={() => {
                  props.handleClose();
                }}
              >
                Close
              </OutlineBtn>
              <NormalBtn loading={laoding} onClick={handleAddConfig}>
                Apply
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {props.modalName === 'Business' && (
        <Modal className={`${cx.ctsModal}`} show={props.show}>
          <Modal.Header closeButton>
            <Modal.Title>Choose Business</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Business<span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger key={placement} overlay={<Tooltip id={`tooltip-${placement}`}>Business</Tooltip>}>
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                <Select
                  options={businessListData}
                  onChange={(e: any) => {
                    console.log(e);

                    setBusinessId((prev: any) => {
                      return { ...prev, id: e.value };
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div>
              <NormalBtn
                loading={laoding}
                onClick={async () => {
                  if (businessId.id == null) {
                    notify('You must choose business');
                  } else {
                    showloading(true);
                    await dispatch(companyId());

                    await dispatch(businessSync({ company_id: businessId?.id }));
                    // await dispatch(products({ id: businessId?.id }));
                    // await dispatch(taxes({ id: businessId?.id }));
                    await dispatch(paymentMehods({ id: businessId?.id }));
                    // await dispatch(branches({ id: businessId?.id }));
                    await dispatch(priceTags({ id: businessId?.id }));
                    navigate('/');

                    console.log(statusMarketPlace);
                    showloading(false);
                    props.handleClose(true);
                  }
                }}
              >
                Apply
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {props.modalName === 'edit products' && (
        <Modal
          className={`${cx.ctsModal} ${advanceOptions ? cx.ctsModalSize : ''}`}
          show={props.show}
          // onHide={props.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Products</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Row> */}
            {statusComman.showProductListData?.map((res: any) => {
              return (
                <Row
                  className='d-flex align-items-center'
                  style={{
                    border: '1px solid #E1E1E1',
                    borderRadius: '20px',
                    marginTop: '10px',
                    padding: '10px',
                  }}
                >
                  <Col md={6} lg={6}>
                    <Form.Group className={`${cx.formField}`}>
                      <Form.Label>{res?.name}</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col
                    md={6}
                    lg={6}
                    // style={{ border: "1px solid #B3B3B3", padding: "10px" }}
                  >
                    <Form.Group className={`${cx.formField}`}>
                      <Form.Label>Odoo</Form.Label>

                      <Select
                        isSearchable={true}
                        defaultValue={productsData
                          .filter((a: any) =>
                            productsData
                              .map((a: any) => a.value)
                              .filter((item: any) =>
                                statusComman?.showProductListData.map((e: any) => Number(e.odoo_id)).includes(item),
                              )
                              .includes(a.value),
                          )
                          .filter((a: any) => a.value == res.odoo_id)}
                        options={productsData}
                        onChange={(e: any) => {
                          setArrProduct([...arrProduct, { dot: res?.id, odoo: e?.value }]);
                        }}
                        placeholder='Choose...'
                      />
                    </Form.Group>
                  </Col>
                </Row>
              );
            })}
            {/* </Row> */}
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                Close
              </OutlineBtn>
              <NormalBtn loading={laoding} onClick={handleProducts}>
                Apply
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {props.modalName === 'edit customers' && (
        <Modal
          className={`${cx.ctsModal} ${advanceOptions ? cx.ctsModalSize : ''}`}
          show={props.show}
          // onHide={props.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Products</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Row> */}
            {statusComman.showCustomersListData?.map((res: any) => {
              return (
                <Row
                  className='d-flex align-items-center'
                  style={{
                    border: '1px solid #E1E1E1',
                    borderRadius: '20px',
                    marginTop: '10px',
                    padding: '10px',
                  }}
                >
                  <Col md={6} lg={6}>
                    <Form.Group className={`${cx.formField}`}>
                      <Form.Label>{res?.name}</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col
                    md={6}
                    lg={6}
                    // style={{ border: "1px solid #B3B3B3", padding: "10px" }}
                  >
                    <Form.Group className={`${cx.formField}`}>
                      <Form.Label>Odoo</Form.Label>

                      <Select
                        isSearchable={true}
                        defaultValue={customersData
                          .filter((a: any) =>
                            customersData
                              .map((a: any) => a.value)
                              .filter((item: any) =>
                                statusComman?.showCustomersListData.map((e: any) => Number(e.odoo_id)).includes(item),
                              )
                              .includes(a.value),
                          )
                          .filter((a: any) => a.value == res.odoo_id)}
                        options={customersData}
                        onChange={(e: any) => {
                          setArrCustomers([...arrCustomers, { dot: res?.id, odoo: e?.value }]);
                        }}
                        placeholder='Choose...'
                      />
                    </Form.Group>
                  </Col>
                </Row>
              );
            })}
            {/* </Row> */}
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div>
              <Button
                className={`${cx.btnClose}`}
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                Close
              </Button>
              <LoadingButton loading={laoding} className={`${cx.SubmitBtn}`} onClick={handleCustomers}>
                Apply
              </LoadingButton>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {props.modalName === 'edit branches' && (
        <Modal
          className={`${cx.ctsModal} ${advanceOptions ? cx.ctsModalSize : ''}`}
          show={props.show}
          // onHide={props.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Branches</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Row> */}
            {statusComman.showbranchesListData?.map((res: any) => {
              return (
                <Row
                  className='d-flex align-items-center'
                  style={{
                    border: '1px solid #E1E1E1',
                    borderRadius: '20px',
                    marginTop: '10px',
                    padding: '10px',
                  }}
                >
                  <Col md={6} lg={6}>
                    <Form.Group className={`${cx.formField}`}>
                      <Form.Label>{res?.name}</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6} lg={6}>
                    <Form.Group className={`${cx.formField}`}>
                      <Form.Label>Odoo</Form.Label>
                      <Select
                        isSearchable={true}
                        noOptionsMessage={() => 'No more options Branches in odoo'}
                        defaultValue={branchesData
                          .filter((a: any) =>
                            branchesData
                              .map((a: any) => a.value)
                              .filter((item: any) =>
                                statusComman?.showbranchesListData.map((e: any) => Number(e.odoo_id)).includes(item),
                              )
                              .includes(a.value),
                          )
                          .filter((a: any) => a.value == res.odoo_id)}
                        options={branchesData}
                        onChange={(e: any) => {
                          console.log(e);

                          setArrBranches([...arrBranches, { dot: res?.id, odoo: e?.value }]);
                          console.log(arrBranches);
                        }}
                        placeholder='Choose...'
                      />
                    </Form.Group>
                  </Col>
                </Row>
              );
            })}
            {/* </Row> */}
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                Close
              </OutlineBtn>
              <NormalBtn loading={laoding} onClick={handleBranches}>
                Apply
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {props.modalName === 'edit taxandgroups' && (
        <Modal
          className={`${cx.ctsModal} ${advanceOptions ? cx.ctsModalSize : ''}`}
          show={props.show}
          // onHide={props.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Taxes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Row> */}
            {statusCommanTax?.showAllshowTaxList?.map((res: any) => {
              return (
                <Row
                  className='d-flex align-items-center'
                  style={{
                    border: '1px solid #E1E1E1',
                    borderRadius: '20px',
                    marginTop: '10px',
                    padding: '10px',
                  }}
                >
                  <Col
                    md={6}
                    lg={6}

                    //
                  >
                    <Form.Group className={`${cx.formField}`}>
                      <Form.Label>{res?.name}</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6} lg={6}>
                    <Form.Group className={`${cx.formField}`}>
                      <Form.Label>Odoo</Form.Label>
                      <Select
                        isSearchable={true}
                        noOptionsMessage={() => 'No more options Taxes in odoo'}
                        defaultValue={taxGroups
                          .filter((a: any) =>
                            taxGroups
                              .map((a: any) => a.value)
                              .filter((item: any) =>
                                statusCommanTax?.showAllshowTaxList?.map((e: any) => Number(e.odoo_id)).includes(item),
                              )
                              .includes(a.value),
                          )
                          .filter((a: any) => a.value == res?.odoo_id)}
                        options={taxGroups}
                        onChange={(e: any) => {
                          setArrTax([...arrTax, { dot: res?.id, odoo: e?.value }]);
                        }}
                        placeholder='Choose...'
                      />
                    </Form.Group>
                  </Col>
                </Row>
              );
            })}
            {/* </Row> */}
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                Close
              </OutlineBtn>
              <NormalBtn loading={laoding} onClick={handleTax}>
                Apply
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {props.modalName === 'edit paymentMethod' && (
        <Modal
          className={`${cx.ctsModal} ${advanceOptions ? cx.ctsModalSize : ''}`}
          show={props.show}
          // onHide={props.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Payment Methods</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Row> */}
            {statusComman.showPaymentMethodsListData?.map((res: any) => {
              return (
                <Row
                  className='d-flex align-items-center'
                  style={{
                    border: '1px solid #E1E1E1',
                    borderRadius: '20px',
                    marginTop: '10px',
                    padding: '10px',
                  }}
                >
                  <Col md={6} lg={6}>
                    <Form.Group className={`${cx.formField}`}>
                      <Form.Label>{res?.name}</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6} lg={6}>
                    <Form.Group className={`${cx.formField}`}>
                      <Form.Label>Odoo</Form.Label>
                      <Select
                        isSearchable={true}
                        options={paymentData}
                        defaultValue={paymentData
                          .filter((a: any) =>
                            paymentData
                              .map((a: any) => a.value)
                              .filter((item: any) =>
                                statusComman?.showPaymentMethodsListData
                                  ?.map((e: any) => Number(e.odoo_id))
                                  .includes(item),
                              )
                              .includes(a.value),
                          )
                          .filter((a: any) => a.value == res.odoo_id)}
                        noOptionsMessage={() => 'No more options Payment Methods in odoo'}
                        onChange={(e: any) => {
                          setArrPaymentMethods([...arrPaymentMethods, { dot: res?.id, odoo: e?.value }]);
                        }}
                        placeholder='Choose...'
                      />
                    </Form.Group>
                  </Col>
                </Row>
              );
            })}
            {/* </Row> */}
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                Close
              </OutlineBtn>
              <NormalBtn loading={laoding} onClick={handlePaymentMethod}>
                Apply
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {props.modalName === 'edit priceTags' && (
        <Modal
          className={`${cx.ctsModal} ${advanceOptions ? cx.ctsModalSize : ''}`}
          show={props.show}
          // onHide={props.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Price tags</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Row> */}
            {statusComman.showprice_tagsListData?.map((res: any) => {
              return (
                <Row
                  className='d-flex align-items-center'
                  style={{
                    border: '1px solid #E1E1E1',
                    borderRadius: '20px',
                    marginTop: '10px',
                    padding: '10px',
                  }}
                >
                  <Col md={6} lg={6}>
                    <Form.Group className={`${cx.formField}`}>
                      <Form.Label>{res?.name}</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6} lg={6}>
                    <Form.Group className={`${cx.formField}`}>
                      <Form.Label>Odoo</Form.Label>
                      <Select
                        isSearchable={true}
                        noOptionsMessage={() => 'No more options Price List in odoo'}
                        defaultValue={arrPriceTags
                          .filter((a: any) =>
                            arrPriceTags
                              .map((a: any) => a.value)
                              .filter((item: any) =>
                                statusComman?.showprice_tagsListData?.map((e: any) => Number(e.odoo_id)).includes(item),
                              )
                              .includes(a.value),
                          )
                          .filter((a: any) => a.value == res.odoo_id)}
                        options={arrPriceTags}
                        onChange={(e: any) => {
                          setArrPriceTags([...arrPriceTags, { dot: res?.id, odoo: e?.value }]);
                        }}
                        placeholder='Choose...'
                      />
                    </Form.Group>
                  </Col>
                </Row>
              );
            })}
            {/* </Row> */}
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                Close
              </OutlineBtn>
              <NormalBtn loading={laoding} onClick={handlePriceTags}>
                Apply
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {props.modalName === 'edit single product' && (
        <Modal
          className={`${cx.ctsModal} ${advanceOptions ? cx.ctsModalSize : ''}`}
          show={props.show}
          // onHide={props.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update {props?.resProp.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>Dot</Form.Label>
                </Form.Group>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{props?.resProp?.name}</Form.Label>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>Odoo</Form.Label>
                  <Select
                    isSearchable={true}
                    defaultValue={productsData.filter((a: any) => a.value == props.resProp.odoo_id)}
                    noOptionsMessage={() => 'No more options Products in odoo'}
                    options={productsData}
                    onChange={(e: any) => {
                      setSingleProduct(e.value);
                    }}
                    placeholder='Choose...'
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                Close
              </OutlineBtn>
              <NormalBtn loading={laoding} onClick={handleProductsConnection}>
                Apply
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {props.modalName === 'edit single branch' && (
        <Modal
          className={`${cx.ctsModal} ${advanceOptions ? cx.ctsModalSize : ''}`}
          show={props.show}
          // onHide={props.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update {props.res.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Row> */}

            <Row>
              <Col>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{props.res.name}</Form.Label>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>Odoo</Form.Label>
                  <Select
                    isSearchable={true}
                    noOptionsMessage={() => 'No more options Branches in odoo'}
                    defaultValue={branchesData.filter((a: any) => a.value == props.res.odoo_id)}
                    options={branchesData}
                    onChange={(e: any) => {
                      setSingleBranch(e.value);
                    }}
                    placeholder='Choose...'
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* </Row> */}
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                Close
              </OutlineBtn>
              <NormalBtn
                loading={laoding}
                onClick={async () => {
                  console.log(singleProduct);
                  showloading(true);
                  await dispatch(
                    syncObject({
                      model: 'branches',
                      odoo_id: singleBranch,
                      dot_id: props.res.id,
                    }),
                  ).unwrap();
                  await dispatch(showbranchesList());
                  props.handleClose();
                  showloading(false);
                }}
              >
                Apply
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {props.modalName === 'edit single payment' && (
        <Modal
          className={`${cx.ctsModal} ${advanceOptions ? cx.ctsModalSize : ''}`}
          show={props.show}
          // onHide={props.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update {props.res.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Row> */}

            <Row>
              <Col>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>Dot</Form.Label>
                </Form.Group>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{props.res.name}</Form.Label>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>Odoo</Form.Label>
                  <Select
                    isSearchable={true}
                    noOptionsMessage={() => 'No more options Payment Method  in odoo'}
                    defaultValue={paymentMethods.filter((a: any) => a.value == props.res.odoo_id)}
                    options={paymentMethods}
                    onChange={(e: any) => {
                      setSinglePayment(e.value);
                    }}
                    placeholder='Choose...'
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* </Row> */}
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                Close
              </OutlineBtn>
              <NormalBtn loading={laoding} onClick={handlePaymentMethodConnection}>
                Apply
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {props.modalName === 'edit single tax' && (
        <Modal
          className={`${cx.ctsModal} ${advanceOptions ? cx.ctsModalSize : ''}`}
          show={props.show}
          // onHide={props.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update {props.res.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Row> */}

            <Row>
              <Col>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>Dot</Form.Label>
                </Form.Group>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{props.res.name}</Form.Label>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>Odoo</Form.Label>
                  <Select
                    isSearchable={true}
                    noOptionsMessage={() => 'No more options Tax  in odoo'}
                    defaultValue={taxes.filter((a: any) => a.value == props.res.odoo_id)}
                    options={taxes}
                    onChange={(e: any) => {
                      setSingleTax(e.value);
                    }}
                    placeholder='Choose...'
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* </Row> */}
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                Close
              </OutlineBtn>
              <NormalBtn loading={laoding} onClick={handleTaxConnection}>
                Apply
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {props.modalName === 'edit single price list' && (
        <Modal
          className={`${cx.ctsModal} ${advanceOptions ? cx.ctsModalSize : ''}`}
          show={props.show}
          // onHide={props.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update {props.res.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Row> */}

            <Row>
              <Col>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{props.res.name}</Form.Label>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>Odoo</Form.Label>
                  <Select
                    isSearchable={true}
                    noOptionsMessage={() => 'No more options Tax  in odoo'}
                    defaultValue={arrPriceTags.filter((a: any) => a.value == props.res.odoo_id)}
                    options={arrPriceTags}
                    onChange={(e: any) => {
                      setSinglePriceList(e.value);
                    }}
                    placeholder='Choose...'
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* </Row> */}
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                Close
              </OutlineBtn>
              <NormalBtn
                loading={laoding}
                onClick={async () => {
                  console.log(singleProduct);
                  showloading(true);
                  await dispatch(
                    syncObject({
                      model: 'price_tags',
                      odoo_id: singlePriceList,
                      dot_id: props.res.id,
                    }),
                  ).unwrap();
                  await dispatch(showTaxList());
                  props.handleClose();
                  showloading(false);
                }}
              >
                Apply
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {props.modalName === 'edit single customer' && (
        <Modal
          className={`${cx.ctsModal} ${advanceOptions ? cx.ctsModalSize : ''}`}
          show={props.show}
          // onHide={props.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update {props.res.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Row> */}

            <Row>
              <Col>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>Dot</Form.Label>
                </Form.Group>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>{props.res.name}</Form.Label>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className={`${cx.formField}`}>
                  <Form.Label>Odoo</Form.Label>
                  <Select
                    isSearchable={true}
                    noOptionsMessage={() => 'No more options Customers in odoo'}
                    defaultValue={customers.filter((a: any) => a.value == props.res.odoo_id)}
                    options={customers}
                    onChange={(e: any) => {
                      setSingleCustomer(e.value);
                    }}
                    placeholder='Choose...'
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* </Row> */}
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                onClick={() => {
                  props.handleClose();
                  setOrderType('');
                }}
              >
                Close
              </OutlineBtn>
              <NormalBtn loading={laoding} onClick={handleDeliveryPartnersConnection}>
                Apply
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      <SubModal
        show={subshow}
        handleClose={props.handleClose}
        handleSubClose={subhandleClose}
        modalName={submodalName}
        compID={compID}
        setCompID={setCompID}
        addConfig={addConfig}
        setAddConfig={setAddConfig}
      />
    </>
  );
};

const SubModal = (props: any) => {
  // -------------- hooks -----------------
  const dispatch = useDispatch();
  const param = useParams();
  const [laoding, showloading] = useState(false);
  const [configLoading, setConfigLoading] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [journals, setJournals] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [deliveryLocations, setdeliveryLocations] = useState<any[]>([]);
  // ---------------- functions ------------------

  const CustomersList = useSelector((state: any) => state.MarketPlaceApiReducer.CustomersList);
  const JournalsList = useSelector((state: any) => state.MarketPlaceApiReducer.JournalsList);
  const ProductsList = useSelector((state: any) => state.MarketPlaceApiReducer.ProductsList);
  const connectionConfigList = useSelector((state: any) => state.MarketPlaceApiReducer.connectionConfigList);
  const BranchesList = useSelector((state: any) => state.manageCommanApiReducer.showbranchesListData);
  const DeliveryLocationsList = useSelector((state: any) => state.MarketPlaceApiReducer.DeliveryLocationsList);

  function validtionConfigurationSettings() {
    if (props.addConfig.configurations.company_id === 0) {
      notify('Enter your default Company');
      return false;
    }
    if (props.addConfig.configurations.customer_id === 0) {
      notify('Enter your default Customer');
      return false;
    }

    if (props.addConfig.configurations.journal_id === 0) {
      notify('Enter your default Journal');
      return false;
    }

    if (props.addConfig.configurations.product_id === 0) {
      notify('Enter your default Product');
      return false;
    }

    if (props.addConfig.configurations.picking_type_id === 0) {
      notify('Enter your default Delivery Location');
      return false;
    }

    if (props.addConfig.branches.length === 0) {
      notify('Enter the Branches');
      return false;
    }

    return true;
  }

  const handleAddConfig = async () => {
    if (validtionConfigurationSettings()) {
      try {
        await dispatch(addConfigurationApi(props.addConfig)).unwrap();
        await dispatch(connectionConfig(param?.id)).unwrap();
        props.handleClose();
      } catch (error: any) {
        console.log(error.message, 'error');
      } finally {
        showloading(false);
        props.handleSubClose();
        props.handleClose();
      }
    }
  };
  function selectBranchesHandler(items: any) {
    props.setAddConfig((prev: any) => {
      return { ...prev, branches: items.map((e: any) => e.value) };
    });
  }

  useEffect(() => {
    const customer: any[] = [];
    const journal: any[] = [];
    const product: any[] = [];
    const branch: any[] = [];
    const deliveryLocation: any[] = [];

    CustomersList?.map((item: any, index: number) => {
      customer.push({
        label: item.name,
        value: item.id,
      });
    });
    JournalsList?.map((item: any, index: number) => {
      journal.push({
        label: item.name,
        value: item.id,
      });
    });
    ProductsList?.map((item: any, index: number) => {
      product.push({
        label: item.name,
        value: item.id,
      });
    });
    DeliveryLocationsList?.map((item: any, index: number) => {
      deliveryLocation.push({
        label: item.name,
        value: item.id,
      });
    });
    BranchesList?.map((item: any, index: number) => {
      branch.push({
        label: item.name,
        value: item.id,
      });
    });
    setCustomers(customer);
    setJournals(journal);
    setProducts(product);
    setBranches(branch);
    setdeliveryLocations(deliveryLocation);
  }, [ProductsList, JournalsList, DeliveryLocationsList, CustomersList, BranchesList]);

  useEffect(() => {
    const requestBody = {
      connection_id: param?.id,
      company_id: props.compID,
    };
    if (props.compID && param?.id) {
      (async () => {
        try {
          setConfigLoading(true);
          await dispatch(getOdooCustomers(requestBody)).unwrap();
          await dispatch(getOdooJournals(requestBody)).unwrap();
          await dispatch(getOdooProducts(param?.id)).unwrap();
          await dispatch(getPaymentMethods(requestBody)).unwrap();
          await dispatch(getTaxes(requestBody)).unwrap();
          console.log(props.compID, 'compID');
        } catch (error: any) {
          console.log(error?.response?.data?.message);
        } finally {
          setConfigLoading(false);
        }
      })();
    }
  }, [props.compID, param?.id]);

  return (
    <>
      {props.modalName === 'add config' && (
        <Modal className={`${cx.ctsModal}`} show={props.show}>
          <Modal.Header closeButton>
            <Modal.Title>Configuration Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Default Customer <span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={<Tooltip id={`tooltip-${placement}`}>Add your Default Customer</Tooltip>}
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                {configLoading ? (
                  <Form.Control type='text' disabled placeholder='loading...' />
                ) : (
                  <Select
                    defaultValue={{
                      value: CustomersList?.find(
                        (item: any) => item?.id == props.addConfig?.configurations?.customer_id,
                      )?.id,
                      label: CustomersList?.find(
                        (item: any) => item?.id == props.addConfig?.configurations?.customer_id,
                      )?.name,
                    }}
                    isSearchable={true}
                    options={customers}
                    onChange={(e: any) => {
                      props.setAddConfig((prev: any) => {
                        return {
                          ...prev,
                          configurations: {
                            ...prev.configurations,
                            customer_id: e?.value,
                          },
                        };
                      });
                    }}
                    placeholder='Select Customer..'
                  />
                )}
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Default Journal <span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={<Tooltip id={`tooltip-${placement}`}>Add your Default Journal</Tooltip>}
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                {configLoading ? (
                  <Form.Control type='text' disabled placeholder='loading...' />
                ) : (
                  <Select
                    defaultValue={{
                      value: JournalsList?.find((item: any) => item?.id == props.addConfig?.configurations?.journal_id)
                        ?.id,
                      label: JournalsList?.find((item: any) => item?.id == props.addConfig?.configurations?.journal_id)
                        ?.name,
                    }}
                    isSearchable={true}
                    options={journals}
                    onChange={(e: any) => {
                      props.setAddConfig((prev: any) => {
                        return {
                          ...prev,
                          configurations: {
                            ...prev.configurations,
                            journal_id: e?.value,
                          },
                        };
                      });
                    }}
                    placeholder='Select Journal..'
                  />
                )}
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Default Delivery Locations <span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={<Tooltip id={`tooltip-${placement}`}>Add your Default Delivery Location</Tooltip>}
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                {configLoading ? (
                  <Form.Control type='text' disabled placeholder='loading...' />
                ) : (
                  <Select
                    defaultValue={{
                      value: DeliveryLocationsList?.find(
                        (item: any) => item?.id == props.addConfig?.configurations?.picking_type_id,
                      )?.id,
                      label: DeliveryLocationsList?.find(
                        (item: any) => item?.id == props.addConfig?.configurations?.picking_type_id,
                      )?.name,
                    }}
                    isSearchable={true}
                    options={deliveryLocations}
                    onChange={(e: any) => {
                      props.setAddConfig((prev: any) => {
                        return {
                          ...prev,
                          configurations: {
                            ...prev.configurations,
                            picking_type_id: e?.value,
                          },
                        };
                      });
                    }}
                    placeholder='Select Delivery Locations..'
                  />
                )}
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Default Product<span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={<Tooltip id={`tooltip-${placement}`}>Add your Default Product</Tooltip>}
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                {configLoading ? (
                  <Form.Control type='text' disabled placeholder='loading...' />
                ) : (
                  <Select
                    defaultValue={{
                      value: ProductsList?.find((item: any) => item?.id == props.addConfig?.configurations?.product_id)
                        ?.id,
                      label: ProductsList?.find((item: any) => item?.id == props.addConfig?.configurations?.product_id)
                        ?.name,
                    }}
                    isSearchable={true}
                    options={products}
                    onChange={(e: any) => {
                      props.setAddConfig((prev: any) => {
                        return {
                          ...prev,
                          configurations: {
                            ...prev.configurations,
                            product_id: e?.value,
                          },
                        };
                      });
                    }}
                    placeholder='Select Product..'
                  />
                )}
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>
                  Applies on<span style={{ color: 'red' }}>*</span>
                  {['top'].map(placement => (
                    <OverlayTrigger
                      key={placement}
                      overlay={
                        <Tooltip id={`tooltip-${placement}`}>
                          The branches that this configuration will applied on
                        </Tooltip>
                      }
                    >
                      <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
                        <AiOutlineInfoCircle />
                      </span>
                    </OverlayTrigger>
                  ))}
                </Form.Label>
                {configLoading ? (
                  <Form.Control type='text' disabled placeholder='loading...' />
                ) : (
                  <FilterDropdown
                    defaultoptions={connectionConfigList?.configuration?.branches?.map((item: any) => {
                      const defaultData = BranchesList?.find((x: any) => {
                        return x?.id == item;
                      });
                      return {
                        label: defaultData?.name,
                        value: defaultData?.value,
                      };
                    })}
                    options={branches}
                    selectedValue={selectBranchesHandler}
                    isSelectAll='true'
                    selectAllText='All Branches'
                  />
                )}
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <div></div>
            <div className='d-flex'>
              <OutlineBtn
                loading={showLoading}
                onClick={() => {
                  props.handleSubClose();
                }}
              >
                Back
              </OutlineBtn>
              <NormalBtn loading={laoding} onClick={handleAddConfig}>
                Apply
              </NormalBtn>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Modals;
