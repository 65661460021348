import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetch10, fetch2, fetch5, fetch6, fetch9 } from "../../Apis/commonApis";
import { AnyARecord } from "dns";

export const reservationList: any = createAsyncThunk(
  "reservationList",
  async (body) => {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/branch-reservations`,
      body
    );
    return result;
  }
);

export const addReservation: any = createAsyncThunk(
  "addReservation",
  async (body) => {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/branch-reservations`,
      body
    );
    return result.data;
  }
);

export const updateReservation: any = createAsyncThunk(
  "updateReservation",
  async (body: any) => {
    const result = await fetch9(
      `${process.env.REACT_APP_BASEURL}/branch-reservations/${body?.id}`,
      body
    );
    return result.data;
  }
);

export const disableReservation: any = createAsyncThunk(
  "disableReservation",
  async (body) => {
    const result = await fetch10(
      `${process.env.REACT_APP_BASEURL}/branch-reservations/${body}`
    );
    return result.data;
  }
);

export const disableAllReservation: any = createAsyncThunk(
  "disableAllReservation",
  async (body) => {
    const result = await fetch10(
      `${process.env.REACT_APP_BASEURL}/branch-reservations/disable-all`
    );
    return result.data;
  }
);

const initialState: any = {
  loading: true,
  reservationListItems: [],
  reservationListCount: 0,
  loadingStatus: 0,
};

const manageMoreReservationSlice: any = createSlice({
  name: "manageMoreReservationSlice",
  initialState,
  reducers: {},

  extraReducers(builder) {
    builder.addCase(reservationList.fulfilled, (state, action) => {
      state.loading = false;
      state.reservationListCount = state.reservationListItems.length;
      state.reservationListItems = action.payload;
    });
    builder.addCase(reservationList.pending, (state, action) => {
      state.loading = true;

    });
    builder.addCase(addReservation.fulfilled, (state, action) => {
      state.loading = false;
      state.reservationListCount += 1;
    });
    builder.addCase(updateReservation.fulfilled, (state, action) => {
      state.loading = false;
      state.reservationListCount += 1;
    });
    builder.addCase(disableReservation.fulfilled, (state, action) => {
      state.loading = false;
      state.reservationListCount += 1;
    });
    builder.addCase(disableAllReservation.fulfilled, (state, action) => {
      state.loading = false;
      state.reservationListCount += 1;
    });
  },
});

export const { } = manageMoreReservationSlice.actions;
export default manageMoreReservationSlice.reducer;
