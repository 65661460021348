import React, { useState, useEffect, useRef } from 'react';
import forgetPassword from './ForgetPassword.module.scss';
import login from '../Login/Login.module.scss';
import { Container, Col, Form } from 'react-bootstrap';
import logo from '../../images/dot-logo.png';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeLanguage } from '../../store/global/global.slice';
import TopBarProgressInd from '../../shared/TopBarProgressInd';
import { notify } from '../../constants/notifyconstants';
import { useLanguage } from '../../hooks/useLanguage';
import { NormalBtn } from '../../components/buttons/NormalBtn';
import CustomInput from '../../components/inputs/PlaceHolderInput';
import OtpVerification from './OtpVerification';
import Cookies from 'js-cookie';
import { validateUserData } from '../../redux_toolkit/reducer/ForgetPasswordReducer';

export default function ForgetPassword() {
  const [show, setShow] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const dispatch = useDispatch();
  const [isEmailSended, setEmailSended] = useState(false);
  

  // ----------------------------- validation ----------------------------- //
  const {
    trigger,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (formData: any) => {
    setShowLoading(true)
    const res = await dispatch(validateUserData(formData));

    if (res.payload.code !== 404) {
      notify('you will receive your login credentials on your email ', 'success');
      setEmailSended(true);
      const expirationTime = new Date();
      expirationTime.setTime(expirationTime.getTime() + 30 * 60 * 1000);
      Cookies.set('isOtpSended', 'true', { expires: expirationTime });
      //  Cookies.set('status', formData.email, { expires: expirationTime });
    } else {
      notify('please enter a valid email and business reference', 'error');
    }
    setShowLoading(false)
  };
  //----------------------------- Language  -----------------------------//
  const { t, language } = useLanguage();
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      trigger();
    } else {
      mounted.current = true;
    }
  }, [language]);
 
  const emailValidationCookie = Cookies.get('isOtpSended');
  useEffect(() => {
    const checkCookie = () => {
      const cookieValue: any = Cookies.get('isOtpSended');
      
      if (cookieValue) {
        setEmailSended(true);
      } else {
        setEmailSended(false);
        // Cookies.remove('isOtpSended');
      }
    };

    checkCookie();
  }, [emailValidationCookie]);
  const toggleDirection = () => {
    const newDirection = language === 'en' ? 'ar' : 'en';
    dispatch(changeLanguage({ language: newDirection }));
    trigger();
  };

  //----------------------------- return -----------------------------//
  return (
    <>
      {show && <TopBarProgressInd />}

      <ToastContainer />
      {isEmailSended ? (
        <section className={`${forgetPassword.loginSection} vh-100 vw-100 bg-white row `}>
          <OtpVerification />
        </section>
      ) : (
        <section className={`${forgetPassword.loginSection} vh-100 vw-100 bg-white row `}>
          <div className='position-absolute bottom-0 start-0 p-4'>
            <div style={{ color: '#2e68ff', cursor: 'pointer' }} className=' h5' onClick={toggleDirection}>
              {language === 'en' ? 'عربي' : 'English'}
            </div>
          </div>
          <Container className={`${forgetPassword.loginContainer}  `}>
            <Col className={`${forgetPassword.loginBox}`}>
              <img src={logo} className={`${forgetPassword.logo}`} alt='logo' />
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group
                  className={`mb-4 ${language === 'en' ? `${login.formBoxEn} ` : `${login.formBoxAr}`}
                                   
                                    `}
                >
                  <div className='d-flex flex-column pb-3 ps-0'>
                    {/* <div className={`${login.signIn} `}>{t('GLOBAL.SIGN_IN')}</div> */}
                    <div className={`${login.subHeader}`}>
                      {t('GLOBAL.NEW_TO_DOT')}
                      <Link to={'/register'} className={`${login.signUp} `} style={{ color: '#004fee' }}>
                        {t('GLOBAL.SIGN_UP')}
                      </Link>
                    </div>
                  </div>
                  <CustomInput
                    onKeyDown={(e: any) => {
                      const charCode = e.which ? e.which : e.keyCode;
                      if (/\p{Script=Arabic}/u.test(String.fromCharCode(charCode))) {
                        e.preventDefault();
                      }
                    }}
                    type='number'
                    pattern={'[0-9]*'}
                    {...register('business_reference')}
                    label={t('GLOBAL.ACCOUNT_NUMBER')}
                    name='business_reference'
                    register={register}
                    errors={errors}
                    minLength={6}
                    maxLength={6}
                  />
                  {/* {renderErrorComponent(errors, 'business_reference')} */}
                </Form.Group>
                <Form.Group className={`mb-4 ${language === 'en' ? `${login.formBoxEn}` : `${login.formBoxAr}`} `}>
                  <CustomInput
                    onKeyDown={(e: any) => {
                      const charCode = e.which ? e.which : e.keyCode;
                      if (/\p{Script=Arabic}/u.test(String.fromCharCode(charCode))) {
                        e.preventDefault();
                      }
                    }}
                    {...register('email')}
                    label={t('GLOBAL.EMAIL')}
                    name='email'
                    register={register}
                    errors={errors}
                    valuePattern={
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                    }
                    messagePattern={`${t('GLOBAL.INVALID_EMAIL')}`}
                  />
                </Form.Group>

                <NormalBtn
                  loading={showLoading}
                  type='submit'
                  className={`${forgetPassword.submitBtn} bg-primary  w-100 rounded-pill py-2 ${
                    show ? 'transparent' : 'text-white text-capitalize'
                  }`}
                >
                  {t('GLOBAL.REQUEST_NEW_PASSWORD')}
                </NormalBtn>
              </Form>
            </Col>
          </Container>
        </section>
      )}
    </>
  );
}
