import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const usePermission = () => {
  //get item from localstorage

  let scope: any;

  const _scope = localStorage.getItem("__scope__");

  if (_scope) {
    scope = JSON.parse(_scope);
    // console.log("scope", scope);
  }
  if (scope) {
    return scope;
  } else {
    return null;
  }
};

type ProtectedRouteType = {
  permissionRequired?: string;
};

const AuthGuardRoute = (props: ProtectedRouteType) => {
  const { permissionRequired } = props;
  const permissions = usePermission();
  let auth: any;

  const _auth = localStorage.getItem("___data");

  if (_auth) {
    auth = JSON.parse(_auth);
    // console.log("--auth--", auth.is_owner);
  }

  if (auth && auth.is_owner === 1) {
    return <Outlet />;
  } else if (props.permissionRequired) {
    return auth ? (
      permissions.includes(permissionRequired) ? (
        <Outlet />
      ) : (
        <Navigate to="/unauthorized" />
      )
    ) : (
      <Navigate to="/" />
    );
  } else {
    return auth ? <Outlet /> : <Navigate to="/" />;
  }
};
export default AuthGuardRoute;
