import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch30, fetch5, fetch6 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

// roles Thunk
export const showrolesList: any = createAsyncThunk('showrolesList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/roles`);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// branches Thunk
export const showbranchesList: any = createAsyncThunk('showbranchesList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/branches`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

export const getbranchesbytags: any = createAsyncThunk('getbranchesbytags', async body => {
  try {
    const response: any = await fetch30(`${process.env.REACT_APP_BASEURL}/byTags/branches`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});
// devices

export const showdevicesList: any = createAsyncThunk('showdevicesList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/devices`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});
export const showFranchisebranchesList: any = createAsyncThunk('showFranchisebranchesList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/branches`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// products Thunk
export const showProductList: any = createAsyncThunk('showProductList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/products`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// discounts Thunk
export const showdiscountsList: any = createAsyncThunk('showdiscountsList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/discounts`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// modifiers Options Thunk
export const showmodifier_optionsList: any = createAsyncThunk('showmodifier_optionsList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/modifier_options`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// modifiers Thunk
export const showModifierList: any = createAsyncThunk('showModifierList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/modifiers`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// tax groups Thunk
export const showtax_groupsList: any = createAsyncThunk('showtax_groupsList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/tax_groups`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// users Thunk
export const showusersList: any = createAsyncThunk('showusersList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/users`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// warehouses Thunk
export const showwarehousesList: any = createAsyncThunk('showwarehousesList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory-warehouses`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// Price tags Thunk
export const showprice_tagsList: any = createAsyncThunk('showprice_tagsList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/price_tags`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// tags Thunk
export const showTagsList: any = createAsyncThunk('showTagsList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);
    return response.data;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// Categories Thunk
export const showCategoriesList: any = createAsyncThunk('showCategoriesList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/categories`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// Inventory Items Thunk
export const showInventoryItemsList: any = createAsyncThunk('showInventoryItemsList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/items`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// Combos Thunk
export const showCombosList: any = createAsyncThunk('showCombosList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/comboses`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// GiftCards Thunk
export const showGiftCardList: any = createAsyncThunk('showGiftCardList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/gift_cards`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// groups Thunk
export const showGroupsList: any = createAsyncThunk('showGroupsList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/groups`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// Delivery Zones Thunk
export const showDeliveryZonesList: any = createAsyncThunk('showDeliveryZonesList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/delivery_zones`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// Customers Thunk
export const showCustomersList: any = createAsyncThunk('showCustomersList', async body => {
  try {
    const response: any = await fetch6(
      `${process.env.REACT_APP_BASEURL}/select/customers`,

      body,
    );
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// coupons Thunk
export const showCouponsList: any = createAsyncThunk('showCouponsList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/coupons`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// charges Thunk
export const showChargesList: any = createAsyncThunk('showChargesList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/charges`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// promotions Thunk
export const showPromotionsList: any = createAsyncThunk('showPromotionsList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/promotions`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// sections Thunk
export const showSectionsList: any = createAsyncThunk('showSectionsList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/sections`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// Tables Thunk
export const showTablesList: any = createAsyncThunk('showTablesList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/section_tables`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// time events Thunk
export const showTimeEventsList: any = createAsyncThunk('showTimeEventsList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/timed_events`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});
//supplier

// Supplier Thunk
export const showSupplierList: any = createAsyncThunk('showSupplierList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/suppliers`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});
export const showItemsList: any = createAsyncThunk('showItemsList', async (body: any) => {
  try {
    const response: any = await fetch6(
      `${process.env.REACT_APP_BASEURL}/${body?.restrict ? 'inventory' : 'select'}/items`,
      body,
    );
    return body?.restrict ? response.data : response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});
// reasons Thunk
export const showReasonsList: any = createAsyncThunk('showReasonsList', async body => {
  try {
    const response: any = await fetch6(
      `${process.env.REACT_APP_BASEURL}/select/reasons`,

      body,
    );
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});
export const showCommonFranchisesList: any = createAsyncThunk('showCommonFranchisesList', async body => {
  try {
    const response: any = await fetch6(
      `${process.env.REACT_APP_BASEURL}/franchise`,

      body,
    );
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// reasons Thunk
export const showPaymentMethodsList: any = createAsyncThunk('showPaymentMethodsList', async body => {
  try {
    const response: any = await fetch6(
      `${process.env.REACT_APP_BASEURL}/select/payment_methods`,

      body,
    );
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

// Bulk Actions
export const modulesBulkActions: any = createAsyncThunk(
  'global/bulkActions',
  async ({ model, type, body, manage = false }: any) => {
    try {
      const response: any = await fetch5(
        `${process.env.REACT_APP_BASEURL}/${manage ? 'manage' : 'general'}/${model}/${type}`,
        body,
      );
      notify(response?.message, 'success');
      return response;
    } catch (error: any) {
      notify(error?.response?.message, 'error');
      throw error;
    }
  },
);

export const salesOrderList: any = createAsyncThunk('salesOrderList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/sale_orders`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

const selectCommanApi: any = createSlice({
  name: 'selectCommanApi',
  initialState: {
    showAllshowrolesList: [],
    showdevicesListData: [],
    showbranchesListData: [],
    showbranchesbytags: [],
    showFranchisebranchesListData: [],
    showProductListData: [],
    showdiscountsListData: [],
    showmodifier_optionsListData: [],
    showtax_groupsListData: [],
    showusersListData: [],
    showwarehousesListData: [],
    showprice_tagsListData: [],
    showTagsListData: [],
    showModifierListData: [],
    showCategoriesData: [],
    showInventoryItemsData: [],
    showCombosListData: [],
    showGiftCardListData: [],
    showGroupsListData: [],
    showDeliveryZonesListData: [],
    showCustomersListData: [],
    showCouponsListData: [],
    showChargeListData: [],
    showPromotionListData: [],
    showSectionsListData: [],
    showTablesListData: [],
    showTimeEventsListData: [],
    showSupplierListData: [],
    showItemsListData: [],

    showReasonsListData: [],
    showPaymentMethodsListData: [],
    showFranchisesListData: [],
    showSalesOrderListData: [],

    isLoading: false,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(showrolesList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showAllshowrolesList = action.payload;
      })
      .addCase(showbranchesList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(showbranchesList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showbranchesListData = action.payload;
      })
      .addCase(showbranchesList.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getbranchesbytags.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getbranchesbytags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showbranchesbytags = action.payload;
      })
      .addCase(getbranchesbytags.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(showdevicesList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(showdevicesList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showdevicesListData = action.payload;
      })
      .addCase(showdevicesList.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(showProductList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showProductListData = action.payload;
      })
      .addCase(showdiscountsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showdiscountsListData = action.payload;
      })
      .addCase(showmodifier_optionsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showmodifier_optionsListData = action.payload;
      })
      .addCase(showModifierList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showModifierListData = action.payload;
      })
      .addCase(showtax_groupsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showtax_groupsListData = action.payload;
      })
      .addCase(showusersList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showusersListData = action.payload;
      })
      .addCase(showwarehousesList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showwarehousesListData = action.payload;
      })
      .addCase(showprice_tagsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showprice_tagsListData = action.payload;
      })
      .addCase(showTagsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showTagsListData = action.payload;
      })
      .addCase(showTagsList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(showCategoriesList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showCategoriesData = action.payload;
      })
      .addCase(showInventoryItemsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showInventoryItemsData = action.payload;
      })
      .addCase(showGiftCardList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showGiftCardListData = action.payload;
      })
      .addCase(showCombosList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showCombosListData = action.payload;
      })
      .addCase(showGroupsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showGroupsListData = action.payload;
      })
      .addCase(showDeliveryZonesList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showDeliveryZonesListData = action.payload;
      })
      .addCase(showCustomersList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showCustomersListData = action.payload;
      })
      .addCase(showCouponsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showCouponsListData = action.payload;
      })
      .addCase(showChargesList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showChargeListData = action.payload;
      })
      .addCase(showPromotionsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showPromotionListData = action.payload;
      })
      .addCase(showSectionsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showSectionsListData = action.payload;
      })
      .addCase(showTablesList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showTablesListData = action.payload;
      })
      .addCase(showTimeEventsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showTimeEventsListData = action.payload;
      })
      .addCase(showSupplierList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showSupplierListData = action.payload;
      })
      .addCase(showItemsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showItemsListData = action.payload;
      })

      .addCase(showReasonsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showReasonsListData = action.payload;
      })
      .addCase(showPaymentMethodsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showPaymentMethodsListData = action.payload;
      })
      .addCase(showCommonFranchisesList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showFranchisesListData = action.payload;
      })
      .addCase(modulesBulkActions.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(modulesBulkActions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(salesOrderList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showSalesOrderListData = action.payload;
      });
  },
});

// export const { changeDeleteValue, setshowLoading,resetFilterData,setFilterData } = selectCouponSlice.actions;
export default selectCommanApi.reducer;
