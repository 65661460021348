import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch2, fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';

import { notify } from '../../constants/notifyconstants';

const initialState = {
  loading: false,
  progressLoading: false,
  isDataFiltered: false,
  inventoryCategoryList: {},
  singleInventoryCategory: {},
  error: '',
  inventoryCategoryListCount: 0,
  inventoryCategoryFilter: 0,
  loadingStatus: 0,
};

export let showLoading: boolean = false;
export let inventoryCategoryListItems: any;
export let filterValue: any = {
  type: '',
  name: '',
  referance: '',
  is_deleted: '',
  updated_at: '',
};

export const inventoryCategoryList: any = createAsyncThunk(
  'inventoryCategoryList',

  async body => {
    try {
      const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory-categories`, body);
      return result;
    } catch (error: any) {
      notify(error.message, 'error');
    }
  },
);

export const addInventoryCategory: any = createAsyncThunk('addInventoryCategory', async body => {
  try {
    const result = await fetch2(`${process.env.REACT_APP_BASEURL}/inventory/inventory-categories`, body);

    notify('Inventory category created successfully', 'success');
    return result;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

export const updateInventoryCategory: any = createAsyncThunk('updateInventoryCategory', async (body: any) => {
  try {
    const result = await fetch9(`${process.env.REACT_APP_BASEURL}/inventory/inventory-categories/${body.id}`, body);
    notify('Inventory category updated successfully', 'success');
    return result;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

export const restoreInventoryCategory: any = createAsyncThunk('restoreInventoryCategory', async (body: any) => {
  try {
    const result = await fetch3(
      `${process.env.REACT_APP_BASEURL}/inventory/restore_inventory_categories_deleted/${body}`,
      'get',
    );
    notify('Inventory category restored successfully', 'success');
    return result;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

export const deleteInventoryCategory: any = createAsyncThunk('deleteInventoryCategory', async (body: any) => {
  try {
    const result = await fetch3(`${process.env.REACT_APP_BASEURL}/inventory/inventory-categories/${body}`, 'delete');
    notify('Inventory category deleted successfully', 'success');
    return result;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

export const deleteManyInventoryCategory: any = createAsyncThunk('deleteManyInventoryCategory', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/inventory-categories/delete_by_selection`,
    body,
  );
  return result;
});

export const restoreManyInventoryCategory: any = createAsyncThunk('restoreManyInventoryCategory', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/inventory-categories/restore_by_selection`,
    body,
  );
  return result;
});

const inventoryCategorySlice: any = createSlice({
  name: 'Inventory Category',
  initialState,
  reducers: {
    setshowLoading(state = initialState, action: any) {
      showLoading = action.payload;
      state.loadingStatus += 1;
    },
    getSingleInventoryCategory(state = initialState, action: any) {
      state.singleInventoryCategory = action.payload;
    },
    setIsDataFiltered(state = initialState, action: any) {
      state.isDataFiltered = action.payload;
    },
    setProgressLoading(state = initialState, action: any) {
      state.progressLoading = action.payload;
    },
  },
  extraReducers: {
    [inventoryCategoryList.fulfilled]: (state: any, { payload }: any) => {
      state.inventoryCategoryList = payload;
      state.loading = false;
      state.progressLoading = false;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [inventoryCategoryList.rejected]: (state, action) => {
      state.loading = false;
    },
    [inventoryCategoryList.pending]: (state, action) => {
      state.loading = true;
    },
    // ===========================================
    [addInventoryCategory.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.inventoryCategoryListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    // ===========================================
    [updateInventoryCategory.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.inventoryCategoryListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [deleteInventoryCategory.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.inventoryCategoryListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [deleteManyInventoryCategory.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.inventoryCategoryListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [restoreManyInventoryCategory.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.inventoryCategoryListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
  },
});

export const { getSingleInventoryCategory, setIsDataFiltered, setProgressLoading } = inventoryCategorySlice.actions;

export default inventoryCategorySlice.reducer;
