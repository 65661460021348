import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { fetch10, fetch3, fetch6, fetch9, fetch5 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

export let warehouseListItems: any = null;
export let singleInventoryWarehouseDetails: any = [];
export let singleInventoryWarehouseItem: any = [];

const initialState: any = {
  loading: false,
  warehouseListCount: 0,
  singleInventoryWarehouseCount: 0,
  isFiltered: false,
  ref: '',
};

export const warehouseListApi: any = createAsyncThunk('warehouseList', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory-warehouses`, body);
  warehouseListItems = result;

  return result;
});
export const warehouseFilterListApi: any = createAsyncThunk('warehouseFilterList', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory-warehouses`, body);
  warehouseListItems = result.data || [];

  return result;
});

export const addWarehouseInventoryApi: any = createAsyncThunk('addWarehouse', async body => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/inventory-warehouses`, body);

  return result;
});

export const updateInventoryWarehouse: any = createAsyncThunk('updateInventoryWarehouse', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/inventory/inventory-warehouses/${body.id}`, body);

  return result;
});

export const deleteInventoryWarehouse: any = createAsyncThunk('deleteInventoryWarehouse', async (id: any) => {
  const result = await fetch10(`${process.env.REACT_APP_BASEURL}/inventory/inventory-warehouses/${id}`);
  return result;
});

export const inventoryWarehouseDetails: any = createAsyncThunk('inventoryWarehouseDetails', async (id: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory-warehouses/${id}`);
  console.log(321);

  singleInventoryWarehouseDetails = result.data;
  // singleInventoryWarehouseItem = result.data.items;
  return result;
});

export const getAllDeletedWarehouse: any = createAsyncThunk('getAllDeletedWarehouse', async () => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory-warehouses_deleted`);
  return result;
});

export const generateWarehouseRef: any = createAsyncThunk('generateWarehouseRef', async (id: any) => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/generate_reference`, {
      model: 'branches',
    });
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

const warehouseSlice: any = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    startLoading(state = initialState) {
      state.loading = true;
    },
    endLoading(state = initialState) {
      state.loading = false;
    },
    changeFilterState(state = initialState, action: any) {
      state.isFiltered = action.payload;
    },
  },
  extraReducers: {
    [warehouseListApi.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.warehouseListCount += 1;
      if (error) {
        notify(message);
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [warehouseListApi.pending]: (state: any) => {
      state.loading = true;
    },
    [warehouseListApi.rejected]: (state: any) => {
      state.loading = false;
    },
    [warehouseFilterListApi.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.warehouseListCount += 1;
      if (error) {
        notify(message);

        state.error = error;
      } else {
        state.error = message;
      }
    },
    [addWarehouseInventoryApi.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.warehouseListCount += 1;
      if (status) {
        notify(message, 'success');
      }
      if (error) {
        notify(message);

        state.error = error;
      } else {
        state.error = message;
      }
    },
    [generateWarehouseRef.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.ref = action.payload.data;
      if (action.payload.status) {
        notify(action.payload.message, 'success');
      }
      if (action.payload.error) {
        notify(action.payloadmessage);

        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [inventoryWarehouseDetails.pending]: state => {
      state.loading = true;
    },
    [inventoryWarehouseDetails.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.singleInventoryWarehouseCount += 1;
      if (error) {
        notify(message);
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [deleteInventoryWarehouse.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.singleInventoryWarehouseCount += 1;
      if (status) {
        notify(message, 'success');
      }
      if (error) {
        notify(message);
      } else {
        state.error = message;
      }
    },
    [updateInventoryWarehouse.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.singleInventoryWarehouseCount += 1;
      if (status) {
        notify(message, 'success');
      }
      if (error) {
        notify(message);
      } else {
        state.error = message;
      }
    },
  },
});

export const { changeFilterState } = warehouseSlice.actions;
export default warehouseSlice.reducer;
