import {
  createSlice,
  createAsyncThunk,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import {
  fetch10,
  fetch2,
  fetch22,
  fetch3,
  fetch5,
  fetch6,
  fetch9,
} from "../../Apis/commonApis";
import { notify } from "../../constants/notifyconstants";
import axios from "axios";
import { useDispatch } from "react-redux";

export const showtimed_eventsList: any = createAsyncThunk(
  "showtimed_eventsList",
  async (body) => {
    try {
      const response: any = await fetch6(
        `${process.env.REACT_APP_BASEURL}/manage/timed_events`,
        body
      );
      // notify("success", "success");
      return response.data;
    } catch (error: any) {
      notify(error.response.data.message, "error");

      // notify(error.message, "error");
      throw error;
    }
  }
);
export const showtimed_eventsDetails: any = createAsyncThunk(
  "showtimed_eventsDetails",
  async (body) => {
    try {
      const response: any = await fetch6(
        `${process.env.REACT_APP_BASEURL}/manage/timed_events/${body}`
      );
      // notify("success", "success");
      return response.data;
    } catch (error: any) {
      notify(error.response.data.message, "error");

      // notify(error.message, "error");
      throw error;
    }
  }
);
export const showtimed_eventsDetailsDel: any = createAsyncThunk(
  "showtimed_eventsDetailsDel",
  async (body) => {
    try {
      const response: any = await fetch10(
        `${process.env.REACT_APP_BASEURL}/manage/timed_events/${body}`
      );
      notify("Time event deleted successfully", "success");
      return response.data;
    } catch (error: any) {
      notify(error.response.data.message, "error");
      throw error;
    }
  }
);
export const showtimed_eventsDetailsEdit: any = createAsyncThunk(
  "showtimed_eventsDetailsEdit",
  async ({ id = "", body = null }: any) => {
    try {
      const response: any = await fetch5(
        `${process.env.REACT_APP_BASEURL}/manage/timed_events/${id}`,
        body
      );
      notify("Time event updated successfully", "success");
      return response.data;
    } catch (error: any) {
      notify(error.response.data.message, "error");
      throw error;
    }
  }
);
export const showbranchesLisTIt: any = createAsyncThunk(
  "showbranchesLisTIt",
  async (body) => {
    try {
      const response: any = await fetch6(
        `${process.env.REACT_APP_BASEURL}/manage/branches`,
        body
      );
      // notify("success", "success");
      return response.data;
    } catch (error: any) {
      // notify(error.message, "error");
      throw error;
    }
  }
);
export const showAllcategories: any = createAsyncThunk(
  "categories",
  async (body) => {
    try {
      const response: any = await fetch6(
        `${process.env.REACT_APP_BASEURL}/menu/categories?filter[is_deleted]=false`,
        body
      );
      // notify("success", "success");
      return response.data;
    } catch (error: any) {
      // notify(error.message, "error");
      throw error;
    }
  }
);
export const showAllproduct: any = createAsyncThunk("product", async (body) => {
  try {
    const response: any = await fetch6(
      `${process.env.REACT_APP_BASEURL}/menu/products?filter[is_deleted]=false`,
      body
    );
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});
export const tagsTi: any = createAsyncThunk("tagsTi", async (body) => {
  try {
    const response: any = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/tags?filter[type]=7&filter[is_deleted]=false`,
      body
    );
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    // notify(error.message, "error");
    throw error;
  }
});
export const delTi: any = createAsyncThunk("delTi", async (body) => {
  try {
    const response: any = await fetch10(
      `${process.env.REACT_APP_BASEURL}/manage/timed_events/${body}`
    );
    notify("Time event deleted successfully", "success");
    return response.data;
  } catch (error: any) {
    notify(error.response.data.message, "error");
    throw error;
  }
});
const manageTimeEventSlice: any = createSlice({
  name: "manage TimeEventSlice",
  initialState: {
    showtimed_eventsListData: null,
    showtimed_eventsListDetailsData: null,
    showbranchesLisTItData: [],
    showAllcategoriesData: [],
    showAllproductData: [],
    tagsTiData: [],
    oneTimeNavTIData: false,
    isLoading: false,
  },
  reducers: {
    oneTimeNavTI(state, action) {
      state.oneTimeNavTIData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(showtimed_eventsList.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.showtimed_eventsListData = action.payload;
    });
    builder.addCase(showtimed_eventsList.pending, (state: any, action) => {
      state.isLoading = true;

    });
    builder.addCase(showtimed_eventsDetails.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.showtimed_eventsListDetailsData = action.payload;
      state.oneTimeNavTIData = false;
    });
    builder.addCase(showtimed_eventsDetails.pending, (state: any, action) => {

      state.oneTimeNavTIData = true;
    });


    builder.addCase(showtimed_eventsDetails.rejected, (state: any, action) => {
      state.isLoading = false;
      state.oneTimeNavTIData = false;
    });
    builder.addCase(showbranchesLisTIt.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.showbranchesLisTItData = action.payload;
    });
    builder.addCase(showAllcategories.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.showAllcategoriesData = action.payload;
    });
    builder.addCase(showAllproduct.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.showAllproductData = action.payload;
    });
    builder.addCase(tagsTi.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.tagsTiData = action.payload;
    });
  },
});

export const { oneTimeNavTI } = manageTimeEventSlice.actions;
export default manageTimeEventSlice.reducer;
