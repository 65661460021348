import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOnBoardingRideReducer } from '../../lib/OnBoardingRide/types/types';


const initialState: IOnBoardingRideReducer = {
  value: '',
  run: false,
  stepIndex: 0,
  tourArray: [],
  tourActive: false,
  showSubNavItem: '',
};

const onBoardingRideReducer = createSlice({
  name: 'onBoardingRideReducer',
  initialState,
  reducers: {
    updateOnBoardState(state, action: PayloadAction<Partial<IOnBoardingRideReducer>>) {
      Object.assign(state, action.payload);
    },
  },
});

export const { updateOnBoardState } = onBoardingRideReducer.actions;

export default onBoardingRideReducer.reducer;
