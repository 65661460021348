import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notify } from '../../constants/notifyconstants';
import { fetch5 } from '../../Apis/commonApis';

export const validateUserData: any = createAsyncThunk('forgetPasswordApi', async (body: any) => {
  try {
    const response = await fetch5(`${process.env.REACT_APP_BASEURL}/auth/send-reset-password-otp`, body);

    return response;
  } catch (error: any) {
    notify(error?.response?.data?.message, 'error');
  }
});
export const verifyOtp: any = createAsyncThunk('verifyOtp', async (body: any) => {
  try {
    const response = await fetch5(`${process.env.REACT_APP_BASEURL}/auth/check-otp`, body);
    notify('Otp verified', 'success');

    return response;
  } catch (error: any) {
    notify('The selected otp is invalid', 'error');
  }
});
export const validateUserPassword: any = createAsyncThunk('validateUserPassword', async (body: any) => {
  try {
    const response = await fetch5(`${process.env.REACT_APP_BASEURL}/auth/reset-password-otp`, body);
    notify('Password changed', 'success');

    return response;
  } catch (error: any) {
    notify('The password does not match', 'error');
  }
});
const initialState = {
  user: null,
  verifyOtpStatus: false,
  verifyPasswordStatus: false,
};
const ForgetPasswordReducer: any = createSlice({
  name: 'forgetPasswordApi',
  initialState,
  reducers: {
    resetResponse(state: any = initialState) {
      state.user = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(validateUserData.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.verifyOtpStatus = action.payload;
      })
      .addCase(validateUserPassword.fulfilled, (state, action) => {
        state.verifyPasswordStatus = action.payload;
      })
  },
});
export const { resetResponse } = ForgetPasswordReducer.actions;
export default ForgetPasswordReducer.reducer;
