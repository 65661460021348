import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetch5, fetch6, fetch10, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  loading: false,
  filterStatus: false,
  error: null,
  filterBody: null,
  costAdjustmentHistoryReportsList: [],
  getInventoryCostAdjustmentHistorybranchesData: [],
  getInventoryCostAdjustmentHistorycategoriesData: [],
  getInventoryCostAdjustmentHistoryitemsData: [],
  getInventoryCostAdjustmentHistoryTagsData: [],
  getInventoryCostAdjustmentHistorySupplierData: [],
};

export const getCostAdjustmentHistoryReportsList: any = createAsyncThunk(
  'getCostAdjustmentHistoryReportsList',
  async body => {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/reports/inventory-report/inventory_cost_adjustment`,
      body,
    );
    return result;
  },
);

export const getInventoryCostAdjustmentHistorybranches: any = createAsyncThunk(
  'getInventoryCostAdjustmentHistorybranches',
  async (body: any) => {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/branches`, body);
    return result.data;
  },
);
export const getInventoryCostAdjustmentHistorycategories: any = createAsyncThunk(
  'getInventoryCostAdjustmentHistorycategories',
  async (body: any) => {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory-categories`, body);
    return result.data;
  },
);
export const getInventoryCostAdjustmentHistoryitems: any = createAsyncThunk(
  'getInventoryCostAdjustmentHistoryitems',
  async (body: any) => {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items`, body);
    return result.data;
  },
);
export const getInventoryCostAdjustmentHistoryTags: any = createAsyncThunk(
  'getInventoryCostAdjustmentHistoryTags',
  async (body: any) => {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);
    return result.data;
  },
);
export const getInventoryCostAdjustmentHistorySupplier: any = createAsyncThunk(
  'getInventoryCostAdjustmentHistorySupplier',
  async () => {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/suppliers?par_page=9999`);
    return result.data;
  },
);

const ReportsCostAdjustmenthistoryApiReducer: any = createSlice({
  name: 'ReportsCostAdjustmenthistoryApiReducer',
  initialState,
  reducers: {
    changeFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    setFilter: (state, action) => {
      state.filterBody = action.payload;
    },
  },
  extraReducers: {
    [getCostAdjustmentHistoryReportsList.pending]: state => {
      state.loading = true;
    },
    [getCostAdjustmentHistoryReportsList.fulfilled]: (state, action) => {
      state.loading = false;
      state.costAdjustmentHistoryReportsList = action.payload;
    },
    [getCostAdjustmentHistoryReportsList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryCostAdjustmentHistorySupplier.pending]: state => {
      state.loading = true;
    },
    [getInventoryCostAdjustmentHistorySupplier.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryCostAdjustmentHistorySupplierData = action.payload;
    },
    [getInventoryCostAdjustmentHistorySupplier.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getInventoryCostAdjustmentHistorybranches.pending]: state => {
      state.loading = true;
    },
    [getInventoryCostAdjustmentHistorybranches.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryCostAdjustmentHistorybranchesData = action.payload;
    },
    [getInventoryCostAdjustmentHistorybranches.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryCostAdjustmentHistorycategories.pending]: state => {
      state.loading = true;
    },
    [getInventoryCostAdjustmentHistorycategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryCostAdjustmentHistorycategoriesData = action.payload;
    },
    [getInventoryCostAdjustmentHistorycategories.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryCostAdjustmentHistoryitems.pending]: state => {
      state.loading = true;
    },
    [getInventoryCostAdjustmentHistoryitems.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryCostAdjustmentHistoryitemsData = action.payload;
    },
    [getInventoryCostAdjustmentHistoryitems.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryCostAdjustmentHistoryTags.pending]: state => {
      state.loading = true;
    },
    [getInventoryCostAdjustmentHistoryTags.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryCostAdjustmentHistoryTagsData = action.payload;
    },
    [getInventoryCostAdjustmentHistoryTags.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});
export const { changeFilterStatus, setFilter } = ReportsCostAdjustmenthistoryApiReducer.actions;
export default ReportsCostAdjustmenthistoryApiReducer.reducer;
