import {
  createSlice,
  createAsyncThunk,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { fetch2, fetch3, fetch5, fetch6 } from "../../Apis/commonApis";
import { notify } from "../../constants/notifyconstants";
import { RoundNum } from "../../utils/functions";

// sales report Thunk
export const getSalesReportsData: any = createAsyncThunk(
  "salesReport/get",
  async (body: any) => {
    try {
      const response: any = await fetch6(
        `${process.env.REACT_APP_BASEURL}/reports/sales/by-${body.report}`,
        body.params
      );

      return response;
    } catch (error: any) {
      notify(error?.response?.data?.message, "error");
      throw error;
    }
  }
);
// sales report Thunk
export const getPaymentReportsData: any = createAsyncThunk(
  "salesReport/get",
  async (body: any) => {
    try {
      const response: any = await fetch6(
        `${process.env.REACT_APP_BASEURL}/reports/payments?page=${body.page}&per_page=${body.perPage}`
      );
      return response;
    } catch (error: any) {
      notify(error?.response?.data?.message, "error");
      throw error;
    }
  }
);

const initialState = {
  reportFilterObject: {
    branches: null,
    order_tags: null,
    branch_tags: null,
    cashier: null,
    category: null,
    combo: null,
    combo_tags: null,
    discount: null,
    coupon: null,
    charge: null,
    creator: null,
    customer: null,
    driver: null,
    option: null,
    source: null,
    type: null,
    product: null,
    section: null,
    table: null,
    promotion: null,
  },
  isFiltered: false,

  salesReportData: [],
  salesReportTotalRows: 0,
};

const reportsSalesApiSlice: any = createSlice({
  name: "salesReportsSlice",
  initialState,
  reducers: {
    setFilterObject: (state, action) => {
      state.isFiltered = true;
      state.reportFilterObject.branches =
        action.payload?.branches?.length > 0 ? action.payload.branches : null;
      state.reportFilterObject.order_tags =
        action.payload?.order_tags?.length > 0
          ? action.payload.order_tags
          : null;
      state.reportFilterObject.branch_tags =
        action.payload?.branch_tags?.length > 0
          ? action.payload.branch_tags
          : null;
      state.reportFilterObject.cashier =
        action.payload?.cashier?.length > 0 ? action.payload.cashier : null;
      state.reportFilterObject.category =
        action.payload?.category?.length > 0 ? action.payload.category : null;
      state.reportFilterObject.combo =
        action.payload?.combo?.length > 0 ? action.payload.combo : null;
      state.reportFilterObject.combo_tags =
        action.payload?.combo_tags?.length > 0
          ? action.payload.combo_tags
          : null;
      state.reportFilterObject.discount =
        action.payload?.discount?.length > 0 ? action.payload.discount : null;
      state.reportFilterObject.coupon =
        action.payload?.coupon?.length > 0 ? action.payload.coupon : null;
      state.reportFilterObject.charge =
        action.payload?.charge?.length > 0 ? action.payload.charge : null;
      state.reportFilterObject.creator =
        action.payload?.creator?.length > 0 ? action.payload.creator : null;
      state.reportFilterObject.customer =
        action.payload?.customer?.length > 0 ? action.payload.customer : null;
      state.reportFilterObject.driver =
        action.payload?.driver?.length > 0 ? action.payload.driver : null;
      state.reportFilterObject.option =
        action.payload?.option?.length > 0 ? action.payload.option : null;
      state.reportFilterObject.source =
        action.payload?.source?.length > 0 ? action.payload.source : null;
      state.reportFilterObject.type =
        action.payload?.type?.length > 0 ? action.payload.type : null;
      state.reportFilterObject.product =
        action.payload?.product?.length > 0 ? action.payload.product : null;
      state.reportFilterObject.section =
        action.payload?.section?.length > 0 ? action.payload.section : null;
      state.reportFilterObject.table =
        action.payload?.table?.length > 0 ? action.payload.table : null;
      state.reportFilterObject.promotion =
        action.payload?.promotion?.length > 0 ? action.payload.promotion : null;
    },
    resetFilterObject: (state, action) => {
      state.isFiltered = false;
      state.reportFilterObject = initialState.reportFilterObject;
    },
    resetReportData: (state, action) => {
      state.salesReportData = [];
      state.salesReportTotalRows = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSalesReportsData.fulfilled, (state, action) => {
      state.salesReportData = action.payload.data.map(
        (obj: { [key: string]: any }) => {
          Object.entries(obj).forEach(([key, value]: any) => {
            obj[key] = !isNaN(+value) ? RoundNum(+value) : value;
          });

          return obj;
        }
      );
      state.salesReportTotalRows = action.payload.total;
    });
  },
});

export const { setFilterObject, resetFilterObject, resetReportData } =
  reportsSalesApiSlice.actions;
export default reportsSalesApiSlice.reducer;
