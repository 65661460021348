import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { fetch10, fetch2, fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  token: '',
  modifierState: 0,
  loading: false,
  error: '',
  showProductList: 0,
  productFilter: 0,
  createProduct: 0,
  deleteProduct: 0,
  singleProductCount: 0,
  getTaxGroupStatus: 0,
  productTagModifierState: 0,
  addTagModifierBranchIngredientCount: 0,
  loadingStatus: 0,
  productsTableData: [],
  singleProductData: {},
  updateModifierData: {},
  updateIngredientData: {},
  productsTableMeta: {},
  isFiltered: false,
  pageCount: 0,
};

export let productFilterValue: any = {
  is_active: null,
  is_deleted: false,
  name: null,
  sku: null,
  barcode: null,
  costing_method: null,
  is_stock_product: null,
  updated_after: null,
  // categories: "",
  // groups: "",
  // tag: "",
  // pricingmethod: "",
};
export let filterArray: any = {
  categories: [],
  group: [],
  modifiers: [],
  texgroup: [],
  tag: [],
};
export let productListItems: any;
export let allProductListItems: any;
export let modifierListItems: any;
export let singleProductDetails: any;
export let addTagDetails: any;
export let taxGroupData: any;
export let tagIngeModifier: any;
export let addProductType: string;
export let addProductTypeName: string;
export let parameter: string;
export let productId: number;
export let getAllTagIngridentModifier: any;
export let UpdateIngredients: any;
export let UpdateModifier: any;
export let tagData: any;
export let ProductViewPerId: any;

export const productList: any = createAsyncThunk('productList', async body => {
  try {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/products`, body);
    console.log(result, 'spoir');
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const productView: any = createAsyncThunk('product/getProduct', async body => {
  try {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/products/${body}`);
    return result.data;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const createProduct: any = createAsyncThunk('createProduct', async body => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/menu/products`, body);

    return result;
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
          notify(error?.response?.data?.errors[field][0]),
        )
      : notify(error?.response?.data?.message);
  }
});

export const updateProduct: any = createAsyncThunk('updateSingleProduct', async (body: any) => {
  try {
    const result = await fetch9(`${process.env.REACT_APP_BASEURL}/menu/products/${body.id}`, body);

    notify(result?.massage || 'Product Updated Successfully', 'success');
    return result;
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
          notify(error?.response?.data?.errors[field][0]),
        )
      : notify(error?.response?.data?.message);
  }
});

export const restoreProduct: any = createAsyncThunk('restoreSingleProduct', async (id: any) => {
  try {
    const result = await fetch9(`${process.env.REACT_APP_BASEURL}/menu/products/${id}/restore`);
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const deleteProduct: any = createAsyncThunk('deleteSingleProduct', async (body: any) => {
  try {
    const result = await fetch10(`${process.env.REACT_APP_BASEURL}/menu/products/${body}`);
    notify(result?.massage || 'Product Deleted Successfully', 'success');
    return result;
  } catch (error: any) {
    return Object.keys(error?.response?.data?.errors)?.length > 0
      ? Object.keys(error?.response?.data?.errors).forEach((field: any) =>
          notify(error?.response?.data?.errors[field][0]),
        )
      : notify(error?.response?.data?.message);
  }
});

// modifiers
export const addModifierToProduct: any = createAsyncThunk('Product/addModifierToProduct', async (body: any) => {
  try {
    const result = await fetch5(
      body.edit
        ? `${process.env.REACT_APP_BASEURL}/menu/products/${body.productId}/modifiers/${body.modifierId}`
        : `${process.env.REACT_APP_BASEURL}/menu/products/${body.productId}/modifier/sync`,
      body.data,
    );
    // const result = await fetch5(
    //   `${process.env.REACT_APP_BASEURL}/menu/products/${body.productId}/modifiers/${body.modifierId}`,
    //   body.data,
    // );
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const deleteProductModifier: any = createAsyncThunk('deleteProductModifier', async (body: any) => {
  try {
    const result = await fetch10(
      `${process.env.REACT_APP_BASEURL}/menu/products/${body.productId}/modifiers/${body.modifierId}`,
    );
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const copyProductModifier: any = createAsyncThunk('CopyProductModifier', async (body: any) => {
  try {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/menu/products/${body.product_id}/copy_modifiers`,
      body.data,
    );
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

// Ingredients
export const addIngredient: any = createAsyncThunk('product/addIngredient', async (body: any) => {
  try {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/menu/products/${body.productId}/ingredients/${body.ingredientId}`,
      body.data,
    );
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const deleteProductIngrident: any = createAsyncThunk('deleteProductIngrident', async (body: any) => {
  try {
    const result = await fetch10(
      `${process.env.REACT_APP_BASEURL}/menu/products/${body.productId}/ingredients/${body.ingredientId}`,
    );
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const deleteBulkProductIngredients: any = createAsyncThunk(
  'products/deleteBulkIngredient',
  async (body: any) => {
    try {
      const result = await fetch5(`${process.env.REACT_APP_BASEURL}/bulk-actions/delete-product-ingredients`, body);
      return result;
    } catch (error: any) {
      return notify(error?.response?.data?.message);
    }
  },
);

// edit branches
export const editBranches: any = createAsyncThunk('products/editBranches', async (body: any) => {
  try {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/menu/products/${body.productId}/branches/${body.branchId}`,
      body.data,
    );
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

// edit price tags
export const editProductPriceTags: any = createAsyncThunk('products/editProductPriceTags', async (body: any) => {
  try {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/manage/price_tags/${body.priceTagId}/products/${body.productId}`,
      body.data,
    );
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

// delete price tags
export const deleteProductPriceTag: any = createAsyncThunk('products/deleteProductPriceTag', async (body: any) => {
  try {
    const result = await fetch10(
      `${process.env.REACT_APP_BASEURL}/manage/price_tags/${body.priceTagId}/products/${body.productId}`,
    );
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const addBulkForProducts: any = createAsyncThunk('products/addBulkForProducts', async (body: any) => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/bulk-actions/${body.endpointName}`, body.data);
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const updateGroupStatus: any = createAsyncThunk('products/updateGroupStatus', async (body: any) => {
  try {
    const result = await fetch9(
      `${process.env.REACT_APP_BASEURL}/menu/products/${body.productId}/groups/${body.groupId}`,
      body.data,
    );
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const generateSKU: any = createAsyncThunk('product/generateSKU', async (id: any) => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/generate_sku`, {
      model: 'products',
    });
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const modifierList: any = createAsyncThunk('modifierList', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/modifierlist_delete`, body);
  modifierListItems = result.data;
  console.log(modifierListItems, 'modifier');
  return result;
});

export const productFilter: any = createAsyncThunk('productFilter', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/product_filter`, body);
  productListItems = result.data;
  console.log(productListItems, 'SSAARRHHAANN');
  return result;
});

export const getTaxGroup: any = createAsyncThunk('getTaxGroup', async (body: any) => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/get_producttag_taxgroup`, body);
  console.log(body, 'body');
  if (body?.type === 'taxgroup') {
    taxGroupData = result.data;
  } else {
    tagData = result.data;
    console.log(tagData, 'tagDatatata');
  }
  console.log(taxGroupData, 'taxGroupData');
  return result;
});
export const activeProduct: any = createAsyncThunk('activeProduct', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/change_product_status`, body);
  ProductViewPerId = result;
  return result;
});

export const addTag: any = createAsyncThunk('addTag', async body => {
  console.log(body, 'addTagaddTagaddTag');

  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/product_multiple_addtag`, body);
  addTagDetails = result.data;
  return result;
});
export const productMultipleIdActive: any = createAsyncThunk('productMultipleIdActive', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/product_multipleid_active`, body);
  addTagDetails = result.data;
  return result;
});
export const productMultipleIdDeactive: any = createAsyncThunk('productMultipleIdDeactive', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/product_multipleid_deactive`, body);
  addTagDetails = result.data;
  return result;
});
export const removeTag: any = createAsyncThunk('removeTag', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/product_multiple_removetag`, body);
  return result;
});
export const addproductModifier: any = createAsyncThunk('addproductModifier', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/product_modifieradd`, body);
  addTagDetails = result.data;
  // console.log(singleProductDetails, "1234567")
  return result;
});
export const updateProductIngrident: any = createAsyncThunk('updateProductIngrident', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/update_product_ingrident`, body);
  return result;
});

export const copyProductIngrident: any = createAsyncThunk('copyProductIngrident', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/copy_product_ingrident`, body);
  return result;
});

export const updateProductModifier: any = createAsyncThunk('updateProductModifier', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/modify_product_modifier`, body);
  return result;
});

export const addPriceTag: any = createAsyncThunk('addPriceTag', async body => {
  console.log(body, 'price taggggggggs');

  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/product_pricetagadd`, body);
  addTagDetails = result.data;
  // console.log(singleProductDetails, "1234567")
  return result;
});
export const modifyProductPricetag: any = createAsyncThunk('modifyProductPricetag', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/modify_product_pricetag`, body);
  addTagDetails = result.data;
  // console.log(singleProductDetails, "1234567")
  return result;
});
export const deleteProductPricetag: any = createAsyncThunk('deleteProductPricetag', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/delete_product_pricetag`, body);
  addTagDetails = result.data;
  // console.log(singleProductDetails, "1234567")
  return result;
});
export const product_costumepriceadd: any = createAsyncThunk('product_costumepriceadd', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/product_costumepriceadd`, body);
  addTagDetails = result.data;
  // console.log(singleProductDetails, "1234567")
  return result;
});
export const productEditcostumeprice: any = createAsyncThunk('productEditcostumeprice', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/modify_product_costumeprice`, body);
  addTagDetails = result.data;
  // console.log(singleProductDetails, "1234567")
  return result;
});
export const productDeleteCustomPrice: any = createAsyncThunk('productDeleteCustomPrice', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/delete_product_costumeprice`, body);
  addTagDetails = result.data;
  return result;
});

export const product_outofstock_brachadd: any = createAsyncThunk('product_outofstock_brachadd', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/product_outofstock_brachadd`, body);
  addTagDetails = result.data;
  // console.log(singleProductDetails, "1234567")
  return result;
});

export const product_inactivebrachadd: any = createAsyncThunk('product_inactivebrachadd', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/product_inactivebrachadd`, body);
  addTagDetails = result.data;
  // console.log(singleProductDetails, "1234567")
  return result;
});

export const productTagModifier: any = createAsyncThunk('productTagModifier', async body => {
  tagIngeModifier = [];
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/productget_tag_modifer_ingrident_pricetag`, body);
  result.data.map((e: any) => {
    tagIngeModifier.push({
      label: e.name ? e.name : e.item_name ? e.item_name : e.english_name,
      value: e.id ? e.id : e.ingrident_id,
    });
  });
  console.log(result, 'productget_tag_modifer_ingrident_pricetag');
  return result;
});
export const exportProduct: any = createAsyncThunk('exportProduct', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/export_products`, body);
  return result;
});
export const exportProductIngredeints: any = createAsyncThunk('exportProductIngredeints', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/export_product_ingredients`, body);
  return result;
});
export const exportProductModifiers: any = createAsyncThunk('exportProductModifiers', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/export_product_modifiers`, body);
  return result;
});
export const importProduct: any = createAsyncThunk('importProduct', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/import_products`, body);
  return result;
});
export const importProductIngredeints: any = createAsyncThunk('importProductIngredeints', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/import_product_ingredients`, body);
  return result;
});
export const importProductModifiers: any = createAsyncThunk('importProductModifiers', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/import_product_modifiers`, body);
  return result;
});
export const productDeletemultiple: any = createAsyncThunk('productDeletemultiple', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/product_multipledelete`, body);
  return result;
});
const productSlice: any = createSlice({
  name: 'Product',
  initialState,
  reducers: {
    productFilterData(state = initialState, action) {
      state.isFiltered = true;
      // productFilterValue.type = productFilterValue.type;
      productFilterValue.name = action.payload.name;
      productFilterValue.sku = action.payload.sku;
      productFilterValue.barcode = action.payload.barcode;
      productFilterValue.is_deleted = action.payload.is_deleted;
      productFilterValue.costing_method =
        action.payload.costing_method != undefined ? action.payload.costing_method : null;
      productFilterValue.is_active = action.payload.is_active;
      productFilterValue.is_stock_product = action.payload.is_stock_product;

      productFilterValue.updated_after = action.payload.updated_after;
      productFilterValue.category = action.payload.category != undefined ? action.payload.category : null;
      productFilterValue.groups = action.payload.groups != undefined ? action.payload.groups : null;
      productFilterValue.modifiers = action.payload.modifiers != undefined ? action.payload.modifiers : null;
      productFilterValue.taxGroup = action.payload.taxGroup != undefined ? action.payload.taxGroup : null;
      productFilterValue.tags = action.payload.tags != undefined ? action.payload.tags : null;
      // state.showProductList += 1;
    },
    addType(state = initialState, action) {
      addProductType = action.payload.name;
      parameter = action.payload.parameter;
      const breakSentence = action.payload.name.split(' ');
      if (action.payload.name == 'Remove Tags') addProductTypeName = 'Remove Tags';
      else if (breakSentence[0] === 'pricetag') {
        addProductTypeName = 'pricetag';
      } else {
        addProductTypeName = breakSentence[1];
      }
      productId = action.payload.id;
    },
    resetOrders(state = initialState) {
      state.isFiltered = false;
      productFilterValue.is_active = null;
      productFilterValue.is_deleted = null;
      productFilterValue.name = null;
      productFilterValue.sku = null;
      productFilterValue.barcode = null;
      productFilterValue.costing_method = null;
      productFilterValue.is_stock_product = null;
      productFilterValue.updated_after = null;
      productFilterValue.category = null;
      productFilterValue.groups = null;
      productFilterValue.modifiers = null;
      productFilterValue.tags = null;
      productFilterValue.taxGroup = null;
      // state.showProductList += 1;
      filterArray.categories = [];
      filterArray.group = [];
      filterArray.modifiers = [];
      filterArray.texgroup = [];
      filterArray.tag = [];
    },
    setshowLoading(state = initialState) {
      state.loadingStatus += 1;
    },
    setUpdateIngredient(state, action) {
      state.updateIngredientData = action.payload;
    },
    setUpdateModifier(state, action) {
      state.updateModifierData = action.payload;
    },
    updateIsFiltered(state, action) {
      state.isFiltered = true;
    },
    setPageCount(state = initialState, action) {
      state.pageCount = action.payload;
    },
  },
  extraReducers: {
    [productList.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.productsTableData = payload.data;
      state.productsTableMeta = payload.meta;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [productList.pending]: (state: any) => {
      state.loading = true;
    },
    [updateProduct.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      // state.productsTableData = payload;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [restoreProduct.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      // state.productsTableData = payload;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [addModifierToProduct.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      // state.productsTableData = payload;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [deleteProduct.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      // state.productsTableData = payload;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },
    [productFilter.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.showProductList += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [productView.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.singleProductData = payload;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload.message;
      }
    },

    [createProduct.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.createProduct += 1;
      if (error) {
        state.error = error;
      } else {
        console.log({ message });

        notify(message, 'success');
        state.error = message;
      }
    },
    [activeProduct.fulfilled]: (state: any, { payload: { error, msg } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(msg, 'success');
        state.error = msg;
      }
    },
    [modifierList.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.modifierState += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [getTaxGroup.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.getTaxGroupStatus += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [productTagModifier.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.productTagModifierState += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [addTag.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      // debugger;
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [productMultipleIdActive.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [productMultipleIdDeactive.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [removeTag.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      // debugger;
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [addproductModifier.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [updateProductIngrident.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [deleteProductIngrident.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [deleteBulkProductIngredients.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [editBranches.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [editProductPriceTags.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [deleteProductPriceTag.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [addBulkForProducts.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [updateGroupStatus.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [generateSKU.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [updateProductModifier.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [deleteProductModifier.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [copyProductIngrident.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [copyProductModifier.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      // state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [addIngredient.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [addPriceTag.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      // debugger;
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [modifyProductPricetag.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [deleteProductPricetag.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [product_costumepriceadd.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [productEditcostumeprice.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [productDeleteCustomPrice.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [product_outofstock_brachadd.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [product_inactivebrachadd.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [exportProduct.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (isRejectedWithValue(action)) {
        state.error = action?.error.data.message;
      } else {
        state.error = action?.payload?.data;
        window.open(`${process.env.REACT_APP_EXPORTBASEURL}` + action?.payload.data, '_blank');
      }
    },
    [exportProductIngredeints.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (isRejectedWithValue(action)) {
        state.error = action?.error.data.message;
      } else {
        state.error = action?.payload?.data;
        window.open(`${process.env.REACT_APP_EXPORTBASEURL}` + action?.payload.data, '_blank');
      }
    },
    [exportProductModifiers.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (isRejectedWithValue(action)) {
        state.error = action?.error.data.message;
      } else {
        state.error = action?.payload?.data;
        window.open(`${process.env.REACT_APP_EXPORTBASEURL}` + action?.payload.data, '_blank');
      }
    },
    [importProduct.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.createProduct += 1;
      if (error) {
        state.error = error;
      } else {
        notify(message, 'success');
        state.error = message;
      }
    },
    [importProductIngredeints.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(message, 'success');
        state.error = message;
      }
    },
    [importProductModifiers.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.addTagModifierBranchIngredientCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(message, 'success');
        state.error = message;
      }
    },
    [productDeletemultiple.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.deleteProduct += 1;
      state.productTagModifierState += 1;
      if (error) {
        state.error = error;
      } else {
        let type = status ? 'success' : '';
        notify(massage, type);
        state.error = massage;
      }
    },
  },
});

export const {
  productFilterData,
  addType,
  resetOrders,
  setshowLoading,
  setUpdateIngredient,
  setUpdateModifier,
  updateIsFiltered,
  setPageCount,
} = productSlice.actions;
export default productSlice.reducer;
