import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

interface Option {
  value: string | number | boolean;
  label: string | number;
}

interface SelectFieldProps {
  options: Option[];
  isMulti?: boolean;
  removeSelectAll?: boolean;
}

const customFilterOption = (option: Option, inputValue: string) => {
  // Modify this condition to customize your search logic
  return `${option.label}`.toLowerCase().includes(inputValue.toLowerCase());
};

export const SelectField: React.FC<any & SelectFieldProps> = ({
  options,
  field,
  name,
  form,
  isMulti,
  defaultValue,
  onChange,
  required,
  removeSelectAll,
  none,
  ...rest
}) => {
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [newOptions, setNewOptions] = useState(options);

  const { t } = useTranslation();

  const value = isMulti
    ? (selectedOptions?.length > 0 &&
        options?.filter((item: Option) => selectedOptions?.some((option: any) => option.value === item.value))) ||
      options?.filter((item: Option) => defaultValue?.some((option: any) => option === item.value))
    : options?.filter((item: Option) => (selectedOptions.value || defaultValue) === item.value);

  useEffect(() => {
    if (
      (selectedOptions?.length === options?.length ||
        (Array.isArray(selectedOptions) ? selectedOptions : [])?.some(
          (item: Option) => item.value === 'select_all_items',
        )) &&
      isMulti
    ) {
      setNewOptions([]);
    } else if (isMulti && !removeSelectAll && value?.length !== options?.length) {
      setNewOptions([{ value: 'select_all_items', label: t('GLOBAL.SELECT_ALL') }, ...(options || [])]);
    } else if (!isMulti && none && !required) {
      setNewOptions([{ value: ' ', label: t('GLOBAL.NONE') }, ...(options || [])]);
    } else {
      setNewOptions(options || []);
    }
  }, [selectedOptions, options, isMulti, t, value]);

  return (
    <Select
      filterOption={customFilterOption}
      placeholder={`${t('GLOBAL.SELECT')}...`}
      options={newOptions}
      name={name}
      // defaultValue={defaultValue}
      value={value?.length > 0 ? value : selectedOptions}
      onChange={(changedOptions: any) => {
        if (isMulti && changedOptions?.some((item: Option) => item.value === 'select_all_items')) {
          setSelectedOptions(newOptions.filter((item: Option) => item.value !== 'select_all_items'));
          onChange(newOptions?.filter((item: Option) => item.value !== 'select_all_items'));
          return;
        }
        setSelectedOptions(changedOptions);
        onChange(changedOptions);
      }}
      isMulti={isMulti}
      closeMenuOnSelect={isMulti ? false : true}
      {...rest}
    />
  );
};

export default SelectField;
