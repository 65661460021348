import React, { useEffect, useState } from 'react';
import cx from './Dropdown.module.scss';
import st from '../../style.module.scss';
import { FaAngleDown } from 'react-icons/fa';

//import { editAddress } from "../../redux_toolkit/reducer/menuCustomerApiReducer";

import icon4 from '../../images/icon-export.svg';
import { Box, ChakraProvider, VStack, Button, extendTheme } from '@chakra-ui/react';
import {
  Calendar,
  CalendarDefaultTheme,
  CalendarControls,
  CalendarPrevButton,
  CalendarNextButton,
  CalendarMonths,
  CalendarMonth,
  CalendarMonthName,
  CalendarWeek,
  CalendarDays,
} from '@uselessdev/datepicker';
import { addDays, subDays } from 'date-fns';
import { Dropdown } from 'react-bootstrap';
import icon2 from '../../images/icon-calendar.svg';
import icon1 from '../../images/icon-branch.svg';
import { useTranslation } from 'react-i18next';

interface OptionsFilter {
  value: any;
  label: string;
  key?: string;
  disabled?: boolean;
}

export const FilterDropdown = (props: any) => {
  const {
    setAllData,
    setOneItemRemoveData,
    options,
    postdata,
    defaultdata,
    defaultoptions = [],
    Setdefaultvalue,
    selectedValue = undefined,
    isSelectAll = false,
    valueDisable = false,
    loading = false,
    id
  } = props;

  const { t } = useTranslation();
  const [show, setshow] = useState(false);
  const [newoptions, setnewoptions] = useState<any[]>(options);
  const [searchString, setsearchString] = useState('');
  const [displayoptions, setdisplayoptions] = useState<OptionsFilter[]>(defaultoptions);

  useEffect(() => {
    if (!loading) {
      setdisplayoptions(defaultoptions);
    }
  }, [loading]);

  const opendropdownhandler = (e: any) => {
    e.stopPropagation();
    setnewoptions(options);
    setshow(!show);
  };

  const onclickhandler = (value: any) => {
    const findoptions: OptionsFilter = newoptions.find((elem: any) => elem.value == value)!;
    const final__options = newoptions.filter((elem: OptionsFilter) => {
      return elem.value != value;
    });
    // for add tag //
    const addtagoption = [...displayoptions, findoptions].map(items => items.value).join(',');
    if (postdata !== undefined) {
      postdata(addtagoption);
    }
    if (defaultdata !== undefined) {
      defaultdata(findoptions);
    }

    if (selectedValue) {
      selectedValue([...displayoptions, findoptions]);
    }
    // console.log(findoptions, "finaloptions");
    setdisplayoptions(prev => [...prev, findoptions]);
    setnewoptions(final__options);
    setsearchString('');
  };
  const onclickSelectAll = () => {
    const final__options = newoptions;
    // for add tag //
    const addtagoption = newoptions?.map(items => items.value).join(',');
    if (postdata !== undefined) {
      postdata(addtagoption);
    }
    if (defaultdata !== undefined) {
      defaultdata(final__options);
    }

    if (selectedValue) {
      selectedValue(final__options);
    }
    setdisplayoptions(final__options);
    setnewoptions(final__options);
    // setshow(false);
    setsearchString('');
  };

  const filteroptions = (event: any) => {
    const searchstring = event.target.value.toLocaleLowerCase();
    setsearchString(searchstring);
  };
  const removeSelectedOptions = (item: any) => {
    const finaloptions = displayoptions.filter((elem: OptionsFilter) => {
      return elem.value != item.value;
    });
    if (setOneItemRemoveData) {
      setOneItemRemoveData(item);
    }
    setnewoptions((prev: any) => {
      const removeDup = [...prev, item].filter((v, i, a) => a.findIndex(v2 => v2.value === v.value) === i);
      return removeDup;
    });

    if (setAllData !== undefined) setAllData(finaloptions);

    setdisplayoptions(finaloptions);
    if (postdata !== undefined) {
      postdata(finaloptions?.map((res: any) => res.value).join(','));
    }
    if (selectedValue) {
      selectedValue(finaloptions);
    }
  };
  let finaloptions = newoptions?.filter((elem: OptionsFilter) => {
    return elem.label?.toLocaleLowerCase()?.includes(searchString);
  });

  let final_finalOptions = finaloptions?.filter(function (val: OptionsFilter) {
    return displayoptions?.flat()?.every((item: any) => item?.value !== val?.value);
  });
  if (Setdefaultvalue !== undefined) Setdefaultvalue(displayoptions.filter(x => x != undefined));

  return (
    <div className={`${cx.filterDropdown} ${cx.multiSelect}`}>
      <button
      id={id}
        onClick={() => {
          setnewoptions(options);
          setshow(!show);
        }}
        disabled={valueDisable || loading}
      >
        {loading
          ? t('GLOBAL.LOADING') + ' ...'
          : displayoptions?.length > 0
          ? displayoptions?.flat()?.map((item: OptionsFilter, index: number) => (
              <span
                key={index}
                className={valueDisable === false ? `${cx.selectedOption}` : `${cx.selectedOptionDisabled}`}
                onClick={() => {
                  valueDisable === false && removeSelectedOptions(item);
                }}
              >
                {item?.label}
              </span>
            ))
          : t('GLOBAL.CHOOSE') + ' ...'}{' '}
        <FaAngleDown className={`${cx.dropdownIcon}`} />
      </button>
      {show && (
        <div className={`${cx.dropdownOption}`}>
          <input
            type='text'
            className='form-control'
            placeholder={t('GLOBAL.TYPE_SOMETHING_TO_START_SEARCHING')}
            onChange={filteroptions}
          />
          <ul>
            {isSelectAll && !searchString && final_finalOptions.length > 0 && (
              <li
                key='-1'
                onClick={() => {
                  onclickSelectAll();
                }}
              >
                {t('GLOBAL.SELECT_ALL')}
              </li>
            )}
            {final_finalOptions?.length > 0 ? (
              <>
                {final_finalOptions?.map((tag: any, index: any) => {
                  return (
                    <li
                      key={index}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        onclickhandler(tag.value);
                        props.handleCloseDropDownHandler && setshow(false);
                      }}
                    >
                      {tag.label}
                    </li>
                  );
                })}
              </>
            ) : (
              <>Nothing was found !</>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export const SingleFilterDropdown = ({
  options,
  onSelect,
  onSelectAllData,
  defaultoptions = [],
  loading = false,
}: any) => {
  const [show, setshow] = useState(false);
  const [newoptions, setnewoptions] = useState(options);
  const [searchString, setsearchString] = useState('');
  // const [displayoptions, setdisplayoptions] = useState<OptionsFilter[]>([]);
  const [displayoptions, setdisplayoptions] = useState<OptionsFilter[]>(defaultoptions);
  const { t } = useTranslation();

  useEffect(() => {
    if (!loading) {
      setdisplayoptions(defaultoptions);
    }
  }, [loading]);
  const opendropdownhandler = () => {
    setshow(!show);
  };
  const onclickhandler = (value: any) => {
    const findoptions: any = newoptions.find((elem: any) => elem.value == value)!;
    const final__options = newoptions.filter((elem: OptionsFilter) => {
      return elem.value != value;
    });
    setdisplayoptions([findoptions]);
    onSelect(findoptions?.value);
    if (onSelectAllData) onSelectAllData(findoptions && findoptions);

    setshow(false);
  };
  const filteroptions = (event: any) => {
    const searchstring = event.target.value.toLocaleLowerCase();

    setsearchString(searchstring);
  };

  const finaloptions = newoptions?.filter((elem: OptionsFilter) => {
    return elem.label.toLocaleLowerCase().includes(searchString);
  });

  return (
    <div className={`${cx.filterDropdown} ${cx.signleSelect}`}>
      <button onClick={opendropdownhandler} disabled={loading}>
        {loading
          ? t('GLOBAL.LOADING') + ' ...'
          : displayoptions?.length == 1
          ? displayoptions?.map((item: OptionsFilter, index: number) => (
              <span key={index} className={`${cx.singleselectedOption}`}>
                {item.label}
              </span>
            ))
          : t('GLOBAL.CHOOSE') + ' ...'}{' '}
        <FaAngleDown className={`${cx.dropdownIcon}`} />
      </button>
      {show && (
        <div className={`${cx.dropdownOption}`}>
          <input
            type='text'
            className='form-control'
            placeholder={t('GLOBAL.TYPE_SOMETHING_TO_START_SEARCHING')}
            onChange={filteroptions}
          />
          <ul>
            {finaloptions?.map((tag: any, index: any) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    onclickhandler(tag.value);
                  }}
                >
                  {tag.label}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

type BranchesProps = {
  options: any;
  selected: any;
  setSelected: (selected: any) => void;
  postdata?: any;
  defaultdata?: any;
  defaultoptions?: any;
  Setdefaultvalue?: any;
  isSelectAll?: any;
};

export const Branches = ({
  selected,
  setSelected,
  options,
  postdata,
  defaultdata,
  defaultoptions = [],
  Setdefaultvalue,
  isSelectAll = false,
}: BranchesProps) => {
  const [condition, setCondition] = useState(true);
  const customValueRenderer = (selected: any, options: any) => {
    return (
      <>
        <img src={icon4} className={`${st.icon}`} /> All Branches
      </>
    );
  };

  const [show, setshow] = useState(false);
  const [newoptions, setnewoptions] = useState<any[]>(options);
  const [searchString, setsearchString] = useState('');
  const [displayoptions, setdisplayoptions] = useState<OptionsFilter[]>(defaultoptions);

  const opendropdownhandler = () => {
    setnewoptions(options);
    setshow(!show);
  };
  const onclickhandler = (value: any) => {
    const findoptions: OptionsFilter = newoptions.find((elem: any) => elem.value == value)!;
    const final__options = newoptions.filter((elem: OptionsFilter) => {
      return elem.value != value;
    });
    // for add tag //
    const addtagoption = [...displayoptions, findoptions].map(items => items.value).join(',');
    postdata(addtagoption);
    if (defaultdata !== undefined) {
      defaultdata(findoptions);
    }
    setdisplayoptions(prev => [...prev, findoptions]);
    setnewoptions(final__options);
    setshow(false);
  };
  const onclickSelectAll = () => {
    const final__options = newoptions;
    // for add tag //
    const addtagoption = newoptions?.map(items => items.value).join(',');
    postdata(addtagoption);
    if (defaultdata !== undefined) {
      defaultdata(final__options);
    }
    setdisplayoptions(final__options);
    setnewoptions(final__options);
    setshow(false);
  };

  const filteroptions = (event: any) => {
    const searchstring = event.target.value.toLocaleLowerCase();
    setsearchString(searchstring);
  };
  const removeSelectedOptions = (item: any) => {
    const finaloptions = displayoptions.filter((elem: OptionsFilter) => {
      return elem.value != item.value;
    });

    setSelected(finaloptions);

    setnewoptions((prev: any) => {
      const removeDup = [...prev, item].filter((v, i, a) => a.findIndex(v2 => v2.value === v.value) === i);
      return removeDup;
    });

    setdisplayoptions(finaloptions);
  };
  let finaloptions = newoptions?.filter((elem: OptionsFilter) => {
    return elem.label?.toLocaleLowerCase()?.includes(searchString);
  });

  let final_finalOptions = finaloptions?.filter(function (val: OptionsFilter) {
    return displayoptions?.flat()?.every((item: any) => item?.value !== val?.value);
  });
  if (Setdefaultvalue !== undefined) Setdefaultvalue(displayoptions.filter(x => x != undefined));

  return (
    <div>
      <div className={`${cx.branchDropdown} ${cx.multiSelect}`}>
        <button onClick={opendropdownhandler}>
          {displayoptions?.length > 0 ? (
            displayoptions?.flat()?.map((item: OptionsFilter, index: number) => (
              <span
                key={index}
                className={`${cx.selectedOption}`}
                onClick={() => {
                  removeSelectedOptions(item);
                }}
              >
                {item?.label}
              </span>
            ))
          ) : (
            <>
              <img src={icon1} className={`${st.icon}`} /> {' All Branches'}
            </>
          )}{' '}
          <FaAngleDown className={`${cx.dropdownIcon}`} />
        </button>
        {show && (
          <div className={`${cx.dropdownOption}`}>
            <input
              type='text'
              className='form-control'
              placeholder='Type something to start searching'
              onChange={filteroptions}
            />
            <ul>
              {isSelectAll && final_finalOptions.length > 0 && (
                <li
                  key='-1'
                  onClick={() => {
                    onclickSelectAll();
                  }}
                >
                  Select All
                </li>
              )}
              {final_finalOptions?.map((tag: any, index: any) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      onclickhandler(tag.value);
                    }}
                  >
                    {tag.label}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

type CustomContentProps = {
  dates: any;
  setDates: (dates: any) => void;
};

export const theme = extendTheme(CalendarDefaultTheme, {
  components: {
    CalendarControl: {
      parts: ['controls'],
      baseStyle: {
        button: {
          height: 7,
          width: 7,
          minWidth: 6,
          padding: 0,
          rounded: 'full',
          color: '#fff',
          bgColor: 'pink.200',

          _hover: {
            bgColor: 'pink.400',
          },
        },
      },
    },

    CalendarDay: {
      baseStyle: {
        width: 35,
        height: 35,
        rounded: 'full',
        _hover: {
          bgColor: '#004fee',
          color: '#fff',
        },
      },
    },
  },
});

export const CustomContent = ({ dates, setDates }: CustomContentProps) => {
  const handleSelectDate = (dates: any) => setDates(dates);

  const today = new Date();

  const todays_date = () =>
    setDates({
      start: today,
      end: today,
    });

  const yesterday_date = () =>
    setDates({
      start: subDays(today, 1),
      end: subDays(today, 1),
    });

  const addSevenDays = () =>
    setDates({
      start: today,
      end: addDays(today, 7),
    });

  const subSevenDays = () =>
    setDates({
      start: subDays(today, 7),
      end: today,
    });
  const addMonthDays = () =>
    setDates({
      start: today,
      end: addDays(today, 30),
    });

  const subMonthDays = () =>
    setDates({
      start: subDays(today, 30),
      end: today,
    });

  return (
    <ChakraProvider theme={theme}>
      <Calendar value={dates} onSelectDate={handleSelectDate}>
        <VStack spacing={4} bgColor='gray.50' p={4} alignItems='stretch' borderEndRadius='md' flex={1}>
          <Button onClick={todays_date} colorScheme='white' color='black' size='xs'>
            Today
          </Button>
          <Button onClick={yesterday_date} colorScheme='white' color='black' size='xs'>
            Yesterday
          </Button>
          <Button onClick={addSevenDays} colorScheme='white' color='black' size='xs'>
            This Week
          </Button>
          <Button onClick={subSevenDays} colorScheme='white' color='black' size='xs'>
            Last Week
          </Button>
          <Button onClick={addMonthDays} colorScheme='white' color='black' size='xs'>
            This Month
          </Button>
          <Button onClick={subMonthDays} colorScheme='white' color='black' size='xs'>
            Last Month
          </Button>
        </VStack>
        <Box position='relative'>
          <CalendarControls>
            <CalendarPrevButton />
            <CalendarNextButton />
          </CalendarControls>

          <CalendarMonths>
            <CalendarMonth>
              <CalendarMonthName />
              <CalendarWeek />
              <CalendarDays />
            </CalendarMonth>
          </CalendarMonths>
        </Box>
      </Calendar>
    </ChakraProvider>
  );
};

export const BusinessDate = ({ dates, setDates }: CustomContentProps) => {
  return (
    <Dropdown className={`${st.calendarDropdown}`}>
      <Dropdown.Toggle id='dropdown-basic'>
        <img src={icon2} className={`${st.icon}`} />
        Business Date
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <CustomContent dates={dates} setDates={setDates} />
      </Dropdown.Menu>
    </Dropdown>
  );
};
