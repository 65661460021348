import React from 'react';

type Props = {
  permissionRequired: any;
  message?: string;
  children?: React.ReactNode;
};

const usePermission = () => {
  let scope: any;

  const _scope = localStorage.getItem('__scope__');

  if (_scope) {
    scope = JSON.parse(_scope);
  }
  if (scope) {
    return scope;
  } else {
    return null;
  }
};

const WithPermission = (props: Props) => {
  const { permissionRequired, message, children } = props;
  const permissions = usePermission();
  let auth: any;

  const _auth = localStorage.getItem('___data');

  if (_auth) {
    auth = JSON.parse(_auth);
    // console.log("--auth--", auth.is_owner);
  }

  if (auth.is_owner === 1) {
    return <>{children}</>;
  } else {
    return <>{permissions?.includes(permissionRequired) ? children : <h3>{message ? message : ''}</h3>}</>;
  }
};

export default WithPermission;
