import { configureStore } from '@reduxjs/toolkit';
import orderModalsReducer from '../reducer/orderModalsReducer';
import todaysOrderModalsReducer from '../reducer/todaysOrderModalsReducer';
import menuCategoriesApiReducer from '../reducer/menuCategoriesApiReducer';
import menuProductsApiReducer from '../reducer/menuProductsApiReducer';
import manageUserApiReducer from '../reducer/manageUserApiReducer';
import manageBranchApiReducer from '../reducer/manageBranchApiReducer';
import manageDeviceApiReducer from '../reducer/manageDeviceApiReducer';
import manageDiscountApiReducer from '../reducer/manageDiscountApiReducer';
import menuModifiersApiReducer from '../reducer/menuModifiersApiReducer';
import manageCouponApiReducer from '../reducer/manageCouponApiReducer';
import managePromotionsApiReducer from '../reducer/managePromotionsApiReducer';
import manageTimeEventApiReducer from '../reducer/manageTimeEventApiReducer';
import menuGiftCardReducer from '../reducer/menuGiftCardReducer';
import menuComboApiReducer from '../reducer/menuComboApiReducer';
import manageTaxGroupApiReducer from '../reducer/manageTaxGroupApiReducer';
import manageMorePaymentApiReducer from '../reducer/manageMorePaymentApiReducer';
import manageMoreDeliveryApiReducer from '../reducer/manageMoreDeliveryApiReducer';
import manageMoreTagsApiReducer from '../reducer/manageMoreTagsApiReducer';
import manageMoreChargesApiReducer from '../reducer/manageMoreChargesApiReducer';
import manageMorePriceTagsApiReducer from '../reducer/manageMorePriceTagsApiReducer';
import manageMoreKitchenFlowApiReducer from '../reducer/manageMoreKitchenFlowApiReducer';
import commonApiReducer from '../reducer/commonApiReducer';
import inventoryItemsApiReducer from '../reducer/inventoryItemsApiReducer';
import inventoryPurchaseOrderApiReducer from '../reducer/inventoryPurchaseOrderApiReducer';
import inventoryPurchaseApiReducer from '../reducer/inventoryPurchaseApiReducer';
import inventoryTransferOrderApiReducer from '../reducer/inventoryTransferOrderApiReducer';
import inventoryTransferApiReducer from '../reducer/inventoryTransferApiReducer';
import inventorySupplierApiReducer from '../reducer/inventorySupplierApiReducer';
import inventoryProductionApiReducer from '../reducer/inventoryProductionApiReducer';
import modifierOptionApiReducer from '../reducer/modifierOptionApiReducer';
import menuCustomerApiReducer from '../reducer/menuCustomerApiReducer';
import menuGroupApiReducer from '../reducer/menuGroupApiReducer';
import manageRoleApiReducer from '../reducer/manageRoleApiReducer';
import ManageMoreSettingApiReducer from '../reducer/ManageMoreSettingApiReducer';
import RegisterBussinessTypeAndLocationReducer from '../reducer/RegisterBussinessTypeAndLocationReducer';
import registerFormReducer from '../reducer/RegisterReducer';
import handelAddRemoveShowQtyResons from '../reducer/handelAddRemoveShowQtyResons';
import LoginReducer from '../reducer/LoginReducer';
import manageMoreReservationApiReducer from '../reducer/manageMoreReservationApiReducer';
import inventoryCategoriesApiReducer from '../reducer/inventoryCategoriesApiReducer';
import warehouseApiReducer from '../reducer/warehouseApiReducer';
import inventoryMoreCostAdjustmentApiReducer from '../reducer/inventoryMoreCostAdjustmentApiReducer';
import inventoryCountSheetApiReducer from '../reducer/inventoryCountSheetApiReducer';
import inventoryQuantityAdjustmentApiReducer from '../reducer/inventoryQuantityAdjustmentApiReducer';
import inventorySpotCheckApiReducer from '../reducer/inventorySpotCheckApiReducer';
import inventoryCountReducer from '../reducer/inventoryCountReducer';
import inventoryOrderTransactionApiReducer from '../reducer/inventoryOrderTransactionApiReducer';
import ReportsInventoryControlApiReducer from '../reducer/ReportsInventoryControlApiReducer';
import ReportsInventoryLevelsApiReducer from '../reducer/ReportsInventoryLevelsApiReducer';
import ReportsInventoryHistoryApiReducer from '../reducer/ReportsInventoryHistoryApiReducer';
import ReportsPurchaseOrdersApiReducer from '../reducer/ReportsPurchaseOrdersApiReducer';
import ReportsPurchasingApiReducer from '../reducer/ReportsPurchasingApiReducer';
import ReportsTransfersApiReducer from '../reducer/ReportsTransfersApiReducer';
import ReportsTransferOrderApiReducer from '../reducer/ReportsTransferOrderApiReducer';
import ReportsCostAdjustmenthistoryApiReducer from '../reducer/ReportsCostAdjustmenthistoryApiReducer';
import manageCommanApiReducer from '../reducer/manageCommanApiReducer';
import dashboardApiReducer from '../reducer/dashboardApiReducer';
import GlobalSlice from './../../store/global/global.slice';
import ReportsSalesApiReducer from '../reducer/ReportsSalesApiReducer';
import ReportsPaymentsApiReducer from '../reducer/ReportsPaymentsApiReducer';
import ReportsBusinessApiReducer from '../reducer/ReportsBusinessApiReducer';
import MarketPlaceApiReducer from '../reducer/MarketPlaceApiReducer';
import deliveryPartenersApiReducer from '../reducer/deliveryPartenersApiReducer';
import ForgetPasswordReducer from '../reducer/ForgetPasswordReducer';
import manageMoreSubscriptionApiReducer from '../reducer/manageMoreSubscriptionApiReducer';
import SalesOrdersApiReducer from '../reducer/SalesOrdersApiReducer';
import franchisingApiReducer from '../reducer/franchisingApiReducer';
import onBoardingRideReducer from '../reducer/onBoardingRideReducer';
import announcementApiReducer from '../reducer/announcementApiReducer';

export const store = configureStore({
  reducer: {
    commonApiReducer: commonApiReducer,
    menuModifiersApiReducer: menuModifiersApiReducer,
    orderModalsReducer: orderModalsReducer,
    todaysOrderModalsReducer: todaysOrderModalsReducer,
    inventoryItemsApiReducer: inventoryItemsApiReducer,
    inventorySpotCheckApiReducer: inventorySpotCheckApiReducer,
    inventoryProductionApiReducer: inventoryProductionApiReducer,
    inventorySupplierApiReducer: inventorySupplierApiReducer,
    inventoryTransferApiReducer: inventoryTransferApiReducer,
    inventoryTransferOrderApiReducer: inventoryTransferOrderApiReducer,
    inventoryPurchaseOrderApiReducer: inventoryPurchaseOrderApiReducer,
    inventoryCountSheetApiReducer: inventoryCountSheetApiReducer,
    warehouseApiReducer: warehouseApiReducer,
    inventoryCategoriesApiReducer: inventoryCategoriesApiReducer,
    menuCustomerApiReducer: menuCustomerApiReducer,
    menuCategoriesApiReducer: menuCategoriesApiReducer,
    menuProductsApiReducer: menuProductsApiReducer,
    manageUserApiReducer: manageUserApiReducer,
    manageBranchApiReducer: manageBranchApiReducer,
    manageDeviceApiReducer: manageDeviceApiReducer,
    manageDiscountApiReducer: manageDiscountApiReducer,
    manageCouponApiReducer: manageCouponApiReducer,
    managePromotionsApiReducer: managePromotionsApiReducer,
    manageTimeEventApiReducer: manageTimeEventApiReducer,
    manageRoleApiReducer: manageRoleApiReducer,
    menuGiftCardReducer: menuGiftCardReducer,
    menuComboApiReducer: menuComboApiReducer,
    menuGroupApiReducer: menuGroupApiReducer,
    manageTaxGroupApiReducer: manageTaxGroupApiReducer,
    manageMorePaymentApiReducer: manageMorePaymentApiReducer,
    manageMoreDeliveryApiReducer: manageMoreDeliveryApiReducer,
    manageMoreTagsApiReducer: manageMoreTagsApiReducer,
    manageMoreChargesApiReducer: manageMoreChargesApiReducer,
    manageMorePriceTagsApiReducer: manageMorePriceTagsApiReducer,
    manageMoreKitchenFlowApiReducer: manageMoreKitchenFlowApiReducer,
    modifierOptionApiReducer: modifierOptionApiReducer,
    ManageMoreSettingApiReducer: ManageMoreSettingApiReducer,
    RegisterBussinessTypeAndLocationReducer: RegisterBussinessTypeAndLocationReducer,
    registerFormReducer: registerFormReducer,
    handelAddRemoveShowQtyResons: handelAddRemoveShowQtyResons,
    LoginReducer: LoginReducer,
    inventoryMoreCostAdjustmentApiReducer: inventoryMoreCostAdjustmentApiReducer,
    inventoryQuantityAdjustmentApiReducer: inventoryQuantityAdjustmentApiReducer,
    inventoryOrderTransactionApiReducer: inventoryOrderTransactionApiReducer,
    ReportsInventoryControlApiReducer: ReportsInventoryControlApiReducer,
    inventoryCountReducer: inventoryCountReducer,
    inventoryPurchaseApiReducer: inventoryPurchaseApiReducer,
    ReportsInventoryLevelsApiReducer: ReportsInventoryLevelsApiReducer,
    ReportsInventoryHistoryApiReducer: ReportsInventoryHistoryApiReducer,
    ReportsPurchaseOrdersApiReducer: ReportsPurchaseOrdersApiReducer,
    ReportsPurchasingApiReducer: ReportsPurchasingApiReducer,
    ReportsTransfersApiReducer: ReportsTransfersApiReducer,
    ReportsTransferOrderApiReducer: ReportsTransferOrderApiReducer,
    ReportsCostAdjustmenthistoryApiReducer: ReportsCostAdjustmenthistoryApiReducer,
    manageCommanApiReducer: manageCommanApiReducer,
    dashboardApiReducer: dashboardApiReducer,
    app: GlobalSlice,
    ReportsSalesApiReducer: ReportsSalesApiReducer,
    ReportsPaymentsApiReducer: ReportsPaymentsApiReducer,
    ReportsBusinessApiReducer: ReportsBusinessApiReducer,
    MarketPlaceApiReducer: MarketPlaceApiReducer,
    deliveryPartenersApiReducer: deliveryPartenersApiReducer,
    ForgetPasswordReducer: ForgetPasswordReducer,
    manageMoreReservationApiReducer: manageMoreReservationApiReducer,
    manageMoreSubscriptionApiReducer: manageMoreSubscriptionApiReducer,
    franchisingApiReducer: franchisingApiReducer,
    SalesOrdersApiReducer: SalesOrdersApiReducer,
    onBoardingRideReducer: onBoardingRideReducer,
    announcementApiReducer: announcementApiReducer,
  },
});
