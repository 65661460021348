import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch6, fetch5, fetch3, fetch9, fetch10 } from '../../Apis/commonApis';
import { useNavigate } from 'react-router-dom';
import { notify } from '../../constants/notifyconstants';

export let inventorySpotCheckBranches: any = [];
export let itemsForInventorySpotCheck: any = [];
export let createNewInventorySpotCheck: any;
export let inventorySpotCheckList: any = [];

export const fetchBranches: any = createAsyncThunk('branches/fetchBranches', async () => {
  const response = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/branches`);
  inventorySpotCheckBranches = response.data;
  return response;
});

export const getItemsForInventorySpotCheck: any = createAsyncThunk('getItemsForInventorySpotCheck', async () => {
  const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items?par_page=99999`);
  itemsForInventorySpotCheck = response.data;

  return response;
});

export const inventorySpotCheckDuplicate: any = createAsyncThunk('inventorySpotCheckDuplicate', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory_spot_check/duplicate/${body.id}`);
  return result;
});
export const getInventorySpotCheckById: any = createAsyncThunk('getInventorySpotCheckById', async body => {
  const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory_spot_check/${body}`);
  return response;
});

export const getTagsForInventorySpotCheck: any = createAsyncThunk('getTagsForInventorySpotCheck', async () => {
  const response = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags/get_tag_by_type?type=InventoryItem`);
  return response;
});
export const filterInventorySpotCheck: any = createAsyncThunk('filterInventorySpotCheck', async body => {
  const response = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/inventory_spot_check/filterInventorySpotCheck`,
    body,
  );
  inventorySpotCheckList = response.data;
  return response;
});

export const getInventorySpotCheckList: any = createAsyncThunk('getInventorySpotCheckList', async (body: any) => {
  const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory_spot_check`, body);
  // inventorySpotCheckList = response.data;
  return response;
});

export const createInventorySpotCheck: any = createAsyncThunk('createInventorySpotCheck', async body => {
  const response = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/inventory_spot_check`, body);

  return response;
});

export const inventorySpotCheckDetails: any = createAsyncThunk('inventorySpotCheckDetails', async (id: any) => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/inventory/inventory_spot_check/${id}`, 'get');

  // singleInventoryWarehouseItem = result.data.items;
  return result;
});

export const deleteInventorySpotCheck: any = createAsyncThunk('deleteInventorySpotCheck', async (id: any) => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/inventory/inventory_spot_check/${id}`, 'delete');
  return result;
});

export const updateInventorySpotCheck: any = createAsyncThunk('updateInventorySpotCheck', async (body: any) => {
  const result = await fetch9(
    `${process.env.REACT_APP_BASEURL}/inventory/inventory_spot_check/${body.id}`,
    body.editBody,
  );
  return result;
});

export const submitInventorySpotCheck: any = createAsyncThunk('submitInventorySpotCheck', async (id: any) => {
  const result = await fetch3(
    `${process.env.REACT_APP_BASEURL}/inventory/inventory_spot_check/submitSpotCheck/${id}`,
    'get',
  );
  return result;
});

export const addInventorySpotCheckItem: any = createAsyncThunk('addInventorySpotCheckItem', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/inventory_spot_check/addItems/${body.id}`, {
    items: body.items,
  });
  return result;
});
export const addInventorySpotCheckItemByTag: any = createAsyncThunk(
  'addInventorySpotCheckItemByTag',
  async (body: any) => {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/inventory_spot_check/addItemsTag/${body.id}`,
      { tags: body.tags },
    );
    return result;
  },
);

export const updateInventorySpotCheckItem: any = createAsyncThunk('updateInventorySpotCheckItem', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/inventory_spot_check/editItems/${body.id}`,
    body?.items,
  );
  return result;
});
export const deleteInventorySpotCheckItem: any = createAsyncThunk('deleteInventorySpotCheckItem', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/inventory_spot_check/deleteItem/${body.id}`, {
    items: [body.items],
  });
  return result;
});

const initialState: any = {
  data: {},
  progressLoading: false,
  spotCheckList: {},
  itemsForInventorySpotCheck: {},
  newInventorySpotCheckId: null,
  singleInventorySpotCheckDetailsCount: 0,
  inventorySpotCheckListCount: 0,
  inventorySpotCheckBranchCount: 0,
  inventorySpotCheckItemsCount: 0,
  inventorySpotCheckBranchesId: null,
  singleInventorySpotCheckDetails: {},
  singleInventorySpotCheckDetailsLoading: false,
  inventoryTags: {},
  inventoryTagsCount: 0,
  loading: false,
  isFiltered: false,
  error: null,
};

const inventorySpotCheckReducer: any = createSlice({
  name: 'inventorySpotCheckReducer',
  initialState,
  reducers: {
    branchId: (state, action) => {
      state.inventorySpotCheckBranchesId = action.payload;
    },

    restSpotcheckId: (state, action) => {
      state.newInventorySpotCheckId = null;
    },
    changeFilterStatus: (state, action) => {
      state.isFiltered = action.payload;
    },
    restSpotcheckDetails: (state, action) => {
      state.singleInventorySpotCheckDetails = {};
    },
    setProgressLoading: (state, action) => {
      state.progressLoading = action.payload;
    },
  },
  extraReducers: builder => {
    builder

      .addCase(fetchBranches.fulfilled, (state: any, action: any) => {
        state.data = action.payload;
        state.loading = false;
        state.inventorySpotCheckBranchCount += 1;
        if (action.payload.errors) notify(action.payload.message);
        state.error = null;
      })
      .addCase(getItemsForInventorySpotCheck.fulfilled, (state: any, action: any) => {
        state.inventorySpotCheckItemsCount += 1;
        if (action.payload.errors) notify(action.payload.message);
        state.loading = false;
        state.error = null;
      })
      .addCase(getTagsForInventorySpotCheck.fulfilled, (state: any, action: any) => {
        // state.itemsForInventorySpotCheck = action.payload;
        state.inventoryTags = action.payload.data;
        state.inventoryTagsCount += 1;

        if (action.payload.errors) notify(action.payload.message);
        state.loading = false;
        state.error = null;
      })
      .addCase(createInventorySpotCheck.fulfilled, (state: any, action: any) => {
        state.newInventorySpotCheckId = action.payload.data[0]?.id;

        if (action.payload.status) notify(action.payload.message, 'success');

        if (action.payload.errors) notify(action.payload.message);

        state.loading = false;
        state.error = null;
      })
      .addCase(createInventorySpotCheck.rejected, (state: any, action: any) => {


        notify('The seleted tags have no items linked', 'error');

      })
      .addCase(deleteInventorySpotCheck.fulfilled, (state: any, action: any) => {
        if (action.payload.status) notify("Delete Spot Check Successfully", 'success');

        if (action.payload.errors) notify(action.payload.message);

        state.loading = false;
        state.error = null;
      })
      .addCase(updateInventorySpotCheck.fulfilled, (state: any, action: any) => {
        if (action.payload.status) notify(action.payload.message, 'success');

        if (action.payload.errors) notify(action.payload.message);

        state.loading = false;
        state.error = null;
      })
      .addCase(submitInventorySpotCheck.fulfilled, (state: any, action: any) => {
        if (action.payload.status) notify(action.payload.message, 'success');

        if (action.payload.errors) notify(action.payload.message);

        state.loading = false;
        state.error = null;
      })
      .addCase(addInventorySpotCheckItem.fulfilled, (state: any, action: any) => {
        if (action.payload.status) notify(action.payload.message, 'success');

        if (action.payload.errors) notify(action.payload.message);

        state.loading = false;
        state.error = null;
      })
      .addCase(updateInventorySpotCheckItem.fulfilled, (state: any, action: any) => {
        if (action.payload.status) notify(action.payload.message, 'success');

        if (action.payload.errors) notify(action.payload.message);

        state.loading = false;
        state.error = null;
      })
      .addCase(inventorySpotCheckDetails.fulfilled, (state: any, action: any) => {
        state.singleInventorySpotCheckDetailsCount += 1;
        state.singleInventorySpotCheckDetails = action.payload.data;
        state.singleInventorySpotCheckDetailsLoading = false;
        if (action.payload.errors) notify(action.payload.message);
        state.loading = false;
        state.error = null;
      })
      .addCase(inventorySpotCheckDetails.pending, (state: any, action: any) => {
        state.singleInventorySpotCheckDetailsLoading = true;

      })
      .addCase(getInventorySpotCheckList.fulfilled, (state: any, action: any) => {
        state.spotCheckList = action.payload;
        state.inventorySpotCheckListCount += 1;
        if (action.payload.errors) notify(action.payload.message);
        state.loading = false;
        state.error = null;
      })
      .addCase(getInventorySpotCheckList.pending, (state: any, action: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(filterInventorySpotCheck.fulfilled, (state: any, action: any) => {
        state.spotCheckList = action.payload;
        state.inventorySpotCheckListCount += 1;
        if (action.payload.errors) notify(action.payload.message);
        state.loading = false;
        state.error = null;
      });
  },
});

// Export the slice reducer
export const { branchId, restSpotcheckId, restSpotcheckDetails, changeFilterStatus, setProgressLoading } =
  inventorySpotCheckReducer.actions;

export default inventorySpotCheckReducer.reducer;
