import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetch5, fetch6, fetch10, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';
import { RoundNum } from '../../utils/functions';

const initialState = {
  loading: false,
  filterStatus: false,
  error: null,
  filterBody: null,
  inventoryControlReportsList: {},
  getInventoryControlbranchesData: [],
  getInventoryControlcategoriesData: [],
  getInventoryControlitemsData: [],
  getInventoryControlTagsData: [],
};

export const getInventoryControlReportsList: any = createAsyncThunk('getInventoryControlReportsList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/reports/inventory-report/inventory_control`, body);
  return result;
});
export const getInventoryControlbranches: any = createAsyncThunk('getInventoryControlbranches', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/branches`, body);
  return result.data;
});
export const getInventoryControlcategories: any = createAsyncThunk(
  'getInventoryControlcategories',
  async (body: any) => {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory-categories`, body);
    return result.data;
  },
);
export const getInventoryControlitems: any = createAsyncThunk('getInventoryControlitems', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items`, body);
  return result.data;
});
export const getInventoryControlTags: any = createAsyncThunk('getInventoryControlTags', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);
  return result.data;
});

const ReportsInventoryControlApiReducer: any = createSlice({
  name: 'ReportsInventoryControlApiReducer',
  initialState,
  reducers: {
    changeFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    setFilter: (state, action) => {
      state.filterBody = action.payload;
    },
  },
  extraReducers: {
    [getInventoryControlReportsList.pending]: state => {
      state.loading = true;
    },
    [getInventoryControlReportsList.fulfilled]: (state, action) => {
      state.loading = false;
      state.inventoryControlReportsList = {
        data: action.payload.data.map((obj: { [key: string]: any }) => {
          Object.entries(obj).forEach(([key, value]: any) => {
            obj[key] = !isNaN(+value) ? RoundNum(+value) : value;
          });

          return obj;
        }),
        ...action.payload,
      };
    },
    [getInventoryControlReportsList.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryControlbranches.pending]: state => {
      state.loading = true;
    },
    [getInventoryControlbranches.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryControlbranchesData = action.payload;
    },
    [getInventoryControlbranches.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryControlcategories.pending]: state => {
      state.loading = true;
    },
    [getInventoryControlcategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryControlcategoriesData = action.payload;
    },
    [getInventoryControlcategories.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryControlitems.pending]: state => {
      state.loading = true;
    },
    [getInventoryControlitems.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryControlitemsData = action.payload;
    },
    [getInventoryControlitems.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventoryControlTags.pending]: state => {
      state.loading = true;
    },
    [getInventoryControlTags.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryControlTagsData = action.payload;
    },
    [getInventoryControlTags.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});
export const { changeFilterStatus, setFilter } = ReportsInventoryControlApiReducer.actions;
export default ReportsInventoryControlApiReducer.reducer;
