import React from 'react';
import st from '../../style.module.scss';
import mp from './MarketPlace.module.scss';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalState } from '../../store/global/global.slice';
import { BsFillHandThumbsUpFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { NormalBtn } from '../../components/buttons/NormalBtn';
import Odoo from '../../images/odoo.png';
import Taker from '../../images/taker.png';
import Zatca from '../../images/ZATCA.svg';

const MarketplaceServices = [
  {
    name: 'Marketplace',
    services: [
      {
        name: 'Odoo',
        img: Odoo,
        desc: 'sync sales and inventory data with Dot sync sales',
        to: '/marketplace/odoo/connections',
      },
      {
        name: 'ZATCA',
        img: Zatca,
        desc: 'Integrate with Zakat, Tax, and Customs Authority',
        to: '/marketplace/zatca/connections',
      },
      {
        name: 'Taker',
        img: Taker,
        desc: 'sync sales and inventory data with Dot sync sales',
        to: '/marketplace/taker/connections',
      },
    ],
  },
  // {
  //   name: 'Delivery Apps',
  //   services: [
  //     {
  //       name: 'Taker',
  //       img: Taker,
  //       desc: 'sync sales and inventory data with Dot sync sales',
  //       to: '/marketplace/taker/connections',
  //     },
  //   ],
  // },
];

export default function Marketplace() {
  const navigate = useNavigate();

  //Check the Language and Change the style
  const language = useSelector((state: { app: GlobalState }) => state.app?.language);

  return (
    <>
      <ToastContainer />
      <section className={`${language === 'en' ? `${st.pageWrapper}` : `${st.pageWrapperAr}`}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.pageTitleRow}`}>
            <div className={`${st.rowTitleLeft}`}>
              <h5>Dot Marketplace</h5>
            </div>
            <div className={`${st.rowTitleRight}`}>
              <button className={`btn`}>
                <span>
                  <BsFillHandThumbsUpFill />
                  <span className=' ms-2'>Authorized Application</span>
                </span>
              </button>
            </div>
          </div>
        </div>
        {MarketplaceServices?.map(section => (
          <div className={`${st.pageWrapperInside}`}>
            <p className={`${mp.headertext}`}>{section?.name}</p>
            <Row>
              {section?.services?.map(service => (
                <Col lg={3}>
                  <Card className={`${mp.customcard}`}>
                    <div className={`${mp.customcardinner}`}>
                      <img className={`${mp.customimage}`} src={service.img} alt='' />
                      <h5 className={`${mp.customtitle}`}>{service.name}</h5>
                      <h5 className={`${mp.customdescription}`}>{service.desc}</h5>
                    </div>
                    <NormalBtn
                      onClick={() => {
                        navigate(service.to);
                      }}
                    >
                      Connect
                    </NormalBtn>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        ))}
      </section>
    </>
  );
}
