import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch10, fetch2, fetch3, fetch5, fetch6, fetch8, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  loading: false,
  chargesListCount: 0,
  chargeOneCount: 0,
  addChargesCount: 0,
  deleteChargesCount: 0,
  loadingStatus: 0,
};
export let chargesFilter = {
  restaurent_id: '',
  type: '',
};
export let chargesListItems: any;
export let chargesListItemsDeleted: any;
export let chargesListRestore: any;
export let chargeOne: any;
export let branchArray: any[] = [];
export let editChargesData: any = {
  branches: [],
  id: '',
  is_auto_applied: false,
  is_calculated_using_subtotal: true,
  is_open_charge: '',
  type: '',
  name: '',
  name_localized: '',
  order_types: [],
  tax_group_id: '',
  item_type: '1',
  value: '',
  associate_to_all_branches: false,
  _method: 'PUT',
};

//get list of charges
export const chargesList: any = createAsyncThunk('chargesList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/charges`, body);
  chargesListItems = result.data;
  return result;
});

//deletedList
export const chargesListDeleted: any = createAsyncThunk('chargesList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/charges`, body);
  chargesListItemsDeleted = result.data;
  console.log(chargesListItemsDeleted);

  return result;
});
//restore
export const restoreCharges: any = createAsyncThunk('restoreCharges', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/charges/${body.id}/restore`);
  chargesListRestore = result.data;
  console.log(body, chargesListRestore);
  return result;
});

//get one
export const chargesGetOne: any = createAsyncThunk('chargesGetOne', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/charges/${body}`, body);
  chargeOne = result.data;
  console.log(body, chargeOne);
  return result;
});
//create
export const addCharges: any = createAsyncThunk('addCharges', async body => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/charges`, body);
    console.log();

    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

//edit
export const editCharges: any = createAsyncThunk('editCharges', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/charges/${body.id}`, body);

  return result;
});
//delete
export const deleteCharges: any = createAsyncThunk('deleteCharges', async body => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/manage/charges/${body}`, 'delete');
  return result;
});

const manageMoreChargesSlice: any = createSlice({
  name: 'manageMoreChargesSlice',
  initialState,
  reducers: {
    editChargesFunc(state = initialState, action) {
      editChargesData.id = action.payload.id;
      editChargesData.name = action.payload.name;
      editChargesData.name_localized = action.payload.name_localized;
      editChargesData.is_open_charge = action.payload.is_open_charge;
      editChargesData.type = action.payload.type;
      editChargesData.value = action.payload.value;
      editChargesData.order_types = action.payload.order_types;

      editChargesData.branches = action.payload.branches;
      editChargesData.is_auto_applied = action.payload.is_auto_applied;
      editChargesData.is_calculated_using_subtotal = action.payload.is_calculated_using_subtotal;
      editChargesData.associate_to_all_branches = action.payload.associate_to_all_branches;
    },
    setshowLoading(state = initialState) {
      state.loadingStatus += 1;
    },
    branchesFunc(state = initialState, action) {
      branchArray = action.payload === 'all' ? [] : action.payload;
    },
    showLoadingOff(state = initialState) {
      state.loadingStatus += 1;
    },
  },
  extraReducers: {
    [chargesList.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.chargesListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [chargesList.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.error;

      notify(action.error.message);
    },
    [chargesGetOne.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.chargeOneCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [addCharges.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.addChargesCount += 1;
      state.loadingStatus += 1;
      notify('Add Charges Successfully', 'success');

      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [deleteCharges.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.deleteChargesCount += 1;
      // state.loadingStatus += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
      notify('Charge deleted successfully', 'success');
    },
    [deleteCharges.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.error;

      notify(action.error.message);
    },

    [restoreCharges.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;

      // state.loadingStatus += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
      notify('Charge restored successfully', 'success');
    },
    [restoreCharges.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.error;

      notify(action.error.message);
    },
    [editCharges.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;

      // state.loadingStatus += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
      notify('Charge edited successfully', 'success');
    },
    [editCharges.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.error;

      notify(action.error.message);
    },
  },
});

export const { editChargesFunc, setshowLoading, branchesFunc, showLoadingOff } = manageMoreChargesSlice.actions;
export default manageMoreChargesSlice.reducer;
