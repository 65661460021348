import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { fetch10, fetch2, fetch22, fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';
import axios from 'axios';

export const showCouponsList: any = createAsyncThunk('showCouponsList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/coupons`, body);
    return response;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    throw error;
  }
});
export const showdiscountsList: any = createAsyncThunk('showdiscountsList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/discounts`, body);
    return response.data;
  } catch (error: any) {
    throw error;
  }
});

export const showCouponsdetails: any = createAsyncThunk('showCouponsdetails', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/coupons/${body}`);
    return response.data;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    throw error;
  }
});
export const createCoupons: any = createAsyncThunk('createCoupons', async body => {
  try {
    const response: any = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/coupons`, body);
    notify('Coupon created successfully', 'success');
    return response.data;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    throw error;
  }
});
export const couponsUpdate: any = createAsyncThunk('couponsUpdate', async ({ id, body }: any) => {
  try {
    const response: any = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/coupons/${id}`, body);
    notify('Coupon updated successfully', 'success');
    return response.data;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    throw error;
  }
});
export const couponsDelete: any = createAsyncThunk('couponsDelete', async body => {
  try {
    const response: any = await fetch10(`${process.env.REACT_APP_BASEURL}/manage/coupons/${body}`);
    notify('Coupon deleted successfully', 'success');
    return response.data;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    throw error;
  }
});
export const couponsrestore: any = createAsyncThunk('couponsrestore', async body => {
  try {
    const response: any = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/coupons/${body}/restore`);
    notify('Coupon restored successfully', 'success');
    return response.data;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    throw error;
  }
});
const manageCouponSlice: any = createSlice({
  name: 'manageCoupon',
  initialState: {
    delResons: null,
    addNewResons: null,
    showAllshowCouponsList: null,
    showAllshowCouponsListDetails: null,
    showdiscountsListData: null,
    isLoadingDataTable: false,
    settypeFilterData: null,
    isLoading: false,
    isLoadingo: false,
    pageCount: 0,
  },
  reducers: {
    settypeFilter: (state, action) => {
      state.settypeFilterData = action.payload;
    },
    setisLoadingo: (state, action) => {
      state.isLoadingo = action.payload;
    },
    setPageCount(state, action) {
      state.pageCount = action.payload;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(showCouponsList.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.isLoadingDataTable = false;
        state.showAllshowCouponsList = action.payload;
      })
      .addCase(showCouponsList.pending, (state: any, action) => {
        state.isLoading = true;
        state.isLoadingDataTable = true;
      })
      .addCase(showCouponsList.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isLoadingDataTable = false;
      })
      .addCase(showCouponsdetails.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.showAllshowCouponsListDetails = action.payload;
        state.isLoadingo = false;
      })
      .addCase(showCouponsdetails.pending, (state: any, action) => {
        state.isLoading = true;
      })
      .addCase(showdiscountsList.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.showdiscountsListData = action.payload;
      });
  },
});

export const { settypeFilter, setisLoadingo, setPageCount } = manageCouponSlice.actions;
export default manageCouponSlice.reducer;
