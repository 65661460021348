import React, { useEffect, useState, lazy, Suspense } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { orderStatus } from './redux_toolkit/reducer/todaysOrderModalsReducer';
import { filterValue } from './redux_toolkit/reducer/menuCategoriesApiReducer';
import AuthGuardRoute from './store/authGuard';
import '../src/style.module.scss';
import TopBarProgress from 'react-topbar-progress-indicator';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
// import { toast, ToastContainer } from 'react-toastify';
import toast, { Toaster } from 'react-hot-toast';
import ReportsSalesPage from './pages/ReportsSalesPage/ReportsSalesPage';
import ReportsInventory from './pages/ReportsInventory/ReportsInventory';
import ReportsBusiness from './pages/ReportsBusiness/ReportsBusiness';
import { useTranslation } from 'react-i18next';
import { GlobalState } from './store/global/global.slice';
import Marketplace from './pages/Marketplace/Marketplace';
import MarketplaceDetails from './pages/MarketplaceDetails/MarketplaceDetails';
import ForgetPassword from './pages/ForgetPassword/ForgetPassword';
import { POSPERMISSIONS } from './utils/Roles';
import PermissionDenied from './pages/Reportssales/pages/PermissionDenied';
import { getCookie } from './utils/functions';
import { requestPermission } from './firebase/firebase';

import RegisterSuccess from './pages/RegisterPage/RegisterSuccess';
import UserProfile from './pages/UserProfile/UserProfile';

const Menucategories = React.lazy(() => import('./pages/Menucategories/Menucategories'));
const MenuProduct = React.lazy(() => import('./pages/Menuproducts/MenuProduct'));
const MenuCombos = React.lazy(() => import('./pages/Menucombos/Menucombos'));
const Menugiftcards = React.lazy(() => import('./pages/Menugiftcards/Menugiftcards'));
const MenuModifiers = React.lazy(() => import('./pages/Menumodifiers/Menumodifiers'));
const MenuModifierOptions = React.lazy(() => import('./pages/Menumodifieroptions/Menumodifieroptions'));
const MenuGroups = React.lazy(() => import('./pages/Menugroups/Menugroups'));
const CostadjustmentView = React.lazy(() => import('./pages/Costadjustmentdetails/CostadjustmentView'));
const EndSubscription = React.lazy(() => import('./pages/EndSubscription/EndSubscription'));
const Costadjustment = React.lazy(() => import('./pages/Costadjustment/Costadjustment'));
const Costadjustmentdetails = React.lazy(() => import('./pages/Costadjustmentdetails/Costadjustmentdetails'));
const Countsheet = React.lazy(() => import('./pages/Countsheet/Countsheet'));
const Customer = React.lazy(() => import('./pages/Customer/Customer'));
const Deliveryparteners = React.lazy(() => import('./pages/DeliveryApp/DeliveryApp'));
const DeliveryAppDetails = React.lazy(() => import('./pages/DeliveryAppDetails/DeliveryAppDetails'));
const Franchise = React.lazy(() => import('./pages/Franchise/Franchise'));
const FranchiseDetails = React.lazy(() => import('./pages/FranchiseDetails/FranchiseDetails'));
const RequestsFromFranchise = React.lazy(() => import('./pages/RequestsFromFranchise/RequestsFromFranchise'));
const RequestsFromFranchiseDetails = React.lazy(
  () => import('./pages/RequeestsFromFranchiseDetails/RequestsFromFranchiseDetails'),
);

const Customerdetails = React.lazy(() => import('./pages/Customerdetails/Customerdetails'));
const Inventorycategories = React.lazy(() => import('./pages/Inventorycategories/Inventorycategories'));
const Inventorycount = React.lazy(() => import('./pages/Inventorycount/Inventorycount'));
const Inventorycountdetails = React.lazy(() => import('./pages/Inventorycountdetails/Inventorycountdetails'));
const Inventorymore = React.lazy(() => import('./pages/Inventorymore/Inventorymore'));
const Items = React.lazy(() => import('./pages/Items/Items'));
const Itemsdetails = React.lazy(() => import('./pages/Itemsdetails/Itemsdetails'));
// const Login = React.lazy(() => import('./pages/Login/Login'));
const LoginCopy = React.lazy(() => import('./pages/Login/LoginCopy'));
// const Menucombosdetails = React.lazy(() => import('./pages/Menucombosdetails/Menucombosdetails'));
const Menucombosdetails = React.lazy(() => import('./pages/Menucombosdetails/Menucombosdetails'));
// const Menugiftcardsdetails = React.lazy(() => import('./pages/Menugiftcardsdetails/Menugiftcardsdetails'));
const Menugiftcardsdetails = React.lazy(() => import('./pages/Menugiftcardsdetails/Menugiftcardsdetails'));
// const Menugroupsdetails = React.lazy(() => import('./pages/Menugroupsdetails/Menugroupsdetails'));
const Menugroupsdetails = React.lazy(() => import('./pages/Menugroupsdetails/Menugroupsdetails'));

const Menumodifieroptionsdetails = React.lazy(
  () => import('./pages/Menumodifieroptionsdetails/Menumodifieroptionsdetails'),
);
// const Menuproductsdetails = React.lazy(() => import('./pages/Menuproductsdetails/Menuproductsdetails'));
const Menuproductsdetails = React.lazy(() => import('./pages/Menuproductsdetails/Menuproductsdetails'));
const Menusortcategories = React.lazy(() => import('./pages/Menusortcategories/Menusortcategories'));
const Menusortgroup = React.lazy(() => import('./pages/Menusortgroup/Menusortgroup'));
const Menusortmodifier = React.lazy(() => import('./pages/Menusortmodifiers/Menusortmodifier'));
const Navigation = React.lazy(() => import('./pages/Navigation/Navigation'));
const Orders = React.lazy(() => import('./pages/Orders/Orders'));
const Ordersdetails = React.lazy(() => import('./pages/Ordersdetails/Ordersdetails'));
const Ordertransaction = React.lazy(() => import('./pages/Ordertransaction/Ordertransaction'));
const OrdertransactiondetailsCopy = React.lazy(() => import('./pages/Ordertransactiondetails/Ordertransactiondetails'));
const Production = React.lazy(() => import('./pages/Production/Production'));
const Productiondetails = React.lazy(() => import('./pages/Productiondetails/Productiondetails'));
const Purchaseorders = React.lazy(() => import('./pages/Purchaseorders/Purchaseorders'));
// const Purchaseordersdetails = React.lazy(() => import('./pages/Purchaseordersdetails/Purchaseordersdetails'));
const PurchaseordersdetailsCopy = React.lazy(() => import('./pages/Purchaseordersdetails/Purchaseordersdetails'));
const PurchaseordersView = React.lazy(() => import('./pages/Purchaseorders/PurchaseOrderView'));
const Purchasing = React.lazy(() => import('./pages/Purchasing/Purchasing'));
const Purchasingdetails = React.lazy(() => import('./pages/Purchasingdetails/Purchasingdetails'));
const Quantityadjustment = React.lazy(() => import('./pages/Quantityadjustment/Quantityadjustment'));
const Quantityadjustmentdetails = React.lazy(
  () => import('./pages/Quantityadjustmentdetails/Quantityadjustmentdetails'),
);
const QuantityadjustmentView = React.lazy(() => import('./pages/Quantityadjustmentdetails/QuantityadjustmentView'));
const Reportsanalysis = React.lazy(() => import('./pages/Reportsanalysis/Reportsanalysis'));
const Reportsbranchestrend = React.lazy(() => import('./pages/Reportsbranchestrend/Reportsbranchestrend'));
const Reportscostanalysis = React.lazy(() => import('./pages/Reportscostanalysis/Reportscostanalysis'));
// const Reportsinventorylevels = React.lazy(() => import('./pages/Reportsinventorylevels/Reportsinventorylevels'));
const Reportsinventorylevels = React.lazy(() => import('./pages/Reportsinventorylevels/Reportsinventorylevels'));
const Reportsmenuengineering = React.lazy(() => import('./pages/Reportsmenuengineering/Reportsmenuengineering'));
// const Reportspayments = React.lazy(() => import('./pages/Reportspayments/Reportspayments'));
const Reportspayments = React.lazy(() => import('./pages/Reportspayments/Reportspayments'));
// const BranchReport = React.lazy(() => import('./pages/Reportssales/pages/BranchReport'));
const BranchReport = React.lazy(() => import('./pages/Reportssales/pages/BranchReport'));
// const CashierReport = React.lazy(() => import('./pages/Reportssales/pages/CashierReport'));
const CashierReport = React.lazy(() => import('./pages/Reportssales/pages/CashierReport'));
// const CategoryReport = React.lazy(() => import('./pages/Reportssales/pages/CategoryReport'));
const CategoryReport = React.lazy(() => import('./pages/Reportssales/pages/CategoryReport'));
// const ComboReport = React.lazy(() => import('./pages/Reportssales/pages/ComboReport'));
const ComboReport = React.lazy(() => import('./pages/Reportssales/pages/ComboReport'));
// const CouponReport = React.lazy(() => import('./pages/Reportssales/pages/CouponReport'));
const CouponReport = React.lazy(() => import('./pages/Reportssales/pages/CouponReport'));
const ChargeReport = React.lazy(() => import('./pages/Reportssales/pages/ChargeReport'));
// const CreatorReport = React.lazy(() => import('./pages/Reportssales/pages/CreatorReport'));
const CreatorReport = React.lazy(() => import('./pages/Reportssales/pages/CreatorReport'));
// const CustomerReport = React.lazy(() => import('./pages/Reportssales/pages/CustomerReport'));
const CustomerReport = React.lazy(() => import('./pages/Reportssales/pages/CustomerReport'));
const DeliveryZoneReport = React.lazy(() => import('./pages/Reportssales/pages/DeliveryZoneReport'));
const DriverReport = React.lazy(() => import('./pages/Reportssales/pages/DriverReport'));
const DiscountReport = React.lazy(() => import('./pages/Reportssales/pages/DiscountReport'));
// const ModifierOptionReport = React.lazy(() => import('./pages/Reportssales/pages/ModiferOptionReport'));
const ModifierOptionReport = React.lazy(() => import('./pages/Reportssales/pages/ModiferOptionReport'));

// const OrderSourceReport= React.lazy(() => import('./pages/Reportssales/pages/OrderSourcesReport'));
const OrderSourceReport = React.lazy(() => import('./pages/Reportssales/pages/OrderSourcesReport'));
// const OrderTypeReport = React.lazy(() => import('./pages/Reportssales/pages/OrderTypeReport'));
const OrderTypeReport = React.lazy(() => import('./pages/Reportssales/pages/OrderTypeReport'));
// const ProductReport = React.lazy(() => import('./pages/Reportssales/pages/ProductReport'));
const ProductReport = React.lazy(() => import('./pages/Reportssales/pages/ProductReport'));
const PromotionReport = React.lazy(() => import('./pages/Reportssales/pages/PromotionReport'));
// const BranchTagsReport = React.lazy(() => import('./pages/Reportssales/pages/BranchTagsReport'));
const BranchTagsReport = React.lazy(() => import('./pages/Reportssales/pages/BranchTagsReport'));
// const ProductTagsReport = React.lazy(() => import('./pages/Reportssales/pages/ProductTagsReport'));
const ProductTagsReport = React.lazy(() => import('./pages/Reportssales/pages/ProductTagsReport'));
// const OrderTagsReport = React.lazy(() => import('./pages/Reportssales/pages/OrderTagsReport'));
const OrderTagsReport = React.lazy(() => import('./pages/Reportssales/pages/OrderTagsReport'));
// const SectionReport = React.lazy(() => import('./pages/Reportssales/pages/SectionReport'));
const SectionReport = React.lazy(() => import('./pages/Reportssales/pages/SectionReport'));
// const TableReport = React.lazy(() => import('./pages/Reportssales/pages/TableReport'));
const TableReport = React.lazy(() => import('./pages/Reportssales/pages/TableReport'));
// const TimeEventsReport = React.lazy(() => import('./pages/Reportssales/pages/TimeEventReport'));
const TimeEventsReport = React.lazy(() => import('./pages/Reportssales/pages/TimeEventReport'));
// const ApplicationReport = React.lazy(() => import('./pages/Reportssales/pages/ApplicationReport'));
const ApplicationReport = React.lazy(() => import('./pages/Reportssales/pages/ApplicationReport'));
const Spotcheck = React.lazy(() => import('./pages/Spotcheck/Spotcheck'));
// const SalesOrders = React.lazy(() => import('./pages/SalesOrders/SalesOrders'));
const SalesOrdersCopy = React.lazy(() => import('./pages/SalesOrders/SalesOrders'));
// const SalesOrderDetails = React.lazy(() => import('./pages/SalesOrderDetails/SalesOrderDetails'));
const SalesOrderDetailsCopy = React.lazy(() => import('./pages/SalesOrderDetails/SalesOrderDetails'));
const Spotcheckdetails = React.lazy(() => import('./pages/Spotcheckdetails/Spotcheckdetails'));
const Suppliers = React.lazy(() => import('./pages/Suppliers/Suppliers'));
const Suppliersdetails = React.lazy(() => import('./pages/Suppliersdetails/Suppliersdetails'));
const Todayorders = React.lazy(() => import('./pages/Todayorders/Todayorders'));
const Transferorders = React.lazy(() => import('./pages/Transferorders/Transferorders'));
const Transferordersdetails = React.lazy(() => import('./pages/Transferordersdetails/Transferordersdetails'));
const Transfers = React.lazy(() => import('./pages/Transfers/Transfers'));
const Transfersdetails = React.lazy(() => import('./pages/Transfersdetails/Transfersdetails'));
const Warehouses = React.lazy(() => import('./pages/Warehouses/Warehouses'));
const Warehousesdetails = React.lazy(() => import('./pages/Warehousesdetails/Warehousesdetails'));
const ManageUsers = lazy(() => import('./pages/ManageUsers/ManageUser'));
const ManageUserDetail = lazy(() => import('./pages/ManageUserDetail/ManageUserDetail'));
const ManageRoles = lazy(() => import('./pages/ManageRoles/ManageRoles'));
const Managebranches = lazy(() => import('./pages/Managebranches/Managebranches'));
const ManageBranchesDetails = lazy(() => import('./pages/ManageBranchesDetails/ManageBranchesDetails'));
const ManageDevices = lazy(() => import('./pages/ManageDevices/ManageDevices'));
const ManageDeviceDetails = lazy(() => import('./pages/ManageDeviceDetails/ManageDeviceDetails'));
// const ManageMoreDeliveryZones = React.lazy(() => import('./pages/ManageMoreDeliveryZones/ManageMoreDeliveryZones'));
const ManageMoreDeliveryZonesCopy = React.lazy(() => import('./pages/ManageMoreDeliveryZones/ManageMoreDeliveryZones'));
// const ManageMoreTags = React.lazy(() => import('./pages/ManageMoreTags/ManageMoreTags'));
const ManageMoreTagsCopy = React.lazy(() => import('./pages/ManageMoreTags/ManageMoreTags'));

const ManageMoreChargesCopy = React.lazy(() => import('./pages/ManageMoreCharges/ManageMoreCharges'));
// const ManageMorePriceTags = React.lazy(() => import('./pages/ManageMorePriceTags/ManageMorePriceTags'));
const ManageMorePriceTagsCopy = React.lazy(() => import('./pages/ManageMorePriceTags/ManageMorePriceTags'));

const ManageMorePriceTagDetailsPage = React.lazy(
  () => import('./pages/ManageMorePriceTagDetailsPage/ManageMorePriceTagDetailsPage'),
);
const ManageMoreKitchenFlow = React.lazy(() => import('./pages/ManageMoreKitchenFlow/ManageMoreKitchenFlow'));
const ManageMoreKitchenFlowDetails = React.lazy(
  () => import('./pages/ManageMoreKitchenFlowDetails/ManageMoreKitchenFlowDetails'),
);
const Dashboard = React.lazy(() => import('./pages/Dashboard/Dashboard'));
// const InventoryControl = React.lazy(() => import('./pages/ReportInventoryControl/InventoryControl'));
const InventoryControl = React.lazy(() => import('./pages/ReportInventoryControl/InventoryControl'));

// const ReportInventoryHistory = React.lazy(() => import('./pages/ReportInventoryHistory/ReportInventoryHistory'));
const ReportInventoryHistory = React.lazy(() => import('./pages/ReportInventoryHistory/ReportInventoryHistory'));

// const ReportMorePurchasing = React.lazy(() => import('./pages/ReportMorePurchasing/ReportMorePurchasing'));
const ReportMorePurchasing = React.lazy(() => import('./pages/ReportMorePurchasing/ReportMorePurchasing'));
const ReportMoreModiferOption = React.lazy(() => import('./pages/ReportMoreModiferOption/ReportMoreModiferOption'));
const ReportMoreProductCost = React.lazy(() => import('./pages/ReportMoreProductCost/ReportMoreProductCost'));
// const ReportMorePurchaseOrder = React.lazy(() => import('./pages/ReportMorePurchaseOrder/ReportMorePurchaseOrder'));
const ReportMorePurchaseOrder = React.lazy(() => import('./pages/ReportMorePurchaseOrder/ReportMorePurchaseOrder'));

const ReportMore = React.lazy(() => import('./pages/ReportsMore/ReportMore'));
// const ReportMoreTaxes = React.lazy(() => import('./pages/ReportMoreTaxes/ReportMoreTaxes'));
const ReportMoreTaxes = React.lazy(() => import('./pages/ReportMoreTaxes/ReportMoreTaxes'));
// const ReportMoreTips = React.lazy(() => import('./pages/ReportMoreTips/ReportMoreTips'));
const ReportMoreTips = React.lazy(() => import('./pages/ReportMoreTips/ReportMoreTips'));
// const ReportMoreGiftCard = React.lazy(() => import('./pages/ReportMoreGiftCard/ReportMoreGiftCard'));
const ReportMoreGiftCard = React.lazy(() => import('./pages/ReportMoreGiftCard/ReportMoreGiftCard'));

// const ReportMoreBusinessDays = React.lazy(() => import('./pages/ReportMoreBusinessDays/ReportMoreBusinessDays'));
const ReportMoreBusinessDays = React.lazy(() => import('./pages/ReportMoreBusinessDays/ReportMoreBusinessDays'));
// const ReportMoreTransfer = React.lazy(() => import('./pages/ReportMoreTransfer/ReportMoreTransfer'));
const ReportMoreTransfer = React.lazy(() => import('./pages/ReportMoreTransfer/ReportMoreTransfer'));
// const ReportMoreTransferOrder = React.lazy(() => import('./pages/ReportMoreTransfer Orders/ReportMoreTransferOrder'));
const ReportMoreTransferOrder = React.lazy(() => import('./pages/ReportMoreTransfer Orders/ReportMoreTransferOrder'));
// const ReportMoreShifts = React.lazy(() => import('./pages/ReportMoreShifts/ReportMoreShifts'));
const ReportMoreShifts = React.lazy(() => import('./pages/ReportMoreShifts/ReportMoreShifts'));
// const ReportMoreDrawerOperation = React.lazy(
//   () => import('./pages/ReportMoreDrawerOperation/ReportMoreDrawerOperation'),
// );
const ReportMoreDrawerOperation = React.lazy(
  () => import('./pages/ReportMoreDrawerOperation/ReportMoreDrawerOperation'),
);
// const ReportMoreTills = React.lazy(() => import('./pages/ReportMoreTills/ReportMoreTills'));
const ReportMoreTills = React.lazy(() => import('./pages/ReportMoreTills/ReportMoreTills'));
const Tilldetails = React.lazy(() => import('./pages/TillsDetails/Tilldetails'));
// const ReportsMoreVoidAndReturns = React.lazy(() => import('./pages/ReportMoreVoidAndReturns/ReportMoreVoidAndReturns'));
const ReportMoreVoidAndReturns = React.lazy(() => import('./pages/ReportMoreVoidAndReturns/ReportMoreVoidAndReturns'));
// const ReportMoreCostAdjustmentHistory = React.lazy(
//   () => import('./pages/ReportMoreCostAdjustmentHistory/ReportMoreCostAdjustmentHistory'),
// );
const ReportMoreCostAdjustmentHistory = React.lazy(
  () => import('./pages/ReportMoreCostAdjustmentHistory/ReportMoreCostAdjustmentHistory'),
);
const ReportMoreInventoryItemsCost = React.lazy(
  () => import('./pages/ReportMoreInventoryItemsCost/ReportMoreInventoryItemsCost'),
);
const ManageMoreSettingDisplayApp = React.lazy(
  () => import('./pages/ManageMoreSettingDisplayApp/ManageMoreSettingDisplayApp'),
);
const ManageMoreSettingPaymentIntegrations = React.lazy(
  () => import('./pages/ManageMoreSettingPaymentIntegrations/ManageMoreSettingPaymentIntegrations'),
);
const ManageMoreSettingSmsProviders = React.lazy(
  () => import('./pages/ManageMoreSettingSmsProviders/ManageMoreSettingSmsProviders'),
);
// const ManageDiscounts = lazy(() => import('./pages/ManageDiscounts/ManageDiscounts'));
const ManageDiscounts = lazy(() => import('./pages/ManageDiscounts/ManageDiscounts'));
// const ManageDiscountDetails = lazy(() => import('./pages/ManageDiscountDetails/ManageDiscountDetails'));
const ManageDiscountDetails = lazy(() => import('./pages/ManageDiscountDetails/ManageDiscountDetails'));
// const ManageCoupons = lazy(() => import('./pages/ManageCoupons/ManageCoupons'));
const ManageCouponsCopy = lazy(() => import('./pages/ManageCoupons/ManageCoupons'));
// const ManageCouponsDetails = lazy(() => import('./pages/ManageCouponsDetails/ManageCouponsDetails'));
const ManageCouponsDetailsCopy = lazy(() => import('./pages/ManageCouponsDetails/ManageCouponsDetails'));
// const ManagePromotions = lazy(() => import('./pages/ManagePromotions/ManagePromotions'));
const ManagePromotionsCopy = lazy(() => import('./pages/ManagePromotions/ManagePromotionsCopy'));
// const ManagePromotionsDetails = lazy(() => import('./pages/ManagePromotionsDetails/ManagePromotionsDetails'));
const ManagePromotionsDetailsCopy = lazy(() => import('./pages/ManagePromotionsDetails/ManagePromotionsDetailsCopy'));
// const ManageAddPromotions = lazy(() => import('./pages/ManageAddPromotions/ManageAddPromotions'));
const ManageAddPromotionsCopy = lazy(() => import('./pages/ManageAddPromotions/ManageAddPromotions'));
const ManageTimedEventsDetails = lazy(() => import('./pages/ManageTimedEventsDetails/ManageTimedEventsDetails'));

const ManageTimedEvents = lazy(() => import('./pages/ManageTimedEvents/ManageTimedEvents'));
const ManageMore = lazy(() => import('./pages/ManageMore/ManageMore'));
const ManageMoreTaxes = lazy(() => import('./pages/ManageMoreTaxesAndGroup/ManageMoreTaxes'));
const ManageMorePaymentMethod = lazy(() => import('./pages/ManageMorePaymentMethods/ManageMorePaymentMethod'));
const ManageMoreSettingsGeneral = React.lazy(
  () => import('./pages/ManageMoreSettingsGeneral/ManageMoreSettingsGeneral'),
);
const ManageMoreSettingsLoyalty = React.lazy(() => import('./pages/ManageMoreSettingLoyalty/ManageMoreSettingLoyalty'));
const ManageMoreSubscription = React.lazy(() => import('./pages/ManageMoreSubscription/ManageMoreSubscription'));
const ManageMoreSettingsCashierApp = React.lazy(
  () => import('./pages/ManageMoreSettingsCashierApp/ManageMoreSettingsCashierApp'),
);
const ManageMoreSettingsWebCashier = React.lazy(
  () => import('./pages/ManageMoreSettingWebCashier/ManageMoreSettingWebCashier'),
);
const ManageMoreSettingsCallCenter = React.lazy(
  () => import('./pages/ManageMoreSettingsCallCenter/ManageMoreSettingsCallCenter'),
);
const ManageMoreOnlineOrderingSetting = React.lazy(
  () => import('./pages/ManageMoreOnlineOrderingSetting/ManageMoreOnlineOrderingSetting'),
);
const ManageMoreReservations = React.lazy(() => import('./pages/ManageMoreReservation/ManageMoreReservations'));
const ManageMoreSettingReceipt = React.lazy(() => import('./pages/ManageMoreSettingReceipt/ManageMoreSettingReceipt'));
const ManageMoreSettingInventoryTransactions = React.lazy(
  () => import('./pages/ManageMoreSettingInventoryTransactions/ManageMoreSettingInventoryTransactions'),
);
const ManageMoreSettings = React.lazy(() => import('./pages/ManageMoreSettings/ManageMoreSettings'));
const ManageMoreReasons = React.lazy(() => import('./pages/ManageMoreReasons/ManageMoreReasons'));

const NewCallCenterOrder = React.lazy(() => import('./pages/NewCallCenterOrder/NewCallCenterOrder'));
const Countsheetdetails = React.lazy(() => import('./pages/Countsheetdetails/Countsheetdetails'));
const ManageBranchesSection = React.lazy(() => import('./pages/ManageBranchSection/ManageBranchesSection'));
// const RegisterPage = React.lazy(() => import('./pages/RegisterPage/RegisterPage'));
const RegisterPageCopy = React.lazy(() => import('./pages/RegisterPage/RegisterPageCopy'));

const MarketplaceConnections = React.lazy(() => import('./pages/MarketplaceConnections/MarketplaceConnections'));

const MarketplaceZatcaConnections = React.lazy(
  () => import('./pages/MarketplaceZatcaConnections/MarketplaceZatcaConnections'),
);

const MarketplaceDeliveryAppsConnections = React.lazy(
  () => import('./pages/MarketplaceDeliveryAppsConnections/MarketplaceDeliveryAppsConnections'),
);

// const MarketplaceConfigPage = React.lazy(() => import('./pages/MarketplaceConfig/MarketplaceConfig'));
const MarketplaceConfig = React.lazy(() => import('./pages/MarketplaceConfig/MarketplaceConfig'));
const MarketplaceDeliveryAppConfig = React.lazy(
  () => import('./pages/MarketplaceDeliveryAppConfig/MarketplaceDeliveryAppConfig'),
);
const MarketplaceZatcaConfig = React.lazy(() => import('./pages/MarketplaceZatcaConfig/MarketplaceZatcaConfig'));

const AnnouncementPage = React.lazy(() => import('./pages/Announcement/AnnouncementPage'));
const AnnouncementDetails = React.lazy(() => import('./pages/Announcement/AnnouncementDetails'));

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
];

const App = () => {
  const [progress, setProgress] = useState(false);
  const [prevLoc, setPrevLoc] = useState({});
  const location = useLocation();
  const [resetKey, setResetKey] = useState(0);
  const { t } = useTranslation();
  const token = localStorage.getItem('xPmOtQST');
  const fcmToken = localStorage.getItem('fcmToken');
  let navigate = useNavigate();
  // Define font families for LTR and RTL directions
  TopBarProgress.config({
    barColors: {
      '0': '#5498FD',
      '1.0': '#5498FD',
    },
    shadowBlur: 5,
  });

  // --------------- selectors ----------------
  const filterorderstatus = useSelector((state: any) => state.orderModalsReducer.toastify);
  const TodayDeclinestatus = useSelector((state: any) => state.todaysOrderModalsReducer.toastify);
  const TodayOrder = useSelector((state: any) => state.todaysOrderModalsReducer.orderToast);
  const currentLanguageCode = useSelector((state: { app: GlobalState }) => state.app?.language);
  const currentLanguage = languages.find(l => l.code === currentLanguageCode);
  const newnavRouteKey: any = useSelector((state: any) => state.menuModifiersApiReducer.newnavRouteKey);

  //---------- firebase --------------

  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  useEffect(() => {
    if (token) {
      requestPermission();
    }
  }, [token, fcmToken]);

  // --------------- side effects ----------------
  useEffect(() => {
    if (token && location.pathname == '/') {
      filterValue.type = 'All';
      navigate('/dashboard');
    }

    if (
      !token &&
      location.pathname !== '/register' &&
      location.pathname !== '/forget-password' &&
      location.pathname !== '/registration-success'
    ) {
      navigate('/login');
    }
  }, [token]);

  useEffect(() => {
    document.body.dir = currentLanguage?.dir || 'ltr';
  }, [currentLanguage, t]);
  useEffect(() => {
    if (filterorderstatus || TodayOrder) toast('Applied SuccessFully');
  }, [filterorderstatus, TodayOrder]);

  useEffect(() => {
    if (TodayDeclinestatus) toast(orderStatus);
  }, [TodayDeclinestatus]);

  useEffect(() => {
    setPrevLoc(location);
    setProgress(true);
    window.scrollTo(0, 0); //Go to top on every page load
  }, [location]);

  useEffect(() => {
    setProgress(false);
  }, [prevLoc]);

  useEffect(() => {
    setResetKey(prevKey => prevKey + 1);
  }, [newnavRouteKey]);

  //--------------------- Routes ---------------------
  return (
    <>
      {progress && <TopBarProgress />}
      {/* <ToastContainer /> */}
      <Toaster position='top-right' toastOptions={{ duration: 3000 }} />
      <Suspense fallback={<TopBarProgress />}>
        <Routes>
          <Route path='newcallcenter' element={<NewCallCenterOrder />} />
          <Route path='/register' element={<RegisterPageCopy />} />
          <Route path='/registration-success' element={<RegisterSuccess />} />
          <Route path='/forget-password' element={<ForgetPassword />} />
          {/* <Route path='/login' element={<Login />} /> */}
          <Route path='/login' element={<LoginCopy />} />

          <Route path='/' element={<Navigation />}>
            <Route path='/' element={<AuthGuardRoute />}>
              <Route path='/unauthorized' element={<PermissionDenied />} />
              <Route path='/user-profile' element={<UserProfile />} />
              <Route path='/end-subscription' element={<EndSubscription />} />
              <Route
                path='franchising/announcement'
                element={
                  <AuthGuardRoute
                    permissionRequired={
                      POSPERMISSIONS.canViewGeneralDashboard ||
                      POSPERMISSIONS.canViewBranchesDashboard ||
                      POSPERMISSIONS.canViewInventoryDashboard ||
                      POSPERMISSIONS.canViewCallCenterDashboard
                    }
                  />
                }
              >
                <Route index element={<AnnouncementPage />} />
                <Route path=':id' element={<AnnouncementDetails />} />
              </Route>

              <Route
                path='dashboard'
                element={
                  <AuthGuardRoute
                    permissionRequired={
                      POSPERMISSIONS.canViewGeneralDashboard ||
                      POSPERMISSIONS.canViewBranchesDashboard ||
                      POSPERMISSIONS.canViewInventoryDashboard ||
                      POSPERMISSIONS.canViewCallCenterDashboard
                    }
                  />
                }
              >
                <Route path='/dashboard' element={<Dashboard />} />
              </Route>
              <Route path='orders' element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canViewOrders} />}>
                <Route path='/orders' element={<Orders />} />
                <Route path='/orders/:id' element={<Ordersdetails />} />
                {/* <Route path='/orders/:id' element={<OrdersdetailsCopy />} /> */}
              </Route>
              {/* <Route path="today-orders" element={<Todayorders />} />
              <Route path="today-orders/:id" element={<Ordersdetails />} /> */}
              <Route path='customers' element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canViewCustomers} />}>
                <Route path='/customers' element={<Customer />} />
                <Route path='/customers/:id' element={<Customerdetails />} />
              </Route>
              <Route
                path='delivery-parteners'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canViewDeliveryPartners} />}
              >
                <Route path='/delivery-parteners' element={<Deliveryparteners />} />
                <Route path='/delivery-parteners/:id' element={<DeliveryAppDetails />} />
              </Route>

              <Route
                path='franchising'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canViewDeliveryPartners} />}
              >
                <Route path='/franchising/franchise' element={<Franchise />} />
                <Route path='/franchising/franchise/:id' element={<FranchiseDetails />} />
              </Route>

              <Route
                path='franchising'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canViewDeliveryPartners} />}
              >
                <Route path='/franchising/requests-from-franchise' element={<RequestsFromFranchise />} />
                <Route path='/franchising/requests-from-franchise/:id' element={<RequestsFromFranchiseDetails />} />
              </Route>

              <Route
                path='inventory/items'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canViewInventoryItems} />}
              >
                <Route path='/inventory/items' element={<Items />} />
                <Route path='/inventory/items/:id' element={<Itemsdetails />} />
              </Route>
              <Route
                path='purchase/purchase-orders'
                element={
                  <AuthGuardRoute
                    permissionRequired={
                      POSPERMISSIONS.canManagePODrafts ||
                      POSPERMISSIONS.canManagePOPosted ||
                      POSPERMISSIONS.canManagePOApproved ||
                      POSPERMISSIONS.canReceivePOApproved
                    }
                  />
                }
              >
                <Route path='/purchase/purchase-orders' element={<Purchaseorders />} />
                {/* <Route path='/purchase/purchase-orders/:id' element={<Purchaseordersdetails />} /> */}
                <Route path='/purchase/purchase-orders/:id' element={<PurchaseordersdetailsCopy />} />
                <Route path='/purchase/purchase-orders/view' element={<PurchaseordersView />} />
              </Route>
              {/* <Route
                path="marketplace/connections"

                element={
                  <AuthGuardRoute
                    permissionRequired={POSPERMISSIONS.canManageMarketplace}
                  />
                }
              > */}
              <Route path='marketplace' element={<Marketplace />} />
              {/* <Route path='/marketplace/connections' element={<MarketplaceConnectionPage />} /> */}
              <Route path='/marketplace/odoo/connections' element={<MarketplaceConnections />} />
              <Route path='/marketplace/taker/connections' element={<MarketplaceDeliveryAppsConnections />} />
              <Route path='/marketplace/zatca/connections' element={<MarketplaceZatcaConnections />} />
              {/* <Route path='/marketplace/connection/:id' element={<MarketplaceConfigPage />} /> */}
              <Route path='/marketplace/odoo/connection/:id' element={<MarketplaceConfig />} />
              <Route path='/marketplace/taker/connection/:id' element={<MarketplaceDeliveryAppConfig />} />
              <Route path='/marketplace/zatca/connection/:id' element={<MarketplaceZatcaConfig />} />
              <Route path='/marketplace/odoo/connection/:id/configuration/:id' element={<MarketplaceDetails />} />
              <Route path='/marketplace/odoo/connection/details' element={<MarketplaceDetails />} />

              {/* </Route> */}
              <Route
                path='inventory/transfer-orders'
                element={
                  <AuthGuardRoute
                    permissionRequired={POSPERMISSIONS.canManageTODrafts || POSPERMISSIONS.canManageTOApproved}
                  />
                }
              >
                <Route path='/inventory/transfer-orders' element={<Transferorders />} />
                <Route path='/inventory/transfer-orders/:id' element={<Transferordersdetails />} />
              </Route>
              <Route
                path='purchase/suppliers'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canViewSuppliers} />}
              >
                <Route path='/purchase/suppliers' element={<Suppliers />} />

                <Route path='/purchase/suppliers/:id' element={<Suppliersdetails />} />
              </Route>
              <Route
                path='inventory/inventory-count'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageInventoryCountDrafts} />}
              >
                <Route path='/inventory/inventory-count' element={<Inventorycount />} />
                <Route path='/inventory/inventory-count/:id' element={<Inventorycountdetails />} />
              </Route>
              <Route
                path='purchase/receive-order'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManagePurchasingDrafts} />}
              >
                <Route path='/purchase/receive-order' element={<Purchasing />} />
                <Route path='/purchase/receive-order/:id' element={<Purchasingdetails />} />
              </Route>
              {/* <Route
                path="inventory/sales-orders"
                element={
                  <AuthGuardRoute
                    permissionRequired={
                      POSPERMISSIONS.canManagePurchasingDrafts
                    }
                  />
                }
              >
                <Route path="/inventory/sales-orders" element={<SalesOrders />} />
                <Route
                  path="/inventory/sales-orders/:id"
                  element={<SalesOrderDetails />}
                />
              </Route> */}
              {/* <Route path='/inventory/sales-orders' element={<SalesOrders />} /> */}
              <Route path='/franchising/sales-orders' element={<SalesOrdersCopy />} />
              {/* <Route path='/inventory/sales-orders/:id' element={<SalesOrderDetails />} /> */}
              <Route path='/franchising/sales-orders/:id' element={<SalesOrderDetailsCopy />} />
              <Route
                path='inventory/transfers'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageTransfersDrafts} />}
              >
                <Route path='/inventory/transfers' element={<Transfers />} />
                <Route path='/inventory/transfers/:id' element={<Transfersdetails />} />
              </Route>
              <Route
                path='inventory/production'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageProductionDrafts} />}
              >
                <Route path='/inventory/production' element={<Production />} />
                <Route path='/inventory/production/:id' element={<Productiondetails />} />
              </Route>
              <Route path='inventory/more' element={<Inventorymore />} />
              <Route
                path='inventory/quantity-adjustment'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageQuantityAdjustmentDrafts} />}
              >
                <Route path='/inventory/quantity-adjustment' element={<Quantityadjustment />} />
                <Route path='/inventory/quantity-adjustment/:id' element={<Quantityadjustmentdetails />} />
                <Route path='/inventory/quantity-adjustment/view' element={<QuantityadjustmentView />} />
              </Route>
              <Route
                path='inventory/cost-adjustment'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageCostAdjustmentDrafts} />}
              >
                <Route path='/inventory/cost-adjustment' element={<Costadjustment />} />
                <Route path='/inventory/cost-adjustment/:id' element={<Costadjustmentdetails />} />
                <Route path='/inventory/cost-adjustment/view' element={<CostadjustmentView />} />
              </Route>
              <Route
                path='inventory/order-transaction'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canViewOrderTransactions} />}
              >
                <Route path='/inventory/order-transaction' element={<Ordertransaction />} />
                <Route path='/inventory/order-transaction/:id' element={<OrdertransactiondetailsCopy />} />
              </Route>
              <Route path='inventory/inventory-categories' element={<Inventorycategories />} />
              <Route path='inventory/inventory-warehouses' element={<Warehouses />} />
              <Route path='inventory/inventory-warehouses/:id' element={<Warehousesdetails />} />
              <Route
                path='inventory/inventory-spotcheck'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageSpotCheckDrafts} />}
              >
                <Route path='/inventory/inventory-spotcheck' element={<Spotcheck />} />
                <Route path='/inventory/inventory-spotcheck/:id' element={<Spotcheckdetails />} />
              </Route>
              <Route path='inventory/inventory-countsheet' element={<Countsheet />} />
              <Route path='inventory/inventory-countsheet/:id' element={<Countsheetdetails />} />
              <Route
                path='reports/sales'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canViewSalesReport} />}
              >
                <Route path='/reports/sales' element={<ReportsSalesPage />} />
                {/* <Route path='/reports/sales/sales-by-branch' element={<BranchReport />} /> */}
                <Route path='/reports/sales/sales-by-branch' element={<BranchReport />} />
                {/* <Route path='/reports/sales/sales-by-cashier' element={<CashierReport />}  */}
                <Route path='/reports/sales/sales-by-cashier' element={<CashierReport />} />
                {/* <Route path='/reports/sales/sales-by-category' element={<CategoryReport />} /> */}
                <Route path='/reports/sales/sales-by-category' element={<CategoryReport />} />

                <Route path='/reports/sales/sales-by-combo' element={<ComboReport />} />
                <Route path='/reports/sales/sales-by-coupon' element={<CouponReport />} />
                <Route path='/reports/sales/sales-by-charge' element={<ChargeReport />} />
                {/* <Route path='/reports/sales/sales-by-creator' element={<CreatorReport />} /> */}
                <Route path='/reports/sales/sales-by-creator' element={<CreatorReport />} />
                {/* <Route path='/reports/sales/sales-by-customer' element={<CustomerReport />} /> */}
                <Route path='/reports/sales/sales-by-customer' element={<CustomerReport />} />
                <Route path='/reports/sales/sales-by-delivery-zone' element={<DeliveryZoneReport />} />
                <Route path='/reports/sales/sales-by-driver' element={<DriverReport />} />
                <Route path='/reports/sales/sales-by-discount' element={<DiscountReport />} />
                {/* <Route path='/reports/sales/sales-by-modifier-option' element={<ModifierOptionReport />} /> */}
                <Route path='/reports/sales/sales-by-modifier-option' element={<ModifierOptionReport />} />

                {/* <Route path='/reports/sales/sales-by-order-source' element={<OrderSourceReportCopy />} /> */}
                <Route path='/reports/sales/sales-by-order-source' element={<OrderSourceReport />} />
                {/* <Route path='/reports/sales/sales-by-order-type' element={<OrderTypeReport />} /> */}
                <Route path='/reports/sales/sales-by-order-type' element={<OrderTypeReport />} />
                {/* <Route path='/reports/sales/sales-by-product' element={<ProductReport />} /> */}
                <Route path='/reports/sales/sales-by-product' element={<ProductReport />} />
                <Route path='/reports/sales/sales-by-promotion' element={<PromotionReport />} />
                {/* <Route path='/reports/sales/sales-by-branch-tags' element={<BranchTagsReport />} /> */}
                <Route path='/reports/sales/sales-by-branch-tags' element={<BranchTagsReport />} />
                {/* <Route path='/reports/sales/sales-by-product-tags' element={<ProductTagsReport />} /> */}
                <Route path='/reports/sales/sales-by-product-tags' element={<ProductTagsReport />} />
                {/* <Route path='/reports/sales/sales-by-order-tags' element={<OrderTagsReport />} /> */}
                <Route path='/reports/sales/sales-by-order-tags' element={<OrderTagsReport />} />
                {/* <Route path='/reports/sales/sales-by-section' element={<SectionReport />} /> */}
                <Route path='/reports/sales/sales-by-section' element={<SectionReport />} />
                {/* <Route path='/reports/sales/sales-by-table' element={<TableReport />} /> */}
                <Route path='/reports/sales/sales-by-table' element={<TableReport />} />
                {/* <Route path='/reports/sales/sales-by-time-event' element={<TimeEventsReport />} /> */}
                <Route path='/reports/sales/sales-by-time-event' element={<TimeEventsReport />} />
                {/* <Route path='/reports/sales/sales-by-application' element={<ApplicationReport />} /> */}
                <Route path='/reports/sales/sales-by-application' element={<ApplicationReport />} />

                {/* <Route path='/reports/sales/payments' element={<Reportspayments />} /> */}
                <Route path='/reports/sales/payments' element={<Reportspayments />} />
              </Route>
              <Route path='reports/inventory' element={<ReportsInventory />} />
              <Route
                path='reports/inventory/inventory-levels'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canInventoryLevelsReport} />}
              >
                {/* <Route path='/reports/inventory/inventory-levels' element={<Reportsinventorylevels />} /> */}
                <Route path='/reports/inventory/inventory-levels' element={<Reportsinventorylevels />} />
              </Route>
              <Route
                path='reports/inventory/inventory-control'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canInventoryControlReport} />}
              >
                {/* <Route path='/reports/inventory/inventory-control' element={<InventoryControl />} /> */}
                <Route path='/reports/inventory/inventory-control' element={<InventoryControl />} />
              </Route>
              <Route
                path='reports/inventory'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canInventoryTransactionsReport} />}
              >
                {/* <Route path='/reports/inventory/inventory-History' element={<ReportInventoryHistory />} /> */}
                <Route path='/reports/inventory/inventory-History' element={<ReportInventoryHistory />} />

                {/* <Route path='/reports/inventory/purchase-order' element={<ReportMorePurchaseOrder />} /> */}
                <Route path='/reports/inventory/purchase-order' element={<ReportMorePurchaseOrder />} />

                {/* <Route path='/reports/inventory/transfer-order' element={<ReportMoreTransferOrder />} /> */}
                <Route path='/reports/inventory/transfer-order' element={<ReportMoreTransferOrder />} />

                {/* <Route path='/reports/inventory/transfer' element={<ReportMoreTransfer />} /> */}
                <Route path='/reports/inventory/transfer' element={<ReportMoreTransfer />} />
                {/* <Route path='/reports/inventory/purchasing' element={<ReportMorePurchasing />} /> */}
                <Route path='/reports/inventory/purchasing' element={<ReportMorePurchasing />} />
              </Route>
              <Route
                path='reports/inventory/cost-adjustmenthistory'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canCostAdjustmentHistoryReport} />}
              >
                {/* <Route path='/reports/inventory/cost-adjustmenthistory' element={<ReportMoreCostAdjustmentHistory />} /> */}
                <Route path='/reports/inventory/cost-adjustmenthistory' element={<ReportMoreCostAdjustmentHistory />} />
              </Route>
              <Route
                path='reports/business'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canOtherReports} />}
              >
                <Route path='/reports/business' element={<ReportsBusiness />} />
                {/* <Route path='/reports/business/taxes' element={<ReportMoreTaxes />} /> */}
                <Route path='/reports/business/taxes' element={<ReportMoreTaxes />} />
                {/* <Route path='/reports/business/tips' element={<ReportMoreTips />} /> */}
                <Route path='/reports/business/tips' element={<ReportMoreTips />} />
                {/* <Route path='/reports/business/gift-cards' element={<ReportMoreGiftCard />} /> */}
                <Route path='/reports/business/gift-cards' element={<ReportMoreGiftCard />} />
                {/* <Route path='/reports/business/business-days' element={<ReportMoreBusinessDays />} /> */}
                <Route path='/reports/business/business-days' element={<ReportMoreBusinessDays />} />
                {/* <Route path='/reports/business/shifts' element={<ReportMoreShifts />} /> */}
                <Route path='/reports/business/shifts' element={<ReportMoreShifts />} />
                {/* <Route path='/reports/business/tills' element={<ReportMoreTills />} /> */}
                <Route path='/reports/business/tills' element={<ReportMoreTills />} />
                <Route path='/reports/business/tills/:id' element={<Tilldetails />} />
                {/* <Route path='/reports/business/drawer-operations' element={<ReportMoreDrawerOperation />} /> */}
                <Route path='/reports/business/drawer-operations' element={<ReportMoreDrawerOperation />} />
                {/* <Route path='/reports/business/void-returns' element={<ReportsMoreVoidAndReturns />} /> */}
                <Route path='/reports/business/void-returns' element={<ReportMoreVoidAndReturns />} />
              </Route>

              {/* <Route path="reports/analysis" element={<Reportsanalysis />} />
              <Route
                path="reports/analysis/menu-engineering"
                element={<Reportsmenuengineering />}
              />
              <Route
                path="reports/analysis/costanalysis"
                element={<Reportscostanalysis />}
              />
              <Route
                path="reports/analysis/speed-of-Service"
                element={<Reportsspeedservice />}
              />
              <Route
                path="reports/analysis/branches-trend"
                element={<Reportsbranchestrend />}
              />
              <Route
                path="reports/analysis/product-cost"
                element={<ReportMoreProductCost />}
              />
              <Route
                path="reports/analysis/modifer-option"
                element={<ReportMoreModiferOption />}
              />
              <Route
                path="reports/analysis/inventoryitem-cost"
                element={<ReportMoreInventoryItemsCost />}
              /> */}
              <Route path='menu' element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canViewMenu} />}>
                <Route path='/menu/categories' element={<Menucategories />} />
                <Route path='/menu/categories/sort-categories' element={<Menusortcategories />} />
                <Route path='/menu/products' element={<MenuProduct />} />
                {/* <Route path='/menu/products/:id' element={<Menuproductsdetails />} /> */}
                <Route path='/menu/products/:id' element={<Menuproductsdetails />} />
                <Route path='/menu/combos' element={<MenuCombos />} />
                {/* <Route path='/menu/combos/:id' element={<Menucombosdetails />} /> */}
                <Route path='/menu/combos/:id' element={<Menucombosdetails />} />
                <Route path='/menu/gift-cards' element={<Menugiftcards />} />
                {/* <Route path='/menu/gift-cards/:id' element={<Menugiftcardsdetails />} /> */}
                <Route path='/menu/gift-cards/:id' element={<Menugiftcardsdetails />} />
                <Route path='/menu/modifiers' element={<MenuModifiers />} />
                <Route path='/menu/modifiers/sort-modifier' element={<Menusortmodifier />} />
                <Route path='/menu/modifier-options' element={<MenuModifierOptions />} />
                {/* <Route path='/menu/modifier-options/:id' element={<Menumodifieroptionsdetails />} /> */}
                <Route path='/menu/modifier-options/:id' element={<Menumodifieroptionsdetails />} />
                <Route path='/menu/groups' element={<MenuGroups />} />
                {/* <Route path='/menu/groups/:id' element={<Menugroupsdetails />} /> */}
                <Route path='/menu/groups/:id' element={<Menugroupsdetails />} />
                <Route path='/menu/groups/sort-group/:id' element={<Menusortgroup />} />
              </Route>
              <Route path='manage' element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageUsers} />}>
                <Route path='/manage/users' element={<ManageUsers />} />

                <Route path='/manage/users/:id' element={<ManageUserDetail />} />

                <Route path='/manage/roles' element={<ManageRoles />} />
              </Route>
              <Route
                path='manage/branches'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageBranches} />}
              >
                <Route path='/manage/branches' element={<Managebranches />} />

                <Route path='/manage/branches/:id' element={<ManageBranchesDetails />} />

                <Route path='/manage/branches/:branchId/sections/:sectionId' element={<ManageBranchesSection />} />
              </Route>
              <Route
                path='manage/devices'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageDevices} />}
              >
                <Route path='/manage/devices' element={<ManageDevices />} />
                <Route path='/manage/devices/:id' element={<ManageDeviceDetails />} />
              </Route>
              <Route
                path='marketing/discounts'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageDiscounts} />}
              >
                {/* <Route path='/marketing/discounts' element={<ManageDiscounts />} /> */}
                <Route path='/marketing/discounts' element={<ManageDiscounts />} />
                {/* <Route path='/marketing/discounts/manage-discount/:id' element={<ManageDiscountDetails />} /> */}
                <Route path='/marketing/discounts/manage-discount/:id' element={<ManageDiscountDetails />} />
              </Route>
              <Route
                path='marketing/coupons'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageCoupons} />}
              >
                {/* <Route path='/marketing/coupons' element={<ManageCoupons />} /> */}
                <Route path='/marketing/coupons' element={<ManageCouponsCopy />} />
                {/* <Route path='/marketing/coupons/:id' element={<ManageCouponsDetails />} /> */}
                <Route path='/marketing/coupons/:id' element={<ManageCouponsDetailsCopy />} />
              </Route>
              <Route
                path='marketing/promotions'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManagePromotions} />}
              >
                {/* <Route path='/marketing/promotions' element={<ManagePromotions />} /> */}
                <Route path='/marketing/promotions' element={<ManagePromotionsCopy />} />
                {/* <Route path='/marketing/promotions/promotions-detail/:id' element={<ManagePromotionsDetails />} /> */}
                <Route path='/marketing/promotions/promotions-detail/:id' element={<ManagePromotionsDetailsCopy />} />
                {/* <Route path='/marketing/promotions/add-promotions' element={<ManageAddPromotions />} /> */}
                <Route path='/marketing/promotions/add-promotions' element={<ManageAddPromotionsCopy />} />
              </Route>
              <Route
                path='manage/timed-events'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageTimedEvents} />}
              >
                <Route path='/manage/timed-events' element={<ManageTimedEvents />} />
                <Route path='/manage/timed-events/timed-events-details/:id' element={<ManageTimedEventsDetails />} />
              </Route>
              <Route path='/manage/manage-more' element={<ManageMore />} />
              <Route
                path='manage/manage-more/taxes'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageTaxesAndGroups} />}
              >
                <Route path='/manage/manage-more/taxes' element={<ManageMoreTaxes />} />
              </Route>
              <Route
                path='manage/manage-more/payments-method'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManagePaymentMethods} />}
              >
                <Route path='/manage/manage-more/payments-method' element={<ManageMorePaymentMethod />} />
              </Route>
              <Route
                path='manage/manage-more/delivery-zones'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageDeliveryZones} />}
              >
                <Route path='/manage/manage-more/delivery-zones' element={<ManageMoreDeliveryZonesCopy />} />
              </Route>
              <Route
                path='manage/manage-more/tags'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageTagsSettings} />}
              >
                <Route path='/manage/manage-more/tags' element={<ManageMoreTagsCopy />} />
              </Route>
              <Route
                path='manage/manage-more/reasons'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageReasonsSettings} />}
              >
                <Route path='/manage/manage-more/reasons' element={<ManageMoreReasons />} />
              </Route>
              <Route
                path='manage/manage-more/charges'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageCharges} />}
              >
                <Route path='/manage/manage-more/charges' element={<ManageMoreChargesCopy />} />
              </Route>
              <Route
                path='manage/manage-more/price-tags'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManagePriceTagsSettings} />}
              >
                <Route path='/manage/manage-more/price-tags' element={<ManageMorePriceTagsCopy />} />
                <Route path='/manage/manage-more/price-tags/:id' element={<ManageMorePriceTagDetailsPage />} />
              </Route>
              {/* <Route
                path="/manage/manage-more/kitchen-flow"
                element={<ManageMoreKitchenFlow />}
              />
              <Route
                path="/manage/manage-more/kitchen-flow/:id"
                element={<ManageMoreKitchenFlowDetails />}
              /> */}
              <Route
                path='manage/manage-more/reservation'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageReservationsSettings} />}
              >
                <Route path='/manage/manage-more/reservation' element={<ManageMoreReservations />} />
              </Route>
              {/* <Route
                path="/manage/manage-more/online-ordering"
                element={<ManageMoreOnlineOrderingSetting />}
              /> */}
              <Route
                path='manage/manage-more'
                element={<AuthGuardRoute permissionRequired={POSPERMISSIONS.canManageSettings} />}
              >
                <Route path='/manage/manage-more/subscription' element={<ManageMoreSubscription />} />
                <Route
                  path='/manage/manage-more/settings'
                  element={
                    <>
                      <ManageMoreSettings />
                      {/* <ManageMoreSettingsGeneral /> */}
                    </>
                  }
                />
                <Route
                  path='/manage/manage-more/settings/general'
                  element={
                    <>
                      <ManageMoreSettings />
                      <ManageMoreSettingsGeneral />
                    </>
                  }
                />
                <Route
                  path='/manage/manage-more/settings/loyalty'
                  element={
                    <>
                      <ManageMoreSettings />
                      <ManageMoreSettingsLoyalty />
                    </>
                  }
                />
                <Route
                  path='/manage/manage-more/settings/receipt'
                  element={
                    <>
                      <ManageMoreSettings />
                      <ManageMoreSettingReceipt />
                    </>
                  }
                />
                <Route
                  path='/manage/manage-more/settings/web-cashier'
                  element={
                    <>
                      <ManageMoreSettings />
                      <ManageMoreSettingsWebCashier />
                    </>
                  }
                />
                <Route
                  path='/manage/manage-more/settings/call-center'
                  element={
                    <>
                      <ManageMoreSettings />
                      <ManageMoreSettingsCallCenter />
                    </>
                  }
                />
                <Route
                  path='/manage/manage-more/settings/cashier-app'
                  element={
                    <>
                      <ManageMoreSettings />
                      <ManageMoreSettingsCashierApp />
                    </>
                  }
                />

                <Route
                  path='/manage/manage-more/settings/inventory-transactionst'
                  element={
                    <>
                      <ManageMoreSettings />
                      <ManageMoreSettingInventoryTransactions />
                    </>
                  }
                />
                <Route
                  path='/manage/manage-more/settings/display-app'
                  element={
                    <>
                      <ManageMoreSettings />
                      <ManageMoreSettingDisplayApp />
                    </>
                  }
                />
                <Route
                  path='/manage/manage-more/settings/payment-integrations'
                  element={
                    <>
                      <ManageMoreSettings />
                      <ManageMoreSettingPaymentIntegrations />
                    </>
                  }
                />

                <Route
                  path='/manage/manage-more/settings/sms-providers'
                  element={
                    <>
                      <ManageMoreSettings />
                      <ManageMoreSettingSmsProviders />
                    </>
                  }
                />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
