import React, { useEffect } from "react";
import TopBarProgress from "react-topbar-progress-indicator";

const TopBarProgressInd = () => {
  useEffect(() => {
    TopBarProgress.config({
      barColors: {
        "0": "#004fee",
        "1.0": "#004fee",
      },
      shadowBlur: 5,
    });
  }, []);

  return (
    <div>
      <TopBarProgress />
      {/* Your other components and content go here */}
    </div>
  );
};

export default TopBarProgressInd;
