import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetch5, fetch6, fetch10, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';
import { RoundNum } from '../../utils/functions';

const initialState = {
  loading: false,
  filterStatus: false,
  error: null,
  filterBody: null,
  inventoryHistoryReportsList: {},
  getInventoryHistorybranchesData: [],
  getInventoryHistorycategoriesData: [],
  getInventoryHistoryitemsData: [],
  getInventoryHistoryTagsData: [],
};
// const queryParams = {
//   category_id: [
//     'c95d59c6-cab4-4f56-80d8-95408fd29b17',
//     'another_category_id',
//     'yet_another_category_id'
//   ]
// };

// const queryString = Object.entries(queryParams)
//   .map(([key, values]) => values.map(value => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&'))
//   .join('&');

// const endpoint = `${url}/reports/inventory_history?${queryString}`;

export const getInventoryHistoryReportsList: any = createAsyncThunk('getInventoryHistoryReportsList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/reports/inventory-report/inventory_history`, body);
  console.log(result);

  return result;
});

export const getInventoryHistorybranches: any = createAsyncThunk('getInventoryHistorybranches', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/branches`, body);
  return result.data;
});
export const getInventoryHistorycategories: any = createAsyncThunk(
  'getInventoryHistorycategories',
  async (body: any) => {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory-categories`, body);
    return result.data;
  },
);
export const getInventoryHistoryitems: any = createAsyncThunk('getInventoryHistoryitems', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items`, body);
  return result.data;
});
export const getInventoryHistoryTags: any = createAsyncThunk('getInventoryHistoryTags', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);
  return result.data;
});

const ReportsInventoryHistoryApiReducer: any = createSlice({
  name: 'ReportsInventoryHistoryApiReducer',
  initialState,
  reducers: {
    changeFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    setFilter: (state, action) => {
      state.filterBody = action.payload;
    },
  },
  extraReducers: {
    [getInventoryHistoryReportsList.pending]: state => {
      state.loading = true;
    },
    [getInventoryHistoryReportsList.fulfilled]: (state, action) => {
      state.loading = false;
      state.inventoryHistoryReportsList = {
        data: action.payload.data.map((obj: { [key: string]: any }) => {
          Object.entries(obj).forEach(([key, value]: any) => {
            obj[key] = !isNaN(+value) ? RoundNum(+value) : value;
          });

          return obj;
        }),
        ...action.payload,
      };
    },
    [getInventoryHistoryReportsList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },

    [getInventoryHistorybranches.pending]: state => {
      state.loading = true;
    },
    [getInventoryHistorybranches.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryHistorybranchesData = action.payload;
    },
    [getInventoryHistorybranches.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getInventoryHistorycategories.pending]: state => {
      state.loading = true;
    },
    [getInventoryHistorycategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryHistorycategoriesData = action.payload;
    },
    [getInventoryHistorycategories.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getInventoryHistoryitems.pending]: state => {
      state.loading = true;
    },
    [getInventoryHistoryitems.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryHistoryitemsData = action.payload;
    },
    [getInventoryHistoryitems.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getInventoryHistoryTags.pending]: state => {
      state.loading = true;
    },
    [getInventoryHistoryTags.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryHistoryTagsData = action.payload;
    },
    [getInventoryHistoryTags.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
  },
});
export const { changeFilterStatus, setFilter } = ReportsInventoryHistoryApiReducer.actions;
export default ReportsInventoryHistoryApiReducer.reducer;
