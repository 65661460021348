export const ROLES: any = {
  viewer: "VIEWER",
  manager: "MANAGER",
};

export const SCOPES: any = {
  canManage: "can-manage",
  canView: "can-view",
};

export const permissions: any = {
  [ROLES.viewer]: [SCOPES.canView],
  [ROLES.manager]: [SCOPES.canManage],
};

export const POSPERMISSIONS: any = {
  canViewOrders: "orders:read",
  canManageOrders: "orders:manage",
  canManageTags: "orders:manage_tags",
  canViewCustomers: "customers:read",
  canViewCustomerInsights: "customers:read_insights",
  canManageCustomers: "customers:manage",
  canManageHouseAccount: "customers:manage_house_account",
  canManageLoyalty: "customers:manage_loyalty",
  canViewDeliveryPartners: "delivery-partners:read",
  canViewDeliveryPartnerInsights: "delivery-partners:read_insights",
  canManageDeliveryPartners: "delivery-partners:manage",
  canManageDeliveryPartnerHouseAccount:
    "delivery-partners:manage_house_account",
  canManageDeliveryPartnerLoyalty: "delivery-partners:manage_loyalty",
  canViewInventoryItems: "inventory_items:read",
  canManageInventoryItems: "inventory_items:manage",
  canViewSuppliers: "suppliers:read",
  canManageSuppliers: "suppliers:manage",
  canManagePODrafts: "po:drafts:manage",
  canManagePOPosted: "po:posted:manage",
  canManagePOApproved: "po:approved:manage",
  canReceivePOApproved: "po:approved:receive",
  canManageTODrafts: "to:drafts:manage",
  canManageTOApproved: "to:approved:manage",
  canManageTransfersDrafts: "transfers:drafts:manage",
  canManageTransfersClosed: "transfers:closed:manage",
  canManagePurchasingDrafts: "purchasing:drafts:manage",
  canManagePurchasingClosed: "purchasing:closed:manage",
  canManagePurchasingFromPODrafts: "purchasing_from_po:drafts:manage",
  canManageDirectPurchasingDrafts: "direct_purchasing:drafts:manage",
  canManageProductionDrafts: "production:drafts:manage",
  canManageProductionClosed: "production:closed:manage",
  canManageQuantityAdjustmentDrafts: "quantity_adjustment:drafts:manage",
  canManageQuantityAdjustmentClosed: "quantity_adjustment:closed:manage",
  canManageCostAdjustmentDrafts: "cost_adjustment:drafts:manage",
  canManageCostAdjustmentClosed: "cost_adjustment:closed:manage",
  canManageInventoryCountDrafts: "inventory_count:drafts:manage",
  canManageInventoryCountClosed: "inventory_count:closed:manage",
  canViewOrderTransactions: "order_transactions:read",
  canManageSpotCheckDrafts: "spot_check:drafts:manage",
  canManageSpotCheckClosed: "spot_check:closed:manage",
  canManageCountSheet: "count_sheet:drafts:manage",
  canViewMenu: "menu:read",
  canManageMenu: "menu:manage",

  // Franchising permissions
  // canViewFranchising: "franchising:read",
  // canManageFranchising: "franchising:manage",

  canViewFranchisingFranchise: "franchising:franchise:read",
  canManageFranchisingFranchise: "franchising:franchise:manage",

  canViewFranchisingSalesOrders: "franchising:sales_order:read",
  canManageFranchisingSalesOrders: "franchising:sales_order:manage",

  canViewFranchisingAnnouncement: "franchising:announcement:read",
  canManageFranchisingAnnouncement: "franchising:announcement:manage",

  canViewFranchisingRequestsFromFranchise: "franchising:requests-from-franchise:read",
  canManageFranchisingRequestsFromFranchise: "franchising:requests-from-franchise:manage",

  canManageIngredients: "ingredients:manage",
  canManageCost: "cost:manage",
  canManageBranches: "branches:manage",
  canManageCoupons: "coupons:manage",
  canManageDevices: "devices:manage",
  canManageDiscounts: "discounts:manage",
  canManageGiftCards: "gift_cards:manage",
  canManagePromotions: "promotions:manage",
  canManageSettings: "settings:manage",
  canManageDeliveryZones: "delivery_zones:manage",
  canManageTimedEvents: "timed_events:manage",
  canManageUsers: "users:manage",
  canManageApps: "apps:manage",
  canManageTaxesAndGroups: "settings:manage_taxes_and_groups",
  canManagePaymentMethods: "settings:manage_payment_methods",
  canManageCharges: "settings:manage_charges",
  canManageTagsSettings: "settings:manage_tags",
  canManageReasonsSettings: "settings:manage_reasons",
  canManageKitchenFlowsSettings: "settings:manage_kitchen_flows",
  canManageReservationsSettings: "settings:manage_reservations",
  canManageOnlineOrderingSettings: "settings:manage_online_ordering",
  canManagePriceTagsSettings: "settings:manage_price_tags",
  canManageNotificationsSettings: "settings:manage_notifications",
  canCostAnalysisReport: "reports:cost_analysis",
  canInventoryControlReport: "reports:inventory_control",
  canInventoryLevelsReport: "reports:inventory_levels",
  canInventoryTransactionsReport: "reports:inventory_transactions",
  canOtherReports: "reports:other",
  canViewSalesReport: "reports:sales",
  canCostAdjustmentHistoryReport: "reports:cost_adjustment_history",
  canFoodicsPayReport: "reports:foodics_pay",
  canMenuCostReport: "reports:menu_cost",
  canInventoryItemsCostReport: "reports:inventory_items_cost",
  canViewGeneralDashboard: "dashboard:general",
  canViewBranchesDashboard: "dashboard:branches",
  canViewInventoryDashboard: "dashboard:inventory",
  canViewCallCenterDashboard: "dashboard:call_center",
  canManageMarketplace: "marketplace:manage",
};
