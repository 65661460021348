import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch10, fetch2, fetch3, fetch5, fetch6, fetch7, fetch8, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  loading: false,
  pageLoading: false,
  loadingProducts: false,
  priceTagsListCount: 0,
  addPriceTagsCount: 0,
  priceTagPerIdCount: 0,
  deletePriceTagCount: 0,
  addProductsInPriceTagCount: 0,
  editProductPriceCount: 0,
  priceTagComboDeleteCount: 0,
  priceTagComboEditCount: 0,
  comboSizeCount: 0,
  comboGroupListCount: 0,
  comboGroupOptionListCount: 0,
  priceTagComboAddCount: 0,
  priceTagModifierListCount: 0,
  priceTagModifierAddDeleteCount: 0,
  editModifierPricesCount: 0,
  branchsBusinessCount: 0,
  loadingStatus: 0,
  isFilter: false,
  isFilterProduct: false,
  isFilterModifier: false,
  priceTagProducts: [],
  priceTagModifierListData: [],
  pageCount: 0,
};
export let priceTagsFilter: any = {
  name: '',
  ['order_tag_id']: [],
  is_deleted: false,
};

export let priceTagsFilterProducts: any = {
  name: '',
  catergory: [],
  tags: [],
};

export let priceTagsFilterModifier: any = {
  filter: {
    name: '',
    modifier: [],
  },
};
export let priceTagsListItems: any;
export let branchsBusinessItems: any;
export let priceTagViewDetails: any;

// export let priceTagProducts: any;
export let comboSizeList: any;
export let bodyData: any;

export let comboGroupListData: any;
export let comboGroupOptionListData: any;
export let priceTagModifierListData: any;
export let editSingleProductPrice = {
  price: 0,
  product_id: 0,
  priceOrginal: 0,
  name: '',
};

export let editSingleCombo = {
  name: '',
  price: 0,
  comboOptionId: 0,
  pricetag_id: 0,
  comboSizeId: 0,
  productId: 0,
};

export let editSingleModifierOption = {
  name: '',
  price: 0,
  modifierOptionId: 0,
  pricetag_id: 0,
  priceOrginal: 0,
};

//done
export const priceTagsList: any = createAsyncThunk('priceTagsList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/price_tags`, body);
  bodyData = body;
  return result;
});

//done
export const addPriceTags: any = createAsyncThunk('addPriceTags', async body => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/price_tags`, body);
  return result;
});
export const editPriceTags: any = createAsyncThunk('editPriceTags', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/price_tags/${body.id}`, body);

  return result;
});

//done
export const priceTagPerId: any = createAsyncThunk('priceTagPerId', async id => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/price_tags/${id}`);

  return result;
});

//done
export const deletePriceTag: any = createAsyncThunk('deletePriceTag', async body => {
  const result = await fetch10(`${process.env.REACT_APP_BASEURL}/manage/price_tags/${body}`, body);
  return result;
});

export const addProductsInPriceTag: any = createAsyncThunk('addProductsInPriceTag', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/manage/price_tags/${body.priceTagId}/products/${body.productsId}`,
    body,
  );

  return result;
});

export const getAllProductsPriceTags: any = createAsyncThunk('getAllProductsPriceTags', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/price_tags/${body.id}/products`, body.filter);
  // priceTagProducts = result
  return result;
});
// done
export const editProductPrices: any = createAsyncThunk(
  'editProductPrices',
  // `${perIdData.id}/products/${productSelectedData.product_id}` ,
  //   {price:"20"})
  async (body: any) => {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/manage/price_tags/${body.perIdData}/products/${body.productsId}`,
      body,
    );

    return result;
  },
);

//done
export const deleteProductPrices: any = createAsyncThunk('deleteProductPrices', async body => {
  const result = await fetch10(`${process.env.REACT_APP_BASEURL}/manage/price_tags/${body}`, body);
  return result;
});

export const priceTagComboDelete: any = createAsyncThunk('priceTagComboDelete', async (body: any) => {
  const result = await fetch10(
    `${process.env.REACT_APP_BASEURL}/manage/price_tags/${body.perIdData}/combo_options/${body.comboOptionId}/sizes/${body.comboSizeId}/products/${body.productId}`,
    body,
  );
  return result;
});

export const priceTagComboEdit: any = createAsyncThunk('priceTagComboEdit', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/manage/price_tags/${body.perIdData}/combo_options/${body.comboOptionId}/sizes/${body.comboSizeId}/products/${body.productId}`,
    body,
  );

  return result;
});

export const comboSize: any = createAsyncThunk('comboSize', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/combo_size`, body);
  comboSizeList = result.data;
  return result;
});

export const comboGroupList: any = createAsyncThunk('comboGroupList', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/combo_sizegrouplist`, body);
  comboGroupListData = result.data;
  return result;
});

export const comboGroupOptionList: any = createAsyncThunk('comboGroupOptionList', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/price_tags/${body.id}/combo_options`, body);
  comboGroupOptionListData = result.data;

  return result;
});

export const priceTagComboAdd: any = createAsyncThunk('priceTagComboAdd', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/manage/price_tags/${body?.id}/combo_options/${body.option_id}/sizes/${body.size_id}/products/${body.product_id}`,
    body,
  );
  return result;
});
export const pricelistRules: any = createAsyncThunk('pricelistRules', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/price_tags/${body?.id}/advance-settings`, body);
  return result;
});

export const priceTagModifierList: any = createAsyncThunk('priceTagModifierList', async (body: any) => {
  const result = await fetch6(
    `${process.env.REACT_APP_BASEURL}/manage/price_tags/${body.id}/modifier_options`,
    body.filter,
  );
  // priceTagModifierListData = result.data
  return result;
});
// done
export const priceTagModifierDelete: any = createAsyncThunk('priceTagModifierDelete', async (body: any) => {
  const result = await fetch10(
    `${process.env.REACT_APP_BASEURL}/manage/price_tags/${body.pricetag_id}/modifier_options/${body.modifierOptionId}`,
    body,
  );
  priceTagModifierListData = result.data;
  return result;
});
export const priceTagModifierAdd: any = createAsyncThunk('priceTagModifierAdd', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/manage/price_tags/${body.priceTagId}/modifier_options/${body.modifierOptionId}`,
    body,
  );
  // priceTagModifierListData = result.data
  return result;
});
export const priceTagModifierEdit: any = createAsyncThunk('priceTagModifierEdit', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/manage/price_tags/${body.pricetag_id}/modifier_options/${body.modifierOptionId}`,
    body,
  );

  priceTagModifierListData = result.data;
  return result;
});

export const editModifierPrices: any = createAsyncThunk('editModifierPrices', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/manage/price_tags/${body.perIdData}/modifier_options/${body.modifierId}`,
    body,
  );
  return result;
});

const manageMorePriceTagsSlice: any = createSlice({
  name: 'manageMorePriceTagsSlice',
  initialState,
  reducers: {
    setshowLoading(state = initialState) {
      // state.loadingStatus += 1
      state.loading = true;
    },
    setPageLoading(state = initialState) {
      state.pageLoading = true;
    },
    setFilterDataPriceTags(state = initialState, action) {
      state.isFilter = true;

      priceTagsFilter.name = action.payload.name;
      priceTagsFilter['order_tag_id'] = action.payload['order_tag_id'];
    },
    singleProductPriceFunc(state = initialState, action) {
      editSingleProductPrice.price = action.payload.price;
      editSingleProductPrice.name = action.payload.name;
      editSingleProductPrice.priceOrginal = action.payload.priceOrginal;
      editSingleProductPrice.product_id = action.payload.product_id;
    },
    singleComboPriceFunc(state = initialState, action) {
      editSingleCombo.name = action.payload.name;
      editSingleCombo.pricetag_id = action.payload.pricetag_id;
      editSingleCombo.price = action.payload.price;
      editSingleCombo.comboOptionId = action.payload.comboOptionId;
      editSingleCombo.comboSizeId = action.payload.comboSizeId;
      editSingleCombo.productId = action.payload.productId;
    },
    singleModifierOptionPriceFunc(state = initialState, action) {
      editSingleModifierOption.name = action.payload.name;
      editSingleModifierOption.price = action.payload.price;
      editSingleModifierOption.priceOrginal = action.payload.priceOrginal;
      editSingleModifierOption.modifierOptionId = action.payload.modifierOptionId;
      editSingleModifierOption.pricetag_id = action.payload.pricetag_id;
    },
    resetFilter(state = initialState) {
      state.isFilter = false;
      priceTagsFilter.name = '';
      priceTagsFilter['order_tag_id'] = '';
      priceTagsFilter.is_deleted = false;
    },
    productsFilter(state = initialState, action) {
      state.isFilterProduct = true;
      priceTagsFilterProducts.name = action.payload.name;
      priceTagsFilterProducts.category = action.payload.category;
      priceTagsFilterProducts.tags = action.payload.tags;
    },
    productsFilterReset(state = initialState, action) {
      state.isFilterProduct = false;

      priceTagsFilterProducts.name = '';
      priceTagsFilterProducts.category = [];
      priceTagsFilterProducts.tags = [];
    },
    modifierFilter(state = initialState, action) {
      state.isFilterModifier = true;
      priceTagsFilterModifier.name = action.payload.name;
      priceTagsFilterModifier.modifer = action.payload.modifier;
    },
    modifierFilterReset(state = initialState, action) {
      state.isFilterModifier = false;
      priceTagsFilterModifier.name = '';
      priceTagsFilterModifier.modifer = [];
    },
    setPageCount(state = initialState, action) {
      state.pageCount = action.payload;
    },
  },
  extraReducers: {
    [priceTagsList.pending]: (state: any) => {
      state.loading = true;
    },
    [priceTagsList.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.priceTagsListItems = action.payload;

      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [priceTagsList.rejected]: (state: any) => {
      state.loading = false;
    },
    [addPriceTags.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addPriceTagsCount += 1;
      // notify('Add Price Tags Successfully', 'success');

      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [addPriceTags.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.pageLoading = false;
      notify(action.error.message);
    },

    [editPriceTags.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.editPriceTagsCount += 1;
      // notify('Add Price Tags Successfully', 'success');

      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [priceTagPerId.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.priceTagViewDetails = action.payload;
      state.pageLoading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [priceTagPerId.rejected]: (state: any) => {
      state.loading = false;
      state.pageLoading = false;
    },
    [getAllProductsPriceTags.fulfilled]: (state: any, action: any) => {
      state.loadingProducts = false;
      state.priceTagProducts = action.payload;
      state.pageLoading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [getAllProductsPriceTags.pending]: (state: any) => {
      state.loadingProducts = true;
    },
    [getAllProductsPriceTags.rejected]: (state: any) => {
      state.loading = false;
      state.pageLoading = false;
    },
    [deletePriceTag.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.deletePriceTagCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [addProductsInPriceTag.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [addProductsInPriceTag.rejected]: (state: any) => {
      state.loading = false;
      state.pageLoading = false;
    },
    [priceTagModifierAdd.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [priceTagModifierAdd.rejected]: (state: any) => {
      state.loading = false;
      state.pageLoading = false;
    },
    [editProductPrices.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [priceTagComboDelete.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.priceTagComboDeleteCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [priceTagComboEdit.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.priceTagComboEditCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [comboSize.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.comboSizeCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [comboGroupList.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.comboGroupListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [comboGroupOptionList.fulfilled]: (state: any, action: any) => {
      state.loading = true;
    },
    [comboGroupOptionList.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.comboGroupOptionListCount += 1;
      state.comboGroupOptionListData = action.payload;
    },
    [priceTagComboAdd.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.priceTagComboAddCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [pricelistRules.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.priceTagComboAddCount += 1;

      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [priceTagComboAdd.rejected]: (state: any) => {
      state.loading = false;
      state.pageLoading = false;
    },
    [priceTagModifierList.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [priceTagModifierList.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.priceTagModifierListCount += 1;
      state.priceTagModifierListData = action.payload;
    },
    [priceTagModifierDelete.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.priceTagModifierAddDeleteCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [editModifierPrices.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.editModifierPricesCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
  },
});

export const {
  productsFilter,
  productsFilterReset,
  modifierFilter,
  modifierFilterReset,
  singleProductPriceFunc,
  setFilterDataPriceTags,
  resetFilter,
  singleComboPriceFunc,
  singleModifierOptionPriceFunc,
  setshowLoading,
  setPageLoading,
  setPageCount,
} = manageMorePriceTagsSlice.actions;
export default manageMorePriceTagsSlice.reducer;
