import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch3, fetch5, fetch6 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  orderTransactionList: {},
  orderTransactionDetails: {},
  progressLoading: false,
  isDataFiltered: false,
  pageLoading: false,
  loading: false,
  error: '',
  pageCount: 0,
};

export const orderTransactionList: any = createAsyncThunk('orderTransactionList', async body => {
  try {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/order_transactions`, body);
    return result;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

// ==============================================================

export const filterOrderTransaction: any = createAsyncThunk('filterOrderTransaction', async body => {
  try {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/order_transactions/filterOrderTransactions`,
      body,
    );

    return result;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

// ==============================================================

export const orderTransactionDetails: any = createAsyncThunk('orderTransactionDetails', async (body: any) => {
  try {
    const result = await fetch3(`${process.env.REACT_APP_BASEURL}/inventory/order_transactions/${body}`, 'get');
    return result.data;
  } catch (error: any) {
    notify(error.message, 'error');
  }
});

const inventoryOrderTransactionSlice: any = createSlice({
  name: 'order transaction',
  initialState,
  reducers: {
    setshowLoading(state = initialState, action: any) {
      state.loading = action.payload;
    },
    setProgressLoading(state = initialState, action: any) {
      state.progressLoading = action.payload;
    },
    setPageLoading(state = initialState, action: any) {
      state.pageLoading = true;
    },
    setIsDataFiltered(state = initialState, action: any) {
      state.isDataFiltered = action.payload;
    },
    setPageCount(state = initialState, action) {
      state.pageCount = action.payload;
    },
  },
  extraReducers: {
    [orderTransactionList.fulfilled]: (state: any, action: any) => {
      state.orderTransactionList = action.payload;
      state.loading = false;
      state.isDataFiltered = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [orderTransactionList.pending]: (state: any) => {
      state.loading = true;
    },
    // ==================================================================
    [filterOrderTransaction.fulfilled]: (state: any, action: any) => {
      state.orderTransactionList = action.payload;
      state.loading = false;
      state.isDataFiltered = true;
      if (action.payload.error) {
        state.error = action.payload.error;
        state.loading = false;
      } else {
        state.error = action.payload.message;
        state.loading = false;
      }
    },
    [filterOrderTransaction.rejected]: (state: any, action: any) => {
      state.loading = false;
    },
    // ==================================================================
    [orderTransactionDetails.fulfilled]: (state: any, action: any) => {
      state.orderTransactionDetails = action.payload;
      state.loading = false;
      state.pageLoading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [orderTransactionDetails.rejected]: (state: any, action: any) => {
      state.loading = false;
    },
    [orderTransactionDetails.pending]: (state: any, action: any) => {
      state.loading = true;
    },
  },
});

export const { setshowLoading, setProgressLoading, setPageLoading, setIsDataFiltered, setPageCount } =
  inventoryOrderTransactionSlice.actions;

export default inventoryOrderTransactionSlice.reducer;
