import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { fetch3, fetch4 } from "../../Apis/commonApis";

// First API endpoint
export const fetchBussinesType:any = createAsyncThunk(
  "data/fetchBussinesType",
  async (body) => {
    const response = await fetch4(`${process.env.REACT_APP_BASEURL}/first`, body);
    return response.data;
  }
);

// Second API endpoint
export const fetchBussinesLocation:any = createAsyncThunk(
  "data/fetchBussinesLocation",
  async (body) => {
    const response = await fetch4(`${process.env.REACT_APP_BASEURL}/second`, body);
    return response.data;
  }
);

// Third API endpoint
export const fetchRegisterForm:any = createAsyncThunk(
  "data/fetchRegisterForm",
  async (body) => {
    const response = await fetch4(`${process.env.REACT_APP_BASEURL}/third`, body);
    return response.data;
  }
);
// Initial state of the reducer

const initialState = {
  BussinesType: null,
  BussinesLocation: null,
  regForm: null,
  status: "idle",
  error: null,
};
// Redux slice

const registerFormReducer:any = createSlice({
  name: "data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // First API states
      .addCase(fetchBussinesType.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.BussinesType = action.payload;
      })
      .addCase(fetchBussinesType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBussinesType.rejected, (state:any, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Second API states
      .addCase(fetchBussinesLocation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.BussinesLocation = action.payload;
      })
      .addCase(fetchBussinesLocation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBussinesLocation.rejected, (state:any, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Third API states
      .addCase(fetchRegisterForm.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.regForm = action.payload;
      })
      .addCase(fetchRegisterForm.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRegisterForm.rejected, (state:any, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default registerFormReducer.reducer;
