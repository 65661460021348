import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetch5, fetch6, fetch10, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

export let inventoryPurchaseList: any = [];

const initialState = {
  loading: false,
  isFiltered: false,
  error: null,
  inventoryPurchaseSuppliers: [],
  inventoryPurchaseBranches: [],
  inventoryPurchaseItems: [],
  inventoryPurchaseTags: [],
  inventoryPurchaseDetails: {},
  inventoryPurchaseListCount: 0,
  inventoryPurchaseBrancheId: null,
  inventoryPurchaseId: null,
  inventoryPurchaseSupplierId: null,
};

export const getInventoryPurchaseList: any = createAsyncThunk('getInventoryPurchaseList', async body => {
  const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/purchasing`, body);
  inventoryPurchaseList = response;
  return response;
});

export const getInventoryPurchaseById: any = createAsyncThunk('getInventoryPurchaseById', async id => {
  const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/purchasing/${id}`);
  return response;
});
export const returnItemsApi: any = createAsyncThunk('returnItemsApi', async id => {
  const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/purchasing/return_to_supplier/${id}`);
  return response;
});
export const receiveItemsInventoryPurchase: any = createAsyncThunk('receiveItemsInventoryPurchase', async id => {
  try {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/purchasing/receiver_items/${id}`);
    notify('Items received successfully', 'success');
    return response;
  } catch (error: any) {
    notify(error?.data?.message)

    return error;
  }
});
export const createInventoryPurchase: any = createAsyncThunk('createInventoryPurchase', async body => {
  try {
    const response = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/purchasing`, body);
    return response;
  } catch (error: any) {
    return error.response.data;
  }
});
export const duplicateInventoryPurchasing: any = createAsyncThunk('duplicateInventoryPurchasing', async (id: any) => {
  try {
    const response = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/purchasing/duplicate/` + id);
    return response;
  } catch (error: any) {
    return error.response.data;
  }
});
export const retrunItemsInventoryPurchase: any = createAsyncThunk('retrunItemsInventoryPurchase', async (body: any) => {
  const response = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/purchasing/return_items_to_supplier/${body.id}`,
    body.returnItems,
  );
  return response;
});
export const addInventoryPurchaseItem: any = createAsyncThunk('addInventoryPurchaseItem', async (body: any) => {
  try {
    const response = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/purchasing/create_purchasing_item/${body.id}`,
      body.body,
    );
    return response;
  } catch (error: any) {
    return error.response.data;
  }
});
export const addReturnItem: any = createAsyncThunk('addReturnItem', async (body: any) => {
  const response = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/purchasing/add_new_items_to_retruen_to_supplier/${body.id}`,
    body.body,
  );
  return response;
});
export const updateInventoryPurchaseItem: any = createAsyncThunk('updateInventoryPurchaseItem', async (body: any) => {
  const response = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/purchasing/update_purchasing_item/${body.id}`,
    body.body,
  );
  return response;
});
export const updateReturnItem: any = createAsyncThunk('updateReturnItem', async (body: any) => {
  try {
    const response = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/purchasing/update_return_to_suppliers_item/${body.id}`,
      body.body,
    );
    return response;
  } catch (error: any) {

    notify(error.data.message);
    return error.data.message;
  }
});
export const updateReturnItems: any = createAsyncThunk('updateReturnItems', async (body: any) => {
  const response = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/purchasing/update_qtys_costs`, body);
  return response;
});
export const updateInventoryPurchaseItems: any = createAsyncThunk('updateInventoryPurchaseItems', async (body: any) => {
  const response = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/purchasing/update_qtys_costs`, body);
  return response;
});
export const editInventoryPurchase: any = createAsyncThunk('editInventoryPurchase', async (body: any) => {
  const response = await fetch9(`${process.env.REACT_APP_BASEURL}/inventory/purchasing/${body.id}`, body.body);
  return response;
});
export const editReturnSupplier: any = createAsyncThunk('editInventoryPurchase', async (body: any) => {
  const response = await fetch9(
    `${process.env.REACT_APP_BASEURL}/inventory/purchasing/update_return_to_supplier/${body.id}`,
    body.body,
  );
  return response;
});
export const deleteInventoryPurchase: any = createAsyncThunk('deleteInventoryPurchase', async id => {
  const response = await fetch10(`${process.env.REACT_APP_BASEURL}/inventory/purchasing/${id}`);
  return response;
});
export const deleteInventoryPurchaseItem: any = createAsyncThunk('deleteInventoryPurchaseItem', async (body: any) => {
  const response = await fetch10(
    `${process.env.REACT_APP_BASEURL}/inventory/purchasing/${body.id}/delete_purchasing_item/${body.item_id}`,
  );
  return response;
});
const inventoryPurchaseApiReducer: any = createSlice({
  name: 'inventoryPurchaseApiReducer',
  initialState,
  reducers: {
    setPurchaseViewIds: (state, action) => {
      state.inventoryPurchaseBrancheId = action.payload.branchId;
      state.inventoryPurchaseSupplierId = action.payload.supplierId;
    },

    restPurchaseId: (state, action) => {
      state.inventoryPurchaseId = null;
    },
    restPurchaseDetails: (state, action) => {
      state.inventoryPurchaseDetails = {};
    },
    changeIsFiltered(state = initialState, action: any) {
      state.isFiltered = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createInventoryPurchase.fulfilled, (state: any, action: any) => {
        state.inventoryPurchaseId = action?.payload?.data?.id;

        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          if (action.payload.status) notify(action.payload.message, 'success');
          else notify(action.payload.message);
          state.error = action.payload.message;
          state.error = action.payload.message;
        }
      })
      .addCase(duplicateInventoryPurchasing.fulfilled, (state: any, action: any) => {
        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          notify(action.payload.message, 'success');

          state.error = action.payload.message;
        }
      })
      .addCase(createInventoryPurchase.rejected, (state: any, action: any) => {
        state.inventoryPurchaseId = action.payload.data.id;

        console.log(action.payload);

        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          if (action.payload.status) notify(action.payload.message, 'success');
          else notify(action.payload.message);
          state.error = action.payload.message;
          state.error = action.payload.message;
        }
      })
      .addCase(receiveItemsInventoryPurchase.rejected, (state: any, action: any) => {
        notify(action.error.message, 'error');
        console.log(action, "jkghj");




      })
      .addCase(addInventoryPurchaseItem.fulfilled, (state: any, action: any) => {
        console.log(action.payload);

        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          if (action.payload.status) notify(action.payload.message, 'success');
          else notify(action.payload.message);
          state.error = action.payload.message;
          state.error = action.payload.message;
        }
      })
      .addCase(addInventoryPurchaseItem.rejected, (state: any, action: any) => {

        notify(action.error.message, 'error')

      })
      .addCase(getInventoryPurchaseList.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.inventoryPurchaseListCount += 1;
        state.inventoryPurchaseList = action.payload.data;

        if (action.payload.errors) notify(action.payload.message);
      })
      .addCase(getInventoryPurchaseList.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getInventoryPurchaseById.fulfilled, (state: any, action: any) => {
        state.inventoryPurchaseDetails = action.payload.data;
        state.loading = false;

        if (action.payload.errors) notify(action.payload.message);
      })
      .addCase(getInventoryPurchaseById.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(updateReturnItem.fulfilled, (state: any, action: any) => {
        if (action.payload.errors) notify(action.payload.message);
      })
      .addCase(deleteInventoryPurchaseItem.fulfilled, (state: any, action: any) => {
        if (action.payload.errors) notify(action.payload.message);
      });
  },
});

export const { changeIsFiltered, setPurchaseViewIds, restPurchaseId, restPurchaseDetails } =
  inventoryPurchaseApiReducer.actions;

export default inventoryPurchaseApiReducer.reducer;
