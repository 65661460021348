import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetch5, fetch6, fetch10, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

export let inventoryPurchaseList: any = [];

const initialState = {
  loading: false,
  isFiltered: false,
  error: null,
  type: null,
  source: null,
  customer: null,
  destination: null,
  franchise_id: null,
  notice: null,
  business_date: null,
  inventoryPurchaseItems: [],
  salesOrderList: [],
  inventoryPurchaseTags: [],
  inventoryPurchaseDetails: {},
  inventoryPurchaseListCount: 0,
  inventoryPurchaseBrancheId: null,
  inventoryPurchaseId: null,
  inventoryPurchaseSupplierId: null,
  filterList: {}
};

export const getSalesOrderList: any = createAsyncThunk('getSalesOrderList', async body => {
  const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/sale-order`, body);
  // inventoryPurchaseList = response;
  return response;
});

export const getSalesOrderById: any = createAsyncThunk('getSalesOrderById', async id => {
  const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/sale-order/${id}`);
  return response;
});
export const exportSalesOrderById: any = createAsyncThunk('exportSalesOrderById', async id => {
  const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/sale-order/${id}/pdf`);
  return response;
});

export const createSalesOrder: any = createAsyncThunk('createSalesOrder', async body => {
  try {
    const response = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/sale-order`, body);
    return response;
  } catch (error: any) {
    return error.response.data;
  }
});
export const duplicateInventoryPurchasing: any = createAsyncThunk('duplicateInventoryPurchasing', async (id: any) => {
  try {
    const response = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/sale-order/duplicate/` + id);
    return response;
  } catch (error: any) {
    return error.response.data;
  }
});

export const addSalesOrderItem: any = createAsyncThunk('addSalesOrderItem', async (body: any) => {
  try {
    const response = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/sale-order/${body.id}/attach-item`,
      body.body,
    );
    return response;
  } catch (error: any) {
    return error.response.data;
  }
});
export const pay: any = createAsyncThunk('pay', async (body: any) => {
  try {
    const response = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/sale-order/${body.id}/pay`, body.body);
    return response;
  } catch (error: any) {
    return error.response.data;
  }
});
export const confirmSalesOrder: any = createAsyncThunk('pay', async (id: any) => {
  try {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/sale-order/${id}/closed`);
    return response;
  } catch (error: any) {
    return error.response.data;
  }
});

export const updateSalesOrderItem: any = createAsyncThunk('updateSalesOrderItem', async (body: any) => {
  const response = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/sale-order/update_items/${body.id}`,
    body.body,
  );
  return response;
});

export const updateSalesOrderItems: any = createAsyncThunk('updateSalesOrderItems', async (body: any) => {
  const response = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/sale-order/${body.id}/update-items`, body);
  return response;
});
export const editSalesOrder: any = createAsyncThunk('editSalesOrder', async (body: any) => {
  const response = await fetch9(`${process.env.REACT_APP_BASEURL}/inventory/sale-order/${body.id}`, body.body);
  return response;
});

export const deleteSalesOrder: any = createAsyncThunk('deleteSalesOrder', async id => {
  const response = await fetch10(`${process.env.REACT_APP_BASEURL}/inventory/sale-order/${id}`);
  return response;
});
export const deleteSalesOrderItem: any = createAsyncThunk('deleteSalesOrderItem', async (body: any) => {
  const response = await fetch10(
    `${process.env.REACT_APP_BASEURL}/inventory/sale-order/${body.id}/delete_purchasing_item/${body.item_id}`,
  );
  return response;
});
const SalesOrdersApiReducer: any = createSlice({
  name: 'SalesOrdersApiReducer',
  initialState,
  reducers: {
    setViewIds: (state, action) => {
      state.type = action.payload.type;
      state.source = action.payload.branch_id;
      state.destination = action.payload.destination_id;
      state.franchise_id = action.payload.franchise_id;
      state.customer = action.payload.customer_id;
      state.business_date = action.payload.business_date;
      state.notice = action.payload.notice;
    },

    restPurchaseId: (state, action) => {
      state.inventoryPurchaseId = null;
    },
    restPurchaseDetails: (state, action) => {
      state.inventoryPurchaseDetails = {};
    },
    changeIsFiltered(state = initialState, action: any) {
      state.isFiltered = action.payload;
    },
    changeFilterList(state = initialState, action: any) {
      state.filterList = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createSalesOrder.fulfilled, (state: any, action: any) => {
        state.inventoryPurchaseId = action?.payload?.data?.id;

        console.log(action.payload);

        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          if (action.payload.status) notify(action.payload.message, 'success');
          else notify(action.payload.message);
          state.error = action.payload.message;
          state.error = action.payload.message;
        }
      })
      .addCase(duplicateInventoryPurchasing.fulfilled, (state: any, action: any) => {
        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          if (action.payload.status) notify(action.payload.message, 'success');
          else notify(action.payload.message);
          state.error = action.payload.message;
          state.error = action.payload.message;
        }
      })
      .addCase(createSalesOrder.rejected, (state: any, action: any) => {
        state.inventoryPurchaseId = action.payload.data.id;

        console.log(action.payload);

        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          if (action.payload.status) notify(action.payload.message, 'success');
          else notify(action.payload.message);
          state.error = action.payload.message;
          state.error = action.payload.message;
        }
      })
      .addCase(addSalesOrderItem.fulfilled, (state: any, action: any) => {
        console.log(action.payload);

        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          if (action.payload.status) notify(action.payload.message, 'success');
          else notify(action.payload.message);
          state.error = action.payload.message;
          state.error = action.payload.message;
        }
      })
      .addCase(getSalesOrderList.fulfilled, (state: any, action: any) => {
        state.inventoryPurchaseListCount += 1;
        state.loading = false;

        state.salesOrderList = action.payload;

        if (action.payload.errors) notify(action.payload.message);
      })
      .addCase(getSalesOrderList.pending, (state: any, action: any) => {
        state.loading = true;
      })
      .addCase(getSalesOrderById.fulfilled, (state: any, action: any) => {
        state.details = action.payload.data;

        if (action.payload.errors) notify(action.payload.message);
      })
      .addCase(deleteSalesOrderItem.fulfilled, (state: any, action: any) => {
        if (action.payload.errors) notify(action.payload.message);
      });
  },
});

export const { changeIsFiltered, setViewIds, restPurchaseId, restPurchaseDetails, changeFilterList } = SalesOrdersApiReducer.actions;

export default SalesOrdersApiReducer.reducer;
