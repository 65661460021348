import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch10, fetch2, fetch22, fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';
import { trueFalseOptions } from '../../constants/dropdownconstants';

const initialState = {
  loading: false,
  loadingProgress: false,
  userDeviceListCount: 0,
  createDeviceCount: 0,
  branchListCount: 0,
  loadingStatus: 0,
  deviceDetailCount: 0,
  editDeviceCount: 0,
  deleteDeviceCount: 0,
  tagsListData: [],
  pageLoading: false,
  userDeviceListData: [],
  isFiltered: false,
  pageCount: 0,
};

export let userDeviceFilterValue: any = {
  filter: {
    code: '',
    type: 0,
    ['tags.id']: [],
    in_use: 0,
    is_online_receiver: 0,
    branch_id: [],
    reference: '',
    name: '',
    is_deleted: false,
  },
  type: 'all',
  include: ['branch', 'group'],
};

export let userDeviceOrderFilterValue: any = {
  filter: {
    is_deleted: false,
  },
  type: 'all',
};

export let filterArray: any = {
  tag: [],
  branches: [],
};
export let userDeviceListItems: any = [];
export let singleUserDeviceDetails: any;
export let branchData: any;
export let generateReferenceData: any;
export let bodyData: any;

//Get all devices list
export const userDeviceList: any = createAsyncThunk('userDeviceList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/devices`, body);
  bodyData = body;

  userDeviceListItems = result.data;
  return result;
});
//Get all devices list
export const userDeviceListAll: any = createAsyncThunk('userDeviceListAll', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/devices`, body);

  return result;
});
//get one device
export const getDeviceDetail: any = createAsyncThunk('getDeviceDetail', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/devices/${body}`);
  singleUserDeviceDetails = result;

  return result;
});
//tags filter
export const tagsList: any = createAsyncThunk('tagsList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);
  return result;
});
export const createDeviceData: any = createAsyncThunk('createDeviceData', async body => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/devices`, body);
  return result;
});
//PUT
export const modifyDevice: any = createAsyncThunk('modifyDevice', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/devices/${body.id}`, body);
  return result;
});
export const modifyDeviceSettings: any = createAsyncThunk('modifyDeviceSettings', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/devices/${body.id}`, { settings: body });
  return result;
});
export const copyDeviceSettings: any = createAsyncThunk('copyDeviceSettings', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/devices/${body.id}/copy_settings`, {
    devices_ids: body.target_id,
  });
  return result;
});
export const branchGet: any = createAsyncThunk('branchGet', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/branches`, { type: 'all' });
  branchData = [];
  result.data.map((e: any) => {
    branchData.push({
      label: e.name,
      value: e.id,
    });
  });
  return result;
});
//done
export const addDeviceTags: any = createAsyncThunk('addDeviceTags', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/devices/${body.device_ids}`, body);
  return result;
});
export const removeDeviceTags: any = createAsyncThunk('removeDeviceTags', async (body: any) => {
  const result = await fetch10(`${process.env.REACT_APP_BASEURL}/manage/devices/${body.device_ids}`, body);
  return result;
});
//done
export const deleteDevice: any = createAsyncThunk('deleteDevice', async (body: any) => {
  const result = await fetch10(`${process.env.REACT_APP_BASEURL}/manage/devices/${body.id}`, body);
  return result;
});
export const tableDevice: any = createAsyncThunk('tableDevice', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/tables`, body);

  return result;
});

export const generateReference: any = createAsyncThunk('generateReference', async (body: any) => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/generate_reference`, {
      model: 'devices',
      type: body,
    });
    generateReferenceData = result.data;
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});
export const syncDevicesApi: any = createAsyncThunk('syncDevicesApi', async (body: any) => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/devices/force-sync`, body);
    notify(result?.[0], 'success');
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});
const manageDeviceSlice: any = createSlice({
  name: 'manageDevice',
  initialState,
  reducers: {
    setshowLoading(state = initialState) {
      state.loadingStatus += 1;
    },
    setPageLoading(state = initialState) {
      state.pageLoading = true;
    },
    setFilterData(state = initialState, action) {
      state.isFiltered = true;
      userDeviceFilterValue.filter.type = action.payload.type;
      userDeviceFilterValue.filter.name = action.payload.name;
      userDeviceFilterValue.filter.code = action.payload.code;
      userDeviceFilterValue.filter.branch_id = action.payload.branch_id;
      userDeviceFilterValue.filter.branch_by_tag = action.payload.branch_by_tag;
      userDeviceFilterValue.filter.reference = action.payload.reference;
      userDeviceFilterValue.filter['tags.id'] = action.payload['tags.id'];
      userDeviceFilterValue.filter.in_use = action.payload.in_use;
      userDeviceFilterValue.filter.is_online_receiver = action.payload.is_online_receiver;
    },
    resetFilterState(state = initialState) {
      state.isFiltered = false;

      userDeviceFilterValue.filter.type = '';
      userDeviceFilterValue.filter.name = '';
      userDeviceFilterValue.filter.code = '';
      userDeviceFilterValue.filter.branch_id = [];
      userDeviceFilterValue.filter.branch_by_tag = '';
      userDeviceFilterValue.filter.reference = '';
      userDeviceFilterValue.filter['tags.id'] = [];
      userDeviceFilterValue.filter.in_use = '';
      userDeviceFilterValue.filter.is_online_receiver = '';
      filterArray.tag = [];
      filterArray.branches = [];
      userDeviceFilterValue.filter.is_deleted = false;
    },
    setPageCount(state = initialState, action) {
      state.pageCount = action.payload;
    },
  },
  extraReducers: {
    [userDeviceList.fulfilled]: (state: any, action) => {
      state.loading = false;

      state.loadingProgress = false;
      state.userDeviceListCount += 1;
      state.userDeviceListData = action.payload;

      // if (error) {
      //     state.error = error;
      // } else {
      //     state.error = message;
      // }
    },
    [userDeviceList.pending]: (state: any) => {
      state.loading = true;
      state.loadingProgress = true;
    },
    [userDeviceList.rejected]: (state: any) => {
      state.loading = false;
      state.loadingProgress = false;
    },
    [userDeviceListAll.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.userDeviceListDataAll = action.payload;
    },
    [userDeviceListAll.rejected]: (state: any, action: any) => {
      state.loading = false;

      // notify(action.error.message);
    },

    [createDeviceData.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.createDeviceCount += 1;
      notify(message, 'success');
      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, 'success');
        else notify('Create Device successfully', 'success');
        // state.error = message;
      }
    },
    [createDeviceData.rejected]: (state: any, action: any) => {
      state.loading = false;

      notify(action.error.message);
    },

    //tags
    [tagsList.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.tagsListData = action.payload;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [tagsList.rejected]: (state: any) => {
      state.loading = false;
    },

    [tableDevice.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.tagsListData = action.payload;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [tableDevice.rejected]: (state: any) => {
      state.loading = false;
    },
    [modifyDevice.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.editDeviceCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, 'success');
        else notify(`Edit  Device successfully`, 'success');

        state.error = message;
      }
    },
    [modifyDevice.rejected]: (state: any, action: any) => {
      state.loading = false;
      notify(action.error.message);
    },
    [modifyDeviceSettings.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.editDeviceCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify('Device settings modified successfully', 'success');
        else notify('Device settings modified successfully', 'success');
        state.error = message;
      }
    },
    [modifyDeviceSettings.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.error;
      notify(action.error.message);
    },
    [copyDeviceSettings.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.editDeviceCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify('settings copied successfull', 'success');
        else notify('settings copied successfully', 'success');
        state.error = message;
      }
    },
    [removeDeviceTags.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.editDeviceCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [addDeviceTags.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.editDeviceCount += 1;
      if (error) {
        state.error = error;
        notify(error, 'success');
      } else {
        notify('Add tags successfully', 'success');

        // else notify(msg);
        // state.error = msg;
      }
    },
    [addDeviceTags.rejected]: (state: any, action) => {
      state.loading = false;
      state.error = action.error;
      notify(action.error.message);
    },
    [branchGet.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.branchListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [getDeviceDetail.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.pageLoading = false;
      state.deviceDetailCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [getDeviceDetail.rejected]: (state: any) => {
      state.loading = false;
      state.pageLoading = false;
    },
    [deleteDevice.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.deleteDeviceCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify('device deleted successfully', 'success');
        state.error = message;
      }
    },
    [deleteDevice.rejected]: (state: any, action: any) => {
      state.error = action.error;

      notify(action.error.message);
    },
  },
});

export const { setshowLoading, setFilterData, resetFilterState, setPageLoading, setPageCount } =
  manageDeviceSlice.actions;
export default manageDeviceSlice.reducer;
