import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch2, fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  groupListItems: {},
  loading: false,
  groupDetails: {},
  showPageLoading: false,
  groupSortListItems: {},
  loadingGroup: false,
  isDataFiltered: false,
  progressLoader: false,
  pageCount: 0,
  groupListCount: 0,
  addGroupCount: 0,
  editdeleteGroupCount: 0,
  deleteGroupCount: 0,
  groupViewCount: 0,
  loadingStatus: 0,
  modifySortCount: 0,
  groupSortListCount: 0,
};

export let groupFilterValue: any = {
  type: '',
  name: '',
  deleted: '',
  updateafter: '',
};

export let groupListItems: any;
export let groupSortListItems: any;
export let groupViewPerId: any;

// ------------------------------------------------
export const getGroupList: any = createAsyncThunk('getGroupList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/groups`, body);

  return result;
});

// -----------------------------------------------

export const groupDetails: any = createAsyncThunk('groupDetails', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/groups/${body}`);
  return result;
});
// ------------------------------------------------
export const addGroup: any = createAsyncThunk('addGroup', async body => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/menu/groups`, body);
  return result;
});
// ------------------------------------------------
export const editGroup: any = createAsyncThunk('editGroup', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/menu/groups/${body.id}`, body);
  return result;
});
// ------------------------------------------------
export const editAddGroup: any = createAsyncThunk('editAddGroup', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/menu/groups/${body.id}`, { ...body, _method: 'PUT' });
  return result;
});
// ------------------------------------------------
export const deleteGroup: any = createAsyncThunk('deleteGroup', async (body: any) => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/menu/groups/${body}`, 'delete');
  return result;
});
// ------------------------------------------------
export const restoreGroup: any = createAsyncThunk('restoreGroup', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/menu/groups/${body}/restore`);
  return result;
});
// ------------------------------------------------
export const groupRelationSort: any = createAsyncThunk('groupRelationSort', async body => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/group_relations_sort?group_id=${body}`, 'get');
  return result;
});
// ------------------------------------------------
export const addCategoryToGroup: any = createAsyncThunk('addCategoryToGroup', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/menu/groups/addGroupsToCategory/${body?.id}`, body);
  return result;
});
// ------------------------------------------------
export const addTagToGroup: any = createAsyncThunk('addTagToGroup', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/menu/groups/addGroupsToTag/${body?.id}`, body);
  return result;
});
// ------------------------------------------------

export const saveSubGroup: any = createAsyncThunk('saveSubGroup', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/saveSubGroup`, body);
  return result;
});
export const addEditGroupCombos: any = createAsyncThunk('addEditGroupCombos', async body => {
  console.log(body, 'addEditGroupCombos');

  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/add_edit_Group_Combos`, body);
  return result;
});
export const addEditGroupGiftProducts: any = createAsyncThunk('addEditGroupGiftProducts', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/add_edit_Group_giftProducts`, body);
  return result;
});
export const addEditGroupProducts: any = createAsyncThunk('addEditGroupProducts', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/add_edit_Group_Products`, body);
  return result;
});

export const modifyGroupRelationsSort: any = createAsyncThunk('modifyGroupRelationsSort', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/modify_group_relations_sort`, body);
  return result;
});
const menuGroupSlice: any = createSlice({
  name: 'menuGroupSlice',
  initialState,
  reducers: {
    setshowLoading(state = initialState) {
      state.loading = true;
    },
    setShowPageLoading(state = initialState) {
      state.showPageLoading = true;
    },
    setIsDataFiltered(state = initialState, action: any) {
      state.isDataFiltered = action.payload;
    },
    setProgressLoading(state = initialState, action: any) {
      state.progressLoader = action.payload;
    },
    setPageCount(state = initialState, action: any) {
      state.pageCount = action.payload;
    },
  },
  extraReducers: {
    [getGroupList.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.loadingGroup = false;
      state.groupListItems = action.payload;
    },
    [getGroupList.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.loadingGroup = false;
    },
    [getGroupList.pending]: (state: any) => {
      state.loading = true;
      state.loadingGroup = true;
    },
    // ------------------------------------------------
    [groupDetails.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.showPageLoading = false;
      state.groupDetails = action.payload;
    },
    [groupDetails.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.showPageLoading = false;
    },
    [groupDetails.pending]: (state: any) => {
      state.loading = true;
      state.showPageLoading = true;
    },
    // ------------------------------------------------
    [addGroup.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [addGroup.rejected]: (state: any, action: any) => {
      state.loading = false;
    },
    // ------------------------------------------------
    [editGroup.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [editGroup.rejected]: (state: any, action: any) => {
      state.loading = false;
    },
    // ------------------------------------------------
    [editAddGroup.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [editAddGroup.rejected]: (state: any, action: any) => {
      state.loading = false;
    },
    // ------------------------------------------------
    [deleteGroup.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [deleteGroup.rejected]: (state: any, action: any) => {
      state.loading = false;
    },
    // ------------------------------------------------
    [restoreGroup.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [restoreGroup.rejected]: (state: any, action: any) => {
      state.loading = false;
    },
    // ------------------------------------------------
    [groupRelationSort.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [groupRelationSort.rejected]: (state: any, action: any) => {
      state.loading = false;
    },
    // ------------------------------------------------
    [addCategoryToGroup.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [addCategoryToGroup.rejected]: (state: any, action: any) => {
      state.loading = false;
    },
    // ------------------------------------------------
    [addTagToGroup.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.error = action.payload.message;
      }
    },
    [addTagToGroup.rejected]: (state: any, action: any) => {
      state.loading = false;
    },
    // ------------------------------------------------
    [modifyGroupRelationsSort.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.loadingStatus += 1;
      state.modifySortCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },

    [saveSubGroup.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.loadingStatus += 1;
      state.editdeleteGroupCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [addEditGroupCombos.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.loadingStatus += 1;
      state.editdeleteGroupCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [addEditGroupProducts.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.loadingStatus += 1;
      state.editdeleteGroupCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [addEditGroupGiftProducts.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.loadingStatus += 1;
      state.editdeleteGroupCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
  },
});

export const { resetGroup, setshowLoading, setShowPageLoading, setIsDataFiltered, setProgressLoading, setPageCount } =
  menuGroupSlice.actions;
export default menuGroupSlice.reducer;
