import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetch5, fetch6, fetch10, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';
import { RoundNum } from '../../utils/functions';

const initialState = {
  loading: false,
  filterStatus: false,
  error: null,
  filterBody: null,
  inventoryLevelsReportsList: {},
  inventoryLevelsMetaReports: {},
  getInventoryLevelsbranchesData: [],
  getInventoryLevelscategoriesData: [],
  getInventoryLevelsitemsData: [],
  getInventoryLevelsTagsData: [],
};

export const getInventoryLevelsReportsList: any = createAsyncThunk('getInventoryLevelsReportsList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/reports/inventory-report/inventory_levels`, body);
  return result;
});
export const getInventoryLevelsbranches: any = createAsyncThunk('getInventoryLevelsbranches', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/branches`, body);
  return result.data;
});
export const getInventoryLevelscategories: any = createAsyncThunk('getInventoryLevelscategories', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory-categories`, body);
  return result.data;
});
export const getInventoryLevelsitems: any = createAsyncThunk('getInventoryLevelsitems', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items`, body);
  return result.data;
});
export const getInventoryLevelsTags: any = createAsyncThunk('getInventoryLevelsTags', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);
  return result.data;
});

const ReportsInventoryLevelsApiReducer: any = createSlice({
  name: 'ReportsInventoryLevelsApiReducer',
  initialState,
  reducers: {
    changeFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    setFilter: (state, action) => {
      state.filterBody = action.payload;
    },
  },
  extraReducers: {
    [getInventoryLevelsReportsList.pending]: state => {
      state.loading = true;
    },
    [getInventoryLevelsReportsList.fulfilled]: (state, action) => {
      state.loading = false;
      state.inventoryLevelsReportsList = {
        data: action.payload.data.map((obj: { [key: string]: any }) => {
          Object.entries(obj).forEach(([key, value]: any) => {
            obj[key] = !isNaN(+value) ? RoundNum(+value) : value;
          });

          return obj;
        }),
        ...action.payload,
      };

      // notify('update inventory lvl','success')
    },
    [getInventoryLevelsReportsList.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    [getInventoryLevelsbranches.pending]: state => {
      state.loading = true;
    },
    [getInventoryLevelsbranches.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryLevelsbranchesData = action.payload;
    },
    [getInventoryLevelsbranches.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    [getInventoryLevelscategories.pending]: state => {
      state.loading = true;
    },
    [getInventoryLevelscategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryLevelscategoriesData = action.payload;
    },
    [getInventoryLevelscategories.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    [getInventoryLevelsitems.pending]: state => {
      state.loading = true;
    },
    [getInventoryLevelsitems.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryLevelsitemsData = action.payload;
    },
    [getInventoryLevelsitems.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    [getInventoryLevelsTags.pending]: state => {
      state.loading = true;
    },
    [getInventoryLevelsTags.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryLevelsTagsData = action.payload;
    },
    [getInventoryLevelsTags.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
  },
});

export const { changeFilterStatus, setFilter } = ReportsInventoryLevelsApiReducer.actions;
export default ReportsInventoryLevelsApiReducer.reducer;
