import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetch5, fetch6, fetch10, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';
import { RoundNum } from '../../utils/functions';

const initialState = {
  loading: false,
  filterStatus: false,
  error: null,
  filterBody: null,
  purchasingReportsList: [],
  getInventorypurchasingbranchesData: [],
  getInventorypurchasingcategoriesData: [],
  getInventorypurchasingitemsData: [],
  getInventorypurchasingTagsData: [],
  getInventorypurchasingSupplierData: [],
};

export const getPurchasingReportsList: any = createAsyncThunk('getPurchasingReportsList', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/reports/inventory-report/inventory_purchasing`, body);
  return result;
});

export const getInventorypurchasingbranches: any = createAsyncThunk(
  'getInventorypurchasingbranches',
  async (body: any) => {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/branches`, body);
    return result.data;
  },
);
export const getInventorypurchasingcategories: any = createAsyncThunk(
  'getInventorypurchasingcategories',
  async (body: any) => {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/inventory-categories`, body);
    return result.data;
  },
);
export const getInventorypurchasingitems: any = createAsyncThunk('getInventorypurchasingitems', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items`, body);
  return result.data;
});
export const getInventorypurchasingTags: any = createAsyncThunk('getInventorypurchasingTags', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);
  return result.data;
});
export const getInventorypurchasingSupplier: any = createAsyncThunk(
  'getInventorypurchasingSupplier',
  async (body: any) => {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/suppliers`);
    return result.data;
  },
);
const ReportsPurchasingApiReducer: any = createSlice({
  name: 'ReportsPurchasingApiReducer',
  initialState,
  reducers: {
    changeFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    setFilter: (state, action) => {
      state.filterBody = action.payload;
    },
  },
  extraReducers: {
    [getPurchasingReportsList.pending]: state => {
      state.loading = true;
    },
    [getPurchasingReportsList.fulfilled]: (state, action) => {
      state.loading = false;
      state.purchasingReportsList = action.payload.data.map((obj: { [key: string]: any }) => {
        Object.entries(obj).forEach(([key, value]: any) => {
          obj[key] = !isNaN(+value) ? RoundNum(+value) : value;
        });

        return obj;
      });
    },
    [getPurchasingReportsList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getInventorypurchasingSupplier.pending]: state => {
      state.loading = true;
    },
    [getInventorypurchasingSupplier.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventorypurchasingSupplierData = action.payload;
    },
    [getInventorypurchasingSupplier.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getInventorypurchasingbranches.pending]: state => {
      state.loading = true;
    },
    [getInventorypurchasingbranches.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventorypurchasingbranchesData = action.payload;
    },
    [getInventorypurchasingbranches.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventorypurchasingcategories.pending]: state => {
      state.loading = true;
    },
    [getInventorypurchasingcategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventorypurchasingcategoriesData = action.payload;
    },
    [getInventorypurchasingcategories.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventorypurchasingitems.pending]: state => {
      state.loading = true;
    },
    [getInventorypurchasingitems.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventorypurchasingitemsData = action.payload;
    },
    [getInventorypurchasingitems.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getInventorypurchasingTags.pending]: state => {
      state.loading = true;
    },
    [getInventorypurchasingTags.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventorypurchasingTagsData = action.payload;
    },
    [getInventorypurchasingTags.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});
export const { changeFilterStatus, setFilter } = ReportsPurchasingApiReducer.actions;
export default ReportsPurchasingApiReducer.reducer;
