import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { fetch10, fetch2, fetch3, fetch4, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  loading: true,
  inventoryItemListCount: 0,
  getCategoriesInItemListCount: 0,
  addEditInventoryItemCount: 0,
  singleInventoryItemCount: 0,
  tag_forItemCount: 0,
  get_ingridentForItemCount: 0,
  get_supplier_itemCount: 0,
  item_tag_ingre_supp_branchCount: 0,
  loadingStatus: 0,
  inventoryItemListItems: [],
  loadingProgress: false,
  isFiltered: false,
  generateReferenceData: '',
  loadingReference: false,
  showSupplierListIdData: {},
  singleInventoryItemingredientDetails: {},
  pageLoading: false,
  deleted: '',
  addInventory: {},
  TagsInInventoryItemListItemsBranch: [],
  inventoryItemListLoading: false,
  inventoryItemListDropDownData: [],
};
export let inventoryItem_list_delete: any = {
  name: '',
  sku: '',
  categories: [],
  barcode: '',
  tags: [],
  costing_method: null,
  updated_after: '',
  is_deleted: null,

  supplier: [],
};

export let filterArray: any = {
  categories: [],
  suppliers: [],
  tag: [],
};
// export let inventoryItemListItems: any;
export let CategoryInInventoryItemListItems: any;
export let TagsInInventoryItemListItems: any;
export let TagsInInventoryItemListItemsBranch: any;
export let IngredientsInInventoryItemListItems: any;
export let SupplierInInventoryItemListItems: any;
export let singleInventoryItemDetails: any;
export let getAllTagSupplierIngridentCustomLevel: any;
export let inventoryItemId: number | string;
export let parameter: string;
export let addInventoryItemType: string;
export let SelectedItemIds: any = [];
export let addInventory: any = {};

export const inventoryItemList: any = createAsyncThunk('inventoryItemList', async (body: any) => {
  try {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/inventory/items?include[0]=category&per_page=50`,
      body,
    );

    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});
export const inventoryItemListDropDown: any = createAsyncThunk('inventoryItemListDropDown', async (body: any) => {
  try {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/inventory/items`,
      body,
    );

    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const getCategoriesInItemList: any = createAsyncThunk('get_categories_initem', async () => {
  const result = await fetch6(
    `${process.env.REACT_APP_BASEURL}/inventory/inventory-categories?filter[is_deleted]=false&type=all`,
  );
  CategoryInInventoryItemListItems = result.data;
  console.log(result, 'CategoryInInventoryItemListItems');

  return result;
});

export const tag_foritem: any = createAsyncThunk('manage/tags', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);
  TagsInInventoryItemListItems = result.data;
  // console.log(TagsInInventoryItemListItems, "TagsInInventoryItemListItems");
  return result;
});

export const tag_branch: any = createAsyncThunk('tag_branch', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);

  console.log(result, 'TagsInInventoryItemListItems');
  return result.data;
});

//

export const get_supplier_item: any = createAsyncThunk('get_supplier_item', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/suppliers`, body);
  SupplierInInventoryItemListItems = result.data;
  // console.log(
  //   SupplierInInventoryItemListItems,
  //   "SupplierInInventoryItemListItems"
  // );
  return result;
});

export const addInventoryItem: any = createAsyncThunk(
  'addInventoryItem',

  async (body: any) => {
    try {
      const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/items`, body);

      return result;
    } catch (error: any) {
      return notify(error?.response?.data?.message);
    }
  },
);
export const restoreItem: any = createAsyncThunk(
  'restoreItem',

  async (body: any) => {
    try {
      const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items/restore_items_deleted/${body.id}`);

      return result;
    } catch (error: any) {
      return notify(error?.response?.data?.message);
    }
  },
);
export const editInventoryItem: any = createAsyncThunk(
  'editInventoryItem',

  async (body: any) => {
    // try {
    const result = await fetch9(`${process.env.REACT_APP_BASEURL}/inventory/items/${body.id}`, body);

    notify('Edit Item Sucessfully', 'success');

    return result;
    // }
  },
);

export const addSupplier: any = createAsyncThunk(
  'addSupplier',

  async (body: any) => {
    try {
      const result = await fetch5(
        `${process.env.REACT_APP_BASEURL}/inventory/suppliers/add_or_update_items/${body.id}`,
        body,
      );

      // notify('Add Supplier Sucessfully', 'success');

      return result;
    } catch (error: any) {
      return notify(error?.response?.data?.message);
    }
  },
);
export const editSupplier: any = createAsyncThunk(
  'editSupplier',

  async (body: any) => {
    try {
      const result = await fetch5(
        `${process.env.REACT_APP_BASEURL}/inventory/suppliers/update_supplier_items/${body.id}`,

        body,
      );
      notify('Edit  Supplier Sucessfully', 'success');

      return result;
    } catch (error: any) {
      return notify(error?.response?.data?.message);
    }
  },
);
export const deleteSupplier: any = createAsyncThunk(
  'deleteSupplier',

  async (body: any) => {
    try {
      const result = await fetch10(
        `${process.env.REACT_APP_BASEURL}/inventory/suppliers/remove_item_supplier/${body.id}`,
      );
      notify('Delete Supplier Sucessfully', 'success');
      return result;
    } catch (error: any) {
      return notify(error?.response?.data?.message);
    }
  },
);

export const deleteIngredientsApi: any = createAsyncThunk(
  'deleteIngredientsApi',

  async (body: any) => {
    try {
      const result = await fetch10(
        `${process.env.REACT_APP_BASEURL}/inventory/items/${body.id}/ingredients/${body.itemId}`,
      );
      notify('Delete Ingredient Sucessfully', 'success');

      return result;
    } catch (error: any) {
      return notify(error?.response?.data?.message);
    }
  },
);

export const inventoryItemView: any = createAsyncThunk('inventoryItemView', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items/${body}`);

  return result.data;
});
export const inventoryItemViewIngredient: any = createAsyncThunk('inventoryItemViewIngredient', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items/${body}`);

  return result.data;
});

export const tagadd_item: any = createAsyncThunk('tagadd_item', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/items/add_or_update_tags/${body.id}`, body);

  return result;
});

export const ingridentadd_item: any = createAsyncThunk('ingredientAdd_items', async (body: any) => {
  try {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/items/${body.id}/ingredients/${body.itemId}`,
      body,
    );
    // notify('Add Ingredient Sucessfully', 'success');

    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const ingridentedit_item: any = createAsyncThunk('ingridentedit_item', async (body: any) => {
  try {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/items/${body.id}/ingredients/${body.itemId}`,
      body,
    );
    // console.log(result, "ingridentadd_item");
    notify('Edit Ingredient Sucessfully', 'success');

    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const supplieradd_item: any = createAsyncThunk('supplieradd_item', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/supplieradd_item`, body);
  // console.log(result, "supplieradd_item");
  return result;
});

export const customlevel_additem: any = createAsyncThunk('customlevel_additem', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/items/${body.id}/branches/${body.branchId}`,
    body,
  );
  // console.log(result, "customlevel_additem");
  return result;
});

export const customLevelEdit: any = createAsyncThunk('customLevelEdit', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/items/${body.id}/branches/${body.branchId}`,
    body,
  );
  // console.log(result, "customlevel_additem");
  return result;
});

export const customlevel_deleteitem: any = createAsyncThunk('customlevel_deleteitem', async (body: any) => {

  try {
    const result = await fetch10(
      `${process.env.REACT_APP_BASEURL}/inventory/items/${body.id}/branches/${body.branchId}`,
      body,
    );
    // console.log(result, "ingridentadd_item");
    notify('Deleted Sucessfully', 'success');

    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const selected_tagaddremove_delete: any = createAsyncThunk('selected_tagaddremove_delete', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/items/add_or_update_tags/${body.id}`, body);
  // console.log(result, "selected_tagaddremove_delete");
  return result;
});

export const item_deletemultiple: any = createAsyncThunk('item_deletemultiple', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/general/items/delete_bulck_data`, body);
  console.log(result, 'item_deletemultiple');
  return result;
});
export const item_restoremultiple: any = createAsyncThunk('item_restoremultiple', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/general/items/restore_bulck_data`, body);
  return result;
});
export const deleteItems: any = createAsyncThunk('deleteItems', async (id: any) => {
  const result = await fetch10(`${process.env.REACT_APP_BASEURL}/inventory/items/` + id);
  return result;
});
export const supplier_itemdelete: any = createAsyncThunk('item_deletemultiple', async id => {
  const result = await fetch10(`${process.env.REACT_APP_BASEURL}/inventory/suppliers/remove_item_supplier/${id}`);
  console.log(result, 'item_deletemultiple');
  return result;
});
export const importItems: any = createAsyncThunk('importItems', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/import_items`, body);
  return result;
});
export const importItemsIngredients: any = createAsyncThunk('importItemsIngredients', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/import_items_Ingredients`, body);
  return result;
});
export const exportItems: any = createAsyncThunk('exportItems', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/export_Items`, body);
  return result;
});
export const exportItemsIngredients: any = createAsyncThunk('exportItemsIngredients', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/export_items_Ingredients`, body);
  return result;
});

export const generateReference: any = createAsyncThunk('generateReferenceItems', async (body: any) => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/generate_sku`, {
      model: 'items',
    });

    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

// Supplier id Thunk
export const showSupplierListId: any = createAsyncThunk('showSupplierListId', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/suppliers/${body}`);
    return response.data;
  } catch (error: any) {
    notify(error?.response?.data?.message, 'error');
    throw error;
  }
});
const inventoryItemSlice: any = createSlice({
  name: 'InventoryItem',
  initialState,
  reducers: {
    setPageLoading(state = initialState) {
      state.pageLoading = true;
    },
    setTypeDeleted(state = initialState, action) {
      state.deleted = action.payload;
    },
    addType(state = initialState, action) {
      parameter = action.payload.parameter;
      addInventoryItemType = action.payload.name;
      inventoryItemId = action.payload.id;
    },
    changeIsFiltered(state = initialState, action) {
      console.log(action.payload.isFiltered);

      state.isFiltered = action.payload.isFiltered;
    },
    itemsFilterData(state = initialState, action) {
      state.isFiltered = true;
      //   userBranchFilterValue.type = action.payload.type;
      inventoryItem_list_delete.sku = action.payload.sku;
      inventoryItem_list_delete.name = action.payload.name;
      inventoryItem_list_delete.barcode = action.payload.barcode;

      inventoryItem_list_delete.tags = action.payload.tags;
      inventoryItem_list_delete.suppliers = action.payload.suppliers;
      inventoryItem_list_delete.categories = action.payload.categories;

      inventoryItem_list_delete.costing_method = action.payload.costing_method;
      inventoryItem_list_delete.updated_after = action.payload.updated_after;
      inventoryItem_list_delete.is_deleted = action.payload.is_deleted;
    },
    resetItem(state = initialState) {
      state.isFiltered = false;

      inventoryItem_list_delete.name = '';
      inventoryItem_list_delete.sku = '';
      inventoryItem_list_delete.barcode = '';

      inventoryItem_list_delete.costing_method = null;
      inventoryItem_list_delete.updated_after = '';
      inventoryItem_list_delete.is_deleted = null;

      filterArray.categories = [];
      filterArray.suppliers = [];
      filterArray.tag = [];
    },
    showLoadingoff(state = initialState) {
      state.loadingStatus += 1;
    },
    setItemIds(state = initialState, action: any) {
      SelectedItemIds = action.payload;
    },
    setIconShow(state = initialState, action: any) {
      console.log('ssss');

      state.isFiltered = true;
    },
    setIconReset(state = initialState, action: any) {
      state.isFiltered = false;
    },
  },
  extraReducers: {
    [inventoryItemList.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.inventoryItemListLoading = false;
      state.inventoryItemListItems = action.payload;

      state.loadingProgress = false;
    },
    [inventoryItemList.pending]: (state: any) => {
      state.loading = true;
      state.inventoryItemListLoading = true;
      state.loadingProgress = true;
    },
    [restoreItem.fulfilled]: (state: any, action: any) => {
      state.loading = false;

      state.pageLoading = false;
    },
    [restoreItem.pending]: (state: any) => {
      // state.loading = true;
      state.pageLoading = true;
      console.log('pending');
    },
    [inventoryItemList.rejected]: (state: any, action: any) => {
      state.loadingProgress = true;
      state.inventoryItemListLoading = false;
    },
    [inventoryItemListDropDown.pending]: (state: any, action: any) => {
      // state.inventoryItemListDropDownData = action.payload;
    },
    [inventoryItemListDropDown.fulfilled]: (state: any, action: any) => {
      state.inventoryItemListDropDownData = action.payload.data;
    },
    [getCategoriesInItemList.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.getCategoriesInItemListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [addInventoryItem.fulfilled]: (state: any, action: any) => {
      state.loadingStatus += 1;
      state.loading = false;
      notify('Create Item Successfully', 'success');
      state.addEditInventoryItemCount += 1;
      addInventory = action.payload;
    },
    [inventoryItemView.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.pageLoading = false;
      state.singleInventoryItemCount += 1;
      state.singleInventoryItemDetails = action.payload;
    },
    [inventoryItemView.pending]: (state: any, action: any) => {
      state.pageLoading = true;
    },
    [inventoryItemViewIngredient.fulfilled]: (state: any, action: any) => {
      state.loading = false;

      state.singleInventoryItemingredientDetails = action.payload;
    },
    [tag_foritem.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.tag_forItemCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },

    [get_supplier_item.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.get_supplier_itemCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [tagadd_item.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.loadingStatus += 1;
      state.item_tag_ingre_supp_branchCount += 1;
      if (error) {
        state.error = error;
      } else {
        let type = status ? 'success' : '';
        notify(massage, type);
        state.error = massage;
      }
    },
    [ingridentadd_item.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;

      if (error) {
        state.error = error;
      } else {
        let type = status ? 'success' : '';
        notify(massage, type);
        state.error = massage;
      }
    },
    [supplieradd_item.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.loadingStatus += 1;
      state.item_tag_ingre_supp_branchCount += 1;
      if (error) {
        state.error = error;
      } else {
        let type = status ? 'success' : '';
        notify(massage, type);
        state.error = massage;
      }
    },
    [customlevel_additem.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.loadingStatus += 1;
      state.item_tag_ingre_supp_branchCount += 1;
      if (error) {
        state.error = error;
      } else {
        let type = status ? 'success' : '';
        notify(massage, type);
        state.error = massage;
      }
    },
    [customlevel_deleteitem.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.loadingStatus += 1;
      state.item_tag_ingre_supp_branchCount += 1;
      if (error) {
        state.error = error;
      } else {
        let type = status ? 'success' : '';
        notify(massage, type);
        state.error = massage;
      }
    },
    [selected_tagaddremove_delete.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.loadingStatus += 1;
      state.inventoryItemListCount += 1;
      if (error) {
        state.error = error;
      } else {
        let type = status ? 'success' : '';
        notify(massage, type);
        state.error = massage;
      }
    },
    [item_deletemultiple.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.loadingStatus += 1;
      state.inventoryItemListCount += 1;
      if (error) {
        state.error = error;
      } else {
        let type = status ? 'success' : '';
        notify(massage, type);
        state.error = massage;
      }
    },
    [supplier_itemdelete.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.loadingStatus += 1;
      state.item_tag_ingre_supp_branchCount += 1;
      if (error) {
        state.error = error;
      } else {
        let type = 'success';
        notify('Deleted item Successfully', type);
        state.error = massage;
      }
    },
    [importItems.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.addEditInventoryItemCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(message, 'success');
        state.error = message;
      }
    },
    [importItemsIngredients.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.addEditInventoryItemCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(message, 'success');
        state.error = message;
      }
    },
    [exportItems.fulfilled]: (state: any, action) => {
      state.loading = false;
      if (isRejectedWithValue(action)) {
        state.error = action?.error.data.message;
      } else {
        state.error = action?.payload?.data;
        window.open(`${process.env.REACT_APP_EXPORTBASEURL}` + action?.payload.data, '_blank');
      }
    },
    [exportItemsIngredients.fulfilled]: (state: any, action) => {
      state.loading = false;
      if (isRejectedWithValue(action)) {
        state.error = action?.error.data.message;
      } else {
        state.error = action?.payload?.data;
        window.open(`${process.env.REACT_APP_EXPORTBASEURL}` + action?.payload.data, '_blank');
      }
    },
    [generateReference.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.loadingReference = true;
      state.generateReferenceData = action.payload;
    },
    [showSupplierListId.fulfilled]: (state: any, action: any) => {
      state.loading = false;

      state.showSupplierListIdData = action.payload;
    },
    [tag_branch.fulfilled]: (state: any, action: any) => {
      state.loading = false;

      state.TagsInInventoryItemListItemsBranch = action.payload;
    },
  },
});

export const {
  addType,
  resetItem,
  itemsFilterData,
  changeIsFiltered,
  showLoadingoff,
  setItemIds,
  setPageLoading,
  setTypeDeleted,
  setIconReset,
  setIconShow,
} = inventoryItemSlice.actions;
export default inventoryItemSlice.reducer;
