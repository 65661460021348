import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { fetch10, fetch2, fetch3, fetch5, fetch11, fetch9, fetch6 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';
import axios from 'axios';

const initialState: any = {
  loading: true,
  isFiltered: false,
  inventorySupplierListCount: 0,
  addEditInventorySupplieCount: 0,
  singleInventorySupplierCount: 0,
  supplieradd_tagCount: 0,
  tagforsupplierCount: 0,
  loadingStatus: 0,
  loadingStatusTableD: false,
  loadingStatusOneTime: false,
  inventoryItemsData: null,
  inventoryDetailsData: null,
  inventoryDetailsDataAfterCreated: null,
  inventorySupplierListItems: [],
};
export let addSupplierData: any = {};
export let SelectedSupplierIds: any = [];
export let supplier_list_ordelete: any = {
  id: '',
  search: '',
};
export let supplier_filter: any = {
  name: '',
  code: '',
  contact_name: '',
  email: '',
  phone: '',
  tag_id: '',
  is_deleted: null,
};
export let filterArray: any = {
  tag: [],
  item: [],
};
export let allTagsAndSupp: any = {
  suppliers: [],
  tags: [],
};
export let editItemDataX: any = {
  created_at: '',
  id: '',
  item_id: '',
  item_name: '',
  item_purchase_costsar: '',
  item_suppliercode: '',
  item_suppliers_id: '',
  order_qty: '',
  order_unit: '',
  sku: '',
  unit: '',
  updated_at: '',
};
export let editItemData: any = {
  item_purchase_costsar: '',
  unit: '',
  order_qty: '',
  order_unit: '',
  order_to_storage_factor: '',
  minimum_order_quantity: '',
  item_supplier_code: '',
  costs: '',
  code: '',
  id: '',
};
// export let inventoryItemsData: any;
export let inventorySupplierListItems: any;
export let inventorySupplierListType: any;
export let getAllTagItem: any;
export let singleInventorySupplierDetails: any;
export let singleInventorySupplierItem: any;
export let inventorySupplierId: any;
export let inventoryTagsForSupplier: any;
export let addInventorySupplierType: string;
export let getAllTagModifier: any;
export let parameter: string;
export let allinventoryItemsData: any;
export let allinventoryTagsData: any;

// export const inventorySupplierList: any = createAsyncThunk(
//   "inventorySupplierList",
//   async (page: any = 1, type: any = "all") => {
//     console.log(page,type,'inventorySupplierListinventorySupplierList');

//     const result = await fetch11(
//       `${process.env.REACT_APP_BASEURL}/inventory/suppliers?page=${page}&type=${type}`
//     );
//     inventorySupplierListItems = result.data.data;
//     console.log(result, "inventorySupplierList");
//     return result;
//   }
// );
export const inventorySupplierList: any = createAsyncThunk('inventorySupplierList', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/suppliers`, body);
  // inventorySupplierListItems = result;
  return result;
});
export const inventorySupplierUpdateItemCost: any = createAsyncThunk(
  'inventorySupplierUpdateItemCost',
  async ({ body, id }: any) => {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/suppliers/update_supplier_items/${id}`,
      body,
    );

    return result;
  },
);
export const inventorySupplierListaddMultTag: any = createAsyncThunk('inventorySupplierListaddMultTag', async body => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/suppliers/add_multi_tags`, body);
  // inventorySupplierListItems = result.data.data;
  // inventorySupplierListType = type;
  notify('Tags added successfully', 'success');

  console.log(result, 'inventorySupplierList');
  return result;
});
export const inventorySupplierDelById: any = createAsyncThunk('inventorySupplierDelById', async body => {
  const result = await fetch10(`${process.env.REACT_APP_BASEURL}/inventory/suppliers/${body}`);
  console.log(result, 'inventorySupplierDelById');
  notify('inventory Supplier deleted successfully', 'success');
  return result;
});
export const inventorySupplierRestoreById: any = createAsyncThunk('inventorySupplierRestoreById', async body => {
  const result = await fetch11(`${process.env.REACT_APP_BASEURL}/inventory/suppliers/restore_supplier_deleted/${body}`);
  console.log(result, 'inventorySupplierDelById');
  return result;
});
export const inventoryFilterSupplierList: any = createAsyncThunk('inventoryFilterSupplierList', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/supplier_filter`, body);
  inventorySupplierListItems = result.data;
  // console.log(inventorySupplierListItems, "inventorySupplierList");
  return result;
});

export const inventorySupplierView: any = createAsyncThunk('inventorySupplierView', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/suppliers/${body}`);
  console.log(result.data, 'singleInventorySupplierDetails');
  singleInventorySupplierDetails = result.data;
  singleInventorySupplierItem = result.data.items;
  getAllTagItem = result;
  getAllTagModifier = result;

  return result;
});

export const tagforsupplier: any = createAsyncThunk('tagforsupplier', async () => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/tagforsupplier`, 'GET');
  inventoryTagsForSupplier = result;

  console.log(inventoryTagsForSupplier, 'tagforsupplier');
  return result;
});
export const addEditInventorySupplier: any = createAsyncThunk('addEditInventorySupplier', async body => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/suppliers`, body);
    addSupplierData = result;
    if (result.data.status == true) {
      notify(result.data.message, 'success');
    }
    return result;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    addSupplierData = error;
  }
});

export const editInventorySupplier: any = createAsyncThunk(
  'supplier_edit',

  async ({ body, id }: any) => {
    try {
      const result = await fetch9(`${process.env.REACT_APP_BASEURL}/inventory/suppliers/${id}`, body);
      console.log(result, 'supplieradd_tag');
      return result;
    } catch (error: any) {
      console.log('238', error.response.data);

      notify(error.response.data.message);
    }
  },
);
// export const editInventorySupplier: any = createAsyncThunk(
//   "supplier_edit",
//   async (body) => {
//     // console.log(body, id, "supplier_editsupplier_editsupplier_edit");
//     let x: any = "";
//     x = body;
//     console.log(body, x.id, "supplier_editsupplier_editsupplier_edit");
//     console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa123456789");
//     notify("updated successfully", "success");

//     const headers = {
//       Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")!)}`,
//     };
//     const config = { headers };
//     const response = await axios.put(
//       `${process.env.REACT_APP_BASEURL}/inventory/suppliers/${x.id}`,
//       body,
//       config
//     );

//     // singleInventorySupplierDetails = response.data;
//     // singleInventorySupplierItem = result.data.data.items;
//     // getAllTagItem = result;
//     // getAllTagModifier = result;
//     return response;
//   }
// );

export const supplieradd_tag: any = createAsyncThunk('supplieradd_tag', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/supplieradd_tag`, body);
  console.log(result, 'supplieradd_tag');
  return result;
});
export const importSupplier: any = createAsyncThunk('importSupplier', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/import_supplier`, body);
  return result;
});

export const importSupplierInventoryItems: any = createAsyncThunk('importSupplierInventoryItems', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/import_supplier_inventory_items`, body);
  return result;
});
export const exportSupplierInventoryItems: any = createAsyncThunk('exportSupplierInventoryItems', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/export_supplier_inventory_items`, body);
  return result;
});
export const exportSupplier: any = createAsyncThunk('exportSupplier', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/export_supplier`, body);
  return result;
});

export const inventoryItems: any = createAsyncThunk('inventoryItems', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items`, body);
  allinventoryItemsData = result.data;
  return result.data;
});

export const createItemForSupplier: any = createAsyncThunk('createItemForSupplier', async ({ body, id }: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/suppliers/add_or_update_items/${id}`, body);
  notify(' update items success', 'success');
  console.log(result.data, 'inventoryItemsData');
  return result.data;
});
export const getTagsForSupplier: any = createAsyncThunk('getTagsForSupplier', async () => {
  const result = await fetch11(`${process.env.REACT_APP_BASEURL}/manage/tags/get_tag_by_type?type=Supplier`);
  allinventoryTagsData = result.data;

  console.log(result.data, 'inventoryItemsData');
  return result.data;
});
export const addTagsToSupplier: any = createAsyncThunk('addTagsToSupplier', async ({ body, id }: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/suppliers/add_or_update_tags/${id}`, body);
  notify('update tag success', 'success');

  console.log(result.data, 'inventoryItemsData');
  return result.data;
});
// export const suppFilterData: any = createAsyncThunk(
//   "suppFilterData",
//   async ({name,code,contact_name}:any) => {
//     const result = await fetch6(
//       `${process.env.REACT_APP_BASEURL}/inventory/suppliers?name=${name}&code=${code}&contact_name=${contact_name}`
//     );
//     // notify('update tag success','success')
//     inventorySupplierListItems = result.data.data
//     console.log(result.data.data, "inventoryItemsData");
//     return result.data;
//   }
// );
export const suppFilterData: any = createAsyncThunk('suppFilterData', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/suppliers`, body);
  // notify('update tag success','success')
  inventorySupplierListItems = result.data.data;
  console.log(result.data.data, 'inventoryItemsData');
  return result.data;
});

export const restoreSupplier: any = createAsyncThunk('restoreSupplier', async (body: any) => {
  const result = await fetch6(
    `${process.env.REACT_APP_BASEURL}/inventory/suppliers/restore_supplier_deleted/${body.id}`,
  );
  // notify('update tag success','success')

  return result;
});
const inventorySupplierSlice: any = createSlice({
  name: 'InventorySupplier',
  initialState,
  reducers: {
    changeFilterStatus(state = initialState, action) {
      state.isFiltered = action.payload;
    },
    addType(state = initialState, action) {
      parameter = action.payload.parameter;
      addInventorySupplierType = action.payload.name;
      inventorySupplierId = action.payload.id;
    },
    onetimeloaderTableNavg(state = initialState, action) {
      state.loadingStatusOneTime = action.payload;
    },
    onetimeloaderTableNavg1(state = initialState, action) {
      state.loadingStatusOneTime = action.payload;
    },

    addAllSuppIdAndTags(state, action) {
      allTagsAndSupp.tags = action.payload.tags;
      allTagsAndSupp.suppliers = action.payload.suppliers;
    },
    supplierFilterData(state = initialState, action) {
      console.log(action, 'supplier_filter');
      supplier_filter.name = action.payload.name;
      supplier_filter.code = action.payload.code;
      supplier_filter.contact_name = action.payload.contact_name;
      supplier_filter.email = action.payload.email;
      supplier_filter.tag_id = action.payload.tag_id;
      supplier_filter.phone = action.payload.phone;
      supplier_filter.is_deleted = action.payload.is_deleted;
    },
    resetSupplier(state = initialState) {
      supplier_filter.name = '';
      supplier_filter.code = '';
      supplier_filter.contact_name = '';
      supplier_filter.email = '';
      supplier_filter.tag_id = '';
      supplier_filter.phone = '';
      filterArray.tag = [];
      filterArray.item = [];
      supplier_filter.is_deleted = null;
    },
    editItemFunc(state = initialState, action) {
      console.log(action);
      editItemData.id = action.payload.id;
      editItemData.item_purchase_costsar = action.payload.pivot.item_purchase_costsar;
      editItemData.unit = action.payload.pivot.unit;
      editItemData.order_qty = action.payload.pivot.order_qty;
      editItemData.order_unit = action.payload.order_unit;
      editItemData.order_to_storage_factor = action.payload.order_to_storage_factor;
      editItemData.minimum_order_quantity = action.payload.minimum_order_quantity;
      editItemData.item_supplier_code = action.payload.item_supplier_code;
      editItemData.costs = action.payload.costs;
      editItemData.code = action.payload.code;
    },
    showLoadingoff(state = initialState) {
      state.loadingStatus += 1;
    },
    setSupplierIds(state = initialState, action: any) {
      SelectedSupplierIds = action.payload;
    },
  },
  extraReducers: {
    [inventorySupplierList.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.inventorySupplierListItems = action.payload;
    },
    [inventorySupplierList.pending]: (state: any) => {
      state.loading = true;
    },
    [inventorySupplierList.rejected]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
    },

    [inventoryItems.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.inventoryItemsData = state.payload;
    },
    [inventorySupplierRestoreById.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      notify('inventory Supplier Restored', 'success');
    },

    [inventoryFilterSupplierList.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.inventorySupplierListCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [inventorySupplierUpdateItemCost.pending]: (state: any) => {
      state.loading = true;
    },
    [inventorySupplierUpdateItemCost.fulfilled]: (state: any) => {
      state.loading = false;
      state.addEditInventorySupplieCount += 1;
      notify('inventory Supplier Update Item Cost', 'success');
      // if (error) {
      //   console.log(error);
      //   console.log(message);
      //   state.error = error;
      // } else {
      //   if (status) notify(message, "success");
      //   else notify('updated success','success');
      //   state.error = message;
      // }
    },
    [addEditInventorySupplier.fulfilled]: (state: any) => {
      state.loading = false;
    },
    [editInventorySupplier.fulfilled]: (state: any, { payload: { error, message, status, data } }) => {
      console.log(error, message, status, data, 'inventoryDetailsDataAfterCreatedinventoryDetailsDataAfterCreated');

      state.loading = false;
      notify(data.message, 'success');
      if (error) {
        console.log(error, 'inventoryDetailsDataAfterCreatedinventoryDetailsDataAfterCreated');
        console.log(message);
        notify(error.message, 'error');
        state.error = error;
      } else {
        if (status) notify(message, 'success');
        else notify(message);
        state.error = message;
        console.log(error, 'inventoryDetailsDataAfterCreatedinventoryDetailsDataAfterCreated');
      }
    },
    // [editInventorySupplier.rejected]: (
    //   state: any,
    //   { payload }
    // ) => {
    //   console.log(state, payload,'inventoryDetailsDataAfterCreatedinventoryDetailsDataAfterCreated');

    //   state.loading = false;
    //   // notify(error.message, "error")

    // },

    [suppFilterData.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.inventorySupplierListCount += 1;
      if (error) {
        console.log(error);
        console.log(message);
        state.error = error;
      } else {
        // notify("suppFilterData", "success");
      }
    },

    [inventorySupplierView.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      state.loadingStatusOneTime = false;
      state.singleInventorySupplierCount += 1;

      state.inventoryDetailsData = payload.data;
    },
    [inventorySupplierView.pending]: (state: any, { payload }) => {
      state.loading = true;
    },

    [tagforsupplier.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.tagforsupplierCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [supplieradd_tag.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.supplieradd_tagCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [importSupplier.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.addEditInventorySupplieCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(message, 'success');
        state.error = message;
      }
    },
    [importSupplierInventoryItems.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.addEditInventorySupplieCount += 1;
      if (action?.error) {
        state.error = action?.error;
      } else {
        notify(action?.payload.message, 'success');
        state.error = action?.payload.message;
      }
    },
    [exportSupplier.fulfilled]: (state: any, action) => {
      state.loading = false;
      if (isRejectedWithValue(action)) {
        state.error = action?.error.data.message;
      } else {
        state.error = action?.payload?.data;
        window.open(`${process.env.REACT_APP_EXPORTBASEURL}` + action?.payload.data, '_blank');
      }
    },
    [exportSupplierInventoryItems.fulfilled]: (state: any, action) => {
      state.loading = false;
      if (isRejectedWithValue(action)) {
        state.error = action?.error.data.message;
      } else {
        state.error = action?.payload?.data;
        window.open(`${process.env.REACT_APP_EXPORTBASEURL}` + action?.payload.data, '_blank');
      }
    },
  },
});

export const {
  addType,
  supplierFilterData,
  resetSupplier,
  editItemFunc,
  showLoadingoff,
  setSupplierIds,
  onetimeloaderTableNavg,
  changeFilterStatus,
  addAllSuppIdAndTags,
} = inventorySupplierSlice.actions;
export default inventorySupplierSlice.reducer;
