import React, { useEffect, useRef, useState } from 'react';
import { NormalBtn } from '../../components/buttons/NormalBtn';
import forgetPassword from './ForgetPassword.module.scss';
import Cookies from 'js-cookie';
import { useCustomForm } from '../../utils/useCustomForm';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { verifyOtp } from '../../redux_toolkit/reducer/ForgetPasswordReducer';
import ChangePassword from './ChangePassword';
import { Controller } from 'react-hook-form';

const OtpVerification = () => {
  const [otp, setOtp] = useState(['', '', '', '', '']);
  const [changePassword, setChangePassword] = useState(false);
  const [showLoading, setshowLoading] = useState(false);
  const inputsRef = useRef<any[]>([]);
  const {
    trigger,
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
  } = useCustomForm({ trim: true });

  // Function to handle input change
  const handleInputChange = (index: any, event: any) => {
    const { value } = event.target;
    if (!isNaN(value) && value !== '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < otp.length - 1 && value !== '') {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index: any, e: any) => {
    if (e.key === 'Backspace') {
      if (index > 0 && otp[index] === '') {
        inputsRef.current[index - 1].focus();
      } else {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
        inputsRef.current[index > 0 ? index - 1 : index].focus();
      }
    }
  };
  const dispatch = useDispatch();
  const onSubmit = async (formData: any) => {
    setshowLoading(true)
    const otpString = Object.keys(formData)
      .sort((a, b) => a.localeCompare(b))
      .map(key => formData[key])
      .join('');

    const result = { otp: otpString };

    console.log('Result:', result);
    const response = await dispatch(verifyOtp(result));
    console.log('Response:', response);
    
    if (response?.payload?.code === 200) {
      const expirationTime = new Date();
      expirationTime.setTime(expirationTime.getTime() + 30 * 60 * 1000);
      Cookies.set('verify_token', response?.payload?.verify_token , { expires: expirationTime });
      setChangePassword(true);
    }
    setshowLoading(false)

  };
  const tokenValidationCookie = Cookies.get('verify_token');

  useEffect(() => {
    const checkCookie = () => {
      const cookieValue: any = Cookies.get('verify_token');
      
      if (cookieValue) {
        setChangePassword(true);
      } else {
        setChangePassword(false);
        // Cookies.remove('isOtpSended');
      }
    };

    checkCookie();
  }, [tokenValidationCookie]);
  return (
    <>
      {changePassword ? (
        <ChangePassword />
      ) : (
        <div className='container p-5'>
          <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-5 mt-5'>
              <Form onSubmit={handleSubmit(onSubmit)} className=' p-5 rounded-3 '>
                <div>
                  <p className='text-center text-success' style={{ fontSize: '5.5rem' }}>
                    {/* <img src={emailCheck} alt='logo' className='text-center' style={{ width: '5.5rem' }} /> */}
                  </p>
                  <p className='text-center text-center h5 '>Please check your email</p>
                  <p className='text-muted text-center'>We've sent a code to {Cookies.get('email')}</p>
                  <div className='row  pt-4 pb-2 justify-content-between '>
                    {otp.map((digit, index) => (
                      <div key={index} className='col-2'>
                        {/* <input key={index} className={`${forgetPassword.otpLetterInput}`} type='number' /> */}
                        <Controller
                          key={index}
                          name={`otp-${index}`}
                          control={control}
                          defaultValue={digit}
                          render={({ field: { onChange, onBlur, value, ref } }) => (
                            <input
                              key={index}
                              className={forgetPassword.otpLetterInput}
                              type='text'
                              maxLength={1}
                              value={value}
                              onChange={event => {
                                onChange(event); // Call the react-hook-form onChange
                                handleInputChange(index, event); // Call your custom onChange
                              }}
                              onKeyDown={e => handleKeyDown(index, e)}
                              ref={el => (inputsRef.current[index] = el)}
                            />
                          )}
                        />
                      </div>
                    ))}
                  </div>
                  <p className='text-muted text-center'>
                    Didn't get the code?{' '}
                    <a href='#' className='text-primary'>
                      Click to resend.
                    </a>
                  </p>

                  <div className='row pt-5'>
                    <div className='col-6'>{/* <OutlineBtn className='w-100'>Cancel</OutlineBtn> */}</div>
                    <div className='col-3'></div>
                    <div className='col-3'>
                      <NormalBtn className='w-100' loading={showLoading} type='submit'>
                        Verify
                      </NormalBtn>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OtpVerification;
