import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch10, fetch11, fetch5, fetch6 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

export const showrolesList: any = createAsyncThunk('showrolesList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/roles`, body);
    return response.data;
  } catch (error: any) {
    console.log(error);

    notify(error.response.data.message, 'error');
    throw error;
  }
});
export const getAllusers: any = createAsyncThunk('getAllusers', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/auth/users`, body);
    return response.data;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    throw error;
  }
});
export const addolesList: any = createAsyncThunk('addolesList', async body => {
  try {
    const response: any = await fetch5(`${process.env.REACT_APP_BASEURL}/roles`, body);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    throw error;
  }
});
export const showRolesDetails: any = createAsyncThunk('showRolesDetails', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/roles/${body}`);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    throw error;
  }
});
export const delRole: any = createAsyncThunk('delRole', async body => {
  try {
    const response: any = await fetch10(`${process.env.REACT_APP_BASEURL}/roles/${body}`);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    console.log(error);

    notify(error.response.data.message, 'error');
    throw error;
  }
});
export const editRole: any = createAsyncThunk('editRole', async ({ id = '', body = null }: any) => {
  try {
    const response: any = await fetch11(`${process.env.REACT_APP_BASEURL}/roles/${id}`, body);
    // notify("success", "success");
    return response.data;
  } catch (error: any) {
    notify(error.response.data.message, 'error');
    throw error;
  }
});

const roleSlice: any = createSlice({
  name: 'roleSlice',
  initialState: {
    showAllshowrolesList: null,
    showAllshowrolesListData: null,
    addNewRoleData: null,
    getAllusersRoleData: null,

    isLoading: false,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(showrolesList.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.showAllshowrolesList = action.payload;
      })
      .addCase(showrolesList.pending, (state: any, action) => {
        state.isLoading = true;
      })
      .addCase(showrolesList.rejected, (state: any, action) => {
        state.isLoading = false;
      })
      .addCase(addolesList.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.addNewRoleData = action.payload;
      })
      .addCase(showRolesDetails.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.showRolesDetailsData = action.payload;
      })
      .addCase(getAllusers.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.getAllusersRoleData = action.payload;
      });
  },
});

// export const { changeDeleteValue, setshowLoading,resetFilterData,setFilterData } = manageCouponSlice.actions;
export default roleSlice.reducer;
