import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetch2, fetch3, fetch5, fetch6, fetch9 } from "../../Apis/commonApis";
import { notify } from "../../constants/notifyconstants";

const initialState = {
  loadingStatus: 0,
  generalSettingsInfoCount: 0,
  editgeneralInfoCount: 0,
  loyaltySettingsInfoCount: 0,
  editloyaltyInfoCount: 0,
  receiptSettingsInfoCount: 0,
  editreceiptInfoCount: 0,
  cashierappSettingsInfoCount: 0,
  editcashierappInfoCount: 0,
  displaySettingsInfoCount: 0,
  editdisplayInfoCount: 0,
  inventoryTransactionSettingsInfoCount: 0,
  editinventoryTransactionInfoCount: 0,
  callcenterSettingsInfoCount: 0,
  editcallcenterInfoCount: 0,
  smsProvidersSettingsInfoCount: 0,
  editsmsProvidersInfoCount: 0,
  paymentIntegrationsSettingsInfoCount: 0,
  editpaymentIntegrationsInfoCount: 0,
  GroupsCount: 0,
  listPrinterModelCount: 0
};

export let listPrinterModelData: any;
export let generalSettingsInfo: any;
export let loyaltySettingsInfo: any;
export let cashierappSettingsInfo: any;
export let receiptSettingsInfo: any;
export let displaySettingsInfo: any;
export let inventoryTransactionSettingsInfo: any;
export let callcenterSettingsInfo: any;
export let smsProvidersSettingsInfo: any;
export let paymentIntegrationsSettingsInfo: any;
export let getGroupsList: any;


////////////////////GeneralSettings///////////////////////
export const getGeneralSettingsInfo: any = createAsyncThunk(
  "getGeneralSettingsInfo",
  async (body) => {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/settings`
    );

    generalSettingsInfo = result.data;
    localStorage.setItem("settings", JSON.stringify(result));
    return result;
  }
);

export const saveGeneralSettings: any = createAsyncThunk(
  "saveGeneralSettings",
  async (body) => {
    const result = await fetch9(
      `${process.env.REACT_APP_BASEURL}/manage/settings`,
      body
    );
    console.log(result, "---updateGeneralSettings__result---");
    console.log(body, "---updateGeneralSettings__body---");
    return result;
  }
);
////////////////////LoyaltySettings///////////////////////
export const getloyaltySettingsInfo: any = createAsyncThunk(
  "getloyaltySettingsInfo",
  async (body) => {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/settings`
    );
    console.log(result, "---getloyaltySettings__result---");

    loyaltySettingsInfo = result.data;

    return result.data;
  }
);

export const saveLoyaltySettings: any = createAsyncThunk(
  "saveLoyaltySettings",
  async (body) => {
    const result = await fetch9(
      `${process.env.REACT_APP_BASEURL}/manage/settings`,
      body
    );
    console.log(result, "---saveLoyaltySettings__result---");
    console.log(body, "---saveLoyaltySettings__body---");
    return result;
  }
);

////////////////////ReceiptSettings///////////////////////

export const getReceiptSettingsInfo: any = createAsyncThunk(
  "getReceiptSettingsInfo",
  async (body) => {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/settings`
    );
    console.log(result.data, "---getReceiptAppSettings__result---");
    console.log(body, "---getReceiptAppSettings__body---");
    receiptSettingsInfo = result.data;
    return result;
  }
);

export const saveReceiptAppSettings: any = createAsyncThunk(
  "saveReceiptAppSettings",
  async (body) => {
    try {

      const result = await fetch9(
        `${process.env.REACT_APP_BASEURL}/manage/settings`,
        body
      );

      return result;
    } catch (error: any) {
      return notify(error.response.data.message);
      // console.log(error.response.data.message);

    }
  }
);

////////////////////webCashierSettings///////////////////////

export const saveWebCashierSettings: any = createAsyncThunk(
  "saveWebCashierSettings",
  async (body) => {
    const result = await fetch9(
      `${process.env.REACT_APP_BASEURL}/manage/settings`
    );


    return result;
  }
);

//printer model
export const listPrinterModel: any = createAsyncThunk(
  "listPrinterModel",
  async (body) => {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/printer_models`
    );
    listPrinterModelData = result.data;

    return result;
  }
);

////////////////////CashierAppSettings///////////////////////

export const getCashierAppSettingsInfo: any = createAsyncThunk(
  "getCashierAppSettingsInfo",
  async (body) => {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/settings`
    );
    console.log(result, "---getCashierAppSettings__result---");
    console.log(body, "---getCashierAppSettings__body---");
    cashierappSettingsInfo = result.data;
    return result;
  }
);

export const saveCashierAppSettings: any = createAsyncThunk(
  "saveCashierAppSettings",
  async (body) => {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/settings`,
      body
    );
    console.log(result, "---saveCashierAppSettings__result---");
    console.log(body, "---saveCashierAppSettings__body---");
    return result;
  }
);

////////////////////DisplaySettings///////////////////////

export const getDisplaySettingsInfo: any = createAsyncThunk(
  "getDisplaySettingsInfo",
  async (body) => {
    const result = await fetch3(
      `${process.env.REACT_APP_BASEURL}/display_settings_info?restaurant_id=${body}`,
      "get"
    );
    displaySettingsInfo = result.data;
    return result;
  }
);

export const saveDisplayAppSettings: any = createAsyncThunk(
  "saveDisplayAppSettings",
  async (body) => {
    const result = await fetch2(
      `${process.env.REACT_APP_BASEURL}/edit_cashierapp_settings`,
      body
    );
    return result;
  }
);

////////////////////InventoryTransSettings///////////////////////

export const getInventoryTransSettingsInfo: any = createAsyncThunk(
  "getInventoryTransSettingsInfo",
  async (body) => {
    const result = await fetch3(
      `${process.env.REACT_APP_BASEURL}/inventory_transaction_settings_info?restaurant_id=${body}`,
      "get"
    );
    inventoryTransactionSettingsInfo = result.data;
    return result;
  }
);

export const saveInventoryTransSettings: any = createAsyncThunk(
  "saveInventoryTransSettings",
  async (body) => {
    const result = await fetch2(
      `${process.env.REACT_APP_BASEURL}/edit_inventory_transaction_settings`,
      body
    );
    return result;
  }
);

////////////////////CallCenterSettings///////////////////////

export const getCallCenterSettingsInfo: any = createAsyncThunk(
  "getCallCenterSettingsInfo",
  async (body) => {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/settings`
    );
    console.log(result, "---getCallCenterSettings__result---");
    console.log(body, "---getCallCenterSettings__body---");
    callcenterSettingsInfo = result.data;
    return result;
  }
);

export const saveCallCenterSettings: any = createAsyncThunk(
  "saveCallCenterSettings",
  async (body) => {
    const result = await fetch9(
      `${process.env.REACT_APP_BASEURL}/manage/settings`,
      body
    );
    console.log(result, "---saveCallCenterSettings__result---");
    console.log(body, "---saveCallCenterSettings__body---");
    return result;
  }
);

////////////////////getSmsProvidersSettings///////////////////////

export const getSmsProvidersSettingsInfo: any = createAsyncThunk(
  "getSmsProvidersSettingsInfo",
  async (body) => {
    const result = await fetch3(
      `${process.env.REACT_APP_BASEURL}/sms_providers_settings_info?restaurant_id=${body}`,
      "get"
    );
    smsProvidersSettingsInfo = result.data;
    return result;
  }
);

export const saveSmsProvidersSettings: any = createAsyncThunk(
  "saveSmsProvidersSettings",
  async (body) => {
    const result = await fetch2(
      `${process.env.REACT_APP_BASEURL}/edit_sms_providers_settings`,
      body
    );
    return result;
  }
);

////////////////////PaymentIntegrationSettings///////////////////////

export const getPaymentIntegrationSettingsInfo: any = createAsyncThunk(
  "getPaymentIntegrationSettingsInfo",
  async (body) => {
    const result = await fetch3(
      `${process.env.REACT_APP_BASEURL}/payment_integrations_settings_info?restaurant_id=${body}`,
      "get"
    );
    paymentIntegrationsSettingsInfo = result.data;
    return result;
  }
);

export const savePaymentIntegrationSettings: any = createAsyncThunk(
  "savePaymentIntegrationSettings",
  async (body) => {
    const result = await fetch2(
      `${process.env.REACT_APP_BASEURL}/edit_payment_integrations_settings`,
      body
    );
    return result;
  }
);

///////////////////Groups

export const getGroups: any = createAsyncThunk("getGroups", async (body) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/groups`, { type: "all" });

  getGroupsList = result.data;
  return result;
});

const manageMoreSetttingSlice: any = createSlice({
  name: "Settings",
  initialState,
  reducers: {
    showLoadingoff(state = initialState) {
      state.loadingStatus += 1;
    },
  },
  extraReducers: {
    ////////////////////GeneralSettings///////////////////////
    [listPrinterModel.fulfilled]: (
      state: any,
      { payload: { error, message } }
    ) => {
      state.loading = false;
      state.listPrinterModelData = state.payload;
      state.listPrinterModelCount = state.payload
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [getGeneralSettingsInfo.fulfilled]: (
      state: any,
      { payload: { error, message } }
    ) => {
      state.loading = false;
      state.generalSettingsInfoCount +=1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [getGroups.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.GroupsCount = state.payload;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },

    [saveGeneralSettings.fulfilled]: (
      state: any,
      { payload: { error, message, status } }
    ) => {
      state.loading = true;
      state.editgeneralInfoCount += 1;
      notify("Edit Successfully", "success")
      console.log(state.loading, "bdj");

      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, "success");
        else notify(message);
        state.error = message;
      }
    },
    [saveGeneralSettings.rejected]: (state: any, actions: any) => {
      state.loading = false;
      console.log(actions);
      notify(actions.error.message);
    },
    ////////////////////LoyaltySettings///////////////////////
    [getloyaltySettingsInfo.fulfilled]: (
      state: any,
      { payload: { error, message } }
    ) => {
      state.loading = false;
      state.loyaltySettingsInfoCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },

    [saveLoyaltySettings.fulfilled]: (
      state: any,
      { payload: { error, message, status } }
    ) => {
      state.loading = false;
      state.editloyaltyInfoCount += 1;
      notify("Edit Successfully", "success")

      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, "success");
        else notify(message);
        state.error = message;
      }
    },
    [saveLoyaltySettings.rejected]: (state: any, actions: any) => {
      state.loading = false;
      console.log(actions);
      notify(actions.error.message);
    },
    ////////////////////ReceiptSettings///////////////////////
    [getReceiptSettingsInfo.fulfilled]: (
      state: any,
      { payload: { error, message } }
    ) => {
      state.loading = false;
      state.receiptSettingsInfoCount += 1;

      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [saveReceiptAppSettings.fulfilled]: (
      state: any,
      { payload: { error, message, status } }
    ) => {
      state.loading = false;
      state.editreceiptInfoCount += 1;
      notify("Edit Successfully", "success")

      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, "success");
        else notify(message);
        state.error = message;
      }
    },
    [saveReceiptAppSettings.rejected]: (state: any, actions: any) => {
      state.loading = false;
      console.log(actions);
      notify(actions.error.message);
    },


    [saveWebCashierSettings.fulfilled]: (
      state: any,
      { payload: { error, message, status } }
    ) => {
      state.loading = false;
      state.editreceiptInfoCount += 1;
      notify("Edit Successfully", "success")

      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, "success");
        else notify(message);
        state.error = message;
      }
    },
    [saveWebCashierSettings.rejected]: (state: any, actions: any) => {
      state.loading = false;
      console.log(actions);
      notify(actions.error.message);
    },



    ////////////////////CashierAppSettings///////////////////////
    [saveCashierAppSettings.fulfilled]: (
      state: any,
      { payload: { error, message, status } }
    ) => {
      state.loading = false;
      state.editreceiptInfoCount += 1;
      notify("Edit Successfully", "success")

      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, "success");
        else notify(message);
        state.error = message;
      }
    },
    [saveCashierAppSettings.rejected]: (state: any, actions: any) => {
      state.loading = false;
      console.log(actions);
      notify(actions.error.message);
    },
    [getCashierAppSettingsInfo.fulfilled]: (
      state: any,
      { payload: { error, message } }
    ) => {
      state.loading = false;
      state.cashierappSettingsInfoCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [saveCallCenterSettings.fulfilled]: (
      state: any,
      { payload: { error, message, status } }
    ) => {
      state.loading = false;
      state.editcashierappInfoCount += 1;
      notify("Edit Successfully", "success")

      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, "success");
        else notify(message);
        state.error = message;
      }
    },
    [getDisplaySettingsInfo.fulfilled]: (
      state: any,
      { payload: { error, message } }
    ) => {
      state.loading = false;
      state.displaySettingsInfoCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [saveDisplayAppSettings.fulfilled]: (
      state: any,
      { payload: { error, message, status } }
    ) => {
      state.loading = false;
      state.editdisplayInfoCount += 1;
      notify("Edit Successfully", "success")

      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, "success");
        else notify(message);
        state.error = message;
      }
    },
    [getInventoryTransSettingsInfo.fulfilled]: (
      state: any,
      { payload: { error, message } }
    ) => {
      state.loading = false;
      state.inventoryTransactionSettingsInfoCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [saveInventoryTransSettings.fulfilled]: (
      state: any,
      { payload: { error, message, status } }
    ) => {
      state.loading = false;
      state.editinventoryTransactionInfoCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, "success");
        else notify(message);
        state.error = message;
      }
    },
    [getCallCenterSettingsInfo.fulfilled]: (
      state: any,
      { payload: { error, message } }
    ) => {
      state.loading = false;
      state.callcenterSettingsInfoCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [saveCallCenterSettings.fulfilled]: (
      state: any,
      { payload: { error, message, status } }
    ) => {
      state.loading = false;
      state.editcallcenterInfoCount += 1;
      notify("Edit Successfully", "success")

      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, "success");
        else notify(message);
        state.error = message;
      }
    },

    [saveCallCenterSettings.rejected]: (state: any, actions: any) => {
      state.loading = false;
      console.log(actions);
      notify(actions.error.message);
    },
    [getSmsProvidersSettingsInfo.fulfilled]: (
      state: any,
      { payload: { error, message } }
    ) => {
      state.loading = false;
      state.smsProvidersSettingsInfoCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [saveSmsProvidersSettings.fulfilled]: (
      state: any,
      { payload: { error, message, status } }
    ) => {
      state.loading = false;
      state.editsmsProvidersInfoCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, "success");
        else notify(message);
        state.error = message;
      }
    },
    [getPaymentIntegrationSettingsInfo.fulfilled]: (
      state: any,
      { payload: { error, message } }
    ) => {
      state.loading = false;
      state.paymentIntegrationsSettingsInfoCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [savePaymentIntegrationSettings.fulfilled]: (
      state: any,
      { payload: { error, message, status } }
    ) => {
      state.loading = false;
      state.editpaymentIntegrationsInfoCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(message, "success");
        else notify(message);
        state.error = message;
      }
    },
  },
});

export const { showLoadingoff } = manageMoreSetttingSlice.actions;
export default manageMoreSetttingSlice.reducer;
