import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { fetch10, fetch2, fetch3, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  loading: false,
  isFiltered: false,
  inventoryPurchaseOrderListCount: 0,
  addEditInventoryPurchaseOrderCount: 0,
  singleInventoryPurchaseOrderCount: 0,
  get_supplier_branchCount: 0,
  loadingStatus: 0,
  inventoryPurchaseOrderListItems: [],
  PurshaseOrderLoading: false,
  inventoryPurchaseSupplierId: '',
  inventoryPurchaseDestinationId: '',
  inventoryPurchaseDelivaryDate: '',
  inventoryPurchaseNote: '',
  singleInventoryPurchaseOrderDetails: {},
  addPurchaseData: {},
  getTagsItemsListData: [],
  getSuppliersItemsListData: [],
  singleLoading: true,
  getItemsListData: [],
  getItemsListSupplierData: [],
  purchasingData: {},
  itemsList: [],
  loadingPage: false,
  pageCount: null,
};

export let Purchaseorder_list_search: any = {
  type: '',
  reference: '',
  status: '',
  supplier: [],
  submitter: [],
  destination: [],
  creator: [],
  tags: [],
  poster: [],
  updated_after: '',
  delivary_datevary: '',
  business_date: '',

  is_deleted: false,
};

export let inventoryPurchaseOrderListItems: any;
export let getAllTagItem: any;
export let singleInventoryPurchaseOrderDetails: any;
export let singleInventoryPurchaseOrderItems: any;
export let getSupplier: any;
export let getSupplierBranches: any;
export let inventoryPurchaseOrderId: any;
export let inventoryTagsForPurchaseOrder: any;
export let addInventoryPurchaseOrderType: string;
export let addPurchaseData: any;
export let purchasingData: any;
export let getItemsListData: any;

export const inventoryPurchaseOrderList: any = createAsyncThunk('inventoryPurchaseOrderList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/purchase_orders`, body);

  return result;
});

export const itemsBySuppliers: any = createAsyncThunk('itemsBySuppliers', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items`, body);

  return result;
});
export const inventoryItemList: any = createAsyncThunk('inventoryItemList', async (body: any) => {
  try {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items?include[0]=category&type=all`, body);

    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const inventoryPurchaseOrderView: any = createAsyncThunk('inventoryPurchaseOrderView', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/purchase_orders/${body}`);
  // console.log(result?.data?.data, "+-+-InventoryPurchaseOrderView-+-+");

  getAllTagItem = result;
  return result;
});

export const get_supplier_branch: any = createAsyncThunk('get_supplier_branch', async () => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/get_supplier_branch`, 'GET');
  getSupplierBranches = result?.branchesh;
  getSupplier = result?.supplier;
  console.log(getSupplier, getSupplierBranches, 'get_supplier_branch');
  return result;
});

export const addInventoryPurchaseOrderApi: any = createAsyncThunk('addInventoryPurchaseOrderApi', async body => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/purchase_orders`, body);
    addPurchaseData = result;
    console.log(result);

    return result;
  } catch (error: any) {
    addPurchaseData = error;

    return notify(error?.response?.data?.message);
  }
});

export const createPurchasing: any = createAsyncThunk('createPurchasing', async body => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/purchasing`, body);

  console.log(result);
  purchasingData = result;

  return result;
});
export const editInventoryPurchaseOrderApi: any = createAsyncThunk(
  'editInventoryPurchaseOrderApi',
  async (body: any) => {
    try {
      const result = await fetch5(
        `${process.env.REACT_APP_BASEURL}/inventory/purchase_orders/create-purchase-orders-item/${body.id}`,
        body,
      );
      console.log(result, 'addInventoryPurchaseOrder');
      return result;
    } catch (error: any) {
      console.log(error);

      notify(error?.data?.message);
    }
  },
);
export const editInventoryPurchaseOrderAll: any = createAsyncThunk(
  'editInventoryPurchaseOrderAll',
  async (body: any) => {
    try {
      const result = await fetch9(`${process.env.REACT_APP_BASEURL}/inventory/purchase_orders/${body.id}`, body);
      return result;
    } catch (error: any) {
      console.log(error, 'error');

      notify(error.response.data.message);
    }
  },
);
export const editItemApi: any = createAsyncThunk('editItemApi', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/inventory/purchase_orders/update-purchase-orders-item/${body.id}`,
    body,
  );
  console.log(result, 'addInventoryPurchaseOrder');
  return result;
});
export const deleteInventoryPurchaseOrderApi: any = createAsyncThunk('deleteInventoryPurchaseOrderApi', async body => {
  const result = await fetch10(`${process.env.REACT_APP_BASEURL}/inventory/purchase_orders/${body}`, body);
  console.log(result, 'addInventoryPurchaseOrder');
  return result;
});
export const deleteItems: any = createAsyncThunk('deleteItems', async (body: any) => {
  const result = await fetch10(
    `${process.env.REACT_APP_BASEURL}/inventory/delete_single_item/${body.item}/purchase_order/${body.purchase_id}`,
    body,
  );
  console.log(result, 'addInventoryPurchaseOrder');
  return result;
});
export const exportPurchaseOrder: any = createAsyncThunk('exportPurchaseOrder', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/export_Purchase_orders`, body);
  return result;
});
export const exportPurchaseOrderItem: any = createAsyncThunk('exportPurchaseOrderItem', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/export_Purchase_orders_items`, body);
  return result;
});
export const getTagsItemsList: any = createAsyncThunk('getTagsItemsList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/tags`, body);
  return result;
});

export const getSuppliersItemsList: any = createAsyncThunk('getSuppliersItemsList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/suppliers`, body);
  return result;
});

export const getItemsList: any = createAsyncThunk('getItemsList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items`, body);

  console.log(result);

  return result;
});
export const getItemsListSupplier: any = createAsyncThunk('getItemsListSupplier', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items`, body);
  return result;
});
export const duplicatePurchaseOrder: any = createAsyncThunk('getItemsListSupplier', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/purchase_orders/duplicate/${body.id}`);
  return result;
});
const inventoryPurchaseOrderSlice: any = createSlice({
  name: 'InventoryPurchaseOrder',
  initialState,
  reducers: {
    setPurchaseViewIds: (state = initialState, action) => {
      state.inventoryPurchaseDelivaryDate = action.payload.delivery_date;
      state.inventoryPurchaseSupplierId = action.payload.supplier;
      state.inventoryPurchaseDestinationId = action.payload.destination;
      state.inventoryPurchaseNote = action.payload.notes;
    },
    setFilterData(state = initialState, action) {
      state.isFiltered = true;
      Purchaseorder_list_search.type = action.payload.type;
      Purchaseorder_list_search.reference = action.payload.reference;
      Purchaseorder_list_search.status = action.payload.status;
      Purchaseorder_list_search.supplier = action.payload.supplier;
      Purchaseorder_list_search.submitter = action.payload.submitter;
      Purchaseorder_list_search.destination = action.payload.destination;
      Purchaseorder_list_search.creator = action.payload.creator;
      Purchaseorder_list_search.tags = action.payload.tags;
      Purchaseorder_list_search.poster = action.payload.poster;
      Purchaseorder_list_search.updated_after = action.payload.updated_after;
      Purchaseorder_list_search.delivary_date = action.payload.delivary_date;
      Purchaseorder_list_search.business_date = action.payload.business_date;
    },
    setFilterIcon(state = initialState, action) {
      state.isFiltered = true;
    },
    setFilterResetIcon(state = initialState, action) {
      state.isFiltered = false;
    },
    resetFilterState(state = initialState) {
      state.isFiltered = false;

      Purchaseorder_list_search.type = '';
      Purchaseorder_list_search.reference = '';
      Purchaseorder_list_search.status = '';

      Purchaseorder_list_search.destination = [];
      Purchaseorder_list_search.supplier = [];
      Purchaseorder_list_search.creator = [];
      Purchaseorder_list_search.submitter = [];
      Purchaseorder_list_search.tags = [];
      Purchaseorder_list_search.poster = [];
      Purchaseorder_list_search.updated_after = '';
      Purchaseorder_list_search.delivary_date = '';
      Purchaseorder_list_search.business_date = '';
    },
    addType(state = initialState, action) {
      addInventoryPurchaseOrderType = action.payload.name;
      inventoryPurchaseOrderId = action.payload.id;
    },
    setshowLoading(state = initialState) {
      state.loadingStatus += 1;
    },
    setPageLoading(state: any) {
      state.loadingPage = true;
    },
    setPageCount(state = initialState, action) {
      state.pageCount = action.payload;
    },
  },
  extraReducers: {
    [inventoryItemList.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.itemsList = action.payload;
    },
    [get_supplier_branch.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.get_supplier_branchCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [inventoryPurchaseOrderList.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.PurshaseOrderLoading = false;
      // state.isFiltered = false;
      state.inventoryPurchaseOrderListItems = action.payload;
    },
    [editInventoryPurchaseOrderApi.rejected]: (state: any, action: any) => {
      console.log(action, "acccccccccc");

    },
    [inventoryPurchaseOrderList.pending]: (state: any) => {
      state.loading = true;
      state.PurshaseOrderLoading = true;
    },

    [editInventoryPurchaseOrderAll.fulfilled]: (state: any, action: any) => {
      state.loadingPage = false;
    },
    [editInventoryPurchaseOrderAll.pending]: (state: any, action: any) => {
      state.loadingPage = true;
    },
    [editItemApi.fulfilled]: (state: any, action: any) => {
      state.loadingPage = false;
    },
    [editItemApi.pending]: (state: any, action: any) => {
      state.loadingPage = true;
    },

    [inventoryPurchaseOrderView.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.loadingPage = false;
      state.singleInventoryPurchaseOrderCount += 1;
      state.singleInventoryPurchaseOrderDetails = action.payload;
    },
    [inventoryPurchaseOrderView.pending]: (state: any) => {
      state.loading = true;
      state.loadingPage = true;
    },
    [addInventoryPurchaseOrderApi.fulfilled]: (state: any, action) => {
      state.loading = false;
    },
    [addInventoryPurchaseOrderApi.pending]: (state: any, action) => {
      state.loading = true;
    },

    [getTagsItemsList.fulfilled]: (state: any, action) => {
      state.loading = false;
      state.getTagsItemsListData = action.payload;
    },
    [getSuppliersItemsList.fulfilled]: (state: any, action) => {
      state.loading = false;
      state.getSuppliersItemsListData = action.payload;
    },
    [getItemsList.fulfilled]: (state: any, action) => {
      state.loading = false;
      state.getItemsListData = action.payload;
    },
    [getItemsListSupplier.fulfilled]: (state: any, action) => {
      state.loading = false;
      state.getItemsListSupplierData = action.payload;
    },
    [createPurchasing.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      console.log(action.payload);

      // state.purchasingData = action.payload;
    },
    [exportPurchaseOrderItem.fulfilled]: (state: any, action) => {
      state.loading = false;
      if (isRejectedWithValue(action)) {
        state.error = action?.error.data.message;
      } else {
        state.error = action?.payload?.data;
        window.open(`${process.env.REACT_APP_EXPORTBASEURL}` + action?.payload.data, '_blank');
      }
    },
    [exportPurchaseOrder.fulfilled]: (state: any, action) => {
      state.loading = false;
      if (isRejectedWithValue(action)) {
        state.error = action?.error.data.message;
      } else {
        state.error = action?.payload?.data;
        window.open(`${process.env.REACT_APP_EXPORTBASEURL}` + action?.payload.data, '_blank');
      }
    },
  },
});

export const {
  addType,
  setPurchaseViewIds,
  setshowLoading,
  setFilterData,
  resetFilterState,
  setFilterResetIcon,
  setFilterIcon,
  setPageLoading,
  setPageCount,
} = inventoryPurchaseOrderSlice.actions;
export default inventoryPurchaseOrderSlice.reducer;
