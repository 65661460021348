import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { setCookie } from '../utils/functions';
import { fetch30 } from '../Apis/commonApis';

const firebaseConfig = {
  apiKey: 'AIzaSyCvZWAqRZIDX5a24iBtl5ngYKFYs_5jZfU',
  authDomain: 'notification-system-cf06d.firebaseapp.com',
  projectId: 'notification-system-cf06d',
  storageBucket: 'notification-system-cf06d.appspot.com',
  messagingSenderId: '158108643795',
  appId: '1:158108643795:web:8ebfa4cce2cf3ea0f9ff26',
  measurementId: 'G-J7NQZTDP33',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);
export function requestPermission() {
  console.log('Requesting permission...');
  Notification.requestPermission().then(permission => {
    if (permission === 'granted') {
      getToken(messaging, {
        vapidKey: 'BNF4iXu9GttUc9FpozXiGWEuo4rqHK_2uKvtYxerfuFUpFPvvmBTmMhyAvcXBtEXDn3tfFxKNiCQgSpDxwS-8xQ',
      })
        .then(currentToken => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            console.log(currentToken);
            // setCookie('fcmToken', currentToken, 1);
            localStorage.setItem('fcmToken', currentToken);
            fetch30(`${process.env.REACT_APP_BASEURL}/auth/update-fcm`, { fcm_token: currentToken });
            // ...
          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
          }
        })
        .catch(err => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });
    } else {
      console.log('Unable to get permission to notify.');
    }
  });
}

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
