import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetch2, fetch3, fetch5, fetch9, fetch6 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

export let countSheetListItems: any = null;
export let singleInventoryCountSheetDetails: any = null;
export let itemListItems: any = [];

const initialState = {
  loading: false,
  countSheetListCount: 0,
  singleInventorycountSheetCount: 0,
  inventoryCountSheetTags: [],
};

export const countSheetListApi: any = createAsyncThunk('countSheetListApi', async (body: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/count_sheets`, body);
  countSheetListItems = result;
  console.log(countSheetListItems);

  return result;
});

export const addcountSheetInventoryApi: any = createAsyncThunk('addcountSheet', async body => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/count_sheets`, body);

  return result;
});

export const updateInventorycountSheet: any = createAsyncThunk('updateInventorycountSheet', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/inventory/count_sheets/${body.id}`, body);

  return result;
});

export const deleteInventorycountSheet: any = createAsyncThunk('deleteInventorycountSheet', async (id: any) => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/inventory/count_sheets/${id}`, 'delete');
  return result;
});
export const deleteInventorycountSheetItem: any = createAsyncThunk('deleteInventorycountSheetItem', async (id: any) => {
  const result = await fetch3(
    `${process.env.REACT_APP_BASEURL}/inventory/count_sheets/delete-count_sheets-item/${id}`,
    'delete',
  );
  return result;
});

export const inventorycountSheetDetails: any = createAsyncThunk('inventorycountSheetDetails', async (id: any) => {
  const result = await fetch3(`${process.env.REACT_APP_BASEURL}/inventory/count_sheets/${id}`, 'get');
  console.log(result.data);

  singleInventoryCountSheetDetails = result.data;
  // singleInventoryWarehouseItem = result.data.items;
  return result;
});

export const createCountSheetItem: any = createAsyncThunk('createCountSheetItem', async (body: any) => {
  try {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/count_sheets/create-count_sheets-item/${body.id}`,
      body,
    );
    return result;
  } catch (e: any) {
    console.log(e);
    return e.response.data;
  }
});
export const deleteCountSheetItem: any = createAsyncThunk('deleteCountSheetItem', async (id: any) => {
  const result = await fetch3(
    `${process.env.REACT_APP_BASEURL}/inventory/count_sheets/delete-count_sheets-item/${id}`,
    'delete',
  );
  return result;
});

export const itemList: any = createAsyncThunk('itemList', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/items`, body);
  itemListItems = result.data;
  return result;
});
export const saveSortingItems: any = createAsyncThunk('saveSortingItems', async body => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/count_sheets/sort`, body);
  return result;
});
const countsheetSlice: any = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    startLoading(state = initialState) {
      state.loading = true;
    },
    endLoading(state = initialState) {
      state.loading = false;
    },
  },
  extraReducers: {
    [countSheetListApi.fulfilled]: (state: any, { payload: { errors, message } }) => {
      state.loading = false;
      state.countSheetListCount += 1;

      if (errors) {
        notify(message);
      }
    },
    [countSheetListApi.pending]: (state: any) => {
      state.loading = true;
    },

    [addcountSheetInventoryApi.fulfilled]: (state: any, { payload: { errors, message, status } }) => {
      state.loading = false;
      state.countSheetListCount += 1;
      if (status) {
        notify(message, 'success');
      }
      if (errors) {
        notify(message);
      }
    },
    [inventorycountSheetDetails.fulfilled]: (state: any, { payload: { errors, message } }) => {
      state.loading = false;
      state.singleInventorycountSheetCount += 1;

      if (errors) {
        notify(message);
      }
    },
    [inventorycountSheetDetails.pending]: (state: any) => {
      state.loading = true;
    },
    [deleteInventorycountSheet.fulfilled]: (state: any, { payload: { errors, message, status } }) => {
      state.loading = false;
      state.singleInventorycountSheetCount += 1;
      if (status) {
        notify(message, 'success');
      }
      if (errors) {
        notify(message);
      }
    },
    [deleteInventorycountSheetItem.fulfilled]: (state: any, { payload: { errors, message, status } }) => {
      state.loading = false;
      state.singleInventorycountSheetCount += 1;
      if (status) {
        notify(message, 'success');
      }
      if (errors) {
        notify(message);
      }
    },
    [deleteInventorycountSheetItem.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.singleInventorycountSheetCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [updateInventorycountSheet.fulfilled]: (state: any, { payload: { errors, message, status } }) => {
      state.loading = false;
      state.singleInventorycountSheetCount += 1;
      if (status) {
        notify(message, 'success');
      }
      if (errors) {
        notify(message);
      }
    },

    [saveSortingItems.fulfilled]: (state: any, { payload: { errors, message, status } }) => {
      state.loading = false;

      console.log(errors);
      if (status) {
        notify(message, 'success');
      }

      if (errors) {
        notify(message);
      }
    },
    [createCountSheetItem.fulfilled]: (state: any, { payload: { errors, message, status } }) => {
      state.loading = false;

      if (status) {
        notify(message, 'success');
      }
      if (errors) {
        notify(message);
      }
    },
    [deleteCountSheetItem.fulfilled]: (state: any, { payload: { errors, message, status } }) => {
      state.loading = false;

      if (status) {
        notify(message, 'success');
      }
      if (errors) {
        notify(message);
      }
    },
  },
});

export default countsheetSlice.reducer;
