import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetch10, fetch4, fetch5, fetch6, fetch9 } from '../../Apis/commonApis';
import toast from 'react-hot-toast';

export const delManageReason: any = createAsyncThunk('delManageReason', async (resonId: any) => {
  console.log(resonId);

  const response = await fetch10(`${process.env.REACT_APP_BASEURL}/manage/reasons/${resonId}`);
  toast.success(response.message);

  return response;
});
export const restoreManageReason: any = createAsyncThunk('restoreManageReason', async (resonId: any) => {
  console.log(resonId);

  const response = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/reason/restore_reason_deleted/${resonId}`);
  toast.success(response.message);

  return response;
});

export const addManageReason: any = createAsyncThunk('addManageReason', async body => {
  const response = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/reasons`, body);

  toast.success(response.message);

  return response;
});

export const UpdateReasons: any = createAsyncThunk('UpdateReasons', async (body: any) => {
  const response = await fetch9(`${process.env.REACT_APP_BASEURL}/manage/reasons/${body.reasonId}`, body.reason);
  toast.success(response.message);

  return response.data;
});
export const showQtyAdjustmentReason = createAsyncThunk(
  'showQtyAdjustmentReason',

  async (body: any) => {
    const result = await fetch6(`${process.env.REACT_APP_BASEURL}/manage/reasons`, body);
    return result;
  },
);

const handelAddRemoveShowQtyResons: any = createSlice({
  name: 'qtyAdjustmentReasonSlice',
  initialState: { delResons: null, addNewResons: null, showAllResons: null },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(delManageReason.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.delResons = action.payload;
      })

      .addCase(showQtyAdjustmentReason.fulfilled, (state: any, action) => {
        state.isLoading = false;

        state.showAllResons = action.payload;
      })
      .addCase(showQtyAdjustmentReason.pending, (state: any, action) => {
        state.isLoading = true;

        state.showAllResons = action.payload;
      });
  },
});

export default handelAddRemoveShowQtyResons.reducer;
