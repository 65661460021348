import React, { ReactNode } from 'react';
interface BadgeProps {
  type: 'Success' | 'Danger' | 'Primary' | 'Secondary' | 'Light' | 'Warning' | 'Dark' | 'Info' | 'Outline';
  text: ReactNode;
  size?: 'small';
  className?: string;
  onClick?: () => void;
}

{/* <Badge text='Secondary' type='Secondary' />
<Badge text='Light' type='Light' />
<Badge text='Danger' type='Danger' />
<Badge text='Success' type='Success' />
<Badge text='Primary' type='Primary' />
<Badge text='Info' type='Info' />
<Badge text='Warning' type='Warning' />
<Badge text='Dark' type='Dark' />
<Badge text='Outline' type='Outline' /> */}
export const Badge: React.FC<BadgeProps> = ({ type, text, size, className, onClick }) => {

  return (
    <span className={`custom__badge custom__badge-${type} custom__badge-${size} ${className}`} onClick={onClick}>
      {text}
    </span>
  );
};
