import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch10, fetch2, fetch3, fetch5, fetch6, fetch7, fetch8, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState: {
  loading: any;
  pageLoading: any;
  comboListCount: any;
  addComboCount: any;
  deleteComboCount: any;
  comboViewCount: any;
  addSizeCount: any;
  activeComboCount: any;
  getSizeCount: any;
  addGroupCount: any;
  getGroupCount: any;
  loadingStatus: any;
  addCustomPriceCount: any;
  activeInactiveBranchCount: any;
  addTagCount: any;
  groupListItems: any;
  editComboGroupOption: any;
  isFiltered: any;
  pageCount: any;
} = {
  loading: false,
  pageLoading: true,
  comboListCount: 0,
  addComboCount: 0,
  deleteComboCount: 0,
  comboViewCount: 0,
  addSizeCount: 0,
  activeComboCount: 0,
  getSizeCount: 0,
  addGroupCount: 0,
  getGroupCount: 0,
  loadingStatus: false,
  addCustomPriceCount: 0,
  activeInactiveBranchCount: 0,
  addTagCount: 0,
  groupListItems: [],
  editComboGroupOption: null,
  isFiltered: null,
  pageCount: 0,
};

export let comboFilterValue: any = {
  type: '',
  search: '',
  name: '',
  sku: '',
  barcode: '',
  categories: '',
  groups: '',
  tag: '',
};
export let filterArray: any = {
  categories: [],
  groups: [],
  tag: [],
};
export let comboListItems: any = [];
export let comboViewPerId: any;
export let sizeList: any;
export let groupList: any;
export let editComboSizeData: any;
export let editCustomPriceData: any;
// export let editComboGroupOption: any;
export let editLinkProduct: any;
export let editComboGroup: any;
export let combogroupId: any = '';
export const comboList: any = createAsyncThunk('  ', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/combos`, body);
  comboListItems = result;
  return result;
});

export const addCombo: any = createAsyncThunk('addCombo', async body => {
  console.log(body, '12340 combo create');

  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/menu/combos`, body);
  return result;
});
export const editCombo: any = createAsyncThunk('editCombo', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/menu/combos/${body.id}`, body.body);
  return result;
});
export const restoreCombo: any = createAsyncThunk('restoreCombo', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/menu/combos/${body.id}/restore`);
  return result;
});
export const deleteCombo: any = createAsyncThunk('deleteCombo', async (body: any) => {
  const result = await fetch10(`${process.env.REACT_APP_BASEURL}/menu/combos/${body}`);
  return result;
});

export const comboView: any = createAsyncThunk('comboView', async id => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/combos/` + id);
  comboViewPerId = result;

  return result;
});

export const activeCombo: any = createAsyncThunk('activeCombo', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/menu/combo_activate_deactivate`, body);
  comboViewPerId = result;
  return result;
});

export const addSize: any = createAsyncThunk('addSize', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/menu/combos/${body.id}/sizes`, body.body);
  return result;
});
export const comboAddOptions: any = createAsyncThunk('comboAddOptions', async (body: any) => {
  console.log(body);

  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/menu/combos/${body.combo_id}/items/${body.item_id}/options`,
    body.body,
  );
  return result;
});
export const comboEditOptions: any = createAsyncThunk('comboEditOptions', async (body: any) => {
  console.log(body);

  const result = await fetch9(
    `${process.env.REACT_APP_BASEURL}/menu/combos/${body.combo_id}/items/${body.item_id}/options/${body.option_id}`,
    body.body,
  );
  return result;
});
export const attachProductToComboOption: any = createAsyncThunk('attachProductToComboOption', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/menu/combos/${body.combo_id}/items/${body.item_id}/options/${body.option_id}/sizes/${body.size_id}/products/${body.product_id}`,
    body.body,
  );
  return result;
});
export const deleteComboOptions: any = createAsyncThunk('deleteComboOptions', async (body: any) => {
  const result = await fetch10(
    `${process.env.REACT_APP_BASEURL}/menu/combos/${body.combo_id}/items/${body.item_id}/options/${body.option_id}`,
  );
  return result;
});
export const modifyComboSize: any = createAsyncThunk('modifyComboSize', async (body: any) => {
  const result = await fetch9(
    `${process.env.REACT_APP_BASEURL}/menu/combos/${body.combo_id}/sizes/${body.size_id}`,
    body.body,
  );
  return result;
});
export const deleteComboSize: any = createAsyncThunk('deleteComboSize', async (body: any) => {
  const result = await fetch10(
    `${process.env.REACT_APP_BASEURL}/menu/combos/${body.combo_id}/sizes/${body.size_id}`,
    body,
  );
  return result;
});
export const getSize: any = createAsyncThunk('getSize', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/menu/comboget_size`, body);
  sizeList = result.data;
  return result;
});

export const addGroup: any = createAsyncThunk('addGroup', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/menu/combos/${body.combo_id}/items`, body.body);
  return result;
});
export const deleteComboGroup: any = createAsyncThunk('deleteComboGroup', async (body: any) => {
  const result = await fetch10(`${process.env.REACT_APP_BASEURL}/menu/combos/${body.combo_id}/items/${body.item_id}`);
  return result;
});
export const ModifyComboGroup: any = createAsyncThunk('ModifyComboGroup', async (body: any) => {
  const result = await fetch9(
    `${process.env.REACT_APP_BASEURL}/menu/combos/${body.combo_id}/items/${body.item_id}`,
    body.body,
  );
  return result;
});
export const getGroup: any = createAsyncThunk('getGroup', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/comboview_getgroup`, body);
  groupList = result.data;
  return result;
});
export const comboDeletemultiple: any = createAsyncThunk('comboDeletemultiple', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/combo_multi_delete`, body);
  return result;
});
export const comboAddCustomPrice: any = createAsyncThunk('comboAddCustomPrice', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/menu/combos/${body.comboid}/items/${body.combogroup}/options/${body.option_id}/sizes/${body.combosize}/branches/${body.branchid}`,
    { price: +body.price },
  );
  return result;
});
export const linkComboOptionItem: any = createAsyncThunk('linkComboOptionItem', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/link_combo_option_item`, body);
  return result;
});
export const comboModifyCustomPrice: any = createAsyncThunk('comboModifyCustomPrice', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/modify_combo_customprice`, body);
  return result;
});

export const comboDeleteCustomPrice: any = createAsyncThunk('comboDeleteCustomPrice', async (body: any) => {
  const result = await fetch10(
    `${process.env.REACT_APP_BASEURL}/menu/combos/${body.comboId}/items/${body.itemId}/options/${body.optionId}/sizes/${body.sizeId}/branches/${body.branchId}`,
  );
  return result;
});

export const comboMultipleAddtag: any = createAsyncThunk('comboMultipleAddtag', async body => {
  console.log(body, 'comboMultipleAddtag');

  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/combo_multiple_addtag`, body);
  return result;
});

export const comboMultipleRemovetag: any = createAsyncThunk('comboMultipleRemovetag', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/combo_multiple_deletetag`, body);
  return result;
});

export const comboActiveBrachBultiAdd: any = createAsyncThunk('comboActiveBrachBultiAdd', async (body: any) => {
  const result = await fetch5(
    `${process.env.REACT_APP_BASEURL}/menu/combos/${body.combo_id}/branches/${body.branch_id}`,
    { is_active: body.is_active },
  );
  return result;
});

export const comboDeActiveBrachBultiAdd: any = createAsyncThunk('comboDeActiveBrachBultiAdd', async (body: any) => {
  const result = await fetch10(
    `${process.env.REACT_APP_BASEURL}/menu/combos/${body.combo_id}/branches/${body.branch_id}`,
  );
  return result;
});

export const activeComboInGroups: any = createAsyncThunk('activeComboInGroups', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/active_combo_in_groups`, body);
  return result;
});

export const deactiveComboInGroups: any = createAsyncThunk('activeComboInGroups', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/combo_deactivebrachadd`, body);
  return result;
});

export const getGroupListData: any = createAsyncThunk('getGroupListData', async body => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/menu/groups`, body);

  return result;
});
export const generateSKU: any = createAsyncThunk('generateSKU', async (id: any) => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/manage/generate_sku`, {
      model: 'comboses',
    });
    return result;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});
const menuComboSlice: any = createSlice({
  name: 'menuComboSlice',
  initialState,
  reducers: {
    comboFilterData(state = initialState, action) {
      comboFilterValue.name = action.payload.name;
      comboFilterValue.sku = action.payload.sku;
      comboFilterValue.barcode = action.payload.barcode;
      comboFilterValue.search = action.payload.search;
      comboFilterValue.tag = action.payload.tag;
      comboFilterValue.groups = action.payload.groups;
      comboFilterValue.categories = action.payload.categories;
    },
    resetCombo(state = initialState) {
      comboFilterValue.name = '';
      comboFilterValue.sku = '';
      comboFilterValue.barcode = '';
      comboFilterValue.email = '';
      comboFilterValue.groups = '';
      comboFilterValue.categories = '';
      comboFilterValue.tag = '';
      filterArray.categories = [];
      filterArray.groups = [];
      filterArray.tag = [];
    },
    setshowLoading(state = initialState) {
      state.loadingStatus = !state.loadingStatus;
    },
    setEditComboSizeData(state = initialState, action: any) {
      editComboSizeData = action.payload;
    },
    setEditCustomPriceData(state = initialState, action: any) {
      editCustomPriceData = action.payload;
    },
    setComboGroupId(state = initialState, action: any) {
      combogroupId = action.payload;
      console.log(combogroupId);
    },
    setEditComboGroupOption(state = initialState, action: any) {
      console.log(action.payload);

      state.editComboGroupOption = action.payload;
    },
    setEditLinkProduct(state = initialState, action: any) {
      editLinkProduct = action.payload;
    },
    setEditComboGroup(state = initialState, action: any) {
      editComboGroup = action.payload;
    },
    changeIsFiltered(state = initialState, action: any) {
      state.isFiltered = action.payload;
    },
    setPageCount(state = initialState, action: any) {
      state.pageCount = action.payload;
    },
  },
  extraReducers: {
    [comboList.pending]: (state: any, action: any) => {
      state.pageLoading = true;
      // state.pageLoading = action.payload;
    },
    [comboList.fulfilled]: (state: any, action: any) => {
      state.pageLoading = false;
      // state.pageLoading = action.payload;
    },
    [addCombo.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.addComboCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(message, 'success');
        state.error = message;
      }
    },
    [comboView.pending]: (state: any) => {
      state.loading = true;
    },
    [comboView.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.comboViewCount += 1;
      state.pageLoading = false;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [editCombo.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.comboViewCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [addSize.fulfilled]: (state: any, { payload: { error, msg } }) => {
      state.loading = false;
      state.addSizeCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(msg, 'success');
        state.error = msg;
      }
    },
    [modifyComboSize.fulfilled]: (state: any, { payload: { error, msg } }) => {
      state.loading = false;
      state.addSizeCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(msg, 'success');
        state.error = msg;
      }
    },
    [deleteComboSize.fulfilled]: (state: any, { payload: { error, msg } }) => {
      state.loading = false;
      state.addSizeCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(msg, 'success');
        state.error = msg;
      }
    },
    [comboAddOptions.fulfilled]: (state: any, { payload: { error, msg } }) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        notify(msg, 'success');
        state.error = msg;
      }
    },
    [attachProductToComboOption.fulfilled]: (state: any, { payload: { error, msg } }) => {
      state.loading = false;
      state.addSizeCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(msg, 'success');
        state.error = msg;
      }
    },
    [deleteComboOptions.fulfilled]: (state: any, { payload: { error, msg } }) => {
      state.loading = false;
      state.addSizeCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(msg, 'success');
        state.error = msg;
      }
    },
    [ModifyComboGroup.fulfilled]: (state: any, { payload: { error, msg } }) => {
      state.loading = false;
      state.addSizeCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(msg, 'success');
        state.error = msg;
      }
    },
    [deleteComboGroup.fulfilled]: (state: any, { payload: { error, msg } }) => {
      state.loading = false;
      state.addSizeCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(msg, 'success');
        state.error = msg;
      }
    },
    [activeCombo.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.activeComboCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [getSize.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.getSizeCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [addGroup.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.addGroupCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(message, 'success');
        state.error = message;
      }
    },
    [getGroup.fulfilled]: (state: any, { payload: { error, message } }) => {
      state.loading = false;
      state.getGroupCount += 1;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
    [comboDeletemultiple.fulfilled]: (state: any, { payload: { error, message, status } }) => {
      state.loading = false;
      state.loadingStatus += 1;
      state.deleteComboCount += 1;
      if (error) {
        state.error = error;
      } else {
        let type = status ? 'success' : '';
        notify(message, type);
        state.error = message;
      }
    },
    [comboAddCustomPrice.fulfilled]: (state: any, { payload: { error, msg } }) => {
      state.loading = false;
      state.addSizeCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(msg, 'success');
        state.error = msg;
      }
    },
    [linkComboOptionItem.fulfilled]: (state: any, { payload: { error, msg } }) => {
      state.loading = false;
      state.addSizeCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(msg, 'success');
        state.error = msg;
      }
    },
    [comboModifyCustomPrice.fulfilled]: (state: any, { payload: { error, msg } }) => {
      state.loading = false;
      state.addSizeCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(msg, 'success');
        state.error = msg;
      }
    },
    [comboDeleteCustomPrice.fulfilled]: (state: any, { payload: { error, msg } }) => {
      state.loading = false;
      state.addSizeCount += 1;
      if (error) {
        state.error = error;
      } else {
        notify(msg, 'success');
        state.error = msg;
      }
    },
    [comboMultipleAddtag.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [comboMultipleRemovetag.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.addTagCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [comboActiveBrachBultiAdd.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.comboViewCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [comboDeActiveBrachBultiAdd.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.activeInactiveBranchCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [activeComboInGroups.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.activeInactiveBranchCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [getGroupListData.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.groupListItems = action.payload;
    },
    [deactiveComboInGroups.fulfilled]: (state: any, { payload: { error, massage, status } }) => {
      state.loading = false;
      state.activeInactiveBranchCount += 1;
      if (error) {
        state.error = error;
      } else {
        if (status) notify(massage, 'success');
        else notify(massage);
        state.error = massage;
      }
    },
    [generateSKU.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.generatedSKU = action.payload.data;
    },
  },
});

export const {
  resetCombo,
  comboFilterData,
  setshowLoading,
  setEditComboSizeData,
  setEditCustomPriceData,
  setComboGroupId,
  setEditComboGroupOption,
  setEditLinkProduct,
  setEditComboGroup,
  changeIsFiltered,
  setPageCount,
} = menuComboSlice.actions;
export default menuComboSlice.reducer;
