import axios from 'axios';
import { getCookie, removeCookie } from '../utils/functions';
import toast from 'react-hot-toast';

// handle errors in axios
axios.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      // auto logout if 401 response returned from api
      localStorage.removeItem('xPmOtQST');
      localStorage.removeItem('___data');
      window.location.href = '/login';
    } else if (error.response?.status === 500 || error?.code === 'ERR_NETWORK') {
      toast.error('Something went wrong please try again');
    }

    return Promise.reject(error.response);
  },
);

export const fetch2 = async (api: any, body: any) => {
  const userToken = localStorage.getItem('xPmOtQST');

  if (body) {
    let entries = Object.keys(body);
    let data = new FormData();
    for (let i = 0; i < entries.length; i++) {
      data.append(entries[i], body[entries[i]]);
    }
    const res = await fetch(api, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      body: data,
    });
    return await res.json();
  } else {
    const res = await fetch(api, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    return await res.json();
  }
};

export const fetch3 = async (api: any, type: any) => {
  const userToken = localStorage.getItem('xPmOtQST');

  const res = await fetch(api, {
    method: type,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
  return await res.json();
};

export const fetch4 = async (api: any, body: any) => {
  const userToken = localStorage.getItem('xPmOtQST');

  if (body) {
    const res = await fetch(api, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(body),
    });
    return await res.json();
  } else {
    const res = await fetch(api, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    return await res.json();
  }
};

export const fetch5 = async (api: any, body?: any, params?: any) => {
  const userToken = localStorage.getItem('xPmOtQST');

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const config = { headers };

  const response = await axios.post(api, body, config);
  return response.data;
};
export const loginFetch = async (api: any, body?: any) => {
  const userToken = localStorage.getItem('xPmOtQST');

  const response = await axios.post(api, body);
  return response.data;
};

export const fetch6 = async (api: any, body?: any) => {
  const userToken = localStorage.getItem('xPmOtQST');

  const response = await axios.get(api, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    params: body,
  });
  return response.data;
};

export const fetch14 = async (api: any, body?: any) => {
  const userToken = localStorage.getItem('xPmOtQST');

  const response = await axios.get(api, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    params: body,
  });
  // console.log(response, "===SARHAN===");
  return response.data;
};

export const fetch15 = async (api: any, body?: any) => {
  const userToken = localStorage.getItem('xPmOtQST');

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const config = { headers };
  const response = await axios.put(api, body, config);
  return response.data;
};

export const fetch16 = async (api: any, body?: any) => {
  const userToken = localStorage.getItem('xPmOtQST');

  const headers = {
    // Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")!)}`,
    Authorization: `Bearer ${userToken}`,
  };
  const config = { headers };
  const response = await axios.delete(api, config);
  return response.data;
};

export const fetch30 = async (api: any, body?: any) => {
  const userToken = localStorage.getItem('xPmOtQST');

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const config = { headers };
  const response = await axios.post(api, body, config);
  return response.data;
};

export const fetch7 = async (api: any, body?: any) => {
  const userToken = localStorage.getItem('xPmOtQST');

  const headers = {
    Authorization: `Bearer ${userToken}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const config = { headers };
  const response = await axios.post(api, config);
  return response;
};

export const fetch8 = async (api: any, body: any) => {
  const userToken = localStorage.getItem('xPmOtQST');

  if (body) {
    let entries = Object.keys(body);
    let data = new FormData();
    for (let i = 0; i < entries.length; i++) {
      data.append(entries[i], body[entries[i]]);
    }
    const headers = {
      Authorization: `Bearer ${userToken}`,
    };
    const config = { headers };
    const response = await axios.post(api, data, config);
    return response.data;
  }
};

export const fetch9 = async (api: any, body?: any) => {
  const userToken = localStorage.getItem('xPmOtQST');

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const config = { headers };
  const response = await axios.put(api, body, config);
  return response.data;
};

export const fetch10 = async (api: any, body?: any) => {
  const userToken = localStorage.getItem('xPmOtQST');

  const headers = {
    // Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")!)}`,
    Authorization: `Bearer ${userToken}`,
  };
  const config = { headers };
  const response = await axios.delete(api, config);
  return response.data;
};

export const fetch11 = async (api: any, body?: any) => {
  const userToken = localStorage.getItem('xPmOtQST');

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const config = { headers };
  const response = await axios.patch(api, body, config);
  return response;
};

export const fetch22 = async (api: any, body?: any) => {
  const userToken = localStorage.getItem('xPmOtQST');

  const response = await axios.get(api, {
    headers: {
      Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjNkMTQ3NmZjZWE1NjZlZmQ5MzNhM2UwNDk5N2NmNThmZmI0OGNjZmEwODYxZjExMjI4NzRkMjRhOTUyZGIzNzdjZTE5MmVjNDAyNjBlZjNiIn0.eyJhdWQiOiI4ZjllYjNmNi02ZWZhLTRmZWYtODk1ZS1kMWJjNDRiYTQ4MWQiLCJqdGkiOiIzZDE0NzZmY2VhNTY2ZWZkOTMzYTNlMDQ5OTdjZjU4ZmZiNDhjY2ZhMDg2MWYxMTIyODc0ZDI0YTk1MmRiMzc3Y2UxOTJlYzQwMjYwZWYzYiIsImlhdCI6MTY4Nzc3Nzg2MSwibmJmIjoxNjg3Nzc3ODYxLCJleHAiOjE4NDU2MzA2NjEsInN1YiI6Ijk5ODBhMjNjLTBjYzctNDJlMS04Yjg3LWM1MjUwNjk4YmU1MSIsInNjb3BlcyI6WyJnZW5lcmFsLnJlYWQiLCJvcGVyYXRpb25zLnJlYWQiLCJvcmRlcnMubGlzdCIsImNvdXBvbnMucmVhZCIsImN1c3RvbWVycy5hY2NvdW50cy5yZWFkIiwiaW52ZW50b3J5LnRyYW5zYWN0aW9ucy5yZWFkIiwiaW52ZW50b3J5LnRyYW5zYWN0aW9ucy53cml0ZSIsInVzZXJzLnJlYWQiLCJ1c2Vycy53cml0ZSIsIm1lbnUuaW5ncmVkaWVudHMucmVhZCIsIm1lbnUuaW5ncmVkaWVudHMud3JpdGUiLCJpbnZlbnRvcnkuc2V0dGluZ3MucmVhZCIsImludmVudG9yeS5zZXR0aW5ncy53cml0ZSIsIm1lbnUud3JpdGUiLCJjdXN0b21lcnMubGlzdCIsImN1c3RvbWVycy53cml0ZSIsIm9yZGVycy5naWZ0X2NhcmRzLnJlYWQiLCJjdXN0b21lcnMuYWNjb3VudHMud3JpdGUiLCJjdXN0b21lcnMubG95YWx0eS5yZWFkIl0sImJ1c2luZXNzIjoiOTk4MGEyM2MtMWE3MC00MDEyLTkxMzgtYmZiNjNlYWFkM2FkIiwicmVmZXJlbmNlIjoiNDYxMjM3In0.gO6t-KZHUeYcNLeNold46BnqNKjOxbuT8rzQFM6zRJIz3kmAoUbwUkD9fAreq13D9lD9gCo332PavOEucC-ZxjNL1vo8VOdhD35zKKNXHqInsiMTdsX5WeqCVfBfI-PqHVC3tzGslwgd6z0EC_A7wsY22XlRF5O-Hs1s8FEVYsVv9OTOZBDmFaq5WPq71qYgvBm6R8B5F5Cm1SobBz39SD9FDAVR2hRzgDklBDKOAig5PlwqMTLLpJk6OqCgc8kCDvLFmQUEj_algPEOBQgs4bhmkwLhu3WALN3si6ftPpaAOS9LByOdZbIUGlTh_jTCt7ch2ScmKAt1VhHKTD5_vNW6l_-DvSHmmgok_onp8ElU848ntJEosn0rBtg-V-LXXv2s9FOj1sdR4DUpaPkmR0Kfy4IZFbBddnA4TMTVpDfm-XmVEOKJb7bH5d9OyqH9SWYlc8a-UGWMDVFQ5uWAqFVYy-qurkdQ6SmPFvqKzA3vy04Er85WNPYQkdRRsZx4ee8n6-9NKN_N4EfdQahqOqLIwFCBQKrQ_DKy-pIjo__l9LoJWUppMRv75l_2M1Rj811V5ybctiar4fjcU8LNP9Qu0P9mikJbfDc7FxB1xwKmx5nM272utm7XudYCzZHJyLQ-Voa7Xm14eA0Q8_CrbpeLPLTiosN7v5WM__phc2g`,
    },
    params: body,
  });
  return response.data;
};
export const getReqNoToken = async (api: any, body?: any) => {
  const response = await axios.get(api, {
    params: body,
  });
  return response.data;
};
