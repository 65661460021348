import React from 'react';
import logo from '../../../images/POS/dot-logo.png';

const PermissionDenied = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '13rem',
        marginLeft: '10rem',
      }}
    >
      <img src={logo} width={120} alt='logo' />
      <p style={{ marginTop: '1rem' }}>Welcome to Dot . You can select any option from the sidebar to start.</p>
    </div>
  );
};

export default PermissionDenied;
