import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import i18n from "i18next";

export interface GlobalState {
  language: "en" | "ar";
  direction: "rtl" | "ltr";
}

const languageFromLocalStorage = (Cookies.get("App") as "en" | "ar") || "en";
const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE as "en" | "ar";

const language: "en" | "ar" = languageFromLocalStorage ?? defaultLanguage;

const initialState: GlobalState = {
  language,
  direction: language === "en" ? "ltr" : "rtl",
};

const GlobalSlice = createSlice({
  name: "App",
  initialState,
  reducers: {
    changeLanguage: (
      state,
      action: PayloadAction<{ language: "en" | "ar" }>
    ) => {
      state.language = action.payload.language;
      state.direction = action.payload.language === "en" ? "ltr" : "rtl";
      Cookies.set("App", action.payload.language, { expires: 365 });
      i18n.changeLanguage(action.payload.language);
      document.documentElement.style.setProperty(
        "--selected-language",
        action.payload.language
      );
    },
  },
});

export const { changeLanguage } = GlobalSlice.actions;
export default GlobalSlice.reducer;
