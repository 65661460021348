import React from 'react';
import { addDays, startOfMonth, startOfDay, endOfDay, subDays } from 'date-fns';
import moment from 'moment';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import cx from '../components/Modals/Modals.module.scss';
import table from '../datatable.module.scss';
import { useTranslation } from 'react-i18next';
import { notify } from '../constants/notifyconstants';
import { fetch5, fetch6 } from '../Apis/commonApis';

export const tooltipHelper: any = (name: any, content: any, transaltor?: any) => {
  if (transaltor) {
    name = transaltor(`GLOBAL.${name.toUpperCase().replaceAll(' ', '_')}`);
  }
  return (
    <>
      {name}
      {['top'].map(placement => (
        <OverlayTrigger key={placement} overlay={<Tooltip id={`tooltip-${placement}`}>{content}</Tooltip>}>
          <span className={`${cx.tooltips} ms-2`} style={{ top: '1px' }}>
            <AiOutlineInfoCircle />
          </span>
        </OverlayTrigger>
      ))}
    </>
  );
};

export const currentDay = (type: string = 'formal-date') => {
  const today = new Date();

  const startDate = startOfDay(today);
  const endDate = endOfDay(today);

  const dateRange = {
    start: startDate,
    end: endDate,
  };

  const start = moment(dateRange?.start).format('YYYY-MM-DD');
  const end = moment(dateRange?.end).format('YYYY-MM-DD');

  return type === 'string' ? `${start} - ${end}` : dateRange;
};

export const currentWeek = (type: string = 'formal-date') => {
  const today = new Date();

  const startDate = new Date(moment(today).startOf('isoWeek').format('YYYY-MM-DD'));
  const endDate = new Date(moment(today).endOf('isoWeek').format('YYYY-MM-DD'));

  const dateRange: any = {
    start: startDate,
    end: endDate,
  };

  const start = moment(dateRange?.start).format(type === 'string' ? 'YYYY-MM-DD' : 'DD MMM');
  const end = moment(dateRange?.end).format(type === 'string' ? 'YYYY-MM-DD' : 'DD MMM');

  return type === 'string' || type === 'dayMonthString' ? `${start} - ${end}` : dateRange;
};

export const currentMonth = (type: string = 'formal-date') => {
  const today = new Date();

  const startDate = new Date(moment(today).startOf('month').format('YYYY-MM-DD'));
  const endDate = new Date(moment(today).endOf('month').format('YYYY-MM-DD'));

  const dateRange = {
    start: startDate,
    end: endDate,
  };

  const start = moment(dateRange?.start).format('YYYY-MM-DD');
  const end = moment(dateRange?.end).format('YYYY-MM-DD');

  return type === 'string' ? `${start} - ${end}` : dateRange;
};

export const getWeekByIsoWeekString = (date: string) => {
  const today = new Date();

  const startDate = moment(today).startOf('isoWeek');
  const endDate = moment(today).endOf('isoWeek');

  const dateRange = {
    start: startDate,
    end: endDate,
  };
};

export const getMonthWeeksArray = (date: any) => {
  const currentDate = new Date(moment(date).startOf('month').format('YYYY-MM-DD'));

  const weeksArray = [];
  for (let i = 0; i < 5; i++) {
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - ((currentDate.getDay() + 6) % 7) + i * 7);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    const formattedDayMonthWeek = `${startDate.getDate()} ${startDate.toLocaleString('default', {
      month: 'short',
    })} - ${endDate.getDate()} ${endDate.toLocaleString('default', {
      month: 'short',
    })}`;

    const formattedWeek = `${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}`;

    weeksArray.push({
      stringWeek: formattedWeek,
      dayMonthWeekString: formattedDayMonthWeek,
    });
  }

  return weeksArray;
};

export const getWindowSize = () => {
  const { innerWidth } = window;
  return { innerWidth };
};

export function getNumberOfPages(rowCount: number, rowsPerPage: number): number {
  return Math.ceil(rowCount / rowsPerPage);
}

export function formatTimeTo12Hour(timeString: string) {
  const [hours] = timeString.split(':');
  const formattedHours = parseInt(hours, 10);

  if (timeString === '00:00:00') {
    return `12 AM`;
  }

  const period = formattedHours >= 12 ? 'PM' : 'AM';
  const formatted12Hours = formattedHours > 12 ? (formattedHours - 12).toString() : formattedHours.toString();

  return `${formatted12Hours} ${period}`;
}
export const TablePaginationActions = (productsTableMeta: any, handleChangePage: any) => {
  const { t } = useTranslation();
  return (
    <div className={`${table.pagination}`}>
      <div>
        {t('GLOBAL.SHOWING')} {productsTableMeta?.from || 0} {t('GLOBAL.TO')} {productsTableMeta?.to || 0}{' '}
        {t('GLOBAL.OUT_OF')} {productsTableMeta?.total || 0}
      </div>
      <div>
        <button
          disabled={productsTableMeta?.current_page - 1 === 0}
          onClick={e => handleChangePage(e, productsTableMeta?.current_page - 1 - 1)}
        >
          {t('GLOBAL.PREVIOUS')}
        </button>
        <button
          disabled={
            productsTableMeta?.current_page >= getNumberOfPages(productsTableMeta?.total, productsTableMeta?.per_page)
          }
          onClick={e => handleChangePage(e, productsTableMeta?.current_page - 1 + 1)}
        >
          {t('GLOBAL.NEXT')}
        </button>
      </div>
    </div>
  );
};

// convert images to base 64
export const imageBase64Handler = async (imgObj: any) => {
  let image = '';

  const imageConversionPromise = new Promise((resolve: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result as string;
      if (base64String) {
        const base64Data = base64String.split(',')[1];
        image = `data:${imgObj.type};base64,${base64Data}`;
        resolve();
      } else {
        resolve();
      }
    };
    reader.readAsDataURL(imgObj);
  });

  await imageConversionPromise;
  return image;
};

export const exportLinkHandler = async (exportType: string, moduleName: string, is_template: any = 0, date?: any) => {
  const searchParams = new URLSearchParams(window.location.search);

  // check if there is a date in the url
  const isDate = searchParams.get('date');

  // default date if there is no date in the url
  const defaultDate = `${moment().startOf('month').format('YYYY-MM-DD')} - ${moment()
    .endOf('month')
    .format('YYYY-MM-DD')}`;

  const filterQuery: any = { is_template, date: isDate ? searchParams.get('date') : defaultDate };

  // change all values in the url to an array except date to send it to backend
  Object.entries(Object.fromEntries([...searchParams])).forEach(([key, value]) => {
    if (key === 'date' || key === 'business_date') {
      filterQuery[key] = value;
    } else {
      filterQuery[key] = value.split(',');
    }
  });

  try {
    const response: any = await fetch5(` ${process.env.REACT_APP_BASEURL}/${exportType}/${moduleName}`, filterQuery);
    const fileUrl = response?.data?.url;
    let Link = document.createElement('a');
    Link.style.display = 'none';
    Link.href = fileUrl;
    Link.download = moduleName;
    document.body.appendChild(Link);
    Link.click();
    document.body.removeChild(Link);
    notify('Downloaded Successfully', 'success');
  } catch (error: any) {
    notify(error?.data?.message, 'error');
    console.log(error, 'errorerror');
  }
};

export const exportPDFLinkHandler = async (exportType: string, moduleName: string, is_pdf: any = 0, date?: any) => {
  const searchParams = new URLSearchParams(window.location.search);

  // check if there is a date in the url
  const isDate = searchParams.get('date');

  // default date if there is no date in the url
  const defaultDate = `${moment().startOf('month').format('YYYY-MM-DD')} - ${moment()
    .endOf('month')
    .format('YYYY-MM-DD')}`;

  const filterQuery: any = { is_pdf, date: isDate ? searchParams.get('date') : defaultDate };

  // change all values in the url to an array except date to send it to backend
  Object.entries(Object.fromEntries([...searchParams])).forEach(([key, value]) => {
    if (key === 'date' || key === 'business_date') {
      filterQuery[key] = value;
    } else {
      filterQuery[key] = value.split(',');
    }
  });

  try {
    const response: any = await fetch5(` ${process.env.REACT_APP_BASEURL}/${exportType}/${moduleName}`, filterQuery);
    const fileUrl = response?.data?.url;
    let Link = document.createElement('a');
    Link.style.display = 'none';
    Link.href = fileUrl;
    Link.download = moduleName;
    document.body.appendChild(Link);
    Link.click();
    document.body.removeChild(Link);
    notify('Export Successfully', 'success');
  } catch (error: any) {
    notify(error?.response?.data?.message);
    notify('Something error');
  }
};

export const importHandleSubmit = async (e: React.FormEvent<HTMLFormElement>, moduleName: string, file: any) => {
  e.preventDefault();

  if (file) {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await fetch5(`${process.env.REACT_APP_BASEURL}/import/${moduleName}`, formData);

      if (response.code == 200) {
        notify(response.message, 'success');
      }
    } catch (error: any) {
      notify(error?.data?.message);
    }
  }
};
export const importData = async (e: React.FormEvent<HTMLFormElement>, id: any, file: any, moduleName: any) => {
  e.preventDefault();

  if (file) {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await fetch5(
        `${process.env.REACT_APP_BASEURL}/inventory/${moduleName}/import_items/${id}`,
        formData,
      );

      if (response.code == 200) {
        notify(response.message, 'success');
      }
    } catch (error: any) {
      console.log(error, 'eeeeeeeeee');

      notify(error?.data?.message);
    }
  }
};
export const importIngredients = async (e: React.FormEvent<HTMLFormElement>, file: any, moduleName: any) => {
  e.preventDefault();

  if (file) {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await fetch5(`${process.env.REACT_APP_BASEURL}/import/${moduleName}`, formData);

      if (response.code == 200) {
        notify(response.message, 'success');
      }
    } catch (error: any) {
      notify(error?.response?.data?.message);
    }
  }
};

export const TranslateColumns = (col: any, transaltor: any) => {
  return col.map((item: any) => {
    return {
      ...item,
      showName: transaltor(`GLOBAL.${item.showName.toUpperCase().replaceAll(' ', '_')}`),
      name:
        typeof item.name === 'string'
          ? transaltor(`GLOBAL.${item.name.toUpperCase().replaceAll(' ', '_')}`)
          : item.name,
    };
  });
};

export const createFilterParam = (key: string, value: string | string[]) => {
  if (Array.isArray(value) && value.length > 0 && value.toString().trim()) {
    return { [key]: value.toString() };
  } else if (typeof value === 'string' && value.trim()) {
    return { [key]: value.trim() };
  } else {
    return {};
  }
};

export const RoundNum = (value: any, decimalPlaces: number = 5) => {
  const num = +value;
  if (!num) return 0;
  return Number.isInteger(num) ? num : num?.toFixed(decimalPlaces);
};
export function setCookie(name: string, value: string, days: number, path = '/', domain = '') {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  const cookie = `${name}=${value}; ${expires}; path=${path}; domain=${domain}; secure`;
  document.cookie = cookie;
}

// Usage
// setCookie('your_token_cookie_name', 'your_token_value', 1); // Expires in 1 day

export function getCookie(name: string) {
  let cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookiePair = cookies[i].split('=');
    let cookieName = cookiePair[0].trim();
    if (cookieName === name) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

// Usage
// const token = getCookie('your_token_cookie_name');

export function removeCookie(name: string): void {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

export const convertSearchQueryToString = (values: { [key: string]: string | string[] | null }) => {
  return Object.keys(values).reduce((acc: { [key: string]: string }, key) => {
    if (values[key]?.length && Array.isArray(values[key])) {
      acc[key] = (values[key] as string[]).join(',');
    } else if (values[key]) {
      acc[key] = values[key] as string;
    }
    return acc;
  }, {});
};

// export const convertSearchToArray = (values: { [key: string]: string | string[] | null }) => {
//   return Object.keys(values).reduce((acc: { [key: string]: string[] }, key) => {
//     if (values[key]?.length && Array.isArray(values[key])) {
//       acc[key] = values[key] as string[];
//     } else if (values[key]) {
//       acc[key] = [values[key] as string];
//     }
//     return acc;
//   }, {});
// };

export const getProgressPercentage = (steps: { [key: string]: any }[]) => {
  const totalSteps = steps.length;
  const completedSteps = steps.filter(step => step.completed);
  return Math.round((completedSteps.length / totalSteps) * 100);
};
export const formatDateMMDDHHmmA = (dateStr: string) => {
  return moment(dateStr).format('MMM DD HH:mm A');
};
export const getTimeInAmPm = (date: string) => {
  return moment(date).format('h:mm A');
};

export const getCurrentPosition = async () => {
  const response = await fetch6(`https://ipapi.co/json/`);
  return response;
};
export function clearFalsyKeysFromObject<T>(obj: Record<string, T>): Record<string, T> {
  let filteredObj: Record<string, T> = {};

  for (let key in obj) {
    if (obj[key] && obj[key] !== 'false') {
      filteredObj[key] = obj[key];
    }
  }

  return filteredObj;
}
export let resetTabsFilter = (tabs: any[], filterObj: { [key: string]: string | string[] }) => {
  let mergedObject: { [key: string]: string } = {};

  let filteredTabsArray = tabs.filter((tab: any) => tab.key).map((tab: any) => ({ [tab.key]: 'false' }));

  filteredTabsArray.forEach((tab: { [key: string]: string }) => {
    for (let key in tab) {
      mergedObject[key] = tab[key];
    }
  });

  return {
    ...clearFalsyKeysFromObject({
      ...filterObj,
      ...mergedObject,
    }),
  };
};
export function splitIds(str: String) {
  return str?.includes(',') ? str?.split(',') : [str];
}
export function formatTimestampToDateAndTimeAmPm(timestamp: Date) {
  return moment(timestamp).format('MMM D, h:mma');
}
