export const tagoptions = [
  {
    value: '1',
    label: 'Orders',
  },
  {
    value: '2',
    label: 'Orders Items',
  },
  {
    value: '3',
    label: 'Orders Payment',
  },
  {
    value: '4',
    label: 'Orders 1',
  },
];
export const statusFliterTO = [
  {
    value: '1',
    label: 'Draft',
  },
  {
    value: '2',
    label: 'Pending',
  },
  {
    value: '3',
    label: 'Closed',
  },

  {
    value: '5',
    label: 'Declined',
  },
];

export const statusFliterFR = [
  {
    value: '1',
    label: 'Draft',
  },
  {
    value: '2',
    label: 'Pending',
  },
  {
    value: '3',
    label: 'Accepted',
  },
  {
    value: '4',
    label: 'Declined',
  },
  {
    value: '5',
    label: 'Closed',
  },
];
export const typeFilterT = [
  {
    value: null,
    label: 'All',
  },
  {
    value: '1',
    label: 'Sending',
  },
  {
    value: '2',
    label: 'Receiving',
  },
  {
    value: '3',
    label: 'Transfer Return',
  },
];
export const creatoerTO = [
  {
    value: '1',
    label: 'Owner',
  },
  {
    value: '2',
    label: 'Suppourt',
  },
];
export const loadingSelect = [
  {
    value: 'Loading...',
    label: 'Loading...',
  },
];

export const statusFliterT = [
  {
    value: '1',
    label: 'Draft',
  },
  {
    value: '3',
    label: 'Pending',
  },
  {
    value: '2',
    label: 'Closed',
  },
  {
    value: '4',
    label: 'Accepted',
  },
  {
    value: '5',
    label: 'Declined',
  },
];
export const statusFliter = [
  {
    value: '1',
    label: 'Draft',
  },
  {
    value: '2',
    label: 'Closed',
  },
];
// export const statusFliterTO = [
//   {
//     value: "1",
//     label: "Draft",
//   },
//   {
//     value: "2",
//     label: "Pending",
//   },
//   {
//     value: "3",
//     label: "Closed",
//   },
//   {
//     value: "4",
//     label: "Accepted",
//   },
//   {
//     value: "4",
//     label: "Declined",
//   },

export const customertagoptions = [
  {
    value: '1',
    label: 'Customer Tag',
  },
  {
    value: '2',
    label: 'Customer Name',
  },
  {
    value: '3',
    label: 'Customer Payment',
  },
  {
    value: '4',
    label: 'Customer 1',
  },
];

export const customertimezone = [
  {
    value: '1',
    label: 'Delivery zone',
  },
  {
    value: '2',
    label: 'Delivery',
  },
];

export const deleteOptions = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];
export const deleteOptions1 = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export const deleteOptions1Ar = [
  {
    value: true,
    label: 'نعم',
  },
  {
    value: false,
    label: 'لا',
  },
];

export const openValue: any = [
  {
    value: 'true',
    label: 'Yes',
  },
  {
    value: 'false',
    label: 'No',
  },
];
export const openAnyValue: any = [
  {
    value: null,
    label: 'Any',
  },
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export const trueFalseOptions: any = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export const transferOrderStateFilter = [
  {
    value: 'all',
    label: 'Any',
  },
  {
    value: '3',
    label: 'Draft',
  },
  {
    value: '0',
    label: 'Pending',
  },
  {
    value: '4',
    label: 'Closed',
  },
];

export const stockProductOptions = [
  {
    value: '',
    label: 'Any',
  },
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];

export const costingOptions = [
  {
    value: '',
    label: 'Any',
  },
  {
    value: 1,
    label: 'Fixed',
  },
  {
    value: 2,
    label: 'From Transactions',
  },
];

export const itemoption = [
  {
    value: '1',
    label: 'Food',
  },
  {
    value: '2',
    label: 'Plate',
  },
];

export const ingredientsoptions = [
  {
    value: '1',
    label: 'Item 1',
  },
  {
    value: '2',
    label: 'Item 2',
  },
];
export const ingredientsoptionsx = [
  {
    value: '1',
    label: 'Owner',
  },
  {
    value: '2',
    label: 'Suppourt',
  },
];

export const pricingMethod = [
  // {
  //   value: '',
  //   label: 'Any',
  // },
  {
    value: 'fixed',
    label: 'Fixed Price',
  },
  {
    value: 'open',
    label: 'Open price',
  },
];

export const costingMethod = [
  {
    value: 'Fixed Cost',
    label: 'Fixed Cost',
  },
  {
    value: 'From Ingredients',
    label: 'From Transactions',
  },
];
export const costingMethod1 = [
  {
    value: 0,
    label: 'Fixed Cost',
  },
  {
    value: 1,
    label: 'From Transactions',
  },
];
export const costingMethod12 = [
  {
    value: '0',
    label: 'Fixed Cost',
  },
  {
    value: '1',

    label: 'From Ingredients',
  },
];
export const costingMethod11 = [
  {
    value: 0,
    label: 'Fixed Cost',
  },
  {
    value: 1,
    label: 'From Transactions',
  },
];

export const sellingMethod = [
  {
    value: 'unit',
    label: 'Unit',
  },
  {
    value: 'weight',
    label: 'Weight',
  },
];

export const filtertagoptions = [
  {
    value: '1',
    label: 'فرع الاحساء (B01)',
  },
];

export const filterinventory = [
  {
    value: '1',
    label: 'فرع الاحساء (B01)',
  },
  {
    value: '2',
    label: ' Riyadh Warehouse W01 (W01)',
  },
];
export const costanalysisfilter = [
  {
    value: '1',
    label: 'فرع الاحساء (B01)',
    branch_id: '1',
    restaurant_id: '20',
  },
];
export const costcategories = [
  {
    value: '1',
    label: 'Nothing was found!',
  },
];

export const filteritemstag = [
  {
    value: '1',
    label: ' tomt (sk-0559)',
  },
  {
    value: '2',
    label: 'hjvhg cg (sk-0558)',
  },
];

export const lowlevelfilter = [
  {
    value: null,
    label: 'Any',
  },
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export const costingmethodfilter = [
  {
    value: null,
    label: 'Any',
  },
  {
    value: '0',
    label: 'FIXED_COST',
  },
  {
    value: '1',
    label: 'FROM_TRANSACTIONS',
  },
];

export const constLoyaltyMethod = [
  {
    value: '1',
    label: 'points',
  },
  {
    value: '2',
    label: 'punches',
  },
];
export const mainlocalize = [
  {
    value: 1,
    label: 'Main',
  },
  {
    value: 2,
    label: 'Localized',
  },
  {
    value: 3,
    label: 'Main & Localized',
  },
];
export const costanalysisitems = [
  {
    value: '1',
    label: 'Tomt (sk-0559)',
  },
  {
    value: '2',
    label: 'Hjvhg cg (sk-0558)',
  },
  {
    value: '3',
    label: 'ABC (sk-0557)',
  },
  {
    value: '4',
    label: 'Pizza New (sk-0556)',
  },
  {
    value: '5',
    label: 'Burger (sk-0555)',
  },
];
export const paymentmethod = [
  {
    value: '1',
    label: 'Cash',
  },
  {
    value: '2',
    label: 'Card',
  },
  {
    value: '3',
    label: 'Other',
  },
];
export const filterordertags = [
  {
    value: '1',
    label: 'Nothing was found!',
  },
];
export const choosefilter = [
  {
    value: '1',
    label: 'Choose',
  },
];

export const status = [
  {
    value: '1',
    label: 'Draft',
  },
  {
    value: '2',
    label: 'Pending',
  },
  {
    value: '3',
    label: 'Approved',
  },
  {
    value: '4',
    label: 'Declined',
  },
  {
    value: '7',
    label: 'Partially Received',
  },
  {
    value: '6',
    label: 'Closed',
  },
];
export const reportsfilterordertags = [
  {
    value: '1',
    label: 'ايس كريم',
  },
  {
    value: '1',
    label: 'باربكيو',
  },
  {
    value: '1',
    label: 'بوبس',
  },
  {
    value: '1',
    label: 'مشروبات',
  },
  {
    value: '1',
    label: 'بطاطس',
  },
];
export const kitchensortingmethod = [
  {
    value: 1,
    label: 'As added in the cashier',
  },
  {
    value: 'menu_categories_sorting',
    label: 'Based on menu categories sorting',
  },
];
export const reportsordertagssettings = [
  {
    value: 0,
    label: 'None',
  },
  {
    value: 1,
    label: 'Dine In',
  },
  {
    value: 2,
    label: 'Pick Up',
  },
  {
    value: 3,
    label: 'Delivery',
  },
  {
    value: 4,
    label: 'Drive Thru',
  },
  {
    value: 5,
    label: 'Delivery Partners',
  },
];
export const inactiveordertypes = [
  {
    value: '1',
    label: 'Pick Up',
  },
  {
    value: 'Delivery',
    label: 'Delivery',
  },
];
export const reportsordertags = [
  {
    value: '1',
    label: 'Any',
  },
  {
    value: '1',
    label: 'Nothing was found!',
  },
];
export const roundingnethod = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'up',
    label: 'Up',
  },
  {
    value: 'down',
    label: 'Down',
  },
  {
    value: 'average',
    label: 'Average',
  },
];

export const warehousefilter = [
  {
    value: '1',
    label: 'Void and Returns',
  },
];

export const transactiontype = [
  // {
  //   value: "1",
  //   label: "Select All",
  // },
  {
    value: '1',
    label: 'Purchasing',
  },
  {
    value: '2',
    label: 'Transfer Sending',
  },
  {
    value: '3',
    label: 'Quantity Adjustment',
  },
  {
    value: '4',
    label: 'Return To Supplier',
  },
  {
    value: '5',
    label: 'Production',
  },
  {
    value: '6',
    label: 'Transfer Receiving',
  },
  {
    value: '7',
    label: 'Consumption From Production',
  },
  {
    value: '8',
    label: 'Consumption From Order',
  },
  {
    value: '9',
    label: 'Return From Order',
  },
  {
    value: '10',
    label: 'Waste From Order',
  },
  {
    value: '11',
    label: 'Transfer Return',
  },
  {
    value: '12',
    label: 'Waste From Production',
  },
  {
    value: '0',
    label: 'Inventory Count',
  },
];
export const daysFilter = [
  {
    value: 'Sun',
    label: 'Sun',
  },
  {
    value: 'Mon',
    label: 'Mon',
  },
  {
    value: 'Tue',
    label: 'Tue',
  },
  {
    value: 'Wed',
    label: 'Wed',
  },
  {
    value: 'Thu',
    label: 'Thu',
  },
  {
    value: 'Fri',
    label: 'Fri',
  },
  {
    value: 'Sat',
    label: 'Sat',
  },
];
export const daysFilter1 = [
  {
    value: 'is_sun',
    label: 'Sun',
  },
  {
    value: 'is_mon',
    label: 'Mon',
  },
  {
    value: 'is_tue',
    label: 'Tue',
  },
  {
    value: 'is_wed',
    label: 'Wed',
  },
  {
    value: 'is_thu',
    label: 'Thu',
  },
  {
    value: 'is_fri',
    label: 'Fri',
  },
  {
    value: 'is_sat',
    label: 'Sat',
  },
];
export const ordertypefilter = [
  {
    value: '1',
    label: 'Dine In',
  },
  {
    value: '2',
    label: 'Pick Up',
  },
  {
    value: '3',
    label: 'Delivery',
  },
  {
    value: '4',
    label: 'Drive Thru',
  },
  {
    value: '5',
    label: 'Delivery Partners',
  },
];

export const percentagefilter = [
  {
    value: '1',
    label: 'Percentage',
  },
];
export const paymentmethodfilter = [
  {
    value: 'Cash On Delivery',
    label: 'Cash On Delivery',
  },
  {
    value: 'Card On Delivery',
    label: 'Card On Delivery',
  },
];
export const menugroupfilter = [
  {
    value: 1,
    label: 'None',
  },
  {
    value: 2,
    label: 'Favorite',
  },
];
export const LWFfilter = [
  {
    value: '1',
    label: 'LWF chicken burger delivery',
  },
];

export const customeraddfilter = [
  {
    value: '1',
    label: 'yy',
  },
  {
    value: '2',
    label: 'Sd',
  },
];

export const timefilter = [
  {
    value: '00:00',
    label: '00:00',
  },
  {
    value: '01:00',
    label: '01:00',
  },
  {
    value: '02:00',
    label: '02:00',
  },
  {
    value: '03:00',
    label: '03:00',
  },
  {
    value: '04:00',
    label: '04:00',
  },
  {
    value: '05:00',
    label: '05:00',
  },
  {
    value: '06:00',
    label: '06:00',
  },
  {
    value: '07:00',
    label: '07:00',
  },
  {
    value: '08:00',
    label: '08:00',
  },
  {
    value: '09:00',
    label: '09:00',
  },
  {
    value: '10:00',
    label: '10:00',
  },
  {
    value: '11:00',
    label: '11:00',
  },
  {
    value: '12:00',
    label: '12:00',
  },
  {
    value: '13:00',
    label: '13:00',
  },
  {
    value: '14:00',
    label: '14:00',
  },
  {
    value: '15:00',
    label: '15:00',
  },
  {
    value: '16:00',
    label: '16:00',
  },
  {
    value: '17:00',
    label: '17:00',
  },
  {
    value: '18:00',
    label: '18:00',
  },
  {
    value: '19:00',
    label: '19:00',
  },
  {
    value: '20:00',
    label: '20:00',
  },
  {
    value: '21:00',
    label: '21:00',
  },
  {
    value: '22:00',
    label: '22:00',
  },
  {
    value: '23:00',
    label: '23:00',
  },
];
export const timefilter11 = [
  {
    value: '00:00',
    label: '00:00',
  },
  {
    value: '01:00',
    label: '01:00',
  },
  {
    value: '02:00',
    label: '02:00',
  },
  {
    value: '03:00',
    label: '03:00',
  },
  {
    value: '04:00',
    label: '04:00',
  },
  {
    value: '05:00',
    label: '05:00',
  },
  {
    value: '06:00',
    label: '06:00',
  },
  {
    value: '07:00',
    label: '07:00',
  },
  {
    value: '08:00',
    label: '08:00',
  },
  {
    value: '09:00',
    label: '09:00',
  },
  {
    value: '10:00',
    label: '10:00',
  },
  {
    value: '11:00',
    label: '11:00',
  },
  {
    value: '12:00',
    label: '12:00',
  },
  {
    value: '13:00',
    label: '13:00',
  },
  {
    value: '14:00',
    label: '14:00',
  },
  {
    value: '15:00',
    label: '15:00',
  },
  {
    value: '16:00',
    label: '16:00',
  },
  {
    value: '17:00',
    label: '17:00',
  },
  {
    value: '18:00',
    label: '18:00',
  },
  {
    value: '19:00',
    label: '19:00',
  },
  {
    value: '20:00',
    label: '20:00',
  },
  {
    value: '21:00',
    label: '21:00',
  },
  {
    value: '22:00',
    label: '22:00',
  },
  {
    value: '23:00',
    label: '23:00',
  },
];
export const timefilter1 = [
  {
    value: 0,
    label: '00:00',
  },
  {
    value: 1,
    label: '01:00',
  },
  {
    value: 2,
    label: '02:00',
  },
  {
    value: 3,
    label: '03:00',
  },
  {
    value: 4,
    label: '04:00',
  },
  {
    value: 5,
    label: '05:00',
  },
  {
    value: 6,
    label: '06:00',
  },
  {
    value: 7,
    label: '07:00',
  },
  {
    value: 8,
    label: '08:00',
  },
  {
    value: 9,
    label: '09:00',
  },
  {
    value: 10,
    label: '10:00',
  },
  {
    value: 11,
    label: '11:00',
  },
  {
    value: 12,
    label: '12:00',
  },
  {
    value: 13,
    label: '13:00',
  },
  {
    value: 14,
    label: '14:00',
  },
  {
    value: 15,
    label: '15:00',
  },
  {
    value: 16,
    label: '16:00',
  },
  {
    value: 17,
    label: '17:00',
  },
  {
    value: 18,
    label: '18:00',
  },
  {
    value: 19,
    label: '19:00',
  },
  {
    value: 20,
    label: '20:00',
  },
  {
    value: 21,
    label: '21:00',
  },
  {
    value: 22,
    label: '22:00',
  },
  {
    value: 23,
    label: '23:00',
  },
];

export const timeFilter = [
  {
    value: '1',
    label: '09:00 -  23:00',
  },
  {
    value: '2',
    label: '19:00 - 08:00',
  },
  {
    value: '1',
    label: '10:00 - 13:00',
  },
  {
    value: '1',
    label: '01:00 - 21:00',
  },
  {
    value: '1',
    label: '07:00- 20:00',
  },
];

export const SelectallFilter = [
  {
    value: '1',
    label: 'Select All ',
  },
  {
    value: '2',
    label: 'ايس كريم ',
  },
  {
    value: '3',
    label: 'ايس كريم ',
  },
];

export const deviceType = [
  {
    value: '',
    label: 'Any',
  },
  {
    value: 1,
    label: 'Cashier',
  },
  {
    value: 2,
    label: 'KDS',
  },
  {
    value: 4,
    label: 'Notifier',
  },
  {
    value: 5,
    label: 'Display',
  },
  {
    value: 6,
    label: 'Sub Cashier',
  },
  {
    value: 7,
    label: 'Dashboard',
  },
];

export const discountType = [
  {
    value: "false",
    label: 'Fixed',
  },
  {
    value: "true",
    label: 'Percentage',
  },
];

export const qualificationData = [
  {
    value: 'Order',
    label: 'Order',
  },
  {
    value: 'Product',
    label: 'Product',
  },
  {
    value: 'Order&Product',
    label: 'Order&Product',
  },
];

export const orderType = [
  {
    value: 1,
    label: 'Dine In',
  },
  {
    value: 2,
    label: 'Pick Up',
  },
  {
    value: 3,
    label: 'Delivery',
  },
  {
    value: 4,
    label: 'Drive Thru',
  },
  {
    value: 5,
    label: 'Delivery Partners',
  },
];
export const orderTypeTaxes = [
  {
    value: '1',
    label: 'Dine In',
  },
  {
    value: '2',
    label: 'Pick Up',
  },
  {
    value: '3',
    label: 'Delivery',
  },
  {
    value: '4',
    label: 'Drive Thru',
  },
  {
    value: '5',
    label: 'Delivery Partners',
  },
];

export const orderTypeOrdersPage = [
  {
    value: 2,
    label: 'Pick Up',
  },
  {
    value: 3,
    label: 'Delivery',
  },
  {
    value: 4,
    label: 'Drive Thru',
  },
  {
    value: 5,
    label: 'Delivery Partners',
  },
];
export const discountTypeItems = [
  {
    value: 1,
    label: 'Product',
  },
  {
    value: 2,
    label: 'Order',
  },
];

export const timeEventType = [
  {
    value: 'Set Fixed Price',
    label: 'Set Fixed Price',
  },
  {
    value: 'Reduce Price by Fixed Amount',
    label: 'Reduce Price by Fixed Amount',
  },
  {
    value: 'Reduce Price by Percentage Amount',
    label: 'Reduce Price by Percentage Amount',
  },
  {
    value: 'Increase Price by Fixed Amount',
    label: 'Increase Price by Fixed Amount',
  },
  {
    value: 'Increase Price by Percentage Amount',
    label: 'Increase Price by Percentage Amount',
  },
  {
    value: 'Activate Products',
    label: 'Activate Products',
  },
  {
    value: 'Deactivate Products',
    label: 'Deactivate Products',
  },
];
export const timeEventType1 = [
  {
    value: "1",
    label: 'Set Fixed Price',
  },
  {
    value: "2",
    label: 'Reduce Price by Fixed Amount',
  },
  {
    value: "3",
    label: 'Reduce Price by Percentage Amount',
  },
  {
    value: "4",
    label: 'Increase Price by Fixed Amount',
  },
  {
    value: "5",
    label: 'Increase Price by Percentage Amount',
  },
  {
    value: "6",
    label: 'Activate Products',
  },
  {
    value: "7",
    label: 'Deactivate Products',
  },
];

export const addsizevalueoption = [
  {
    value: '1',
    label: '',
  },
];

export const addsizevaluethree = [
  {
    value: '1',
    label: 'Group 1',
  },
  {
    value: '2',
    label: 'Group 2',
  },
  {
    value: '3',
    label: 'Group 3',
  },
  {
    value: '4',
    label: 'Group 4',
  },
  {
    value: '5',
    label: 'Group 5',
  },
];

export const addsizevaluetwo = [
  {
    value: '1',
    label: 'Size 1',
  },
  {
    value: '2',
    label: 'Size 2',
  },
  {
    value: '3',
    label: 'Size 3',
  },
  {
    value: '4',
    label: 'Size 4',
  },
];

export const addsizevalueone = [
  {
    value: '1',
    label: 'Prego (B01)',
  },
];

export const amountFilter = [
  {
    value: '100',
    label: '100',
  },
];

export const taxFilter = [
  {
    value: '100',
    label: '100',
  },
];

export const chargesType = [
  {
    value: '1',
    label: 'Amount',
  },
  {
    value: '2',
    label: 'Percentage',
  },
];

export const discountTypeData = [
  {
    value: '0',
    label: 'Value',
  },
  {
    value: '1',
    label: 'Percentage',
  },
];
export const basicDiscountTypeData = [
  {
    value: '2',
    label: 'Value',
  },
  {
    value: '1',
    label: 'Percentage',
  },
];
export const orderDiscountTypeData = [
  {
    value: '4',
    label: 'Value',
  },
  {
    value: '3',
    label: 'Percentage',
  },
];
export const discountcallfilter = [
  {
    value: '1',
    label: '10% Discount New Year',
  },
];

export const GenderOption = [
  {
    value: 'Any',
    label: 'Any',
  },
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Female',
    label: 'Female',
  },
];

export const CostingMethod = [
  {
    value: 1,
    label: 'Fixed',
  },
  {
    value: 2,
    label: 'From Transition',
  },
];
export const YesNoOptions = [

  {
    value: 1,
    label: 'Yes',
  },
  {
    value: 0,
    label: 'No',
  },
];
export const YesNoOptions1 = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];
export const YesNoOptions11 = [
  {
    value: false,
    label: 'Any',
  },
];
export const YesNoOptions111 = [
  {
    value: false,
    label: '',
  },
];
export const countryCodes = [
  {
    value: '93',
    label: 'Afghanistan (+93)',
  },
  {
    value: '355',
    label: 'Albania (+355)',
  },
  {
    value: '213',
    label: 'Algeria (+213)',
  },
  {
    value: '1-684',
    label: 'American Samoa (+684)',
  },
  {
    value: '376',
    label: 'Andorra (+376)',
  },
  {
    value: '244',
    label: 'Angola (+244)',
  },
  {
    value: '1-264',
    label: 'Anguilla (+264)',
  },
  {
    value: '672',
    label: 'Antarctica (+672)',
  },
  {
    value: '1-268',
    label: 'Antigua and Barbuda (+268)',
  },
  {
    value: '54',
    label: 'Argentina (+54)',
  },
  {
    value: '374',
    label: 'Armenia (+374)',
  },
  {
    value: '297',
    label: 'Aruba (+297)',
  },
  {
    value: '61',
    label: 'Australia (+61)',
  },
  {
    value: '43',
    label: 'Austria (+43)',
  },
  {
    value: '994',
    label: 'Azerbaijan (+994)',
  },
  {
    value: '1-242',
    label: 'Bahamas (+242)',
  },
  {
    value: '973',
    label: 'Bahrain (+973)',
  },
  {
    value: '880',
    label: 'Bangladesh (+880)',
  },
  {
    value: '1-246',
    label: 'Barbados (+246)',
  },
  {
    value: '375',
    label: 'Belarus (+375)',
  },
  {
    value: '32',
    label: 'Belgium (+32)',
  },
  {
    value: '501',
    label: 'Belize (+501)',
  },
  {
    value: '229',
    label: 'Benin (+229)',
  },
  {
    value: '1-441',
    label: 'Bermuda (+441)',
  },
  {
    value: '975',
    label: 'Bhutan (+975)',
  },
  {
    value: '591',
    label: 'Bolivia (+591)',
  },
  {
    value: '387',
    label: 'Bosnia and Herzegovina (+387)',
  },
  {
    value: '267',
    label: 'Botswana (+267)',
  },
  {
    value: '55',
    label: 'Brazil (+55)',
  },
  {
    value: '246',
    label: 'British Indian Ocean Territory (+246)',
  },
  {
    value: '1-284',
    label: 'British Virgin Islands (+284)',
  },
  {
    value: '673',
    label: 'Brunei (+673)',
  },
  {
    value: '359',
    label: 'Bulgaria (+359)',
  },
  {
    value: '226',
    label: 'Burkina Faso (+226)',
  },
  {
    value: '257',
    label: 'Burundi (+257)',
  },
  {
    value: '855',
    label: 'Cambodia (+855)',
  },
  {
    value: '237',
    label: 'Cameroon (+237)',
  },
  {
    value: '1',
    label: 'Canad (+1)',
  },
  {
    value: '238',
    label: 'Cape Verde (+238)',
  },
  {
    value: '1-345',
    label: 'Cayman Islands (+345)',
  },
  {
    value: '236',
    label: 'Central African Republic (+236)',
  },
  {
    value: '235',
    label: 'Chad (+235)',
  },
  {
    value: '56',
    label: 'Chile (+56)',
  },
  {
    value: '86',
    label: 'China (+86)',
  },
  {
    value: '61',
    label: 'Christmas Island (+61)',
  },
  {
    value: '61',
    label: 'Cocos Islands (+61)',
  },
  {
    value: '57',
    label: 'Colombia (+57)',
  },
  {
    value: '269',
    label: 'Comoros (+269)',
  },
  {
    value: '682',
    label: 'Cook Islands (+682)',
  },
  {
    value: '506',
    label: 'Costa Rica (+506)',
  },
  {
    value: '385',
    label: 'Croatia (+385)',
  },
  {
    value: '53',
    label: 'Cuba (+53)',
  },
  {
    value: '599',
    label: 'Curacao (+599)',
  },
  {
    value: '357',
    label: 'Cyprus (+357)',
  },
  {
    value: '420',
    label: 'Czech Republic (+420)',
  },
  {
    value: '243',
    label: 'Democratic Republic of the Congo (+243)',
  },
  {
    value: '45',
    label: 'Denmark (+45)',
  },
  {
    value: '253',
    label: 'Djibouti (+253)',
  },
  {
    value: '1-767',
    label: 'Dominica (+767)',
  },
  {
    value: '1-809, 1-829, 1-849',
    label: 'Dominican Republic (+1-809, 1-829, 1-849)',
  },
  {
    value: '670',
    label: 'East Timor (+670)',
  },
  {
    value: '593',
    label: 'Ecuador (+593)',
  },
  {
    value: '20',
    label: 'Egypt (+20)',
  },
  {
    value: '503',
    label: 'El Salvador (+503)',
  },
  {
    value: '240',
    label: 'Equatorial Guinea (+240)',
  },
  {
    value: '291',
    label: 'Eritrea (+291)',
  },
  {
    value: '372',
    label: 'Estonia (+372)',
  },
  {
    value: '251',
    label: 'Ethiopia (+251)',
  },
  {
    value: '500',
    label: 'Falkland Islands (+500)',
  },
  {
    value: '298',
    label: 'Faroe Islands (+298)',
  },
  {
    value: '679',
    label: 'Fiji (+679)',
  },
  {
    value: '358',
    label: 'Finland (+358)',
  },
  {
    value: '33',
    label: 'France (+33)',
  },
  {
    value: '689',
    label: 'French Polynesia (+689)',
  },
  {
    value: '241',
    label: 'Gabon (+241)',
  },
  {
    value: '220',
    label: 'Gambia (+220)',
  },
  {
    value: '995',
    label: 'Georgia (+995)',
  },
  {
    value: '49',
    label: 'Germany (+49)',
  },
  {
    value: '233',
    label: 'Ghana (+233)',
  },
  {
    value: '350',
    label: 'Gibraltar (+350)',
  },
  {
    value: '30',
    label: 'Greece (+30)',
  },
  {
    value: '299',
    label: 'Greenland (+299)',
  },
  {
    value: '1-473',
    label: 'Grenada (+473)',
  },
  {
    value: '1-671',
    label: 'Guam (+671)',
  },
  {
    value: '502',
    label: 'Guatemala (+502)',
  },
  {
    value: '44-1481',
    label: 'Guernsey - 44(+481)',
  },
  {
    value: '224',
    label: 'Guinea (+224)',
  },
  {
    value: '245',
    label: 'Guinea-Bissau (+245)',
  },
  {
    value: '592',
    label: 'Guyana (+592)',
  },
  {
    value: '509',
    label: 'Haiti (+509)',
  },
  {
    value: '504',
    label: 'Honduras (+504)',
  },
  {
    value: '852',
    label: 'Hong Kong (+852)',
  },
  {
    value: '36',
    label: 'Hungary (+36)',
  },
  {
    value: '354',
    label: 'Iceland (+354)',
  },
  {
    value: '91',
    label: 'India (+91)',
  },
  {
    value: '62',
    label: 'Indonesia (+62)',
  },
  {
    value: '98',
    label: 'Iran (+98)',
  },
  {
    value: '964',
    label: 'Kenya (+964)',
  },
  {
    value: '353',
    label: 'Ireland (+353)',
  },
  {
    value: '44-1624',
    label: 'Isle of Man (+44-624)',
  },
  {
    value: '972',
    label: 'Israel (+972)',
  },
  {
    value: '39',
    label: 'Italy (+39)',
  },
  {
    value: '225',
    label: 'Ivory Coast (+225)',
  },
  {
    value: '1-876',
    label: 'Jamaica (+876)',
  },
  {
    value: '81',
    label: 'Japan (+81)',
  },
  {
    value: '44-1534',
    label: 'Jersey (+44-534)',
  },
  {
    value: '962',
    label: 'Jordan (+962)',
  },
  {
    value: '7',
    label: 'Kazakhsta (+7)',
  },
  {
    value: '254',
    label: 'Kenya (+254)',
  },
  {
    value: '686',
    label: 'Kiribati (+686)',
  },
  {
    value: '383',
    label: 'Kosovo (+383)',
  },
  {
    value: '965',
    label: 'Kuwait (+965)',
  },
  {
    value: '996',
    label: 'Kyrgyzstan (+996)',
  },
  {
    value: '856',
    label: 'Laos (+856)',
  },
  {
    value: '371',
    label: 'Latvia (+371)',
  },
  {
    value: '961',
    label: 'Lebanon (+961)',
  },
  {
    value: '266',
    label: 'Lesotho (+266)',
  },
  {
    value: '231',
    label: 'Liberia (+231)',
  },
  {
    value: '218',
    label: 'Libya (+218)',
  },
  {
    value: '423',
    label: 'Liechtenstein (+423)',
  },
  {
    value: '370',
    label: 'Lithuania (+370)',
  },
  {
    value: '352',
    label: 'Luxembourg (+352)',
  },
  {
    value: '853',
    label: 'Macau (+853)',
  },
  {
    value: '389',
    label: 'Macedonia (+389)',
  },
  {
    value: '261',
    label: 'Madagascar (+261)',
  },
  {
    value: '265',
    label: 'Malawi (+265)',
  },
  {
    value: '60',
    label: 'Malaysia (+60)',
  },
  {
    value: '960',
    label: 'Maldives (+960)',
  },
  {
    value: '223',
    label: 'Mali (+223)',
  },
  {
    value: '356',
    label: 'Malta (+356)',
  },
  {
    value: '692',
    label: 'Marshall Islands (+692)',
  },
  {
    value: '222',
    label: 'Mauritania (+222)',
  },
  {
    value: '230',
    label: 'Mauritius (+230)',
  },
  {
    value: '262',
    label: 'Mayotte (+262)',
  },
  {
    value: '52',
    label: 'Mexico (+52)',
  },
  {
    value: '691',
    label: 'Micronesia (+691)',
  },
  {
    value: '373',
    label: 'Moldova (+373)',
  },
  {
    value: '377',
    label: 'Monaco (+377)',
  },
  {
    value: '976',
    label: 'Mongolia (+976)',
  },
  {
    value: '382',
    label: 'Montenegro (+382)',
  },
  {
    value: '1-664',
    label: 'Montserrat (+664)',
  },
  {
    value: '212',
    label: 'Morocco (+212)',
  },
  {
    value: '258',
    label: 'Mozambique (+258)',
  },
  {
    value: '95',
    label: 'Myanmar (+95)',
  },
  {
    value: '264',
    label: 'Namibia (+264)',
  },
  {
    value: '674',
    label: 'Nauru (+674)',
  },
  {
    value: '977',
    label: 'Nepal (+977)',
  },
  {
    value: '31',
    label: 'Netherlands (+31)',
  },
  {
    value: '599',
    label: 'Netherlands Antilles (+599)',
  },
  {
    value: '687',
    label: 'New Caledonia (+687)',
  },
  {
    value: '64',
    label: 'New Zealand (+64)',
  },
  {
    value: '505',
    label: 'Nicaragua (+505)',
  },
  {
    value: '227',
    label: 'Niger (+227)',
  },
  {
    value: '234',
    label: 'Nigeria (+234)',
  },
  {
    value: '683',
    label: 'Niue (+683)',
  },
  {
    value: '850',
    label: 'North Korea (+850)',
  },
  {
    value: '1-670',
    label: 'Northern Mariana Islands (+670)',
  },
  {
    value: '47',
    label: 'Norway (+47)',
  },
  {
    value: '968',
    label: 'Oman (+968)',
  },
  {
    value: '92',
    label: 'Pakistan (+92)',
  },
  {
    value: '680',
    label: 'Palau (+680)',
  },
  {
    value: '970',
    label: 'Palestine (+970)',
  },
  {
    value: '507',
    label: 'Panama (+507)',
  },
  {
    value: '675',
    label: 'Papua New Guinea (+675)',
  },
  {
    value: '595',
    label: 'Paraguay (+595)',
  },
  {
    value: '51',
    label: 'Peru (+51)',
  },
  {
    value: '63',
    label: 'Philippines (+63)',
  },
  {
    value: '64',
    label: 'Pitcairn (+64)',
  },
  {
    value: '48',
    label: 'Poland (+48)',
  },
  {
    value: '351',
    label: 'Portugal (+351)',
  },
  {
    value: '1-787, 1-939',
    label: 'Puerto Rico (+ 1-787, 939)',
  },
  {
    value: '974',
    label: 'Qatar (+974)',
  },
  {
    value: '242',
    label: 'Republic of the Congo (+242)',
  },
  {
    value: '262',
    label: 'Reunion (+262)',
  },
  {
    value: '40',
    label: 'Romania (+40)',
  },
  {
    value: '7',
    label: 'Russi (+7)',
  },
  {
    value: '250',
    label: 'Rwanda (+250)',
  },
  {
    value: '590',
    label: 'Saint Barthelemy (+590)',
  },
  {
    value: '290',
    label: 'Saint Helena (+290)',
  },
  {
    value: '1-869',
    label: 'Saint Kitts and Nevis (+869)',
  },
  {
    value: '1-758',
    label: 'Saint Lucia (+758)',
  },
  {
    value: '590',
    label: 'Saint Martin (+590)',
  },
  {
    value: '508',
    label: 'Saint Pierre and Miquelon (+508)',
  },
  {
    value: '1-784',
    label: 'Saint Vincent and the Grenadines (+784)',
  },
  {
    value: '685',
    label: 'Samoa (+685)',
  },
  {
    value: '378',
    label: 'San Marino (+378)',
  },
  {
    value: '239',
    label: 'Sao Tome and Principe (+239)',
  },
  {
    value: '966',
    label: 'Saudi Arabia (+966)',
  },
  {
    value: '221',
    label: 'Senegal (+221)',
  },
  {
    value: '381',
    label: 'Serbia (+381)',
  },
  {
    value: '248',
    label: 'Seychelles (+248)',
  },
  {
    value: '232',
    label: 'Sierra Leone (+232)',
  },
  {
    value: '65',
    label: 'Singapore (+65)',
  },
  {
    value: '1-721',
    label: 'Sint Maarten (+721)',
  },
  {
    value: '421',
    label: 'Slovakia (+421)',
  },
  {
    value: '386',
    label: 'Slovenia (+386)',
  },
  {
    value: '677',
    label: 'Solomon Islands (+677)',
  },
  {
    value: '252',
    label: 'Somalia (+252)',
  },
  {
    value: '27',
    label: 'South Africa (+27)',
  },
  {
    value: '82',
    label: 'South Korea (+82)',
  },
  {
    value: '211',
    label: 'South Sudan (+211)',
  },
  {
    value: '34',
    label: 'Spain (+34)',
  },
  {
    value: '94',
    label: 'Sri Lanka (+94)',
  },
  {
    value: '249',
    label: 'Sudan (+249)',
  },
  {
    value: '597',
    label: 'Suriname (+597)',
  },
  {
    value: '47',
    label: 'Svalbard and Jan Mayen (+47)',
  },
  {
    value: '268',
    label: 'Swaziland (+268)',
  },
  {
    value: '46',
    label: 'Sweden(+ 46)',
  },
  {
    value: '41',
    label: 'Switzerland(+ 41)',
  },
  {
    value: '963',
    label: 'Syria (+963)',
  },
  {
    value: '886',
    label: 'Taiwan (+886)',
  },
  {
    value: '992',
    label: 'Tajikistan (+992)',
  },
  {
    value: '255',
    label: 'Tanzania (+255)',
  },
  {
    value: '66',
    label: 'Thailand(+ 66)',
  },
  {
    value: '228',
    label: 'Togo (+228)',
  },
  {
    value: '690',
    label: 'Tokelau (+690)',
  },
  {
    value: '676',
    label: 'Tonga (+676)',
  },
  {
    value: '1-868',
    label: 'Trinidad and Tobago (+868)',
  },
  {
    value: '216',
    label: 'Tunisia (+216)',
  },
  {
    value: '90',
    label: 'Turkey(+ 90)',
  },
  {
    value: '993',
    label: 'Turkmenistan (+993)',
  },
  {
    value: '1-649',
    label: 'Turks and Caicos Islands (+649)',
  },
  {
    value: '688',
    label: 'Tuvalu (+688)',
  },
  {
    value: '1-340',
    label: 'U.S. Virgin Islands (+340)',
  },
  {
    value: '256',
    label: 'Uganda (+256)',
  },
  {
    value: '380',
    label: 'Ukraine (+380)',
  },
  {
    value: '971',
    label: 'United Arab Emirates (+971)',
  },
  {
    value: '44',
    label: 'United Kingdom(+44)',
  },
  {
    value: '1',
    label: 'United State(+1)',
  },
  {
    value: '598',
    label: 'Uruguay (+598)',
  },
  {
    value: '998',
    label: 'Uzbekistan (+998)',
  },
  {
    value: '678',
    label: 'Vanuatu (+678)',
  },
  {
    value: '379',
    label: 'Vatican (+379)',
  },
  {
    value: '58',
    label: 'Venezuela(+ 58)',
  },
  {
    value: '84',
    label: 'Vietnam(+84)',
  },
  {
    value: '681',
    label: 'Wallis and Futuna (+681)',
  },
  {
    value: '212',
    label: 'Western Sahara (+212)',
  },
  {
    value: '967',
    label: 'Yemen (+967)',
  },
  {
    value: '260',
    label: 'Zambia (+260)',
  },
  {
    value: '263',
    label: 'Zimbabwe (+263)',
  },
];

export const OrderSourceOption = [
  {
    value: null,
    label: 'Any',
  },
  {
    value: '1',
    label: 'Cashier',
  },
  {
    value: '2',
    label: 'API',
  },
  {
    value: '3',
    label: 'Call Center',
  },
];
export const OrderTypeOption = [
  {
    value: null,
    label: 'Any',
  },
  {
    value: '1',
    label: 'Dine In',
  },
  {
    value: '2',
    label: 'Pick Up',
  },
  {
    value: '3',
    label: 'Delivery',
  },
  {
    value: '4',
    label: 'Drive Thru',
  },
  {
    value: '5',
    label: 'Delivery Partners',
  },
];
export const OrderStatusOption = [

  {
    value: '1',
    label: 'Pending',
  },
  {
    value: '2',
    label: 'Active',
  },
  {
    value: '3',
    label: 'Declined',
  },
  {
    value: '4',
    label: 'Done',
  },
  {
    value: '5',
    label: 'Returned',
  },
  {
    value: '6',
    label: 'Joined',
  },
  {
    value: '7',
    label: 'Void',
  },
  {
    value: '8',
    label: 'Draft',
  },
];
export const AppliesOnOption = [
  {
    value: 1,
    label: 'Dine In',
  },
  {
    value: 2,
    label: 'Pick Up',
  },
  {
    value: 3,
    label: 'Delivery',
  },
  {
    value: 4,
    label: 'Drive Thru',
  },
  {
    value: 5,
    label: 'Delivery Partners',
  },
];
export const languagetag = [
  {
    value: 'ar',
    label: 'Arabic',
  },
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'es',
    label: 'Espanol',
  },
  {
    value: 'fr',
    label: 'Francais',
  },
];
export const LoyaltyMethod = [
  {
    value: 'points',
    label: 'points',
  },
  {
    value: 'punches',
    label: 'punches',
  },
];
export const reward_type = [
  {
    label: 'Discount Percentage',
    value: 1,
  },
  {
    label: 'Discount In Amount',
    value: 2,
  },
];
export const copyDeviceSettingOptions = [
  {
    value: 'tags',
    label: 'Tags',
  },
  {
    value: 'discount',
    label: 'Discount',
  },
  {
    value: 'promotions',
    label: 'Promotions',
  },
  {
    value: 'timed_events',
    label: 'Timed Events',
  },
  {
    value: 'delevery_zones',
    label: 'Delivery Zones',
  },
  {
    value: 'users',
    label: 'Users',
  },
  {
    value: 'tax_group',
    label: 'Tax Group',
  },
  {
    value: 'opening_from',
    label: 'Opening From',
  },
  {
    value: 'opening_to',
    label: 'Opening To',
  },
  {
    value: 'inventory_end_of_day',
    label: 'Inventory End of Day',
  },
  {
    value: 'recive_call_center_api_order',
    label: 'Receive call center and API orders',
  },
];

export const SettingTimeZoneOption = [
  {
    value: '-11:00',
    label: 'Pacific/Midway (GMT-11:00)',
  },
  {
    value: '-10:00',
    label: 'America/Adak (GMT-10:00)',
  },
  {
    value: '-09:00',
    label: 'America/Anchorage (GMT-09:00)',
  },
  {
    value: '-08:00',
    label: 'America/Dawson_Creek (GMT-08:00)',
  },
  {
    value: '-07:00',
    label: 'America/Chihuahua (GMT-07:00)',
  },
  {
    value: '-06:00',
    label: 'America/Belize (GMT-06:00)',
  },
  {
    value: 'America/New York',
    label: 'America/New York (GMT-05:00)',
  },
  {
    value: '-04:30',
    label: 'America/Caracas (GMT-04:30)',
  },
  {
    value: '-04:00',
    label: 'America/Campo_Grande (GMT-04:00)',
  },
  {
    value: '-03:00',
    label: 'America/Argentina/Buenos_Aires (GMT-03:00)',
  },
  {
    value: '-03:30',
    label: 'America/St_Johns (GMT-03:30)',
  },
  {
    value: '-02:00',
    label: 'America/Noronha (GMT-02:00)',
  },
  {
    value: '-01:00',
    label: 'Atlantic/Cape_Verde (GMT-01:00)',
  },
  {
    value: 'Europe/London',
    label: 'Europe/London (GMT+00:00)',
  },
  {
    value: 'Africa/Albania',
    label: 'Africa/Albania (GMT+01:00)',
  },
  {
    value: 'Africa/Morocco',
    label: 'Africa/Morocco (GMT+01:00)',
  },
  {
    value: 'Africa/Nigeria',
    label: 'Africa/Nigeria (GMT+01:00)',
  },
  {
    value: 'Africa/Algiers',
    label: 'Africa/Algiers (GMT+01:00)',
  },
  {
    value: 'Europe/Brussels',
    label: 'Europe/Brussels (GMT+01:00)',
  },
  {
    value: 'Africa/Tunis',
    label: 'Africa/Tunis (GMT+01:00)',
  },
  {
    value: 'Africa/Cairo',
    label: 'Africa/Cairo (GMT+02:00)',
  },
  {
    value: 'Africa/Jordan',
    label: 'Africa/Jordan (GMT+02:00)',
  },
  {
    value: 'Africa/Lebanon',
    label: 'Africa/Lebanon (GMT+02:00)',
  },
  {
    value: 'Africa/South Africa',
    label: 'Africa/South Africa (GMT+02:00)',
  },
  {
    value: 'Africa/State of Libya',
    label: 'Africa/State of Libya (GMT+02:00)',
  },
  {
    value: 'Africa/Kuwait',
    label: 'Africa/Kuwait (GMT+02:00)',
  },
  {
    value: 'Africa/Khartoum',
    label: 'Africa/Khartoum (GMT+02:00)',
  },
  {
    value: 'Asia/Damascus',
    label: 'Asia/Damascus (GMT+02:00)',
  },
  {
    value: 'Asia/Riyadh',
    label: 'Asia/Riyadh (GMT+03:00)',
  },
  {
    value: 'Asia/Bahrain',
    label: 'Asia/Bahrain (GMT+03:00)',
  },
  {
    value: 'Europe/Istanbul',
    label: 'Europe/Istanbul (GMT+03:00)',
  },
  {
    value: 'Asia/Mogadishu',
    label: 'Asia/Mogadishu (GMT+03:00)',
  },
  {
    value: 'Asia/Kenya',
    label: 'Asia/Kenya (GMT+03:00)',
  },
  {
    value: 'Asia/Iran',
    label: 'Asia/Iran (GMT+03:00)',
  },
  {
    value: 'Asia/Iraq',
    label: 'Asia/Iraq (GMT+03:00)',
  },
  {
    value: 'Asia/Qatar',
    label: 'Asia/Qatar (GMT+03:00)',
  },
  {
    value: 'Asia/Aden',
    label: 'Asia/Aden (GMT+03:00)',
  },
  {
    value: '+03:30',
    label: 'Asia/Tehran (GMT+03:30)',
  },
  {
    value: 'Asia/Dubai',
    label: 'Asia/Dubai (GMT+04:00)',
  },
  {
    value: 'Asia/Oman',
    label: 'Asia/Oman (GMT+04:00)',
  },
  { value: 'Asia/Kabul', label: 'Asia/Kabul (GMT+04:30)' },
  { value: 'Asia/Pakistan', label: 'Asia/Pakistan (GMT+05:00)' },
  { value: 'Asia/India', label: 'Asia/India (GMT+05:30)' },
  { value: '+05:45', label: 'Asia/Katmandu (GMT+05:45)' },
  { value: 'Asia/Dhaka', label: 'Asia/Dhaka (GMT+06:00)' },
  { value: '+06:30', label: 'Asia/Rangoon (GMT+06:30)' },
  { value: '+07:00', label: 'Asia/Bangkok (GMT+07:00)' },
  { value: 'Asia/Indonesia', label: 'Asia/Indonesia (GMT+07:00)' },
  {
    value: 'Asia/Kuala Lumpur',
    label: 'Asia/Kuala Lumpur (GMT+08:00)',
  },
  {
    value: 'Asia/Manila',
    label: 'Asia/Manila (GMT+08:00)',
  },
  {
    value: '+08:45',
    label: 'Australia/Eucla (GMT+08:45)',
  },
  { value: '+09:00', label: 'Asia/Irkutsk (GMT+09:00)' },
  { value: 'Asia/Republic of Korea', label: 'Asia/Republic of Korea (GMT+09:00)' },
  {
    value: '+09:30',
    label: 'Australia/Adelaide (GMT+09:30)',
  },
  {
    value: '+10:00',
    label: 'Australia/Melbourne (GMT+10:00)',
  },
  {
    value: '+10:30',
    label: 'Australia/Lord_Howe (GMT+10:30)',
  },
  {
    value: '+11:00',
    label: 'Asia/Vladivostok (GMT+11:00)',
  },
  {
    value: '+11:30',
    label: 'Pacific/Norfolk (GMT+11:30)',
  },
  {
    value: '+12:45',
    label: 'Pacific/Chatham (GMT+12:45)',
  },
  {
    value: '+13:00',
    label: 'Pacific/Tongatapu (GMT+13:00)',
  },
  {
    value: '+14:00',
    label: 'Pacific/Kiritimat (GMT+14:00)',
  },
];

export const GroupByOptions = [
  { label: 'None', value: '' },
  { label: 'Branch', value: 'branch_id' },
  { label: 'Cashier', value: 'cashier_id' },
  { label: 'Customer', value: 'customer_id' },
  { label: 'Category', value: 'category_id' },
  { label: 'Product', value: 'product_id' },
  { label: 'Creator', value: 'creator_id' },
  { label: 'Order Source', value: 'source' },
  { label: 'Order Type', value: 'type' },
  { label: 'Coupon', value: 'coupon_id' },
  // { label: 'Charge', value: 'charge_id' },
  // { label: 'Combo', value: 'combo_id' },
  // { label: 'Delivery Zone', value: 'deliveryZon' },
  // { label: 'Discount', value: 'discount_id' },
  // { label: 'Driver', value: 'driver_id' },
  { label: 'Table', value: 'table_id' },
  { label: 'Section', value: 'section_id' },
  { label: 'Modifier', value: 'modifier_option_id' },
  { label: 'Branch Tag', value: 'branch_tag_id' },
  { label: 'Order Tag', value: 'order_tag_id' },
  { label: 'Product Tag', value: 'product_tag_id' },
  // { label: 'Promotion', value: 'promotion' },
  // { label: 'Time Event', value: 'timeEvent' },

  { label: 'Day', value: 'date' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
  { label: 'Quarter', value: 'quarter' },
  { label: 'Year', value: 'year' },
  { label: 'Hour of Day', value: 'hour' },
  { label: 'Day of Week', value: 'dayOfWeek' },
  { label: 'Day of Month', value: 'dayOfMonth' },
  { label: 'Day of Year', value: 'dayOfYear' },
  { label: 'Week of Year', value: 'weekOfYear' },
  { label: 'Month of Year', value: 'monthOfYear' },
  { label: 'Quarter of Year', value: 'quarterOfYear' },
];

export const businessLocation = [
  { id: 'Afghanistan', name_ar: 'أفغانستان', name_en: 'Afghanistan' },
  { id: 'Albania', name_ar: 'ألبانيا', name_en: 'Albania' },
  { id: 'Algeria', name_ar: 'الجزائر', name_en: 'Algeria' },
  { id: 'Bahrain', name_ar: 'البحرين', name_en: 'Bahrain' },
  { id: 'Bangladesh', name_ar: 'بنغلاديش', name_en: 'Bangladesh' },
  { id: 'Belgium', name_ar: 'بلجيكا', name_en: 'Belgium' },
  { id: 'Egypt', name_ar: 'مصر', name_en: 'Egypt' },
  { id: 'India', name_ar: 'الهند', name_en: 'India' },
  { id: 'Indonesia', name_ar: 'إندونيسيا', name_en: 'Indonesia' },
  { id: 'Iran', name_ar: 'إيران', name_en: 'Iran' },
  { id: 'Iraq', name_ar: 'العراق', name_en: 'Iraq' },
  { id: 'Iraq', name_ar: 'الأردن', name_en: 'Jordan' },
  { id: 'Kenya', name_ar: 'كينيا', name_en: 'Kenya' },
  { id: 'Kuwait', name_ar: 'الكويت', name_en: 'Kuwait' },
  { id: 'Lebanon', name_ar: 'لبنان', name_en: 'Lebanon' },
  { id: 'Libya', name_ar: 'ليبيا', name_en: 'Libya' },
  { id: 'Malaysia', name_ar: 'ماليزيا', name_en: 'Malaysia' },
  { id: 'Morocco', name_ar: 'المغرب', name_en: 'Morocco' },
  { id: 'Nigeria', name_ar: 'نيجيريا', name_en: 'Nigeria' },
  { id: 'Oman', name_ar: 'عُمان', name_en: 'Oman' },
  { id: 'Pakistan', name_ar: 'باكستان', name_en: 'Pakistan' },
  { id: 'Philippines', name_ar: 'الفلبين', name_en: 'Philippines' },
  { id: 'Qatar', name_ar: 'قطر', name_en: 'Qatar' },
  { id: 'Republic of Kore', name_ar: 'جمهورية كوريا', name_en: 'Republic of Kore' },
  { id: 'Saudi Arabia', name_ar: 'المملكة العربية السعودية', name_en: 'Saudi Arabia' },
  { id: 'Somalia', name_ar: 'الصومال', name_en: 'Somalia' },
  { id: 'South Africa', name_ar: 'جنوب أفريقيا', name_en: 'South Africa' },
  { id: 'Sudan', name_ar: 'السودان', name_en: 'Sudan' },
  { id: 'Syria', name_ar: 'سوريا', name_en: 'Syria' },
  { id: 'Tunisia', name_ar: 'تونس', name_en: 'Tunisia' },
  { id: 'Turkey', name_ar: 'تركيا', name_en: 'Turkey' },
  { id: 'United Arab Emirates', name_ar: 'الإمارات العربية المتحدة', name_en: 'United Arab Emirates' },
  { id: 'United Kingdom', name_ar: 'المملكة المتحدة', name_en: 'United Kingdom' },
  { id: 'United States', name_ar: 'الولايات المتحدة', name_en: 'United States' },
  { id: 'Yemen', name_ar: 'اليمن', name_en: 'Yemen' },
];
export const Businesses = [
  { id: 'Caterer', name: 'Caterer' },
  { id: 'Food Truck', name: 'Food Truck' },
  { id: 'Bakery', name: 'Bakery' },
  { id: 'Coffee Shop', name: 'Coffee Shop' },
  { id: 'Grocery', name: 'Grocery' },
  { id: 'Fine Dining', name: 'Fine Dining' },
  { id: 'Retail', name: 'Retail' },
  { id: 'Quick Service', name: 'Quick Service' },
  { id: 'Pizzeria', name: 'Pizzeria' },
  { id: 'Other', name: 'Other' },
];
