import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { fetch2, fetch3, fetch4 } from "../../Apis/commonApis";

// First API endpoint
export const gethBussinesType: any = createAsyncThunk(
  "data/gethBussinesType",
  async () => {
    console.log("hi");

    const response = await axios.get(
      `${process.env.REACT_APP_BASEURLAMRTEST}/settings/business-types`
    );
    console.log(response.data);

    return response.data;
  }
);

// Second API endpoint
export const getBussinesLocation: any = createAsyncThunk(
  "data/getBussinesLocation",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASEURLAMRTEST}/settings/countries`
    );
    console.log(response.data);

    return response.data.data;
  }
);

// Redux slice
const registerBussinesTypeAndLocation: any = createSlice({
  name: "data",
  initialState: {
    BussinesType: null,
    BussinesLocation: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // First API endpoint

      .addCase(gethBussinesType.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.BussinesType = action.payload.data;
      })

      // Second API endpoint

      .addCase(getBussinesLocation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.BussinesLocation = action.payload;
      });
  },
});

export default registerBussinesTypeAndLocation.reducer;
