import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetch6 } from "../../Apis/commonApis";
import { notify } from "../../constants/notifyconstants";

const initialState = {
  loading: false,
  subscription: null,
};

export const fetchSubscription: any = createAsyncThunk(
  "fetchSubscription",
  async (body) => {
    const result = await fetch6(
      `${process.env.REACT_APP_BASEURL}/manage/get_subscription_data`,
      body
    );
    return result;
  }
);

const manageMoreSubscriptionSlice: any = createSlice({
  name: "manageMoreSubscriptionSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSubscription.fulfilled]: (
      state: any,
      { payload: { error, message, data } }
    ) => {
      state.loading = false;
      state.subscription = data;
      if (error) {
        state.error = error;
      } else {
        state.error = message;
      }
    },
  },
});

export default manageMoreSubscriptionSlice.reducer;
