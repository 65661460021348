import React, { useCallback, useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateOnBoardState } from '../../redux_toolkit/reducer/onBoardingRideReducer';
import cx from '../../../src/components/Modals/Modals.module.scss';
import OnBoardingToolTipComp from './components/OnBoardingToolTipComp';
import { useOnBoardingSteps } from './hooks/useOnBoardingSteps';
import { IOnBoardingStep } from './types/types';

const OnBoardingRide: React.FC = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const onBoardingRideData = useSelector((state: any) => state.onBoardingRideReducer);
  const [isLoading, setIsLoading] = useState(false);
  // const rideSteps: any[] = onBoardingRideData.tourArray;

  // const Tooltip = ({ continuous, index, step, backProps, closeProps, primaryProps, skipProps, tooltipProps }: any) => (
  //   <OnBoardingToolTipComp
  //     step={step}
  //     index={index}
  //     steps={onBoardingRideData.tourArray}
  //     continuous={continuous}
  //     skipProps={skipProps}
  //     backProps={backProps}
  //     primaryProps={primaryProps}
  //     closeProps={closeProps}
  //     cx={cx}
  //     tooltipProps={tooltipProps}
  //   />
  // );
  // useEffect(() => {
  //   const pageHeight = Math.max(
  //     document.body.scrollHeight,
  //     document.body.offsetHeight,
  //     document.documentElement.clientHeight,
  //     document.documentElement.scrollHeight,
  //     document.documentElement.offsetHeight,
  //   );
  //   document.documentElement.style.setProperty('--overlay-height',  `${pageHeight + 1000}px`);
  // }, []);
  // useEffect(() => {
  //   if (onBoardingRideData?.tourArray[onBoardingRideData?.stepIndex]?.layOutHeight) {
  //     const style = document.createElement('style');
  //     style.textContent = `.react-joyride__overlay { height: ${
  //       onBoardingRideData.tourArray[onBoardingRideData.stepIndex].layOutHeight
  //     } !important; }`;
  //     document.head.append(style);
  //     return () => {
  //       style.remove();
  //     };
  //   }
  // }, [onBoardingRideData.tourArray[onBoardingRideData.stepIndex]]);

  const handleClickStart = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    console.log(e + 'clicked');
    dispatch(
      updateOnBoardState({
        tourActive: true,
        stepIndex: 0,
        run: true,
      }),
    );
  };
  const handleUpdateOnBoardState = useCallback(() => {
    dispatch(
      updateOnBoardState({
        run: true,
      }),
    );
    setIsLoading(false);
  }, []);
  const handleJoyrideCallback = useCallback((data: any) => {
    console.log(data);
    const { action, index, lifecycle, type, step } = data;
    if (step.showSubNavItem) {
      dispatch(
        updateOnBoardState({
          showSubNavItem: step.showSubNavItem || '',
        }),
      );
    }

    if (action === 'next' && type === 'step:after') {
      dispatch(
        updateOnBoardState({
          stepIndex: index + 1,
          // target: step.target,
        }),
      );
    }
    if (step.shouldNavigate && step.navigateUrl && action === 'next' && type === 'error:target_not_found') {
      dispatch(
        updateOnBoardState({
          run: false,
        }),
      );
      navigate(`${step.navigateUrl}`);
      setIsLoading(true);
      const timer = setTimeout(() => {
        handleUpdateOnBoardState();
      }, 1400);
      return () => clearTimeout(timer);
    }
    if (action === 'start' && type === 'tour:start') {
      setIsLoading(true);

      const timer = setTimeout(() => {
        handleUpdateOnBoardState();
      }, 1600);
      return () => clearTimeout(timer);
    }
    if (step.shouldNavigate && step.navigateUrl && action === 'start' && type === 'tour:start') {
      dispatch(
        updateOnBoardState({
          run: false,
        }),
      );
      navigate(`${step.navigateUrl}`);
      setIsLoading(true);
      const timer = setTimeout(() => {
        handleUpdateOnBoardState();
      }, 1400);
      return () => clearTimeout(timer);
    }

    if (action === 'prev' && type === 'step:after') {
      dispatch(
        updateOnBoardState({
          stepIndex: index - 1,
        }),
      );
    }

    if (action === 'prev' && type === 'error:target_not_found') {
      dispatch(
        updateOnBoardState({
          run: false,
        }),
      );
      navigate(`${step.navigateUrl}`);
      setIsLoading(true);
      const timer = setTimeout(() => {
        handleUpdateOnBoardState();

        setIsLoading(false);
      }, 1400);
      return () => clearTimeout(timer);
    }
    if (
      action === 'close' ||
      action === 'skip' ||
      lifecycle === 'tour:end' ||
      lifecycle === 'finished' ||
      type === 'tour:end'
      // (action === 'next' && type === 'step:after' && onBoardingRideData.tourArray - 1 === index)
    ) {
      dispatch(
        updateOnBoardState({
          stepIndex: 0,
          run: false,
          tourActive: false,
        }),
      );
    }
  }, []);

  return (
    <div className=''>
      {/* {isLoading ? <div className='loadingScreen'></div> : <div></div>} */}
      {/* <h1 className=''>Hello dot</h1>
      <div className='btn ' onClick={handleClickStart}>
        Try me!
      </div> */}
      <Joyride
        // tooltipComponent={Tooltip}
        continuous
        disableCloseOnEsc
        showSkipButton
        disableOverlayClose
        steps={onBoardingRideData.tourArray}
        run={onBoardingRideData.run}
        styles={{
          options: {
            zIndex: 9999,
            backgroundColor: 'white',
            primaryColor: '#004feeff',
          },
        }}
        callback={handleJoyrideCallback}
        stepIndex={onBoardingRideData.stepIndex}
        spotlightClicks={true}
      />
    </div>
  );
};

export default OnBoardingRide;
