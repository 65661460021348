import React from 'react';
import login from './RegisterPage.module.scss';
import { NormalBtn } from '../../components/buttons/NormalBtn';
import { t } from 'i18next';

function RegisterSuccess() {
  return (
    <>
      <section className={`${login.successContainer}`}>
        <div className={`${login.successWrapper}`}>
          <h3>{t('GLOBAL.CONGRATULATIONS_YOU_ARE_ALL_SET')}</h3>
          <h5>{t('GLOBAL.DOWNLOAD_THE_APP_AND_SIGN_IN')}</h5>
          <div>
            <p>{t('GLOBAL.ACCOUNT_IS_READY')}</p>

            <div className={`${login.successFooter}`}>
              <a
                href='https://apps.apple.com/in/app/dot-cashier/id6469693064'
                style={{
                  WebkitTextSizeAdjust: 'none',
                  textDecoration: 'none',
                  color: '#f6a1b4',
                  fontSize: 14,
                }}
              >
                <img
                  src='https://efqygta.stripocdn.email/content/guids/CABINET_e48ed8a1cdc6a86a71047ec89b3eabf6/images/92051534250512328.png'
                  alt='App Store'
                  style={{
                    display: 'block',
                    border: 0,
                    outline: 'none',
                    textDecoration: 'none',
                  }}
                  className='adapt-img'
                  title='App Store'
                  width={133}
                />
              </a>{' '}
              <NormalBtn className=' w-50' onClick={() => (window.location.href = '/login')}>
                {t('GLOBAL.LOGIN')}
              </NormalBtn>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RegisterSuccess;
