import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { fetch2, fetch5, fetch6, fetch10, fetch9 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';

const initialState = {
  loading: false,
  isFiltered: false,
  inventoryProductionListCount: 0,
  production_get_branchCount: 0,
  userListCount: 0,
  itemsListCount: 0,
  tagsListCount: 0,
  production_addproductionCount: 0,
  singleInventoryProductionDetailsCount: 0,
  inventoryProductionId: null,
  singleInventoryProductionDetails: {},
  inventoryProductionBranchId: null,
};

export let production_list_type_search: any = {
  type: 'all',
  admin_id: JSON.parse(localStorage.getItem('___data')!)?.id,
};

export let inventoryProductionItemsList: any;
export let addInventoryProductionType: any;
export let production_get_branch: any;
export let inventoryProductionTags: any;
export let inventoryProductionList: any = [];
export let userList: any = [];

export const fetchInventoryProductionList: any = createAsyncThunk('fetchInventoryProductionList', async body => {
  const result: any = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/production`, body);
  inventoryProductionList = result;
  return result;
});

export const production_addproduction: any = createAsyncThunk('production_addproduction', async body => {
  try {
    const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/production`, body);
    return result;
  } catch (error: any) {
    return error.response.data;
  }
});
export const deleteProuctionItem: any = createAsyncThunk('deleteProuctionItem', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/production/deleteItem/${body.id}`, {
    items: [body.items],
  });
  return result;
});
export const exportProduction: any = createAsyncThunk('exportProduction', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/export_production`, body);
  return result;
});
export const exportProductionItems: any = createAsyncThunk('exportProductionItems', async body => {
  const result = await fetch2(`${process.env.REACT_APP_BASEURL}/export_production_items`, body);
  return result;
});

export const filterInventoryProduction: any = createAsyncThunk('filterInventoryProduction', async body => {
  const response = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/production/filterProduction`, body);
  inventoryProductionList = response.data;
  return response.data;
});

export const inventoryProductionDetails: any = createAsyncThunk('inventoryProductionDetails', async (id: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/production/${id}`);

  // singleInventoryProductionDetails = result.data;

  // singleInventoryWarehouseItem = result.data.items;
  return result;
});
export const duplicateInventoryProduction: any = createAsyncThunk('duplicateInventoryProduction', async (id: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/production/duplicate/${id}`);

  return result;
});

export const deleteInventoryProduction: any = createAsyncThunk('deleteInventoryProduction', async (id: any) => {
  const result = await fetch10(`${process.env.REACT_APP_BASEURL}/inventory/production/${id}`);
  return result;
});

export const updateInventoryProduction: any = createAsyncThunk('updateInventoryProduction', async (body: any) => {
  const result = await fetch9(`${process.env.REACT_APP_BASEURL}/inventory/production/${body.id}`, body.body);
  return result;
});

export const submitInventoryProduction: any = createAsyncThunk('submitInventoryProduction', async (id: any) => {
  const result = await fetch6(`${process.env.REACT_APP_BASEURL}/inventory/production/produceItems/${id}`);
  return result;
});

export const addInventoryProductionItem: any = createAsyncThunk('addInventoryProductionItem', async (body: any) => {
  const result = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/production/addItems/${body.id}`, {
    items: body.items,
  });
  return result;
});
export const addInventoryProductionItemByTags: any = createAsyncThunk(
  'addInventoryProductionItemByTags',
  async (body: any) => {
    try {
      const result: any = await fetch5(`${process.env.REACT_APP_BASEURL}/inventory/production/addItemsTag/${body.id}`, {
        tags: body.tags,
      });
      return result;
    } catch (err: any) {
      return err.response.data;
    }
  },
);

export const updateInventoryProductionItem: any = createAsyncThunk(
  'updateInventoryProductionItem',
  async (body: any) => {
    const result = await fetch5(
      `${process.env.REACT_APP_BASEURL}/inventory/production/editItems/${body.id}`,
      body?.items,
    );
    return result;
  },
);

const inventoryProductionSlice: any = createSlice({
  name: 'InventoryProduction',
  initialState,
  reducers: {
    addType(state = initialState, action) {
      addInventoryProductionType = action.payload.name;
      // inventoryProductionId = action.payload.id;
    },

    productionBranchId: (state, action) => {
      state.inventoryProductionBranchId = action.payload;
    },
    restProductionId: (state, action) => {
      state.inventoryProductionId = null;
    },
    restProductionDetails: (state, action) => {
      state.singleInventoryProductionDetails = {};
    },
    changeIsFiltered(state = initialState, action: any) {
      state.isFiltered = action.payload;
    },
  },
  extraReducers: {
    [production_addproduction.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.production_addproductionCount += 1;
      state.inventoryProductionId = action.payload.data?.production?.id;

      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        if (action.payload.status) notify(action.payload.message, 'success');
        else notify(action.payload.message);
        state.error = action.payload.message;
        state.error = action.payload.message;
      }
    },
    [production_addproduction.rejected]: (state: any, action: any) => {
      notify("The selected tags have no items linked", "error")


    },
    [addInventoryProductionItem.fulfilled]: (state: any, action: any) => {
      state.loading = false;

      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        if (action.payload.status) notify(action.payload.message, 'success');
        else notify(action.payload.message);
        state.error = action.payload.message;
        state.error = action.payload.message;
      }
    },
    [duplicateInventoryProduction.fulfilled]: (state: any, action: any) => {
      state.loading = false;

      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        if (action.payload.status) notify(action.payload.message, 'success');
        else notify(action.payload.message);
        state.error = action.payload.message;
        state.error = action.payload.message;
      }
    },
    [addInventoryProductionItemByTags.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      console.log(action.payload);

      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        if (action.payload.status) notify(action.payload.message, 'success');
        else notify(action.payload.message);
        state.error = action.payload.message;
        state.error = action.payload.message;
      }
    },
    [updateInventoryProductionItem.fulfilled]: (state: any, action: any) => {
      state.loading = false;

      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        if (action.payload.status) notify(action.payload.message, 'success');
        else notify(action.payload.message);
        state.error = action.payload.message;
        state.error = action.payload.message;
      }
    },
    [deleteInventoryProduction.fulfilled]: (state: any, action: any) => {
      state.loading = false;

      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        if (action.payload.status) notify(action.payload.message, 'success');
        else notify(action.payload.message);
        state.error = action.payload.message;
        state.error = action.payload.message;
      }
    },
    [updateInventoryProduction.fulfilled]: (state: any, action: any) => {
      state.loading = false;

      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        if (action.payload.status) notify(action.payload.message, 'success');
        else notify(action.payload.message);
        state.error = action.payload.message;
        state.error = action.payload.message;
      }
    },
    [submitInventoryProduction.fulfilled]: (state: any, action: any) => {
      state.loading = false;

      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        if (action.payload.status) notify(action.payload.message, 'success');
        else notify(action.payload.message);
        state.error = action.payload.message;
        state.error = action.payload.message;
      }
    },
    [inventoryProductionDetails.pending]: (state: any, action: any) => {
      state.loading = true;



    },
    [inventoryProductionDetails.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.singleInventoryProductionDetails = action.payload.data;
      state.singleInventoryProductionDetailsCount += 1;

      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        if (!action.payload.status) notify(action.payload.message);
        state.error = action.payload.message;
        state.error = action.payload.message;
      }
    },

    [fetchInventoryProductionList.fulfilled]: (state: any, payload) => {
      state.loading = false;
      state.inventoryProductionListCount += 1;
      state.inventoryProductionId = null;
      if (payload?.error) {
        state.error = payload?.error;
      } else {
        state.error = payload?.message;
      }
    },
    [fetchInventoryProductionList.pending]: (state: any) => {
      state.loading = true;
    },
    [fetchInventoryProductionList.rejected]: (state: any) => {
      state.loading = false;
    },
    [filterInventoryProduction.fulfilled]: (state: any, payload) => {
      state.loading = false;
      state.inventoryProductionListCount += 1;
      if (payload?.error) {
        state.error = payload?.error;
      } else {
        state.error = payload?.message;
      }
    },
    [exportProduction.fulfilled]: (state: any, action) => {
      state.loading = false;
      if (isRejectedWithValue(action)) {
        state.error = action?.error.data.message;
      } else {
        state.error = action?.payload?.data;
        window.open(`${process.env.REACT_APP_EXPORTBASEURL}` + action?.payload.data, '_blank');
      }
    },
    [exportProductionItems.fulfilled]: (state: any, action) => {
      state.loading = false;
      if (isRejectedWithValue(action)) {
        state.error = action?.error.data.message;
      } else {
        state.error = action?.payload?.data;
        window.open(`${process.env.REACT_APP_EXPORTBASEURL}` + action?.payload.data, '_blank');
      }
    },
  },
});

export const { addType, productionBranchId, restProductionId, restProductionDetails, changeIsFiltered } =
  inventoryProductionSlice.actions;
export default inventoryProductionSlice.reducer;
