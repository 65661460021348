import {
  createSlice,
  createAsyncThunk,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { fetch2, fetch3, fetch5, fetch6 } from "../../Apis/commonApis";
import { notify } from "../../constants/notifyconstants";
import { RoundNum } from "../../utils/functions";

export const getPaymentReportsData: any = createAsyncThunk(
  "getPaymentReportsData",
  async (body: any) => {
    try {
      const response: any = await fetch6(
        `${process.env.REACT_APP_BASEURL}/reports/payments`,
        body
      );
      return response;
    } catch (error: any) {
      notify(error?.response?.data?.message, "error");
      throw error;
    }
  }
);
const initialState = {
  reportFilterObject: {
    branches: null,
    order_tags: null,
    branch_tags: null,
    payment_methods: null,
    group_by: null,
    type: null,
    date: null,
  },
  isFiltered: false,
  paymentReportData: [],
  paymentReportTotalRows: 0,
};

const paymentReportsSlice: any = createSlice({
  name: "paymentReportsSlice",
  initialState,
  reducers: {
    setFilterObjects: (state, action) => {
      state.isFiltered = true;
      state.reportFilterObject.branches =
        action.payload?.branches?.length > 0 ? action.payload.branches : null;
      state.reportFilterObject.order_tags =
        action.payload?.order_tags?.length > 0
          ? action.payload.order_tags
          : null;
      state.reportFilterObject.branch_tags =
        action.payload?.branch_tags?.length > 0
          ? action.payload.branch_tags
          : null;
      state.reportFilterObject.payment_methods =
        action.payload?.payment_methods || null;
      state.reportFilterObject.group_by = action.payload?.group_by || null;
      state.reportFilterObject.type = action.payload?.type || null;
      state.reportFilterObject.date = action.payload?.date || null;
      // console.log(action.payload?.group_by);
    },
    resetFilterObject: (state, action) => {
      state.isFiltered = false;
      state.reportFilterObject = initialState.reportFilterObject;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentReportsData.fulfilled, (state, action) => {
      state.paymentReportData = action.payload.data.map(
        (obj: { [key: string]: any }) => {
          Object.entries(obj).forEach(([key, value]: any) => {
            obj[key] = !isNaN(+value) ? RoundNum(+value) : value;
          });

          return obj;
        }
      );
      state.paymentReportTotalRows = action.payload.total;
    });
  },
});

export const { setFilterObjects, resetFilterObject } =
  paymentReportsSlice.actions;
export default paymentReportsSlice.reducer;
